import { Form, Typography } from 'antd';
import { useState } from 'react';
import './kpi.scss';

import { LeftOutlined } from '@ant-design/icons';
import { Button, Col, Input, InputNumber, Row, Select, Switch, message } from 'antd';
import type { Rule } from 'antd/es/form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  type CreateKpiRequest,
  KpiDetailCalculationType,
  KpiDetailParentType,
  KpiDetailType,
} from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import { getQueryParam } from 'src/utils/url-utils';
import KpiService from '../../services/pages/kpiService';
import { isUserAdmin } from '../shared/utils/authRolesProvilege/authRolesPrivilege';
import { kpiPuntualeAcumulato } from '../shared/utils/constants';
import { notifyMessages, requiredFields } from '../shared/utils/notifyMessages';

const BooleanJumpOffPoint = ({ disabled }: { disabled: boolean }) => {
  const { t } = useTranslation();

  return (
    <Form.Item<CreateKpiRequest>
      label={t('general.jumpOffPoint')}
      name="jumpOffPoint"
      valuePropName="checked"
      initialValue={false}
    >
      <Switch
        checkedChildren={t('kpiPage.fatto')}
        unCheckedChildren={t('kpiPage.nonFatto')}
        disabled={disabled}
      />
    </Form.Item>
  );
};

const DisabledJumpOfPoint = () => {
  const { t } = useTranslation();

  return (
    <Form.Item<CreateKpiRequest>
      label={t('general.jumpOffPoint')}
      name="jumpOffPoint"
      initialValue={null}
    >
      <InputNumber
        value={null}
        style={{ width: '100%' }}
        min="0"
        disabled={true}
      />
    </Form.Item>
  );
};

const NormalJumpOffPoint = ({ disabled }: { disabled: boolean }) => {
  const { t } = useTranslation();

  return (
    <Form.Item<CreateKpiRequest>
      label={t('general.jumpOffPoint')}
      name="jumpOffPoint"
      initialValue={0}
      rules={[
        { required: true, message: requiredFields.required },
        {
          validator: async (_, jumpOffPoint) => {
            if (typeof jumpOffPoint !== 'number') {
              return Promise.reject(new Error(`${t('kpiPage.jopNumber')}`));
            }
          },
        },
      ]}
    >
      <InputNumber
        style={{ width: '100%' }}
        min="0"
        disabled={disabled}
      />
    </Form.Item>
  );
};

const PercentageJumpOffPoint = ({ disabled }: { disabled: boolean }) => {
  const { t } = useTranslation();

  return (
    <Form.Item<CreateKpiRequest>
      label={t('general.jumpOffPoint')}
      name="jumpOffPoint"
      initialValue={0}
      rules={[
        { required: true, message: requiredFields.required },
        {
          validator: async (_, jumpOffPoint) => {
            if (jumpOffPoint > 100 || jumpOffPoint < 0 || isNaN(jumpOffPoint) || typeof jumpOffPoint !== 'number') {
              return Promise.reject(new Error(`${t('kpiPage.jopMax100Number')}`));
            }
          },
        },
      ]}
    >
      <InputNumber
        style={{ width: '100%' }}
        min={0}
        max={100}
        formatter={(value) => `${value} %`}
        disabled={disabled}
      />
    </Form.Item>
  );
};

const AddNewKpi = () => {
  const history = useHistory();

  const { t } = useTranslation();
  const companyData = useAppSelector((state) => state.companyData.companyData);
  const userData = useAppSelector((state) => state.userData.userData);

  const [loadingButton, loadingButtonSetter] = useState(false);
  const [fieldsChanged, fieldsChangedSetter] = useState(false);
  const [rollupChecked, rollupCheckedSetter] = useState(false);
  const [kpiTypeGlobale, kpiTypeGlobaleSetter] = useState<KpiDetailType>(KpiDetailType.Global);
  const [disableJumpOfPointResetGlobalKpi, disableJumpOfPointResetGlobalKpiSetter] = useState(false);
  const [selectedType, selectedTypeSetter] = useState<KpiDetailType>();
  const [selectedCumulatoPuntuale, selectedCumulatoPuntualeSetter] = useState<number>();
  const [jumpOffPointComponent, setJumpOffPointComponent] = useState(<NormalJumpOffPoint disabled={false} />);

  const createKpi = async (values: CreateKpiRequest) => {
    const isGlobalKpi = kpiTypeGlobale === KpiDetailType.Global;
    const isRollupKpi = rollupChecked;
    const committeeId = getQueryParam('committeeId') || undefined;

    const newKpi: CreateKpiRequest = {
      ...values,
      isRollupKpi,
      type: kpiTypeGlobale,
      jumpOffPoint: isGlobalKpi || isRollupKpi ? 0 : +values.jumpOffPoint,
      actualYTD: isRollupKpi ? 0 : values.actualYTD,
      committeeId,
    };

    loadingButtonSetter(true);

    try {
      const { data } = await KpiService.addKpi(newKpi);
      const { success, responseObject } = data;

      if (!success || !responseObject?.value) {
        throw new Error('Creation failed');
      }

      message.success(notifyMessages.addSuccess);
      history.goBack();
    } catch {
      message.error(notifyMessages.addFailed);
    }

    loadingButtonSetter(false);
  };

  const validateKpiName = async (_rule: Rule, kpiName: string, callback: (error?: string) => void) => {
    try {
      const response = await KpiService.checkIfKpiNameExist(kpiName);
      const { success, responseObject } = response.data;

      if (!success) {
        throw new Error('Validation failed');
      }

      if (responseObject?.value) {
        return callback(requiredFields.validateKpiName);
      }

      callback();
    } catch {
      message.error(notifyMessages.errorAccured);
    }
  };

  const onCalculationTypeChange = (type: KpiDetailCalculationType) => {
    if (type === KpiDetailCalculationType.IsCompleted) {
      setJumpOffPointComponent(<BooleanJumpOffPoint disabled={rollupChecked} />);
    } else if (type === KpiDetailCalculationType.CompletionPercentage) {
      setJumpOffPointComponent(<PercentageJumpOffPoint disabled={rollupChecked} />);
    } else {
      setJumpOffPointComponent(<NormalJumpOffPoint disabled={rollupChecked} />);
    }
  };

  const handleRollupChecked = (value: boolean) => {
    rollupCheckedSetter(value);
    if (value) {
      kpiTypeGlobaleSetter(KpiDetailType.Global);
      selectedTypeSetter(KpiDetailType.Global);
      checkDisableJumpOfPointResetGlobale();
    }
  };

  const checkDisableJumpOfPointResetGlobale = () => {
    if (
      companyData?.resetGlobalKPI &&
      selectedCumulatoPuntuale === kpiPuntualeAcumulato.acumulato &&
      selectedType === KpiDetailType.Global
    ) {
      disableJumpOfPointResetGlobalKpiSetter(true);
    } else {
      disableJumpOfPointResetGlobalKpiSetter(false);
    }
  };

  const onTypeChange = (value: KpiDetailType) => {
    selectedTypeSetter(value);
    checkDisableJumpOfPointResetGlobale();

    if (!rollupChecked) {
      kpiTypeGlobaleSetter(value);
    }
  };

  const onCumulatoPuntualeChange = (value: number) => {
    selectedCumulatoPuntualeSetter(value);
    checkDisableJumpOfPointResetGlobale();
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-16">
      <div className="tw-bg-white tw-border-2 tw-shrink-0 tw-gap-8 tw-border-b tw-flex tw-items-center tw-justify-between tw-min-h-6 tw-max-h-14 tw-py-2 tw-px-3">
        <div className="tw-flex tw-gap-2 tw-items-center tw-shrink-0">
          <Button
            type="text"
            onClick={() => history.goBack()}
            icon={<LeftOutlined />}
          ></Button>

          <Typography.Text className="tw-text-xl tw-font-semibold"> {t('kpiPage.aggiungiKpi')} </Typography.Text>
        </div>
      </div>

      <Form<CreateKpiRequest>
        layout="vertical"
        onFinish={createKpi}
        onValuesChange={() => {
          fieldsChangedSetter(true);
        }}
      >
        <Row style={{ justifyContent: 'center' }}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 16 }}
          >
            <Row gutter={{ lg: 24 }}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item<CreateKpiRequest>
                  name="isRollupKpi"
                  label={t('kpiPage.gerarchicho')}
                >
                  <Switch
                    defaultChecked={rollupChecked}
                    onChange={handleRollupChecked}
                  />
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item<CreateKpiRequest>
                  label={t('kpiPage.isVisible')}
                  name="isVisible"
                  initialValue={true}
                >
                  <Switch
                    defaultChecked={true}
                    disabled={!isUserAdmin(userData)}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={{ lg: 24 }}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item<CreateKpiRequest>
                  label={t('general.nome')}
                  name="name"
                  rules={[{ required: true, message: requiredFields.required }, { validator: validateKpiName }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 8 }}
              >
                <Form.Item<CreateKpiRequest>
                  label={t('kpiPage.tipoCalcolo')}
                  name="calculationType"
                  hasFeedback
                  rules={[{ required: true, message: requiredFields.required }]}
                >
                  <Select
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}
                    onChange={onCalculationTypeChange}
                  >
                    {Object.values(KpiDetailCalculationType).map((value) => (
                      <Select.Option
                        value={value}
                        key={value}
                      >
                        {t(`kpi.calculationTypes.${value}`)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 8 }}
              >
                {jumpOffPointComponent && !rollupChecked && !disableJumpOfPointResetGlobalKpi && jumpOffPointComponent}
                {(rollupChecked || disableJumpOfPointResetGlobalKpi) && <DisabledJumpOfPoint />}
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 8 }}
              >
                <Form.Item<CreateKpiRequest>
                  label={t('kpiPage.cumulatoPuntuale')}
                  name="parentType"
                  hasFeedback
                  rules={[{ required: true, message: requiredFields.required }]}
                >
                  <Select
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}
                    onChange={onCumulatoPuntualeChange}
                  >
                    {Object.values(KpiDetailParentType).map((value) => (
                      <Select.Option
                        value={value}
                        key={value}
                      >
                        {t(`kpi.parentTypes.${value}`)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 8 }}
              >
                <Form.Item<CreateKpiRequest>
                  label={t('kpiPage.unitaMisura')}
                  name="unitOfMeasure"
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 8 }}
              >
                <Form.Item<CreateKpiRequest>
                  label={t('general.actualYtd')}
                  name="actualYTD"
                >
                  <InputNumber
                    min="0"
                    style={{ width: '100%' }}
                    disabled={!rollupChecked}
                  />
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 8 }}
              >
                <Form.Item<CreateKpiRequest>
                  label={t('general.tipo')}
                  name="type"
                  hasFeedback
                  rules={[{ required: !rollupChecked, message: requiredFields.required }]}
                >
                  <Select
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}
                    disabled={rollupChecked}
                    onChange={(value) => !rollupChecked && value && onTypeChange(value)}
                  >
                    {Object.values(KpiDetailType).map((value) => (
                      <Select.Option
                        value={value}
                        key={value}
                      >
                        {t(`kpi.types.${value}`)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <Form.Item
                  label={t('general.nota')}
                  name="additionalNote"
                >
                  <Input.TextArea rows={4} />
                </Form.Item>
              </Col>
            </Row>

            <Row justify={'end'}>
              <Button
                loading={loadingButton}
                type="primary"
                htmlType="submit"
                disabled={!fieldsChanged}
              >
                {t('buttons.salva')}
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddNewKpi;
