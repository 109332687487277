import {
  CheckOutlined,
  DeleteOutlined,
  EditFilled,
  ExportOutlined,
  FilterOutlined,
  MinusSquareOutlined,
  PlusOutlined,
  SearchOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Avatar, Button, DatePicker, Input, Popconfirm, Popover, Space, Table, Tag, Tooltip, message } from 'antd';
import ButtonGroup from 'antd/es/button/button-group';
import classNames from 'classnames';
import moment from 'moment';
import { Component } from 'react';
import ProjectSectionTitle from 'src/components/shared/components/ProjectSectionTitle/ProjectSectionTitle';
import CommonService from '../../../../services/commonService';
import ActivityService from '../../../../services/pages/activitiesServices';
import TableLayout from '../../../shared/tableLayout';
import { userIsProjectTeamLeaderOrSponsor } from '../../../shared/utils/authRolesProvilege/authRolesPrivilege';
import { activityStatus, formatOfDate, statusPickListCodes } from '../../../shared/utils/constants';
import {
  ResponsiblePopoverContent,
  checkMyActivitiesStatus,
  getActivityIcon,
  projectTypeColumnValues,
} from '../../../shared/utils/functions';
import { notifyMessages } from '../../../shared/utils/notifyMessages';
import '../../project.scss';
import { DeliverableIcon, TaskIcon } from '../deliverablesAndKpi/kanban-view/components/icons';
import EditProjectActivity from './editProjectActivity';
import NewProjectActivity from './newProjectActivity';

class ProjectActivities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      loadingActivities: false,
      projectActivityList: null,
      projectActivityListFiltered: null,
      showEditModal: false,
      showCreateModal: false,
      modalData: null,
      startDate: null,
      endDate: null,
      clearFilters: false,
      statusListFilter: [],
      filteredInfo: [`${props.t('status.attivo')}`],
      activitiesFilter: 'activities',
    };
  }

  componentWillMount() {
    this.retrieveProjectActivityList();
    this.retrieveStatusList();
  }

  async retrieveStatusList() {
    const objectCode = statusPickListCodes.activityStatus;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const obj = [];
          resp.responseObject.value.map((item) => {
            obj.push({ text: item.statusDescription, value: item.statusDescription });
          });

          this.setState({ statusListFilter: obj });
        } else {
        }
      })
      .catch((error) => {});
  }

  componentWillUnmount() {
    this.setState({ loadingActivities: false });
  }

  async retrieveProjectActivityList() {
    const { startDate, endDate, clearFilters } = this.state;
    const { projectId } = this.props;

    let formatedStartDate = this.handleDateChange(startDate);
    let formatedEndDate = this.handleDateChange(endDate);

    if (clearFilters) {
      this.setState({ startDate: null });
      this.setState({ endDate: null });
      formatedStartDate = null;
      formatedEndDate = null;
    }

    this.setState({ loadingActivities: true });
    await ActivityService.getProjectActivities(projectId, formatedStartDate, formatedEndDate)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const respData = resp.responseObject.value;
          this.setState({ projectActivityList: respData });
          this.setState({ projectActivityListFiltered: respData.filter((obj) => obj.activityType === 'activity') });
          this.setState({ loadingActivities: false });
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingActivities: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingActivities: false });
      });
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    this.setState({ searchText: '' });
  };

  toggleEditProjectAtivityModal = (rowData) => {
    this.setState({ showEditModal: true });
    this.setState({ modalData: rowData });
  };

  handleEditModalClose = () => {
    this.setState({ showEditModal: false });
    this.setState({ loadingButton: false });
  };

  handleUpdateProjectActivity = (values, activityID, projectID) => {
    values['activityID'] = activityID;
    values['projectID'] = projectID;
    values.startDate = this.handleDateChange(values.startDate);
    values.endDate = this.handleDateChange(values.endDate);
    this.setState({ loadingButton: true });
    ActivityService.updateActivity(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.handleEditModalClose();
          message.success(notifyMessages.updateSuccess);
          this.retrieveProjectActivityList();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  toggleCreateProjectAtivityModal = () => {
    this.setState({ showCreateModal: true });
  };

  handleCreateModalClose = () => {
    this.setState({ showCreateModal: false });
    this.setState({ loadingButton: false });
  };

  handleSaveProjectActivity = (values, projectID) => {
    values['projectID'] = projectID;
    values.startDate = this.handleDateChange(values.startDate);
    values.endDate = this.handleDateChange(values.endDate);

    this.setState({ loadingButton: true });
    ActivityService.insertActivity(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.handleCreateModalClose();
          message.success(notifyMessages.addSuccess);
          this.retrieveProjectActivityList();
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingButton: false });
      });
  };

  handleDateChange(date) {
    if (date) {
      const formatedDate = moment(date).format('YYYY-MM-DD');
      return formatedDate;
    }
    return null;
  }

  onStartDateChange = (date) => {
    this.setState({ startDate: date });
  };

  onEndDateChange = (date) => {
    this.setState({ endDate: date });
  };

  onFilter = () => {
    this.setState({ clearFilters: false }, () => {
      this.retrieveProjectActivityList();
    });
  };

  onClearFilter = () => {
    this.setState({ clearFilters: true }, () => {
      this.retrieveProjectActivityList();
    });
  };

  onRemoveActivity = (id) => {
    ActivityService.removeActivity(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.deleteSuccess);
          this.retrieveProjectActivityList();
        } else {
          message.error(notifyMessages.deleteFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.deleteFailed);
      });
  };

  handleCloseProjectActivity = (record) => {
    const { projectId } = this.props;
    const values = {};

    values['activityID'] = record.activityID;
    values['deliverableID'] = record.deliverableID;
    values['description'] = record.description;
    values['endDate'] = this.handleDateChange(record.endDate);
    values['ownerID'] = record.ownerID;
    values['projectID'] = projectId;
    values['startDate'] = this.handleDateChange(record.startDate);
    values['status'] = activityStatus.closed;
    values['subject'] = record.subject;

    this.setState({ loadingButton: true });
    ActivityService.updateActivity(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ loadingButton: false });
          message.success(notifyMessages.updateSuccess);
          this.retrieveProjectActivityList();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  dateFilters = () => {
    const { t } = this.props;
    const { startDate, endDate } = this.state;
    return (
      <>
        <DatePicker
          placeholder={t('general.start')}
          value={startDate}
          style={{ width: '100%' }}
          format="DD/MM/YYYY"
          onChange={(date) => this.onStartDateChange(date)}
        />
        <DatePicker
          placeholder={t('general.end')}
          value={endDate}
          style={{ width: '100%' }}
          format="DD/MM/YYYY"
          onChange={(date) => this.onEndDateChange(date)}
        />
        <Tooltip title={t('general.filter')}>
          <Button
            icon={<FilterOutlined />}
            onClick={() => this.onFilter()}
            disabled={!startDate && !endDate}
          />
        </Tooltip>
        <Tooltip title={t('general.clearFilters')}>
          <Button
            className="tw-mr-4"
            icon={<MinusSquareOutlined />}
            onClick={() => this.onClearFilter()}
          />
        </Tooltip>
        <ButtonGroup>
          <Button
            className={
              this.state.activitiesFilter === 'activities' && classNames(['tw-border-blue-500', 'tw-text-primary'])
            }
            onClick={() => this.filterActivities('activities')}
          >
            <TaskIcon />
            {t('proggetiPage.attivitaFilter')}
          </Button>
          <Button
            className={this.state.activitiesFilter === 'prs' && classNames(['tw-border-blue-500', 'tw-text-primary'])}
            onClick={() => this.filterActivities('prs')}
          >
            <DeliverableIcon />
            {t('proggetiPage.deliverableFilter')}
          </Button>
          <Button
            className={this.state.activitiesFilter === 'all' && classNames(['tw-border-blue-500', 'tw-text-primary'])}
            onClick={() => this.filterActivities('all')}
          >
            {t('proggetiPage.allFilter')}
          </Button>
        </ButtonGroup>
      </>
    );
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters.statusDescription,
    });
  };

  filterActivities = (type) => {
    const { projectActivityList } = this.state;

    this.setState({ activitiesFilter: type });

    if (type === 'activities') {
      const filteredData = projectActivityList && projectActivityList.filter((obj) => obj.activityType === 'activity');
      this.setState({ projectActivityListFiltered: filteredData });
    } else if (type === 'prs') {
      const filteredData =
        projectActivityList && projectActivityList.filter((obj) => obj.activityType === 'deliverable' || obj.activityType === 'macroActivity');
      this.setState({ projectActivityListFiltered: filteredData });
    } else {
      this.setState({ projectActivityListFiltered: projectActivityList });
    }
  };

  render() {
    const {
      projectActivityListFiltered,
      loadingActivities,
      loadingButton,
      showCreateModal,
      showEditModal,
      modalData,
      statusListFilter,
      filteredInfo,
    } = this.state;
    const { projectId, projectName, hasUserAccess, currentProject, t, userData } = this.props;

    const projectActivitiesColumns = [
      // {
      //     title: 'Correlato?',
      //     dataIndex: 'isRelatedToProject',
      //     width:"8%",
      //     filters: [
      //         { text: 'True', value: 1 },
      //         { text: 'False', value: 0 },
      //       ],
      //       sorter: (a, b) => { return a.isRelatedToProject.toString().localeCompare(b.isRelatedToProject.toString()) },
      //       onFilter: (value, record) => record.isRelatedToProject === value,
      //       showSorterTooltip: [false],
      //       render: (text, record, index) => {
      //         if (text === 1) {
      //           return (<CheckOutlined style={{ color: "#0dcc2d" }} />)
      //         }
      //         else {
      //           return (<MinusOutlined  style={{ color: "#ababab" }} />)
      //         }
      //       }

      // },
      {
        title: `${t('general.tipo')}`,
        dataIndex: 'activityType',
        width: '10%',
        showSorterTooltip: [false],
        render(title, record) {
          return {
            props: {
              style: {
                borderLeft: '7px solid' + checkMyActivitiesStatus(record),
              },
            },
            children: (
              <div className="tw-flex tw-gap-x-1">
                {getActivityIcon(record.activityType, t)}
                <span>{projectTypeColumnValues(record, t)}</span>
              </div>
            ),
          };
        },
      },
      {
        title: `${t('general.descrizione')}`,
        dataIndex: 'subject',
        overflowWrap: 'break-word',
        sorter: (a, b) => {
          a = a.subject || '';
          b = b.subject || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('subject'),
        render(title, record) {
          return <Tooltip title={title}>{title}</Tooltip>;
        },
        render(title, record) {
          return {
            children: <Tooltip title={title}>{title}</Tooltip>,
          };
        },
      },
      {
        title: `${t('proggetiPage.owner')}`,
        dataIndex: 'fullName',
        width: '200px',
        sorter: (a, b) => {
          a = a.fullName || '';
          b = b.fullName || '';
          return a.localeCompare(b);
        },
        ...this.getColumnSearchProps('fullName'),
        showSorterTooltip: [false],
        render: (title, record) => (
          <Popover
            content={
              <ResponsiblePopoverContent
                data={record}
                t={t}
              />
            }
            title={title}
          >
            <div className="table-avatar">
              <Avatar
                size={'small'}
                // style={record.isGenericResponsabile === true ? { color: '#f2883b' } : {}}
              >
                {title?.charAt(0)}
              </Avatar>
              {title}
            </div>
          </Popover>
        ),
      },
      {
        title: `${t('general.stato')}`,
        dataIndex: 'statusDescription',
        width: '14ch',
        sorter: (a, b) => {
          a = a.statusDescription || '';
          b = b.statusDescription || '';
          return a.localeCompare(b);
        },
        filters: statusListFilter,
        defaultFilteredValue: filteredInfo || null,
        onFilter: (value, record) => (record.statusDescription ? record.statusDescription.includes(value) : ''),
        render(text, record) {
          return {
            children: <Tag>{text}</Tag>,
          };
        },
      },
      // {
      //     title: `${t('general.deliverable')}`,
      //     dataIndex: 'deliverableName',
      //     width: "15%",
      //     sorter: (a, b) => {
      //       a = a.deliverableName || '';
      //       b = b.deliverableName || '';
      //       return a.localeCompare(b)
      //     },
      //     showSorterTooltip: [false],
      //     ...getColumnSearchProps('deliverableName'),
      //     ellipsis: {
      //       showTitle: false,
      //     },
      //     render: title => (
      //       <Tooltip  title={title}>
      //         {title}
      //       </Tooltip>
      //     )
      //   },
      {
        title: `${t('general.start')}`,
        dataIndex: 'startDate',
        width: '16ch',
        sorter: (a, b) => {
          a = a.startDate || '';
          b = b.startDate || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('startDate'),
        render: (text, record) => moment(text).format(formatOfDate),
      },
      {
        title: `${t('general.end')}`,
        dataIndex: 'endDate',
        width: '16ch',
        sorter: (a, b) => {
          a = a.endDate || '';
          b = b.endDate || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('endDate'),
        render: (text, record) => moment(text).format(formatOfDate),
      },
      {
        title: `${t('general.dataChiusura')}`,
        dataIndex: 'actualCloseDate',
        width: '16ch',
        sorter: (a, b) => {
          a = a.actualCloseDate || '';
          b = b.actualCloseDate || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('actualCloseDate'),
        render: (text) => (text ? moment(text).format(formatOfDate) : '-'),
      },
      {
        key: 'action',
        width: '90px',
        render: (_, record) => {
          const isClosed = record.status === activityStatus.closed;
          const quickClose = record.status === activityStatus.active || record.status === activityStatus.draft;
          const hasAccess =
            record.hasAccess ||
            userIsProjectTeamLeaderOrSponsor(userData, currentProject.teamLeaderID, currentProject.sponsorID);

          if (!hasAccess) {
            return null;
          }

          return (
            <div className="tw-flex tw-gap-2">
              {record.deliverableID ? (
                <Button
                  icon={<ExportOutlined />}
                  href={`/deliverables/id/${record.deliverableID}`}
                />
              ) : (
                <Button
                  icon={<EditFilled title={t('buttons.modificaAttivita')} />}
                  onClick={() => this.toggleEditProjectAtivityModal(record)}
                />
              )}

              {!isClosed && (
                <Popconfirm
                  key="remove"
                  title={t('buttons.rimuoveAttivita')}
                  icon={<WarningOutlined />}
                  okText={t('general.si')}
                  cancelText={t('general.no')}
                  onConfirm={() => this.onRemoveActivity(record.activityID)}
                >
                  <Button
                    className="tw-text-red-500"
                    icon={<DeleteOutlined title={t('buttons.rimuoveAttivita')} />}
                  />
                </Popconfirm>
              )}

              {quickClose && (
                <Popconfirm
                  key="close"
                  placement="topRight"
                  title={t('leMieAttivitaPage.chiudiQuestaAttivita')}
                  icon={<WarningOutlined />}
                  okText={t('general.si')}
                  cancelText={t('general.no')}
                  onConfirm={() => this.handleCloseProjectActivity(record)}
                >
                  <Tooltip title={t('buttons.chiusuraRapida')}>
                    <Button icon={<CheckOutlined style={{ color: '#16a34a' }} />} />
                  </Tooltip>
                </Popconfirm>
              )}
            </div>
          );
        },
      },
    ];

    return (
      <TableLayout title={<ProjectSectionTitle title={t('proggetiPage.toDoProject')} />}>
        <TableLayout.Actions>
          {showCreateModal && (
            <NewProjectActivity
              showCreateModal={showCreateModal}
              handleCreateModalClose={this.handleCreateModalClose}
              handleSaveProjectActivity={this.handleSaveProjectActivity}
              loadingButton={loadingButton}
              projectID={projectId}
              projectName={projectName}
              currentProject={currentProject}
              t={t}
            />
          )}

          {showEditModal && (
            <EditProjectActivity
              showEditModal={showEditModal}
              projectActivitiesColumns
              handleEditModalClose={this.handleEditModalClose}
              handleUpdateProjectActivity={this.handleUpdateProjectActivity}
              loadingButton={loadingButton}
              modalData={modalData}
              projectID={projectId}
              projectName={projectName}
              currentProject={currentProject}
              t={t}
            />
          )}
          {this.dateFilters()}

          {!hasUserAccess ? (
            <div className="tw-ml-4">
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => this.toggleCreateProjectAtivityModal()}
              >
                <PlusOutlined /> {t('buttons.aggiungiNuovo')}{' '}
              </Button>
            </div>
          ) : null}
        </TableLayout.Actions>
        <TableLayout.Content>
          <Table
            columns={
              hasUserAccess
                ? projectActivitiesColumns.filter((el) => {
                    return el.dataIndex !== 'action2' && el.dataIndex !== 'action';
                  })
                : projectActivitiesColumns
            }
            dataSource={projectActivityListFiltered}
            rowKey={(obj) => obj.activityID}
            loading={loadingActivities}
            onChange={this.handleChange}
            size="small"
            // pagination={{ showSizeChanger: true, defaultPageSize: 50}}
            pagination={false}
          />
        </TableLayout.Content>
      </TableLayout>
    );
  }
}

export default ProjectActivities;
