import { useCallback, useEffect, useState } from 'react';

type UseZoomOptions = {
  minZoom?: number; // Dimensione minima in pixel
  maxZoom?: number; // Dimensione massima in pixel
  step?: number; // Incremento in pixel
  defaultZoom?: number; // Dimensione iniziale in pixel
};

export const usePresentationZoom = ({
  minZoom = 16,
  maxZoom = 22,
  step = 2,
  defaultZoom = 16,
}: UseZoomOptions = {}) => {
  const [zoomLevel, setZoomLevel] = useState<number>(defaultZoom);

  const updateBodyClass = useCallback(
    (level: number) => {
      if (level !== defaultZoom) {
        document.body.classList.add('zoom-active');
        document.body.style.setProperty('--zoom-level', `${level}px`);
      } else {
        document.body.classList.remove('zoom-active');
        document.body.style.removeProperty('--zoom-level');
      }
    },
    [defaultZoom],
  );

  const zoomIn = useCallback(() => {
    setZoomLevel((prev) => Math.min(prev + step, maxZoom));
  }, [step, maxZoom]);

  const zoomOut = useCallback(() => {
    setZoomLevel((prev) => Math.max(prev - step, minZoom));
  }, [step, minZoom]);

  const resetZoom = useCallback(() => {
    setZoomLevel(defaultZoom);
  }, [defaultZoom]);

  const setZoom = useCallback(
    (value: number) => {
      setZoomLevel(Math.min(Math.max(value, minZoom), maxZoom));
    },
    [minZoom, maxZoom],
  );

  useEffect(() => {
    updateBodyClass(zoomLevel);
  }, [zoomLevel, updateBodyClass]);

  return {
    zoomLevel,
    zoomIn,
    zoomOut,
    resetZoom,
    setZoom,
  };
};
