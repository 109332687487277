import { Form } from 'antd';
import React from 'react';
import './deliverable.scss';

import { InfoCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, InputNumber, Row, Select, Statistic, Switch, Table, Tooltip, message } from 'antd';
import moment from 'moment';
import DeliverableService from '../../../../services/pages/projectServices/deliverableServices';
import Loader2 from '../../../shared/components/loader2/loader2';
import { userIsProjectTeamLeaderOrSponsorOrAdmin } from '../../../shared/utils/authRolesProvilege/authRolesPrivilege';
import { bowlingChartRowName, kpiPuntualeAcumulato, kpiType } from '../../../shared/utils/constants';
import {
  AddSingelSpace,
  alignCurrentMonthCellOnKpiValuesGrid,
  capitalizeFirstLetter,
  checkUserIsKpiResponsabileOrProjectTM,
  inputFormatter,
  inputParser,
  italianNumberFormat,
} from '../../../shared/utils/functions';
import { notifyMessages } from '../../../shared/utils/notifyMessages';
import {
  actualYtdRow,
  currentMonthOfActualYtd,
  currentMonthOfTargetYtd,
  firstMonthOfActual,
  firstMonthOfActualYtd,
  firstMonthOfTarget,
  firstMonthOfTargetYtd,
  lastMonthOfTargetYtd,
  previousMonthOfActualYtd,
  previousMonthOfTargetYtd,
  targetYtdRow,
} from './calculations';
const { Option } = Select;

class KpiValorePercentuale extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      parentType: props.kpiRowData.parentType,
      jumpOffPointValue: props.kpiRowData.jumpOffPoint,
      kpiProjectRelationShipID: props.kpiRowData.kpiProjectRelationShipID,
      finalTargetValue: '',
      targetYtdValue: '',
      actualYtdValue: '',
      showInA3: props.kpiRowData.showInA3,
      fieldsChanged: false,
      loadingButton: false,
      kpiList: null,
      definedTarget: props.kpiRowData.freezeTargetValues,
      kpiData: null,
      bowlingChartData: null,
      loadingData: false,

      hasError: false,
      kpiFieldsChanged: false,
      selectedResponsabile: props.kpiRowData.responsibleID,
    };
  }

  componentDidMount() {
    this.getKpiBowlingChart();
  }

  errorTooltip = () => {
    const { t } = this.props;
    return (
      <Tooltip title={t('kpiPage.percentageLimit')}>
        <InfoCircleOutlined />
      </Tooltip>
    );
  };

  checkTargetPercentageLimit = (text) => {
    if (text > 100 || text < 0) {
      // let button = document.getElementById("saveDataButton");
      // button.disabled = true;
      return true;
    } else {
      // let button = document.getElementById("saveDataButton");
      // button.disabled = false;
      return false;
    }
  };

  checkTargetYtdPercentageLimit = (text) => {
    if (text > 100 || text < 0) {
      const button = document.getElementById('saveDataButton');
      button.disabled = true;
      return true;
    } else {
      const button = document.getElementById('saveDataButton');
      button.disabled = false;
      return false;
    }
  };

  calculateKpiTargetsAndActuals = (bowlingChartData) => {
    if (bowlingChartData) {
      this.calculateFinalTarget(this.state.jumpOffPointValue);
      //this.calculateTargetYtdRow();
    }
  };

  async getKpiBowlingChart() {
    const { projectId, kpiRowData } = this.props;
    this.setState({ loadingData: true });
    await DeliverableService.getKpiBowlingChart(kpiRowData.kpiProjectRelationShipID, projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ bowlingChartData: resp.responseObject.value }, () => {
            this.calculateKpiTargetsAndActuals(this.state.bowlingChartData);
            this.calculateTargetYTD(this.state.bowlingChartData);
            this.calculateActualYTD(this.state.bowlingChartData);
          });
          this.setState({ loadingData: false });

          alignCurrentMonthCellOnKpiValuesGrid();
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingData: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingData: false });
      });
  }

  onConfirm = () => {
    const {
      bowlingChartData,
      jumpOffPointValue,
      kpiProjectRelationShipID,
      finalTargetValue,
      showInA3,
      definedTarget,
      selectedResponsabile,
    } = this.state;
    const { calculationType } = this.props;
    this.props.onSave(
      bowlingChartData,
      jumpOffPointValue,
      kpiProjectRelationShipID,
      calculationType,
      finalTargetValue,
      showInA3,
      definedTarget,
      selectedResponsabile,
    );
  };

  renderTargetColumns = (tableData) => {
    const { kpiRowData } = this.props;
    const { definedTarget } = this.state;

    if (tableData && tableData.length > 0) {
      const columns = [];

      const firstRow = tableData[0];

      for (const col in firstRow) {
        if (col === 'Name') {
          columns.push({
            dataIndex: 'Name',
            fixed: 'left',
            title: '',
            // width: 10,
            render: (text) => AddSingelSpace(text, 6).join(' '),
          });
        } else {
          const currentMonth = moment().format('YYYY-MM');
          const month = moment(col).format('MMM YYYY');
          columns.push({
            dataIndex: col,
            className: col === currentMonth ? 'currentMonthBorderLineIndicatorTarget' : '',
            title: capitalizeFirstLetter(month),
            // width: 20,
            render: (text, record, index) => {
              if (kpiRowData.parentType === kpiPuntualeAcumulato.puntuale) {
                return record.Name === bowlingChartRowName.target ? (
                  <Form.Item
                    validateStatus={this.checkTargetPercentageLimit(text) ? 'error' : 'success'}
                    help={this.checkTargetPercentageLimit(text) && this.errorTooltip()}
                  >
                    <InputNumber
                      disabled={kpiRowData.isRollupKPI || definedTarget}
                      min={0}
                      max={100}
                      formatter={(value) => `${value} %`}
                      size="small"
                      style={{ width: '100%' }}
                      value={text}
                      defaultValue={text}
                      onChange={(value) => this.onTargetChangePuntuale(col, text, record, value)}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    validateStatus={this.checkTargetPercentageLimit(text) ? 'error' : 'success'}
                    help={this.checkTargetPercentageLimit(text) && this.errorTooltip()}
                  >
                    <InputNumber
                      disabled={true}
                      min={0}
                      max={100}
                      formatter={(value) => `${value} %`}
                      size="small"
                      style={{ width: '100%' }}
                      value={text}
                      defaultValue={text}
                      onChange={(value) => this.onTargetYtdChangePuntuale(col, text, record, value)}
                    />
                  </Form.Item>
                );
              } else {
                return record.Name === bowlingChartRowName.target ? (
                  <Form.Item
                    validateStatus={this.checkTargetPercentageLimit(text) ? 'error' : 'success'}
                    help={this.checkTargetPercentageLimit(text) && this.errorTooltip()}
                  >
                    <InputNumber
                      disabled={kpiRowData.isRollupKPI || definedTarget}
                      min={0}
                      max={100}
                      formatter={(value) => `${value} %`}
                      size="small"
                      style={{ width: '100%' }}
                      value={text}
                      defaultValue={text}
                      onChange={(value) => this.onTargetChange(col, text, record, value)}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    validateStatus={this.checkTargetPercentageLimit(text) ? 'error' : 'success'}
                    help={this.checkTargetPercentageLimit(text) && this.errorTooltip()}
                  >
                    <InputNumber
                      disabled={kpiRowData.isRollupKPI || definedTarget}
                      min={0}
                      max={100}
                      formatter={(value) => `${value} %`}
                      size="small"
                      style={{ width: '100%' }}
                      value={text}
                      defaultValue={text}
                      onChange={(value) => this.onTargetYtdChange(col, text, record, value)}
                    />
                  </Form.Item>
                );
              }
            },
          });
        }
      }

      return columns;
    }
  };

  renderActualColumns = (tableData) => {
    const { kpiRowData } = this.props;

    if (tableData) {
      const columns = [];

      if (tableData.length > 0) {
        const firstRow = tableData[0];

        for (const col in firstRow) {
          if (col === 'Name') {
            columns.push({
              dataIndex: 'Name',
              fixed: 'left',
              title: '',
              // width: 10,
              render: (text) => AddSingelSpace(text, 6).join(' '),
            });
          } else {
            const currentMonth = moment().format('YYYY-MM');
            const month = moment(col).format('MMM YYYY');
            columns.push({
              className: col === currentMonth ? 'currentMonthBorderLineIndicatorActual' : '',
              dataIndex: col,
              // width: 20,
              title: capitalizeFirstLetter(month),
              render: (text, record, index) => {
                if (kpiRowData.parentType === kpiPuntualeAcumulato.puntuale) {
                  return record.Name === bowlingChartRowName.actual ? (
                    <Form.Item>
                      <InputNumber
                        disabled={kpiRowData.isRollupKPI}
                        min={0}
                        max={100}
                        formatter={(value) => `${value} %`}
                        size="small"
                        style={{ width: '100%' }}
                        value={text}
                        defaultValue={text}
                        onChange={(value) => this.onActualChangePuntuale(col, text, record, value)}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item>
                      <InputNumber
                        disabled={true}
                        min={0}
                        max={100}
                        formatter={(value) => `${value} %`}
                        size="small"
                        style={{ width: '100%' }}
                        value={text}
                        defaultValue={text}
                        onChange={(value) => this.onActualYtdChangePuntuale(col, text, record, value)}
                      />
                    </Form.Item>
                  );
                } else {
                  return record.Name === bowlingChartRowName.actual ? (
                    <Form.Item>
                      <InputNumber
                        disabled={kpiRowData.isRollupKPI}
                        min={0}
                        max={100}
                        formatter={(value) => `${value} %`}
                        size="small"
                        style={{ width: '100%' }}
                        value={text}
                        defaultValue={text}
                        onChange={(value) => this.onActualChange(col, text, record, value)}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item>
                      <InputNumber
                        disabled={kpiRowData.isRollupKPI}
                        min={0}
                        max={100}
                        formatter={(value) => `${value} %`}
                        size="small"
                        style={{ width: '100%' }}
                        value={text}
                        defaultValue={text}
                        onChange={(value) => this.onActualYtdChange(col, text, record, value)}
                      />
                    </Form.Item>
                  );
                }
              },
            });
          }
        }
      }

      return columns;
    }
  };

  getMonthByDate = (date) => {
    const dateToFormat = moment(date, 'DD-MM-YYYY');
    const monthName = dateToFormat.format('MMMM');
    return monthName;
  };

  onTargetChange = (selectedMonth, oldValue, row, newValue) => {
    const { bowlingChartData, jumpOffPointValue } = this.state;
    const { companyData, kpiRowData } = this.props;
    this.setState({ kpiFieldsChanged: true });

    const targetRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.target)[0];
    const targetFirstMonth = firstMonthOfTarget(bowlingChartData);
    targetRow[selectedMonth] = newValue;
    const isResetGlobalKPI =
      companyData.resetGlobalKPI &&
      kpiRowData.parentType === kpiPuntualeAcumulato.acumulato &&
      kpiRowData.kpiType === kpiType.globale
        ? true
        : false;
    const selectedYear = moment(selectedMonth).year();
    const firstMonthOfSelectedYear = moment(selectedYear, 'YYYY-MM');
    const firstMonthOfSelectedYearFormated = moment(firstMonthOfSelectedYear).format('YYYY-MM');

    for (const key in targetRow) {
      if (key !== 'Name') {
        if (key === selectedMonth) {
          if (key === targetFirstMonth.monthName) {
            const targetYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.targetYtd)[0];
            targetYtdRow[key] = newValue + jumpOffPointValue;
          } else if (isResetGlobalKPI && key === firstMonthOfSelectedYearFormated) {
            const targetYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.targetYtd)[0];
            targetYtdRow[key] = newValue;
          } else {
            const targetYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.targetYtd)[0];
            const previousTargetYtdMonth = previousMonthOfTargetYtd(bowlingChartData, key);
            const previousTargetYtdMonthValue = targetYtdRow[previousTargetYtdMonth.monthName];
            targetYtdRow[key] = previousTargetYtdMonthValue + newValue;
          }
        }

        if (moment(key, 'YYYY-MM') > moment(selectedMonth, 'YYYY-MM')) {
          if (isResetGlobalKPI && moment(key).year() !== selectedYear) {
            return;
          } else {
            const targetYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.targetYtd)[0];
            const previousTargetYtdMonth = previousMonthOfTargetYtd(bowlingChartData, key);
            const previousTargetYtdMonthValue = targetYtdRow[previousTargetYtdMonth.monthName];
            const targetValue = targetRow[key];
            targetYtdRow[key] = previousTargetYtdMonthValue + targetValue;
          }
        }
      }
    }
    // this.setState(bowlingChartData)
    this.setState({ bowlingChartData }, () => {
      this.calculateFinalTarget();
      this.calculateTargetYTD();
    });
  };

  onTargetChangePuntuale = (selectedMonth, oldValue, row, newValue) => {
    const { bowlingChartData } = this.state;
    this.setState({ kpiFieldsChanged: true });

    const targetRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.target)[0];
    const targetFirstMonth = firstMonthOfTarget(bowlingChartData);
    targetRow[selectedMonth] = newValue;

    this.setState({ bowlingChartData }, () => {
      this.calculateFinalTarget();
      this.calculateTargetYTD();
    });
  };

  onTargetYtdChange = (selectedMonth, oldValue, row, newValue) => {
    const { bowlingChartData } = this.state;
    this.setState({ kpiFieldsChanged: true });
    const { companyData, kpiRowData } = this.props;
    const targetYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.targetYtd)[0];
    const targetRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.target)[0];
    const isResetGlobalKPI =
      companyData.resetGlobalKPI &&
      kpiRowData.parentType === kpiPuntualeAcumulato.acumulato &&
      kpiRowData.kpiType === kpiType.globale
        ? true
        : false;
    const selectedYear = moment(selectedMonth).year();

    for (const key in targetYtdRow) {
      if (key !== 'Name') {
        const formatKey = moment(key, 'YYYY-MM');

        if (key === selectedMonth) {
          targetYtdRow[selectedMonth] = newValue;
          targetRow[key] = targetRow[key] + (newValue - oldValue);
        } else if (formatKey > moment(selectedMonth, 'YYYY-MM')) {
          if (isResetGlobalKPI && moment(key).year() !== selectedYear) {
            return;
          } else {
            const preivoustTargetYtdMonthValue = previousMonthOfTargetYtd(bowlingChartData, key);
            targetYtdRow[key] = preivoustTargetYtdMonthValue.monthValue + targetRow[key];
          }
        }
      }
    }
    // this.setState(bowlingChartData)
    this.setState({ bowlingChartData }, () => {
      this.calculateFinalTarget();
      this.calculateTargetYTD();
    });
  };

  onTargetYtdChangePuntuale = (selectedMonth, oldValue, row, newValue) => {
    const { bowlingChartData } = this.state;
    this.setState({ kpiFieldsChanged: true });

    const targetYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.targetYtd)[0];
    const targetRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.target)[0];
    targetYtdRow[selectedMonth] = newValue;

    this.setState({ bowlingChartData }, () => {
      this.calculateFinalTarget();
      this.calculateTargetYTD();
    });
  };

  onActualChange = (selectedMonth, oldValue, row, newValue) => {
    const { bowlingChartData, jumpOffPointValue } = this.state;
    const { companyData, kpiRowData } = this.props;
    this.setState({ kpiFieldsChanged: true });

    const actualFirstMonth = firstMonthOfActual(bowlingChartData);
    const actualRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actual)[0];
    actualRow[selectedMonth] = newValue;
    const isResetGlobalKPI =
      companyData.resetGlobalKPI &&
      kpiRowData.parentType === kpiPuntualeAcumulato.acumulato &&
      kpiRowData.kpiType === kpiType.globale
        ? true
        : false;
    const selectedYear = moment(selectedMonth).year();
    const firstMonthOfSelectedYear = moment(selectedYear, 'YYYY-MM');
    const firstMonthOfSelectedYearFormated = moment(firstMonthOfSelectedYear).format('YYYY-MM');

    for (const key in actualRow) {
      if (key !== 'Name') {
        if (key === selectedMonth) {
          if (key === actualFirstMonth.monthName) {
            const actualYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actualYtd)[0];
            actualYtdRow[key] = newValue + jumpOffPointValue;
          } else if (isResetGlobalKPI && key === firstMonthOfSelectedYearFormated) {
            const actualYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actualYtd)[0];
            actualYtdRow[key] = newValue;
          } else {
            const actualYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actualYtd)[0];
            const previousActualYtdMonth = previousMonthOfActualYtd(bowlingChartData, key);
            const previousActualYtdMonthValue = actualYtdRow[previousActualYtdMonth.monthName];
            actualYtdRow[key] = previousActualYtdMonthValue + newValue;
          }
        }

        if (moment(key, 'YYYY-MM') > moment(selectedMonth, 'YYYY-MM')) {
          if (isResetGlobalKPI && moment(key).year() !== selectedYear) {
            return;
          } else {
            const actualYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actualYtd)[0];
            const previousActualYtdMonth = previousMonthOfTargetYtd(bowlingChartData, key);
            const previousActualYtdMonthValue = actualYtdRow[previousActualYtdMonth.monthName];
            const actualValue = actualRow[key];
            actualYtdRow[key] = previousActualYtdMonthValue + actualValue;
          }
        }
      }
    }
    this.setState({ bowlingChartData }, () => {
      this.calculateActualYTD();
    });
  };

  onActualChangePuntuale = (selectedMonth, oldValue, row, newValue) => {
    this.setState({ kpiFieldsChanged: true });

    const { bowlingChartData } = this.state;
    const actualRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actual)[0];
    actualRow[selectedMonth] = newValue;
    this.setState({ bowlingChartData }, () => {
      this.calculateActualYTD();
    });
  };

  onActualYtdChange = (selectedMonth, oldValue, row, newValue) => {
    const { bowlingChartData } = this.state;
    const { companyData, kpiRowData } = this.props;
    this.setState({ kpiFieldsChanged: true });

    const actualYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actualYtd)[0];
    const actualRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actual)[0];
    const isResetGlobalKPI =
      companyData.resetGlobalKPI &&
      kpiRowData.parentType === kpiPuntualeAcumulato.acumulato &&
      kpiRowData.kpiType === kpiType.globale
        ? true
        : false;
    const selectedYear = moment(selectedMonth).year();

    for (const key in actualYtdRow) {
      if (key !== 'Name') {
        const formatKey = moment(key, 'YYYY-MM');

        if (key === selectedMonth) {
          actualYtdRow[selectedMonth] = newValue;
          actualRow[key] = actualRow[key] + (newValue - oldValue);
        } else if (formatKey > moment(selectedMonth, 'YYYY-MM')) {
          if (isResetGlobalKPI && moment(key).year() !== selectedYear) {
            return;
          } else {
            const preivousActualYtdMonthValue = previousMonthOfActualYtd(bowlingChartData, key);
            actualYtdRow[key] = preivousActualYtdMonthValue.monthValue + actualRow[key];
          }
        }
      }
    }
    this.setState({ bowlingChartData }, () => {
      this.calculateActualYTD();
    });
  };

  onActualYtdChangePuntuale = (selectedMonth, oldValue, row, newValue) => {
    const { bowlingChartData } = this.state;
    this.setState({ kpiFieldsChanged: true });

    const actualYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actualYtd)[0];
    const actualRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actual)[0];
    actualYtdRow[selectedMonth] = newValue;

    this.setState({ bowlingChartData }, () => {
      this.calculateActualYTD();
    });
  };

  handleJumpOffPointChange = (value) => {
    const { parentType, bowlingChartData } = this.state;
    this.setState({ kpiFieldsChanged: true });

    if (parentType && parentType === kpiPuntualeAcumulato.puntuale) {
      this.setState({ jumpOffPointValue: value });
    }

    if (parentType && parentType === kpiPuntualeAcumulato.acumulato) {
      this.setState({ jumpOffPointValue: value }, () => {
        this.calculateFinalTarget();
        this.calculateTargetYTD();
        this.calculateActualYTD();
        this.calculateTargetYtdRow();
        this.calculateActualYtdRow();
      });
    }
    this.setState(bowlingChartData);
  };

  handleShowInA3Change = (value) => {
    this.setState({ kpiFieldsChanged: true });
    this.setState({ showInA3: value });
  };

  calculateFinalTarget = () => {
    const { parentType, bowlingChartData, jumpOffPointValue } = this.state;

    if (parentType === kpiPuntualeAcumulato.puntuale) {
      // let lastMonth = lastMonthOfTarget(bowlingChartData);
      // this.setState({ finalTargetValue: (lastMonth.monthValue) });

      const lastMonth = lastMonthOfTargetYtd(bowlingChartData);
      this.setState({ finalTargetValue: lastMonth.monthValue });
    } else if (parentType === kpiPuntualeAcumulato.acumulato) {
      // let targetTotalMonths = getTotalTargetMonths(bowlingChartData)
      // this.setState({ finalTargetValue: (jumpOffPointValue + targetTotalMonths) });

      const lastMonth = lastMonthOfTargetYtd(bowlingChartData);
      this.setState({ finalTargetValue: lastMonth.monthValue });
    } else {
      return;
    }
  };

  calculateTargetYTD = () => {
    const { parentType, bowlingChartData, jumpOffPointValue } = this.state;

    if (parentType === kpiPuntualeAcumulato.puntuale) {
      // let previousMonth = previousMonthOfTarget(bowlingChartData);
      // this.setState({ targetYtdValue: (previousMonth.monthValue) });

      const currentMonth = moment().format('YYYY-MM');
      const previousMonth = currentMonthOfTargetYtd(bowlingChartData, currentMonth);
      this.setState({ targetYtdValue: previousMonth.monthValue });
    } else if (parentType === kpiPuntualeAcumulato.acumulato) {
      // let targetTotalMonthsYTD = getTotalTargetMonthsYTD(bowlingChartData)
      // this.setState({ targetYtdValue: (jumpOffPointValue + targetTotalMonthsYTD) });

      const currentMonth = moment().format('YYYY-MM');
      const previousMonth = currentMonthOfTargetYtd(bowlingChartData, currentMonth);
      this.setState({ targetYtdValue: previousMonth.monthValue });
    } else {
      return;
    }
  };

  calculateActualYTD = () => {
    const { parentType, bowlingChartData, jumpOffPointValue } = this.state;

    if (parentType === kpiPuntualeAcumulato.puntuale) {
      // let previousMonth = previousMonthOfActual(bowlingChartData);
      // this.setState({ actualYtdValue: (previousMonth.monthValue) });

      const lastMonthActualYtd = currentMonthOfActualYtd(bowlingChartData);
      this.setState({ actualYtdValue: lastMonthActualYtd.monthValue });
    } else if (parentType === kpiPuntualeAcumulato.acumulato) {
      // let actualTotalMonthsYTD = getTotalActualMonthsYTD(bowlingChartData)
      // this.setState({ actualYtdValue: (jumpOffPointValue + actualTotalMonthsYTD) });

      const lastMonthActualYtd = currentMonthOfActualYtd(bowlingChartData);
      this.setState({ actualYtdValue: lastMonthActualYtd.monthValue });
    } else {
      return;
    }
  };

  calculateTargetYtdRow = () => {
    const { bowlingChartData, jumpOffPointValue } = this.state;

    const targetYtdFirstMonth = firstMonthOfTargetYtd(bowlingChartData);

    const targetYtdRowData = targetYtdRow(bowlingChartData);

    for (const key in targetYtdRowData) {
      if (key !== 'Name') {
        if (key === targetYtdFirstMonth.monthName) {
          const targetRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.target)[0];
          const targetValue = targetRow[key];

          const targetYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.targetYtd)[0];
          targetYtdRow[key] = jumpOffPointValue + targetValue;
        } else {
          const targetYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.targetYtd)[0];
          const targetRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.target)[0];

          const previousTargetYtdMonth = previousMonthOfTargetYtd(bowlingChartData, key);
          const previousTargetYtdMonthValue = targetYtdRow[previousTargetYtdMonth.monthName];

          const targetValue = targetRow[key];

          targetYtdRow[key] = previousTargetYtdMonthValue + targetValue;
        }
      }
    }
  };

  calculateActualYtdRow = () => {
    const { bowlingChartData, jumpOffPointValue } = this.state;

    const actualYtdFirstMonth = firstMonthOfActualYtd(bowlingChartData);

    const actualYtdRowData = actualYtdRow(bowlingChartData);

    for (const key in actualYtdRowData) {
      if (key !== 'Name') {
        if (key === actualYtdFirstMonth.monthName) {
          const actualRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actual)[0];
          const actualValue = actualRow[key];

          const actualYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actualYtd)[0];
          actualYtdRow[key] = jumpOffPointValue + actualValue;
        } else {
          const actualYtdRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actualYtd)[0];
          const actualRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actual)[0];

          const previousActualYtdMonth = previousMonthOfActualYtd(bowlingChartData, key);
          const previousActualYtdMonthValue = actualYtdRow[previousActualYtdMonth.monthName];

          const actualValue = actualRow[key];

          actualYtdRow[key] = previousActualYtdMonthValue + actualValue;
        }
      }
    }
  };

  handleDefinedTargetChange = (value) => {
    this.setState({ kpiFieldsChanged: true });
    this.setState({ definedTarget: value });
  };

  handleResponsabileSelect = (option) => {
    this.setState({ kpiFieldsChanged: true });
    if (option) {
      this.setState({ selectedResponsabile: option });
    } else {
      this.setState({ selectedResponsabile: null });
    }
  };

  render() {
    const { loadingButton, kpiRowData, t, companyData, projectTeamMembers, userData, currentProject } = this.props;
    const { bowlingChartData, loadingData, kpiFieldsChanged, definedTarget, selectedResponsabile } = this.state;

    const disableJumpOfPointResetGlobalKpi =
      companyData.resetGlobalKPI &&
      kpiRowData.parentType === kpiPuntualeAcumulato.acumulato &&
      kpiRowData.kpiType === kpiType.globale
        ? true
        : false;

    let targetRow = [];
    let actualRow = [];

    if (bowlingChartData) {
      targetRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.target)[0];
      actualRow = bowlingChartData.filter((item) => item.Name === bowlingChartRowName.actual)[0];
    }

    return (
      <>
        {bowlingChartData && (
          <Row
            gutter={{ lg: 24 }}
            className="row-margin"
          >
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
            >
              <div className="button-wrapper">
                {checkUserIsKpiResponsabileOrProjectTM(
                  kpiRowData.responsibleID,
                  userData,
                  currentProject.teamLeaderID,
                  currentProject.sponsorID,
                ) && (
                  <Form.Item wrapperCol={{ span: 24 }}>
                    <Button
                      loading={loadingButton}
                      type="primary"
                      onClick={() => this.onConfirm()}
                      icon={<SaveOutlined />}
                      disabled={!targetRow || !actualRow || !kpiFieldsChanged}
                    >
                      {t('buttons.salva')}
                    </Button>
                  </Form.Item>
                )}
              </div>
            </Col>
          </Row>
        )}

        {
          <Row
            gutter={{ lg: 24 }}
            className="row-margin"
          >
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 5 }}
            >
              <Form.Item label={t('general.jumpOffPoint')}>
                <InputNumber
                  min={0}
                  max={100}
                  style={{ width: '80%' }}
                  required="required"
                  value={this.state.jumpOffPointValue}
                  onChange={(value) => this.handleJumpOffPointChange(value)}
                  disabled={kpiRowData.isRollupKPI || disableJumpOfPointResetGlobalKpi}
                  // formatter={value => `${value} %`}
                  formatter={(value) => inputFormatter(value) + ' %'}
                  parser={(x) => inputParser(x)}
                />
              </Form.Item>
            </Col>

            {kpiRowData.parentType !== kpiPuntualeAcumulato.puntuale && (
              <>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 4 }}
                >
                  <Statistic
                    title={t('kpiPage.finalTarget')}
                    value={
                      this.state.finalTargetValue ? italianNumberFormat(this.state.finalTargetValue) + ' %' : '0 %'
                    }
                  />
                </Col>

                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 4 }}
                >
                  <Statistic
                    title={t('kpiPage.targetYearDate')}
                    value={this.state.targetYtdValue ? italianNumberFormat(this.state.targetYtdValue) + ' %' : '0 %'}
                  />
                </Col>

                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 4 }}
                >
                  <Statistic
                    title={t('kpiPage.actualYearDate')}
                    value={this.state.actualYtdValue ? italianNumberFormat(this.state.actualYtdValue) + ' %' : '0 %'}
                  />
                </Col>
              </>
            )}

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 3 }}
            >
              <Form.Item label={t('kpiPage.showInA3')}>
                <Switch
                  defaultChecked={this.state.showInA3}
                  onChange={(value) => this.handleShowInA3Change(value)}
                />
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 3 }}
            >
              <Form.Item label={t('kpiPage.definedTarget')}>
                <Switch
                  defaultChecked={definedTarget}
                  onChange={(value) => this.handleDefinedTargetChange(value)}
                />
              </Form.Item>
            </Col>

            {kpiRowData.kpiType === kpiType.specifico && (
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 4 }}
              >
                <Form.Item
                  className="main-container"
                  label={t('general.responsabile')}
                >
                  <Select
                    showSearch
                    value={selectedResponsabile}
                    placeholder={t('general.seleziona')}
                    allowClear
                    style={{ width: '100%' }}
                    onChange={(value) => this.handleResponsabileSelect(value)}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    loading={!projectTeamMembers}
                    disabled={
                      !userIsProjectTeamLeaderOrSponsorOrAdmin(
                        userData,
                        currentProject.teamLeaderID,
                        currentProject.sponsorID,
                      )
                    }
                  >
                    {projectTeamMembers?.map((member) => {
                      return (
                        <Select.Option
                          key={member.userID}
                          value={member.userID}
                          label={member.fullName}
                        >
                          {member.fullName}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
        }
        {loadingData && <Loader2 />}
        {bowlingChartData && (
          <>
            <Row
              gutter={{ lg: 24 }}
              className="row-margin"
            >
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <div style={{ margin: '15px 0px' }}>
                  <Table
                    rowKey={(obj) => obj.Name}
                    columns={this.renderTargetColumns(
                      bowlingChartData.filter((obj) => {
                        return obj.Name === 'Target' || obj.Name === 'TargetYTD';
                      }),
                    )}
                    dataSource={bowlingChartData.filter((obj) => {
                      return obj.Name === 'Target' || obj.Name === 'TargetYTD';
                    })}
                    pagination={false}
                    bordered={true}
                    size="small"
                    scroll={{ x: 'max-content' }}
                  />
                </div>
              </Col>
            </Row>

            <Row
              gutter={{ lg: 24 }}
              className="row-margin"
            >
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <div style={{ margin: '15px 0px' }}>
                  <Table
                    rowKey={(obj) => obj.Name}
                    columns={this.renderActualColumns(
                      bowlingChartData.filter((obj) => {
                        return obj.Name === 'Actual' || obj.Name === 'ActualYTD';
                      }),
                    )}
                    dataSource={bowlingChartData.filter((obj) => {
                      return obj.Name === 'Actual' || obj.Name === 'ActualYTD';
                    })}
                    pagination={false}
                    bordered={true}
                    size="small"
                    // scroll={{ x: 'calc(950px + 50%)' }}
                    scroll={{ x: 'max-content' }}
                  />
                </div>
              </Col>
            </Row>
          </>
        )}

        {bowlingChartData && (
          <Row
            gutter={{ lg: 24 }}
            className="row-margin"
          >
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
            >
              <div className="button-wrapper">
                {checkUserIsKpiResponsabileOrProjectTM(
                  kpiRowData.responsibleID,
                  userData,
                  currentProject.teamLeaderID,
                  currentProject.sponsorID,
                ) && (
                  <Form.Item wrapperCol={{ span: 24 }}>
                    <Button
                      loading={loadingButton}
                      type="primary"
                      onClick={() => this.onConfirm()}
                      icon={<SaveOutlined />}
                      disabled={!targetRow || !actualRow || !kpiFieldsChanged}
                    >
                      {t('buttons.salva')}
                    </Button>
                  </Form.Item>
                )}
              </div>
            </Col>
          </Row>
        )}
      </>
    );
  }
}

export default KpiValorePercentuale;
