import {
  CommentOutlined,
  EditOutlined,
  LeftCircleOutlined,
  MinusSquareOutlined,
  RightCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Avatar, Button, DatePicker, Divider, Input, Select, Space, Table, Tooltip, Typography, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { type CSSProperties, type FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import { enumCast } from 'src/config/connectors';
import {
  type BowlingChartEntry,
  type BowlingChartFilters,
  type GetXMatrixInfoResponseDto,
  type GroupedPlanningsDto,
  KpiDetailCalculationType,
  KpiDetailParentType,
} from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import MatrixService from 'src/services/matrix/matrixService';
import CommitieService from 'src/services/pages/commitieServices';
import DashboardService from '../../services/pages/dashboardService';
import KpiService from '../../services/pages/kpiService';
import { addQueryParam, getQueryParam, removeQueryParam } from '../../utils/url-utils';
import TableLayout from '../shared/tableLayout';
import { italianNumberFormat } from '../shared/utils/functions';
import { notifyMessages } from '../shared/utils/notifyMessages';
import EditKpiCommentModal from './kpiDetailCommentModal';

type PickListEntry = {
  value: string;
  label: string;
};

export enum KpiSummaryType {
  AnnualGoals = 'annualGoal',
  Committees = 'committee',
  Projects = 'project',
}

type TableDataEntry = BowlingChartEntry & {
  values: GroupedPlanningsDto;
};

const KpiSummary: FC<{
  activeXmatrixInfo?: GetXMatrixInfoResponseDto;
  relationId?: string;
  summaryType: KpiSummaryType;
}> = ({ activeXmatrixInfo, relationId, summaryType }) => {
  const { t } = useTranslation();
  const companyData = useAppSelector((state) => state.companyData.companyData);

  const [kpiList, setKpiList] = useState<TableDataEntry[]>([]);
  const [picklistData, setPicklistData] = useState<PickListEntry[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [relationQueryParamName, setRelationQueryParamName] = useState<string>('relationId');
  const [relationNameKey, setRelationNameKey] = useState<string>('general.relazione');
  const [selectedRelation, setSelectedRelation] = useState<string | undefined>(relationId || undefined);
  const [selectedYear, setSelectedYear] = useState(
    getQueryParam('selectedYear') ? Number.parseInt(getQueryParam('selectedYear')) : moment().year(),
  );
  const [selectedMonth, setSelectedMonth] = useState(
    getQueryParam('selectedMonth') ? Number.parseInt(getQueryParam('selectedMonth')) : moment().month() + 1,
  );

  const [showCommentModal, setShowCommentModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<TableDataEntry>();
  const [loadingSaveComment, setLoadingSaveComment] = useState(false);

  const [disableNextButton, setDisableNextButton] = useState(false);
  const [disablePreviousButton, setDisablePreviousButton] = useState(true);

  const KpiSemaphore: FC<{ data: TableDataEntry }> = ({ data }) => {
    return (
      <div className="kpiSemaphore">
        <Avatar
          size={'small'}
          style={semaphoreColor(data)}
        />
      </div>
    );
  };

  const semaphoreColor = (data: TableDataEntry): CSSProperties | undefined => {
    const planning = data.values;
    const parentType = enumCast(KpiDetailParentType, data.details.parentType);
    const actual = parentType === KpiDetailParentType.Cumulative ? planning?.actualYTD : planning?.actual;
    const target = parentType === KpiDetailParentType.Cumulative ? planning?.targetYTD : planning?.target;

    const tolerance = (companyData?.kpiTolerance ?? 0) / 100;
    const calculationType = enumCast(KpiDetailCalculationType, data.details.calculationType);

    let targetDistance = 0;
    if (calculationType === KpiDetailCalculationType.IsCompleted) {
      targetDistance = target - actual;
    } else if (calculationType === KpiDetailCalculationType.Minimum) {
      targetDistance = actual - target - target * tolerance;
    } else {
      targetDistance = target - actual + target * tolerance;
    }

    const greenColor = '#6ABF6A';
    const redColor = '#DC2626';

    return {
      backgroundColor: targetDistance > 0 ? redColor : greenColor,
    };
  };

  useEffect(() => {
    void fetchFilterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (summaryType === KpiSummaryType.AnnualGoals) {
      setRelationNameKey('general.obiettiviAnno');
      setRelationQueryParamName('selectedAnnualGoal');
      setSelectedRelation(relationId || getQueryParam('selectedAnnualGoal'));
    }
    if (summaryType === KpiSummaryType.Committees) {
      setRelationNameKey('general.comitato');
      setRelationQueryParamName('selectedCommittee');
      setSelectedRelation(relationId || getQueryParam('selectedCommittee'));
    }
    if (summaryType === KpiSummaryType.Projects) {
      setRelationNameKey('general.progetti');
      setRelationQueryParamName('selectedProject');
      setSelectedRelation(relationId || getQueryParam('selectedProject'));
    }
  }, [relationId, summaryType]);

  useEffect(() => {
    addQueryParam('selectedMonth', selectedMonth);
    void retrieveKpiList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth]);

  useEffect(() => {
    addQueryParam('selectedYear', selectedYear);
    void retrieveKpiList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    selectedRelation
      ? addQueryParam(relationQueryParamName, selectedRelation)
      : removeQueryParam(relationQueryParamName);
    setDisablePreviousButton(picklistData[0]?.value === selectedRelation);
    setDisableNextButton(picklistData.slice(-1)[0]?.value === selectedRelation);
    void retrieveKpiList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRelation]);

  const fetchFilterData = async () => {
    try {
      if (summaryType === KpiSummaryType.AnnualGoals) {
        const { data } = await MatrixService.getAnnualGoals();
        if (data.responseObject?.value) {
          setPicklistData(
            data.responseObject.value.map((e) => ({ value: e.annualGoalId, label: e.description ?? e.annualGoalId })),
          );
        }
      }

      if (summaryType === KpiSummaryType.Committees) {
        const { data } = await CommitieService.getCommitiesList();
        setPicklistData(data.map((c) => ({ value: c.id, label: c.name })));
      }

      if (summaryType === KpiSummaryType.Projects) {
        const { data } = await DashboardService.getProjectFilterData();
        if (data.responseObject?.value) {
          setPicklistData(
            data.responseObject.value.map((e) => ({
              value: e.projectID,
              label: e.name ?? e.projectCode ?? e.projectID,
            })),
          );
        }
      }
    } catch {}
  };

  const retrieveKpiList = async (xMatrixId?: string) => {
    const xmatrix = xMatrixId ?? activeXmatrixInfo?.xMatrixID;
    if (!xmatrix) {
      return;
    }

    setIsLoading(true);

    const relationKey: keyof BowlingChartFilters & ('annualGoalId' | 'committeeId' | 'projectId') = (() => {
      if (summaryType === KpiSummaryType.AnnualGoals) {
        return 'annualGoalId';
      }
      if (summaryType === KpiSummaryType.Committees) {
        return 'committeeId';
      }
      if (summaryType === KpiSummaryType.Projects) {
        return 'projectId';
      }

      throw new Error(`Invalid summaryType ${summaryType}`);
    })();

    try {
      const { data } = await KpiService.getBowlingChartData({
        year: selectedYear ?? moment().year(),
        isAnnualGoalRelated: !selectedRelation && summaryType === KpiSummaryType.AnnualGoals,
        isCommitteeRelated: !selectedRelation && summaryType === KpiSummaryType.Committees,
        isProjectRelated: !selectedRelation && summaryType === KpiSummaryType.Projects,
        [relationKey]: selectedRelation,
      });

      const parentData = data.entries.map((e) => ({
        ...e,
        values: Object.values(e.monthlyPlannings)[selectedMonth - 1],
      }));

      setKpiList(parentData);
    } catch {}

    setIsLoading(false);
  };

  const toggleKpiComments = (rowData: TableDataEntry) => {
    setSelectedRowData(rowData);
    setShowCommentModal(true);
  };

  const handleSaveComments = async (payload: { kpiDetailId: string; comment: string }) => {
    setLoadingSaveComment(true);

    try {
      const { data } = await KpiService.onCommentSave({
        kpiDetailID: payload.kpiDetailId,
        comment: payload.comment,
        objectTypeID: selectedRelation,
        type: summaryType,
      });
      if (!data.success) {
        throw new Error('Insert failed');
      }

      setShowCommentModal(false);
      void message.success(notifyMessages.updateSuccess);
      void retrieveKpiList();
    } catch {
      void message.error(notifyMessages.updateFailed);
    }

    setLoadingSaveComment(false);
  };

  const onNextObject = () => {
    const currentIndex = picklistData.findIndex((item) => item.value === selectedRelation);
    currentIndex + 1 < picklistData.length && setSelectedRelation(picklistData[currentIndex + 1].value);
  };

  const onPreviousObject = () => {
    const currentIndex = picklistData.findIndex((item) => item.value === selectedRelation);
    currentIndex - 1 >= 0 && setSelectedRelation(picklistData[currentIndex - 1].value);
  };

  const kpiColumns: ColumnsType<TableDataEntry> = [
    {
      title: `${t('general.nome')}`,
      dataIndex: ['details', 'name'],
      ellipsis: {
        showTitle: true,
      },
      align: 'left',
      width: '30%',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters?.()}
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />
      ),
      shouldCellUpdate: (a, b) => !shallowEqual(a.details, b.details),
      onFilter: (value, record) => record.details.name.toLowerCase().includes(`${value}`.trim().toLowerCase()),
      render: (text, record) => {
        const urlParts = {
          baseRoute: '/kpiDashboard/kdid',
          kpiDetailId: record.details.id,
          projectId: summaryType === KpiSummaryType.Projects && selectedRelation ? selectedRelation : 0,
          annualGoalId: summaryType === KpiSummaryType.AnnualGoals && selectedRelation ? selectedRelation : 0,
          xMatrixId: activeXmatrixInfo?.xMatrixID ?? 0,
          year: selectedYear,
          month: selectedMonth,
          part: `${summaryType.charAt(0)}/`,
        };

        const pathname = Object.values(urlParts).map(String).join('/');

        return (
          <Space>
            <Link
              style={{ cursor: 'pointer' }}
              to={{ pathname }}
            >
              {text}
            </Link>
          </Space>
        );
      },
    },
    {
      title: `${t('kpiPage.unitaMisura')}`,
      dataIndex: ['details', 'unitOfMeasure'],
      align: 'right',
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: `${t('general.targetYtd')}`,
      dataIndex: ['values', 'targetYTD'],
      key: 'targetYTD',
      align: 'right',
      ellipsis: {
        showTitle: false,
      },
      render: italianNumberFormat,
    },
    {
      title: `${t('general.actualYtd')}`,
      dataIndex: ['values', 'actualYTD'],
      key: 'actualYTD',
      align: 'right',
      ellipsis: {
        showTitle: false,
      },
      render: italianNumberFormat,
    },
    {
      title: `${t('kpiDashboard.semaphore')}`,
      dataIndex: 'semaforo',
      align: 'center',
      ellipsis: {
        showTitle: false,
      },
      render: (_, record) => <KpiSemaphore data={record} />,
    },
    {
      title: `${t('general.commenti')}`,
      dataIndex: ['notes'],
      align: 'center',
      ellipsis: {
        showTitle: false,
      },
      render: (_, record) => {
        return (
          <Space direction="horizontal">
            <EditOutlined onClick={() => toggleKpiComments(record)} />
            {record.notes && record.notes !== '<p></p>' && (
              <>
                <Divider type="vertical" />
                <CommentOutlined className="tw-text-green-500" />
              </>
            )}
          </Space>
        );
      },
    },
    {
      title: `${t('kpiPage.annualTarget')}`,
      dataIndex: 'finalTarget',
      align: 'right',
      ellipsis: {
        showTitle: false,
      },
      render: (_, record) => {
        const target = record.monthlyPlannings['12'].targetYTD;
        return italianNumberFormat(target);
      },
    },
  ];

  return (
    <>
      {showCommentModal && selectedRowData && (
        <EditKpiCommentModal
          showCommentModal={showCommentModal}
          handleCommentModalClose={() => setShowCommentModal(false)}
          onCommentUpdate={handleSaveComments}
          loadingCommentSave={loadingSaveComment}
          rowData={selectedRowData}
        />
      )}

      <TableLayout className="project-card-wrapper">
        <TableLayout.Actions>
          <DatePicker
            allowClear={true}
            placeholder={t('general.anno')}
            picker="year"
            style={{ width: '100%' }}
            value={moment(selectedYear, 'YYYY')}
            format="YYYY"
            onChange={(date) => setSelectedYear(date.year())}
            className="tw-max-w-32"
          />
          <Select
            placeholder={t('general.mese')}
            value={selectedMonth}
            allowClear={true}
            onChange={setSelectedMonth}
            className="tw-min-w-32"
            options={Array.from({ length: 12 }).map((_, i) => ({
              label: moment(`1987-${i + 1}-27`).format('MMMM'),
              value: i + 1,
            }))}
          />
          <Select
            value={selectedRelation === '' ? null : selectedRelation}
            placeholder={t(relationNameKey)}
            onChange={setSelectedRelation}
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.trim().toLowerCase())}
            showSearch
            allowClear={true}
            labelRender={({ label }) => (
              <Tooltip
                title={label}
                placement="top"
              >
                <Typography.Text className="tw-shrink tw-max-w-16 tw-truncate">
                  {`${label ?? ''}`.replace('...', '')}
                </Typography.Text>
              </Tooltip>
            )}
            className="tw-shrink-0 tw-w-[500px]"
            options={picklistData}
            optionRender={(option) => (
              <Space>
                <Tooltip
                  title={option.label}
                  placement="top"
                >
                  <span className="tw-shrink tw-max-w-16 tw-truncate">{option.label}</span>
                </Tooltip>
              </Space>
            )}
          />

          <Tooltip title={t('general.previous')}>
            <Button
              disabled={disablePreviousButton || isLoading}
              icon={<LeftCircleOutlined />}
              className="tw-shrink-0"
              onClick={() => onPreviousObject()}
            />
          </Tooltip>
          <Tooltip title={t('general.next')}>
            <Button
              className="tw-shrink-0"
              disabled={disableNextButton || isLoading}
              icon={<RightCircleOutlined />}
              onClick={() => onNextObject()}
            />
          </Tooltip>

          <Tooltip title={t('general.clearFilters')}>
            <Button
              disabled={isLoading}
              className="tw-shrink-0 tw-ml-8"
              icon={<MinusSquareOutlined />}
              onClick={() => {
                setSelectedMonth(moment().month() + 1);
                setSelectedYear(moment().year());
                setSelectedRelation(undefined);
              }}
            />
          </Tooltip>
        </TableLayout.Actions>

        <TableLayout.Content>
          <Table
            className="kpiSintesiTable"
            size="small"
            loading={isLoading}
            columns={kpiColumns}
            dataSource={kpiList}
            rowKey={(obj) => obj.kpiId}
            pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
          />
        </TableLayout.Content>
      </TableLayout>
    </>
  );
};

export default KpiSummary;
