import { arrayMove } from '@dnd-kit/sortable';
import { Spin, message } from 'antd';
import classNames from 'classnames';
import { lazy, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  CommitteeType,
  type GetCommitteePicklistDto,
  type GetDivisionPicklistDto,
  type GetRootXMatrixDivisionListResponseDto,
  type GetXMatrixInfoResponseDto,
  type StatusPicklistResponseDto,
  type XMatrixFilters,
} from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import CommonService from '../../services/commonService';
import MatrixService, { type XMatrixDtoWithOrder } from '../../services/matrix/matrixService';
import RelationsService from '../../services/matrix/relationsService';
import DashboardService from '../../services/pages/dashboardService';
import { addQueryParam, addQueryParams, getQueryParam, removeQueryParam } from '../../utils/url-utils';
import { statusPickListCodes } from '../shared/utils/constants';
import { notifyMessages } from '../shared/utils/notifyMessages';
import Center from './data_tables/center/center';
import BigObjectives from './data_tables/longObjectives/longObjectives';
import Projects from './data_tables/projects/projects';
import YearObjectives from './data_tables/yearObjective/yearObjectives';
import FiltersMatrixLocal from './filtersMatrixLocal';
import './matrix.scss';
import ProjectUserRelation from './relation_tables/projects_user';
import RelationTable from './relation_tables/relation-table';
const Kpi = lazy(() => import('./data_tables/kpi/kpi'));
const UsersXmatrix = lazy(() => import('./data_tables/users/users'));

enum SelectableAssets {
  PROJECTS = 'projects',
  USERS = 'users',
  KPI = 'kpis',
  LONG_TERM_GOAL = 'longTermGoals',
  ANNUAL_GOAL = 'annualObjectives',
}

const initFilters = {
  annualGoalId: [],
  longTermGoalId: [],
  sponsorId: [],
  teamLeaderId: [],
  divisionId: [],
  committeeId: [],
  projectID: [],
  includeSubProject: false,
  showAllUsers: false,
  xMatrixID: null,
};

export const LS_XMATRIX_FILTERS_KEY = 'xmFilters';

const filterIds = (filterArray: (string | null | undefined)[], fullArray: (string | null | undefined)[]) => {
  return filterArray
    .filter((id): id is string => !!id)
    .filter((id) => fullArray.filter((id): id is string => !!id).includes(id));
};

const XMatrix = () => {
  const { t } = useTranslation();
  const { id: xMatrixIdParam } = useParams<{ id: string }>();
  const activeXmatrixInfo = useAppSelector((state) => state.activeXMatrix.activeXMatrix);

  const [isLoading, setIsLoading] = useState(true);
  const [committeePickList, setCommitteePickList] = useState<GetCommitteePicklistDto[] | null>(null);
  const [projectStatusList, setProjectStatusList] = useState<StatusPicklistResponseDto[] | null>(null);
  const [goalStatusList, setGoalStatusList] = useState<StatusPicklistResponseDto[] | null>(null);
  const [divisionsList, setDivisionsList] = useState<GetDivisionPicklistDto[] | null>([]);
  const [matrixData, setMatrixData] = useState<XMatrixDtoWithOrder>({
    annualGoals: [],
    longTermGoals: [],
    projects: [],
    kpIs: [],
    users: [],
    goalProjectRelationship: [],
    kpiProjectRelationships: [],
    userProjectRelationship: [],
    longTermAnnualGoalRelationships: [],
    goalKPIRelationship: [],
    annualGoalKPIRelationship: [],
    projectChild: [],
    projectChildProjectRelationship: [],
  });
  const [freeze, setFreeze] = useState(false);
  const [selectedAsset, setSelectionAsset] = useState<SelectableAssets | null>(null); // the selected asset

  const [currentXMatrixData, setCurrentXmatrixData] = useState<GetXMatrixInfoResponseDto | null>(null);

  const [xMatrixList, setXmatrixList] = useState<GetRootXMatrixDivisionListResponseDto[] | null>(null);

  const [userSectionVisibility, setUserSectionVisibility] = useState(false);
  const [kpiSectionVisibility, setKpiSectionVisibility] = useState(false);

  const [xmFilters, setXmFilters] = useState<XMatrixFilters>(initFilters);

  const [selectedAnnualObjectiveIds, setSelectedAnnualObjectiveIds] = useState<string[]>([]);
  const [selectedLongTermObjectiveIds, setSelectedLongTermObjectiveIds] = useState<string[]>([]);
  const [selectedProjectIds, setSelectedProjectIds] = useState<string[]>([]);
  const [selectedKpiIds, setSelectedKpiIds] = useState<string[]>([]);
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const filters = JSON.parse(localStorage.getItem(LS_XMATRIX_FILTERS_KEY) || '{}');

    if (filters) {
      setXmFilters(filters);
    }

    const getData = async () => {
      try {
        await Promise.all([
          retrieveProjectStatuses(),
          retrieveGoalStatuses(),
          retrieveDivisions(),
          retrieveCommittees(),

          getSelectedFromParams(),
          getXmatrixRootList(),
        ]);
      } catch (error) {
        console.error(error);
      }
    };

    void getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xMatrixIdParam]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const getData = async () => {
      await getCurrentXMatrixData();
    };

    if (activeXmatrixInfo) {
      void getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeXmatrixInfo, xmFilters]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (currentXMatrixData?.xMatrixID) {
      void getMatrixData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentXMatrixData, xmFilters]);

  const getSelectedFromParams = async () => {
    // Retrive from qp the selected item
    const selection = getQueryParam('selection');
    const selectedId = getQueryParam('selectedId');

    switch (selection) {
      case 'projects':
        selectProject(selectedId);
        break;
      case 'users':
        selectUser(selectedId);
        break;
      case 'kpis':
        selectKpi(selectedId);
        break;
      case 'longTermGoals':
        selectLongTermObjective(selectedId);
        break;
      case 'annualObjectives':
        selectAnnualObjective(selectedId);
        break;

      default:
        break;
    }

    // Sync kpi & user visibility with qp
    const userSectionVisibility = getQueryParam('userSectionVisibility') === 'true';
    const kpiSectionVisibility = getQueryParam('kpiSectionVisibility') === 'true';
    setUserSectionVisibility(userSectionVisibility);
    setKpiSectionVisibility(kpiSectionVisibility);
  };

  const getCurrentXMatrixData = async () => {
    const xMatrixId = xMatrixIdParam || activeXmatrixInfo?.xMatrixID;

    if (!xMatrixId) {
      console.error('No xmatrix id provided');
      return;
    }

    try {
      const response = await MatrixService.getSelectedXmatrixInfo(xMatrixId);
      const resp = response.data;

      if (resp.success && resp.responseObject?.value) {
        setCurrentXmatrixData(resp.responseObject?.value || []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const retrieveDivisions = async () => {
    try {
      const response = await DashboardService.getDivisionFilterData();
      const resp = response.data;
      if (resp.success) {
        setDivisionsList(resp.responseObject?.value || []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const retrieveProjectStatuses = async () => {
    const objectCode = statusPickListCodes.statusProjects;
    try {
      const response = await CommonService.getStatusData(objectCode);
      const resp = response.data;
      if (resp.success) {
        setProjectStatusList(resp.responseObject?.value || []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const retrieveGoalStatuses = async () => {
    const objectCode = statusPickListCodes.statusObAnnoLong;
    try {
      const response = await CommonService.getStatusData(objectCode);
      const resp = response.data;
      if (resp.success) {
        setGoalStatusList(resp.responseObject?.value || []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const retrieveCommittees = async () => {
    try {
      const response = await CommonService.getCommitiePicklist(CommitteeType.NonOperational);
      const resp = response.data;
      if (resp.success) {
        setCommitteePickList(resp.responseObject?.value || []);
      }
    } catch (error) {}
  };

  const updateAnnualGoalRelation = async (
    selection: { correlationCoefficient: number },
    rowId: string,
    colId: string,
  ) => {
    if (!currentXMatrixData?.xMatrixID) return;

    try {
      const response = await RelationsService.updateAnnualProjectRelation({
        correlationCoefficient: selection.correlationCoefficient,
        xMatrixID: currentXMatrixData?.xMatrixID,
        annualGoalID: colId,
        projectID: rowId,
      });

      const resp = response.data;
      if (resp.success) {
        message.success(notifyMessages.updateSuccess);
        onReload();
      } else {
        message.error(notifyMessages.updateFailed);
      }
    } catch (error) {
      message.error(notifyMessages.updateFailed);
    }
  };

  const updateProjectKpiRelation = async (
    selection: { correlationCoefficient: number },
    rowId: string,
    colId: string,
  ) => {
    if (!currentXMatrixData?.xMatrixID) return;

    try {
      const response = await RelationsService.updateProjectKpiRelation({
        correlationCoefficient: selection.correlationCoefficient,
        xMatrixID: currentXMatrixData?.xMatrixID,
        kpiid: colId,
        projectID: rowId,
      });
      const resp = response.data;
      if (resp.success) {
        message.success(notifyMessages.updateSuccess);
        onReload();
      } else {
        message.error(notifyMessages.updateFailed);
      }
    } catch (error) {
      message.error(notifyMessages.updateFailed);
    }
  };

  const updateLongGoalKpiRelation = async (
    selection: { correlationCoefficient: number },
    rowId: string,
    colId: string,
  ) => {
    if (!currentXMatrixData?.xMatrixID) return;

    try {
      const response = await RelationsService.updateLongGoalKpiRelation({
        correlationCoefficient: selection.correlationCoefficient,
        xMatrixID: currentXMatrixData?.xMatrixID,
        kpiid: colId,
        longTermGoalID: rowId,
      });
      const resp = response.data;
      if (resp.success) {
        message.success(notifyMessages.updateSuccess);
        onReload();
      } else {
        message.error(notifyMessages.updateFailed);
      }
    } catch (error) {
      message.error(notifyMessages.updateFailed);
    }
  };

  const updateLongAnnualRelation = async (
    selection: { correlationCoefficient: number },
    rowId: string,
    colId: string,
  ) => {
    if (!currentXMatrixData?.xMatrixID) return;

    try {
      const response = await RelationsService.updateLongAnnualRelation({
        correlationCoefficient: selection.correlationCoefficient,
        xMatrixID: currentXMatrixData?.xMatrixID,
        annualGoalID: colId,
        longTermGoalID: rowId,
      });
      const resp = response.data;
      if (resp.success) {
        message.success(notifyMessages.updateSuccess);
        onReload();
      } else {
        message.error(notifyMessages.updateFailed);
      }
    } catch (error) {
      message.error(notifyMessages.updateFailed);
    }
  };

  const updateProjectUserRelation = async (
    selection: { correlationCoefficient: number },
    rowId: string,
    colId: string,
  ) => {
    if (!currentXMatrixData?.xMatrixID) return;

    try {
      const {
        data: { success },
      } = await RelationsService.updateProjectUserRelation({
        correlationCoefficient: selection.correlationCoefficient,
        userID: colId,
        projectID: rowId,
      });

      if (!success) {
        throw new Error('Update failed');
      }

      message.success(notifyMessages.updateSuccess);
      void onReload();
    } catch (error) {
      message.error(notifyMessages.updateFailed);
    }
  };

  const onReload = async () => {
    await getMatrixData();
    await getCurrentXMatrixData();
  };

  const getMatrixData = async () => {
    setIsLoading(true);

    try {
      const response = await MatrixService.getMatrixData({
        ...xmFilters,
        includeSubProject: xmFilters.includeSubProject || false,
        showAllUsers: xmFilters.showAllUsers || false,
        xMatrixID: currentXMatrixData?.xMatrixID || activeXmatrixInfo?.xMatrixID,
      });

      setMatrixData(response || []);
    } catch (error) {
      message.error(notifyMessages.retrieveFailed);
    } finally {
      setIsLoading(false);
    }
  };

  const getXmatrixRootList = async () => {
    const response = await MatrixService.getXmatrixDivisionRootList();
    const resp = response.data;
    if (resp.success) {
      setXmatrixList(resp.responseObject?.value || []);
    }
  };

  const manageFilterReset = () => {
    setXmFilters(initFilters);
    localStorage.setItem(LS_XMATRIX_FILTERS_KEY, JSON.stringify({}));
    getMatrixData();
  };

  const manageFilterApplied = () => {
    getMatrixData();
  };

  const toggleUsersSection = () => {
    setUserSectionVisibility(!userSectionVisibility);
    addQueryParam('userSectionVisibility', !userSectionVisibility);
  };

  const toggleKpiSection = () => {
    setKpiSectionVisibility(!kpiSectionVisibility);
    addQueryParam('kpiSectionVisibility', !kpiSectionVisibility);
  };

  const clearSelection = (selectionId: string) => {
    if (getQueryParam('selectedId') === selectionId) {
      removeQueryParam('selection');
      removeQueryParam('selectedId');

      setSelectionAsset(null);
      setSelectedProjectIds([]);
      setSelectedKpiIds([]);
      setSelectedUserIds([]);
      setSelectedAnnualObjectiveIds([]);
      setSelectedLongTermObjectiveIds([]);

      return true;
    }

    return false;
  };

  const selectAnnualObjectives = (
    selection: { projectIds: string[]; longTermGoalIds: string[] } = { projectIds: [], longTermGoalIds: [] },
  ) => {
    if (!matrixData.annualGoals) return [];

    const selectedAnnualObjectiveIds = filterIds(
      matrixData.annualGoals.map(({ annualGoalID }) => annualGoalID),
      [
        ...(matrixData?.goalProjectRelationship
          ?.filter(
            (ag) =>
              ag.highestIDValue && ag.correlationCoefficient !== 3 && selection.projectIds?.includes(ag.highestIDValue),
          )
          .map(({ lowestIDValue }) => lowestIDValue) || []),
        ...(matrixData.longTermAnnualGoalRelationships
          ?.filter(
            (ag) =>
              ag.lowestIDValue &&
              ag.correlationCoefficient !== 3 &&
              selection.longTermGoalIds?.includes(ag.lowestIDValue),
          )
          .map(({ highestIDValue }) => highestIDValue) || []),
      ],
    ) as string[];

    setSelectedAnnualObjectiveIds(selectedAnnualObjectiveIds);

    return selectedAnnualObjectiveIds;
  };

  const selectLongTermObjectives = (
    selection: { annualGoalIds: string[]; kpiIds: string[] } = { annualGoalIds: [], kpiIds: [] },
  ) => {
    if (!matrixData.longTermGoals) return [];

    const selectedLongTermObjectiveIds = filterIds(
      matrixData.longTermGoals.map(({ longTermGoalID }) => longTermGoalID),
      [
        ...(matrixData.longTermAnnualGoalRelationships
          ?.filter(
            (ag) =>
              ag?.highestIDValue &&
              ag.correlationCoefficient !== 3 &&
              selection.annualGoalIds?.includes(ag?.highestIDValue),
          )
          .map(({ lowestIDValue }) => lowestIDValue) || []),
        ...(matrixData.goalKPIRelationship
          ?.filter(
            (ag) =>
              ag.highestIDValue && ag.correlationCoefficient !== 3 && selection.kpiIds?.includes(ag.highestIDValue),
          )
          .map(({ lowestIDValue }) => lowestIDValue) || []),
      ],
    );

    setSelectedLongTermObjectiveIds(selectedLongTermObjectiveIds);
    return selectedLongTermObjectiveIds;
  };

  const selectProjects = (
    selection: { annualGoalIds: string[]; kpiIds: string[]; userIds: string[] } = {
      annualGoalIds: [],
      kpiIds: [],
      userIds: [],
    },
  ) => {
    if (!matrixData.projects) return [];

    const selectedProjectIds = filterIds(
      matrixData.projects.map(({ projectID }) => projectID),
      [
        ...(matrixData.goalProjectRelationship
          ?.filter(
            (ag) =>
              ag?.lowestIDValue &&
              ag.correlationCoefficient !== 3 &&
              selection.annualGoalIds?.includes(ag?.lowestIDValue),
          )
          .map(({ highestIDValue }) => highestIDValue) || []),
        ...(matrixData.kpiProjectRelationships
          ?.filter(
            (ag) =>
              ag.highestIDValue && ag.correlationCoefficient !== 3 && selection.kpiIds?.includes(ag.highestIDValue),
          )
          .map(({ lowestIDValue }) => lowestIDValue) || []),
        ...(matrixData.userProjectRelationship
          ?.filter((ag) => ag.highestIDValue && selection.userIds?.includes(ag.highestIDValue))
          .map(({ lowestIDValue }) => lowestIDValue) || []),
      ],
    );

    setSelectedProjectIds(selectedProjectIds);
    return selectedProjectIds;
  };

  const selectKpis = (
    selection: { projectIds: string[]; longTermGoalIds: string[] } = { projectIds: [], longTermGoalIds: [] },
  ) => {
    if (!matrixData.kpIs) return [];

    const selectedKpiIds = filterIds(
      matrixData.kpIs.map(({ kpiid }) => kpiid),
      [
        ...(matrixData.kpiProjectRelationships
          ?.filter(
            (ag) =>
              ag.lowestIDValue && ag.correlationCoefficient !== 3 && selection.projectIds?.includes(ag.lowestIDValue),
          )
          .map(({ highestIDValue }) => highestIDValue) || []),
        ...(matrixData.goalKPIRelationship
          ?.filter(
            (ag) =>
              ag.lowestIDValue &&
              ag.correlationCoefficient !== 3 &&
              selection.longTermGoalIds?.includes(ag.lowestIDValue),
          )
          .map(({ highestIDValue }) => highestIDValue) || []),
      ],
    );

    setSelectedKpiIds(selectedKpiIds);
    return selectedKpiIds;
  };

  const selectUsers = (selection: { projectIds: string[] } = { projectIds: [] }) => {
    if (!matrixData.users) return [];

    const selectedUserIds = filterIds(
      matrixData.users.map(({ userID }) => userID),
      [
        ...(matrixData.userProjectRelationship
          ?.filter(
            (ag) =>
              ag.lowestIDValue && ag.correlationCoefficient !== 4 && selection.projectIds?.includes(ag.lowestIDValue),
          )
          .map(({ highestIDValue }) => highestIDValue) || []),
      ],
    );

    setSelectedUserIds(selectedUserIds);
    return selectedUserIds;
  };

  const selectKpi = (selectedKpiId: string) => {
    if (clearSelection(selectedKpiId)) return;

    setSelectionAsset(SelectableAssets.KPI);
    setSelectedKpiIds([selectedKpiId]);
    addQueryParams({
      selection: 'kpis',
      selectedId: selectedKpiId,
    });

    const longTermGoalIds1 = selectLongTermObjectives({ kpiIds: [selectedKpiId], annualGoalIds: [] });
    const annualGoalIds1 = selectAnnualObjectives({ longTermGoalIds: longTermGoalIds1, projectIds: [] });

    const projectIds2 = selectProjects({ kpiIds: [selectedKpiId], annualGoalIds: annualGoalIds1, userIds: [] });
    const annualGoalIds2 = selectAnnualObjectives({ projectIds: projectIds2, longTermGoalIds: longTermGoalIds1 });
    selectLongTermObjectives({ annualGoalIds: annualGoalIds2, kpiIds: [selectedKpiId] });
  };

  const selectUser = (selectedUserId: string) => {
    if (clearSelection(selectedUserId)) return;

    setSelectionAsset(SelectableAssets.USERS);
    setSelectedUserIds([selectedUserId]);
    addQueryParams({
      selection: 'users',
      selectedId: selectedUserId,
    });

    const projectIds = selectProjects({ userIds: [selectedUserId], annualGoalIds: [], kpiIds: [] });
    selectKpis({ projectIds, longTermGoalIds: [] });
    const annualGoalIds = selectAnnualObjectives({ projectIds, longTermGoalIds: [] });
    selectLongTermObjectives({ annualGoalIds, kpiIds: [] });
  };

  const selectProject = (selectedProjectId: string) => {
    if (clearSelection(selectedProjectId)) return;

    setSelectionAsset(SelectableAssets.PROJECTS);
    setSelectedProjectIds([selectedProjectId]);
    addQueryParams({
      selection: 'projects',
      selectedId: selectedProjectId,
    });

    const kpiIds1 = selectKpis({ projectIds: [selectedProjectId], longTermGoalIds: [] });
    const longTermGoalIds1 = selectLongTermObjectives({ kpiIds: kpiIds1, annualGoalIds: [] });
    selectAnnualObjectives({ longTermGoalIds: longTermGoalIds1, projectIds: [selectedProjectId] });

    selectUsers({ projectIds: [selectedProjectId] });

    const annualGoalIds2 = selectAnnualObjectives({
      projectIds: [selectedProjectId],
      longTermGoalIds: longTermGoalIds1,
    });
    const longTermGoalIds2 = selectLongTermObjectives({
      annualGoalIds: annualGoalIds2,
      kpiIds: kpiIds1,
    });
    selectKpis({ projectIds: [selectedProjectId], longTermGoalIds: longTermGoalIds2 });
  };

  const selectAnnualObjective = (selectedAnnualObjectiveId: string) => {
    if (clearSelection(selectedAnnualObjectiveId)) return;

    setSelectionAsset(SelectableAssets.ANNUAL_GOAL);
    setSelectedAnnualObjectiveIds([selectedAnnualObjectiveId]);
    addQueryParams({
      selection: 'annualObjectives',
      selectedId: selectedAnnualObjectiveId,
    });

    const projectIds = selectProjects({ annualGoalIds: [selectedAnnualObjectiveId], kpiIds: [], userIds: [] });
    selectKpis({ projectIds, longTermGoalIds: [] });
    selectUsers({ projectIds });

    selectLongTermObjectives({ annualGoalIds: [selectedAnnualObjectiveId], kpiIds: [] });
  };

  const selectLongTermObjective = (selectedLongTermObjectiveId: string) => {
    if (clearSelection(selectedLongTermObjectiveId)) return;

    setSelectionAsset(SelectableAssets.LONG_TERM_GOAL);
    setSelectedLongTermObjectiveIds([selectedLongTermObjectiveId]);

    addQueryParams({
      selection: 'longTermGoals',
      selectedId: selectedLongTermObjectiveId,
    });

    const annualGoalIds = selectAnnualObjectives({ longTermGoalIds: [selectedLongTermObjectiveId], projectIds: [] });
    const projectIds = selectProjects({ annualGoalIds, kpiIds: [], userIds: [] });
    selectKpis({ projectIds, longTermGoalIds: [selectedLongTermObjectiveId] });
    selectUsers({ projectIds });
  };

  const manageSort = async (
    asset: 'projects' | 'longTermGoals' | 'annualGoals' | 'kpIs',
    activeIndex: number,
    overIndex: number,
  ) => {
    const xMatrixId = currentXMatrixData?.xMatrixID;

    if (!xMatrixId) {
      message.error(notifyMessages.updateFailed);
      setFreeze(false);
      return;
    }

    setMatrixData((prev) => ({
      ...prev,
      [asset]: arrayMove(matrixData[asset], activeIndex, overIndex),
    }));

    const reorderedData = {
      ...matrixData,
      [asset]: arrayMove(matrixData[asset], activeIndex, overIndex),
    };

    setFreeze(true);

    try {
      switch (asset) {
        case 'projects':
          await MatrixService.updateXMatrixRelationsOrder(xMatrixId, {
            entries: reorderedData.projects?.reduce<Record<string, number>>((acc, project, index) => {
              if (project.projectID) {
                acc[project.projectID] = index;
              }
              return acc;
            }, {}),
          });
          break;

        case 'annualGoals':
          await MatrixService.updateXMatrixRelationsOrder(xMatrixId, {
            entries: reorderedData.annualGoals?.reduce<Record<string, number>>((acc, project, index) => {
              if (project.annualGoalID) {
                acc[project.annualGoalID] = index;
              }
              return acc;
            }, {}),
          });
          break;

        case 'kpIs':
          await MatrixService.updateXMatrixRelationsOrder(xMatrixId, {
            entries: reorderedData.kpIs?.reduce<Record<string, number>>((acc, project, index) => {
              if (project.kpiid) {
                acc[project.kpiid] = index;
              }
              return acc;
            }, {}),
          });
          break;

        case 'longTermGoals':
          await MatrixService.updateXMatrixRelationsOrder(xMatrixId, {
            entries: reorderedData.longTermGoals?.reduce<Record<string, number>>((acc, project, index) => {
              if (project.longTermGoalID) {
                acc[project.longTermGoalID] = index;
              }
              return acc;
            }, {}),
          });
          break;

        default:
          throw new Error();
      }

      message.success(notifyMessages.updateSuccess);
    } catch {
      setMatrixData((prev) => ({
        ...prev,
        [asset]: matrixData[asset],
      }));
      message.error(notifyMessages.retrieveFailed);
    } finally {
      setFreeze(false);
    }
  };

  return (
    <div className="x-matrix tw-flex tw-flex-col tw-h-full tw-overflow-hidden">
      {currentXMatrixData && (
        <FiltersMatrixLocal
          // xmatrix filters props
          manageFilterReset={manageFilterReset}
          manageFilterApplied={manageFilterApplied}
          //xmatrix header props
          reloadMatrix={onReload}
          selectedXmatrixInfo={currentXMatrixData}
          reloadMatrixOnEdit={onReload}
          reloadXmInfo={() => getCurrentXMatrixData()}
          //left panel xmatrix list props
          filters={xmFilters}
          setFilters={setXmFilters}
          xmatrixData={matrixData}
          xmatrixRootList={xMatrixList || []}
          isSecondLevelXmatrix={false}
        />
      )}

      {isLoading && (
        <div className="tw-min-h-full empty:tw-hidden tw-w-full tw-flex tw-justify-center tw-items-center">
          <Spin size="large" />
        </div>
      )}

      {matrixData && currentXMatrixData && !isLoading && (
        <div
          className={classNames('tw-grow tw-p-4 tw-overflow-auto tw-flex', {
            'tw-opacity-50 tw-pointer-events-none tw-touch-none': freeze,
          })}>
          <div className="grid-container tw-overflow-hidden tw-shrink-0 tw-m-auto">
            {/* row 1 */}

            <div id="annualObjectiveProjectRelation">
              <RelationTable
                colList={matrixData.annualGoals || []}
                rowList={matrixData.projects || []}
                colItemId="annualGoalID"
                rowItemId="projectID"
                origin="topLeft"
                direction="bottomRight"
                relationList={matrixData.goalProjectRelationship || []}
                colSelectionIds={selectedAnnualObjectiveIds}
                rowSelectionIds={selectedProjectIds}
                onUpdateRelation={updateAnnualGoalRelation}
              />
            </div>

            <div
              id="projects"
              className="projects">
              <Projects
                projects={matrixData.projects || []}
                reloadMatrix={onReload}
                comittieList={committeePickList || []}
                statusList={projectStatusList || []}
                onSort={(oldIndex, newIndex) => manageSort('projects', oldIndex, newIndex)}
                divisionsList={divisionsList || []}
                isSecondLevelXmatrix={false}
                selectedXmatrix={currentXMatrixData?.xMatrixID || ''}
                selectedXmatrixInfoData={currentXMatrixData}
                onRowClick={selectProject}
                selectedIds={selectedProjectIds}
                selection={selectedAsset === 'projects' ? 'primary' : 'secondary'}
              />
            </div>

            {kpiSectionVisibility && (
              <div id="projectKpiRelation">
                <RelationTable
                  colList={matrixData.kpIs || []}
                  rowList={matrixData.projects || []}
                  colItemId={'kpiid'}
                  rowItemId={'projectID'}
                  origin="topLeft"
                  direction="bottomLeft"
                  relationList={matrixData.kpiProjectRelationships || []}
                  colSelectionIds={selectedKpiIds}
                  rowSelectionIds={selectedProjectIds}
                  onUpdateRelation={updateProjectKpiRelation}
                />
              </div>
            )}

            <div
              className="tw-w-8"
              id="space"
            />

            {userSectionVisibility && (
              <div id="projectUserRelation">
                <ProjectUserRelation
                  t={t}
                  colList={matrixData.users}
                  rowList={matrixData.projects}
                  colItemId={'userID'}
                  rowItemId={'projectID'}
                  origin="topLeft"
                  direction="bottomLeft"
                  relationList={matrixData.userProjectRelationship}
                  colSelectionIds={selectedUserIds}
                  rowSelectionIds={selectedProjectIds}
                  onUpdateRelation={updateProjectUserRelation}
                  reloadMatrix={onReload}
                  selectedXmatrix={currentXMatrixData?.xMatrixID}
                />
              </div>
            )}

            {/* row 2 */}

            <div
              id="yearObjectives"
              className="rotateYear">
              <YearObjectives
                annualGoals={matrixData.annualGoals || []}
                reloadMatrix={onReload}
                onSort={(oldIndex, newIndex) => manageSort('annualGoals', oldIndex, newIndex)}
                goalStatusList={goalStatusList || []}
                selectedXmatrix={currentXMatrixData?.xMatrixID || ''}
                selectedIds={selectedAnnualObjectiveIds}
                selection={selectedAsset === 'annualObjectives' ? 'primary' : 'secondary'}
                onRowClick={selectAnnualObjective}
              />
            </div>

            <div id="center">
              <Center
                reloadMatrix={onReload}
                comittieList={committeePickList}
                projectStatusList={projectStatusList}
                goalStatusList={goalStatusList}
                divisionsList={divisionsList}
                toggleUsersSection={toggleUsersSection}
                toggleKpiSection={toggleKpiSection}
                isKpiSectionVisible={kpiSectionVisibility}
                isUsersSectionVisible={userSectionVisibility}
                selectedXmatrix={currentXMatrixData?.xMatrixID}
                selectedXmatrixInfo={currentXMatrixData}
                selectedKpiIds={selectedKpiIds}
                selectedProjectIds={selectedProjectIds}
                selectedUserIds={selectedUserIds}
                selectedAnnualObjectiveIds={selectedAnnualObjectiveIds}
                selectedLongTermObjectiveIds={selectedLongTermObjectiveIds}
                annualGoals={matrixData.annualGoals}
                longTermGoals={matrixData.longTermGoals}
                projects={matrixData.projects}
              />
            </div>

            {kpiSectionVisibility && (
              <div
                id="kpi"
                className="rotateKpi">
                <Kpi
                  onSort={(oldIndex, newIndex) => manageSort('kpIs', oldIndex, newIndex)}
                  kpi={matrixData.kpIs || []}
                  reloadMatrix={onReload}
                  selectedXmatrix={currentXMatrixData?.xMatrixID || ''}
                  onRowClick={selectKpi}
                  selectedIds={selectedKpiIds}
                  selection={selectedAsset === 'kpis' ? 'primary' : 'secondary'}
                />
              </div>
            )}

            <div
              className="tw-w-8"
              id="space"
            />

            {userSectionVisibility && (
              <div
                id="usersXmatrix"
                className="rotateUsers">
                <UsersXmatrix
                  usersXmatrix={matrixData.users || []}
                  selectedIds={selectedUserIds}
                  selection={selectedAsset === 'users' ? 'primary' : 'secondary'}
                  onRowClick={selectUser}
                />
              </div>
            )}

            {/* row 3 */}

            <div id="longAnnualObjectiveRelation">
              <RelationTable
                colList={matrixData.annualGoals || []}
                rowList={matrixData.longTermGoals || []}
                colItemId={'annualGoalID'}
                rowItemId={'longTermGoalID'}
                origin="topLeft"
                direction="topRight"
                relationList={matrixData.longTermAnnualGoalRelationships || []}
                colSelectionIds={selectedAnnualObjectiveIds}
                rowSelectionIds={selectedLongTermObjectiveIds}
                onUpdateRelation={updateLongAnnualRelation}
              />
            </div>

            <div
              id="bigObjectives"
              className="big-objectives">
              <BigObjectives
                onSort={(oldIndex, newIndex) => manageSort('longTermGoals', oldIndex, newIndex)}
                longTermGoals={matrixData.longTermGoals || []}
                reloadMatrix={onReload}
                selectedXmatrix={currentXMatrixData?.xMatrixID || ''}
                selectedIds={selectedLongTermObjectiveIds}
                selection={selectedAsset === 'longTermGoals' ? 'primary' : 'secondary'}
                onRowClick={selectLongTermObjective}
              />
            </div>

            {kpiSectionVisibility && (
              <div id="kpiLongObjectivesRelation">
                <RelationTable
                  colList={matrixData.kpIs || []}
                  rowList={matrixData.longTermGoals || []}
                  colItemId={'kpiid'}
                  rowItemId={'longTermGoalID'}
                  origin="topLeft"
                  direction="topLeft"
                  relationList={matrixData.goalKPIRelationship || []}
                  colSelectionIds={selectedKpiIds}
                  rowSelectionIds={selectedLongTermObjectiveIds}
                  onUpdateRelation={updateLongGoalKpiRelation}
                />
              </div>
            )}

            <div
              className="tw-w-8"
              id="space"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default XMatrix;
