import { Card, Tabs } from 'antd';
import { type FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import MatrixService from '../../services/matrix/matrixService';
import { addQueryParam, getQueryParam, hasQueryParam } from '../../utils/url-utils';
import '../projects/projectListViewPage/projectList.scss';
import TableLayout from '../shared/tableLayout';
import './kpiDashboars.scss';
import classNames from 'classnames';
import type { GetXMatrixInfoResponseDto } from 'src/connectors/backend';
import ProjectSectionTitle from '../shared/components/ProjectSectionTitle/ProjectSectionTitle';
import KpiSummary, { KpiSummaryType } from './kpiSummary';

const KpiDashboard: FC<{
  isPresentationMode: boolean;
  presentationProjectId?: string;
  presentationAnnualId?: string;
}> = ({ isPresentationMode, presentationProjectId, presentationAnnualId }) => {
  const urlParams = useParams();
  const { t } = useTranslation();

  const { projectId, annualId } = urlParams;

  const [activeXmatrixInfo, setActiveXmatrixInfo] = useState<GetXMatrixInfoResponseDto>();
  const [activeTab, setActiveTab] = useState('1');

  useEffect(() => {
    getActiveXmatrix();

    if (hasQueryParam('dashboardTab')) {
      setActiveTab(getQueryParam('dashboardTab') ?? '1');
    } else {
      setActiveTab('1');
    }
  }, []);

  const getActiveXmatrix = async () => {
    await MatrixService.getActiveXmatrix()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success && resp.responseObject?.value) {
          setActiveXmatrixInfo(resp.responseObject.value);
        } else {
        }
      })
      .catch((error) => {});
  };

  const onTabChange = (activeKey: string) => {
    setActiveTab(activeKey);
    addQueryParam('dashboardTab', activeKey);
  };

  const tabItems = [
    {
      label: <>{t('general.progetti')}</>,
      key: '1',
      children: (
        <KpiSummary
          summaryType={KpiSummaryType.Projects}
          relationId={projectId ?? presentationProjectId}
          activeXmatrixInfo={activeXmatrixInfo}
        />
      ),
    },
    {
      label: <>{t('general.obiettiviAnno')}</>,
      key: '2',
      children: (
        <KpiSummary
          summaryType={KpiSummaryType.AnnualGoals}
          relationId={annualId ?? presentationAnnualId}
          activeXmatrixInfo={activeXmatrixInfo}
        />
      ),
    },
    {
      label: <>{t('general.comitato')}</>,
      key: '3',
      children: (
        <KpiSummary
          summaryType={KpiSummaryType.Committees}
          relationId={''} // annualId ?? presentationAnnualId} // TODO
          activeXmatrixInfo={activeXmatrixInfo}
        />
      ),
    },
  ];

  return (
    <Card
      className={classNames({
        '!tw-shadow-none[&_.ant-card-body]:!tw-p-0': isPresentationMode,
        '!tw-shadow-none': !isPresentationMode,
      })}
      bordered={false}
    >
      <TableLayout
        title={
          isPresentationMode ? (
            <ProjectSectionTitle
              title={t('proggetiPage.kpiStatus')}
              isPresentationMode={isPresentationMode}
            />
          ) : (
            <ProjectSectionTitle
              title={t('kpiDashboard.kpiDashboard')}
              isPresentationMode={isPresentationMode}
            />
          )
        }
      >
        <TableLayout.Content>
          {activeXmatrixInfo && (
            <Tabs
              items={tabItems}
              defaultActiveKey="1"
              activeKey={activeTab}
              onChange={onTabChange}
            />
          )}
        </TableLayout.Content>
      </TableLayout>
    </Card>
  );
};

export default KpiDashboard;
