import {
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Typography as Text,
  message,
} from 'antd';
import type { FormInstance } from 'antd/es/form';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import weekday from 'dayjs/plugin/weekday';
import { type FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TimeRangePicker from 'src/components/shared/TimeRangePicker';
import {
  type CreateProjectRoutineRequest,
  type GetProjectMembersResponseDto,
  type ProjectRoutineDto,
  ProjectRoutineSchedule,
  ProjectRoutineType,
  type UpdateProjectRoutineRequest,
} from 'src/connectors/backend';
import RoutineService from 'src/services/pages/projectServices/routineServices';
import ModalFooterButtons from '../../../../shared/components/modal/modalFooterButtons';
import { formItemLayout } from '../../../../shared/utils/constants';
import { notifyMessages, requiredFields } from '../../../../shared/utils/notifyMessages';

dayjs.extend(customParseFormat);
dayjs.extend(weekday);
const DATE_FORMAT = 'DD/MM/YYYY';

const WORKING_DAYS = [
  { label: 'Monday', value: 1 },
  { label: 'Tuesday', value: 2 },
  { label: 'Wednesday', value: 3 },
  { label: 'Thursday', value: 4 },
  { label: 'Friday', value: 5 },
  { label: 'Saturday', value: 6 },
  { label: 'Sunday', value: 7 },
];

type RoutineFormType = Omit<
  CreateProjectRoutineRequest | UpdateProjectRoutineRequest,
  'startDate' | 'endDate' | 'frequenceType' | 'timeZoneName' | 'duration'
>;
type ScheduleFormType = {
  frequencyOption: 'regular' | 'weekday';
  dateRange?: [dayjs.Dayjs, dayjs.Dayjs];
};
type ActionRequest = Partial<CreateProjectRoutineRequest | UpdateProjectRoutineRequest> & ScheduleFormType;

const RoutineFormModal: FC<{
  routine?: ProjectRoutineDto;
  // saveAction: CreateAction | UpdateAction;
  endDateProject: string;
  handleClose: (routine?: ProjectRoutineDto) => void;
  loadingButton: boolean;
  projectId: string;
  setLoading: (loading: boolean) => void;
  showModal: boolean;
  startDateProject: string;
  users: GetProjectMembersResponseDto[];
}> = ({
  routine,
  // saveAction,
  endDateProject,
  handleClose,
  loadingButton,
  projectId,
  setLoading,
  showModal: showNewRoutineModal,
  startDateProject,
  users,
}) => {
  const { t } = useTranslation();
  const form = useRef<FormInstance<RoutineFormType & ScheduleFormType>>(null);
  const availableUsers = users
    .filter((a, i) => users.findIndex((s) => a.fullName === s.fullName) === i)
    .map((user) => {
      return {
        value: user.userID as string,
        label: user.fullName as string,
        key: user.userID as string,
        disabled: user.isDeleted === true,
      };
    });

  const [actionRequest, setActionRequest] = useState<ActionRequest>({
    frequence: routine?.frequence || 1,
    integrateInOutlook: routine?.integrateInOutlook || false,
    integrateInTeams: routine?.integrateInTeams || false,
    day: routine?.day || 1,
    endTime: routine?.endTime || undefined,
    extraUsers: availableUsers
      .map((u) => u.value)
      .filter((u) => routine?.attendees?.map((user) => user.id).includes(u)),
    name: routine?.name || undefined,
    schedule: routine?.scheduleDescription
      ? ProjectRoutineSchedule[routine?.scheduleDescription as keyof typeof ProjectRoutineSchedule]
      : undefined,
    startTime: routine?.startTime || undefined,
    type: routine?.typeDescription
      ? ProjectRoutineType[routine?.typeDescription as keyof typeof ProjectRoutineType]
      : undefined,

    dateRange: routine?.startDate && routine?.endDate ? [dayjs(routine.startDate), dayjs(routine.endDate)] : undefined,
    frequencyOption: (routine?.day === 0 ? 'weekday' : 'regular') as ScheduleFormType['frequencyOption'],

    startDate: dayjs(routine?.startDate).format('YYYY-MM-DD'),
    endDate: dayjs(routine?.endDate).format('YYYY-MM-DD'),
  });

  const handleFormChanges = (values: Partial<RoutineFormType & ScheduleFormType>) => {
    if (
      'day' in values ||
      'endTime' in values ||
      'extraUsers' in values ||
      'frequence' in values ||
      'integrateInOutlook' in values ||
      'integrateInTeams' in values ||
      'name' in values ||
      'schedule' in values ||
      'startTime' in values
    ) {
      setActionRequest({ ...actionRequest, ...values });
    }

    if ('type' in values) {
      setActionRequest({
        ...actionRequest,
        type: values.type,
        integrateInOutlook: actionRequest.integrateInOutlook && values.type !== 'steering',
        integrateInTeams: actionRequest.integrateInTeams && values.type !== 'steering',
      });

      form.current?.setFieldValue('integrateInOutlook', actionRequest.integrateInOutlook && values.type !== 'steering');
      form.current?.setFieldValue('integrateInTeams', actionRequest.integrateInTeams && values.type !== 'steering');
    }

    if ('dateRange' in values && values.dateRange) {
      const [startDate, endDate] = values.dateRange;

      setActionRequest({
        ...actionRequest,
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
      });
    }
  };

  const submit = async (values: RoutineFormType & ScheduleFormType) => {
    handleFormChanges(values);

    if (values.frequencyOption === 'weekday') {
      actionRequest.frequence = 0;
    }

    if (actionRequest.schedule === ProjectRoutineSchedule.Daily) {
      delete actionRequest.day;
    }

    if (actionRequest.schedule === ProjectRoutineSchedule.Yearly) {
      actionRequest.frequence = 1;
    }

    setLoading(true);

    try {
      const {
        data: { success, responseObject },
      } = await (routine
        ? RoutineService.updateProjectRoutine(projectId, routine.id, {
            ...(actionRequest as UpdateProjectRoutineRequest),
            timeZoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
          })
        : RoutineService.addProjectRoutine(projectId, {
            ...(actionRequest as CreateProjectRoutineRequest),
            timeZoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
          }));

      if (!success || !responseObject?.value) {
        throw new Error('Insert failed');
      }

      handleClose(responseObject.value);

      void message.success(notifyMessages.addSuccess);
    } catch {
      void message.error(notifyMessages.addFailed);
    }

    setLoading(false);
  };

  return (
    <Modal
      className="add-critic-modal"
      centered
      width={800}
      title={t(routine ? 'proggetiPage.aggiornaRoutineProggeto' : 'proggetiPage.aggiungiRoutineProgetto')}
      open={showNewRoutineModal}
      destroyOnClose={true}
      onCancel={() => handleClose(undefined)}
      footer={[
        <ModalFooterButtons
          disabled={false}
          loadingButton={loadingButton}
          modalClose={handleClose}
          formId={'newProjectRoutine'}
        />,
      ]}
    >
      <Card className="tw-overflow-auto">
        <Form<RoutineFormType & ScheduleFormType>
          ref={form}
          id="newProjectRoutine"
          layout="vertical"
          {...formItemLayout}
          initialValues={actionRequest}
          onFinish={submit}
          onValuesChange={handleFormChanges}
        >
          {
            //#region Name
          }
          <Row
            gutter={{ lg: 24 }}
            className="row-margin"
          >
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
            >
              <Form.Item<RoutineFormType>
                label={t('general.nome')}
                name="name"
                rules={[{ required: true, message: requiredFields.required }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          {
            //#endregion
            //#region Type and participants
          }
          <Row
            gutter={{ lg: 24 }}
            className="row-margin"
          >
            {/* Type field */}
            <Col
              className="gutter-row"
              xs={{ span: 6 }}
              sm={{ span: 6 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item<RoutineFormType>
                label={t('proggetiPage.newRoutineType')}
                name="type"
                rules={[{ required: true, message: requiredFields.required }]}
                hasFeedback
              >
                <Radio.Group>
                  <Space direction="vertical">
                    {Object.entries(ProjectRoutineType).map(([label, key], i) => (
                      <Radio
                        key={key}
                        value={key}
                      >
                        {label}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col
              className="gutter-row"
              xs={{ span: 18 }}
              sm={{ span: 18 }}
              md={{ span: 18 }}
              lg={{ span: 18 }}
            >
              <Form.Item<RoutineFormType>
                className="main-container"
                name="extraUsers"
                label={t('general.partecipanti')}
                hasFeedback
              >
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder={t('general.partecipanti')}
                  options={availableUsers}
                  showSearch
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                />
              </Form.Item>
            </Col>
          </Row>
          {
            //#endregion
            //#region Start time and end time
          }
          <Row
            gutter={{ lg: 24 }}
            className="row-margin"
          >
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
            >
              <TimeRangePicker
                required={true}
                startTime={{
                  name: 'startTime',
                }}
                endTime={{
                  name: 'endTime',
                }}
              />
            </Col>
          </Row>
          {
            //#endregion
            //#region Schedule settings
          }
          <Row
            gutter={{ lg: 24 }}
            className="row-margin"
          >
            <Col
              xs={{ span: 8 }}
              sm={{ span: 8 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item<RoutineFormType>
                label={t('proggetiPage.pianificazione')}
                rules={[{ required: true, message: requiredFields.required }]}
                name="schedule"
              >
                <Radio.Group>
                  <Space direction="vertical">
                    {Object.entries(ProjectRoutineSchedule).map(([name, value]) => (
                      <Radio
                        key={value}
                        value={value}
                      >
                        {name}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>

            {actionRequest.schedule && actionRequest.schedule !== ProjectRoutineSchedule.Yearly && (
              <Col
                xs={{ span: 16 }}
                sm={{ span: 16 }}
                md={{ span: 16 }}
                lg={{ span: 16 }}
              >
                <Text>Dettagli Ricorrenza</Text>
                {actionRequest.schedule === ProjectRoutineSchedule.Daily && (
                  <Form.Item<ScheduleFormType>
                    name="frequencyOption"
                    rules={[{ required: true, message: 'Please choose an option!' }]}
                  >
                    <Radio.Group>
                      {' '}
                      <Space direction="vertical">
                        <Row
                          className="tw-gap-2"
                          align={'middle'}
                        >
                          <Radio value="regular">Every</Radio>
                          <Form.Item<RoutineFormType>
                            className="tw-m-0"
                            name="frequence"
                            rules={[{ required: true, message: 'Please input the frequence!' }]}
                          >
                            <InputNumber
                              className="tw-max-w-12"
                              size="middle"
                              min={1}
                              max={7}
                            />
                          </Form.Item>
                          <Text>{t((actionRequest.frequence ?? 1) === 1 ? 'Common.day' : 'Common.days')}</Text>
                        </Row>
                        <Radio value="weekday">Every weekday</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                )}

                {actionRequest.schedule === ProjectRoutineSchedule.Weekly && (
                  <>
                    <Row
                      className="tw-gap-2"
                      align={'middle'}
                    >
                      <Text>
                        <span style={{ color: 'red' }}>*</span> Recur every
                      </Text>
                      <Form.Item<RoutineFormType>
                        className="tw-m-0"
                        name="frequence"
                        rules={[{ required: true, message: 'Please input the frequence!' }]}
                      >
                        <InputNumber
                          className="tw-max-w-12"
                          size="middle"
                          min={1}
                        />
                      </Form.Item>
                      <Text>{t((actionRequest.frequence ?? 1) === 1 ? 'Common.week' : 'Common.weeks')}</Text>
                      <Text>weeks(s) on:</Text>
                    </Row>
                    <Row>
                      <Form.Item<RoutineFormType>
                        className="tw-m-0"
                        name="day"
                        rules={[{ required: true, message: 'Please select a week day!' }]}
                      >
                        <Radio.Group>
                          <Space direction="vertical">
                            {WORKING_DAYS.map((item, i) => (
                              <Col
                                xs={{ span: 8 }}
                                md={{ span: 6 }}
                                key={i}
                              >
                                <Radio value={item.value}>{item.label}</Radio>
                              </Col>
                            ))}
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </Row>
                  </>
                )}

                {actionRequest.schedule === ProjectRoutineSchedule.Monthly && (
                  <Row className="tw-flex tw-items-center tw-gap-x-2">
                    <Text>Day</Text>
                    <Form.Item<RoutineFormType>
                      className="tw-m-0"
                      name="day"
                      rules={[{ required: true, message: 'Please input a day!' }]}
                    >
                      <InputNumber
                        className="tw-max-w-12"
                        size="middle"
                        min={1}
                        max={31}
                      />
                    </Form.Item>
                    <Text>of every</Text>
                    <Form.Item
                      style={{ marginBottom: '0px' }}
                      name="frequence"
                      rules={[{ required: true, message: 'Please input the frequence!' }]}
                    >
                      <InputNumber
                        className="tw-max-w-12"
                        min={1}
                      />
                    </Form.Item>
                    <Text>{t((actionRequest.frequence ?? 1) === 1 ? 'Common.month' : 'Common.months')}</Text>
                  </Row>
                )}
              </Col>
            )}
          </Row>
          {
            //#endregion
            //#region Start date and end date
          }

          {actionRequest.schedule && (
            <Row
              gutter={{ lg: 24 }}
              className="row-margin"
            >
              <Col xs={{ span: 24 }}>
                <Form.Item<ScheduleFormType>
                  label={t('general.dataInizio')}
                  name="dateRange"
                  rules={[{ required: true, message: requiredFields.required }]}
                >
                  <DatePicker.RangePicker
                    style={{ width: '100%' }}
                    format={DATE_FORMAT}
                    disabledDate={(date, { from }) => {
                      const projectStartDate = dayjs(startDateProject).startOf('day').toISOString();
                      const projectEndDate = dayjs(endDateProject).endOf('day').toISOString();

                      if (from) {
                        if (actionRequest.schedule === ProjectRoutineSchedule.Daily) {
                          return date.isBefore(from.clone().add(1, 'day'), 'days');
                        }

                        if (actionRequest.schedule === ProjectRoutineSchedule.Weekly) {
                          return date.isBefore(from.clone().add(1, 'week'), 'days');
                        }

                        if (actionRequest.schedule === ProjectRoutineSchedule.Monthly) {
                          return date.isBefore(from.clone().add(1, 'month'), 'days');
                        }

                        if (actionRequest.schedule === ProjectRoutineSchedule.Yearly) {
                          return date.isBefore(from.clone().add(1, 'year'), 'days');
                        }
                      }

                      return date.isBefore(projectStartDate) || date.isAfter(projectEndDate);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {
            //#endregion
            //#region Meetings switches
            actionRequest.type && actionRequest.type !== 'steering' && (
              <Row
                gutter={{ lg: 24 }}
                className="row-margin"
              >
                <Col className="gutter-row">
                  <Form.Item<RoutineFormType>
                    label={t('proggetiPage.createReunioneOutlook')}
                    name="integrateInOutlook"
                    valuePropName="checked"
                  >
                    <Switch value={actionRequest.integrateInOutlook} />
                  </Form.Item>
                </Col>

                <Col className="gutter-row">
                  <Form.Item<RoutineFormType>
                    label={t('proggetiPage.createMeetingOnTeams')}
                    name="integrateInTeams"
                    valuePropName="checked"
                  >
                    <Switch value={actionRequest.integrateInTeams} />
                  </Form.Item>
                </Col>
              </Row>
            )
            //#endregion
          }
        </Form>
      </Card>
    </Modal>
  );
};

export default RoutineFormModal;
