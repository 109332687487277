import { AxiosError } from 'axios';
import { Configuration, type SerializableExceptionError } from 'src/connectors/backend';
import type { BaseAPI } from 'src/connectors/backend/base';
import { acquireToken } from './auth/clientAuthProvider';
import deployment from './deploymentConfigs';

export * from 'src/connectors/backend';

interface ApiConstructor<T extends BaseAPI> {
  new (configuration?: Configuration): T;
}

export const api = <T extends BaseAPI>(ctor: ApiConstructor<T>) => {
  return new ctor(
    new Configuration({
      basePath: deployment.backend.href.replace(/\/$/, ''),
      baseOptions: {
        withCredentials: true,
      },
    }),
  );
};

export const authenticatedApi = async <T extends BaseAPI>(ctor: ApiConstructor<T>) => {
  const result = await acquireToken();

  return new ctor(
    new Configuration({
      basePath: deployment.backend.href.replace(/\/$/, ''),
      baseOptions: {
        headers: {
          Authorization: `Bearer ${result?.accessToken}`,
        },
        withCredentials: true,
      },
    }),
  );
};

export const enumCast = <T extends Record<string, string>>(enumObject: T, index?: string | number) => {
  if (typeof index === 'string') {
    if (index in enumObject) {
      return enumObject[index] as unknown as T[keyof T];
    }

    throw new Error(`Enum ${JSON.stringify(enumObject)} does not have a value for position ${index}`);
  }

  if (!index || index < 1) {
    throw new Error(`Enum ${JSON.stringify(enumObject)} does not have a value for position ${index}`);
  }

  const values = Object.values(enumObject);
  if (index - 1 > values.length) {
    throw new Error(`Enum ${JSON.stringify(enumObject)} does not have a value for position ${index}`);
  }

  return values[index - 1] as T[keyof T];
};

export const trackApiPromise = async <T>(promise: Promise<T>, area?: string) => {
  try {
    return await promise;
  } catch (e) {
    if (
      e instanceof AxiosError &&
      typeof e.response?.data === 'object' &&
      'message' in e.response.data &&
      'errorDescription' in e.response.data
    ) {
      return {
        data: e.response.data,
        status: e.response.status,
      } as {
        data: {
          message: string;
          errorDescription: SerializableExceptionError;
        };
        status: number;
      };
    }

    throw e;
  }
};
