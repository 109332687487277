import { Form } from 'antd';
import { type FC, useState } from 'react';

import { Card, Col, Modal, Row } from 'antd';
import BraftEditor from 'braft-editor';
import { useTranslation } from 'react-i18next';
import type { BowlingChartEntry } from 'src/connectors/backend';
import ModalFooterButtons from '../shared/components/modal/modalFooterButtons';
import { formItemLayout, richTectEditoControllsNoImage } from '../shared/utils/constants';

const EditKpiCommentModal: FC<{
  handleCommentModalClose: () => void;
  loadingCommentSave: boolean;
  onCommentUpdate: (payload: { kpiDetailId: string; comment: string }) => Promise<void>;
  rowData: BowlingChartEntry;
  showCommentModal: boolean;
}> = ({ handleCommentModalClose, loadingCommentSave, onCommentUpdate, rowData, showCommentModal }) => {
  const { t } = useTranslation();
  type FormData = Parameters<typeof onCommentUpdate>[0];

  const [fieldsChanged, setFieldsChanged] = useState(false);

  const onSave = ({ comment }: FormData) => {
    onCommentUpdate({
      kpiDetailId: rowData.details.id,
      comment: BraftEditor.createEditorState(comment).toHTML(),
    });
  };

  return (
    <Modal
      className="add-comment-modal"
      width={800}
      title={`${t('general.commenti')} : ${rowData.details.name}`}
      open={showCommentModal}
      destroyOnClose={true}
      onCancel={handleCommentModalClose}
      footer={[
        <ModalFooterButtons
          disabled={!fieldsChanged}
          loadingButton={loadingCommentSave}
          modalClose={handleCommentModalClose}
          key={rowData.details.name}
          formId={'addKpiComment'}
        />,
      ]}
    >
      <Card>
        <Form<FormData>
          id="addKpiComment"
          layout="vertical"
          {...formItemLayout}
          onFinish={(values) => onSave(values)}
          onValuesChange={() => setFieldsChanged(true)}
        >
          <Row
            gutter={{ lg: 24 }}
            className="row-margin"
          >
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
            >
              <Form.Item<FormData>
                className="main-container"
                name="comment"
                initialValue={BraftEditor.createEditorState(rowData.notes)}
              >
                <BraftEditor
                  language="en"
                  id="editor-text"
                  controls={richTectEditoControllsNoImage}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </Modal>
  );
};

export default EditKpiCommentModal;
