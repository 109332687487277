import { DatePicker } from 'antd';
import type { BuiltInControlType, ControlType } from 'braft-editor';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import { ProjectStatus, ProjectType } from 'src/connectors/backend';

export const RichTextEditorControls: ControlType[] = [
  'undo',
  'redo',
  'separator',
  'font-size',
  'separator',
  'text-color',
  'bold',
  'italic',
  'underline',
  'strike-through',
  'separator',
  'remove-styles',
  'separator',
  'text-indent',
  'text-align',
  'separator',
  'list-ul',
  'list-ol',
  'blockquote',
  'code',
  'separator',
  'link',
  'separator',
  'hr',
  'media',
];

export const richTectEditoControllsNoImage: ControlType[] = [
  'undo',
  'redo',
  'separator',
  'font-size',
  'separator',
  'text-color',
  'bold',
  'italic',
  'underline',
  'strike-through',
  'separator',
  'superscript',
  'subscript',
  'remove-styles',
  'emoji',
  'separator',
  'text-indent',
  'text-align',
  'separator',
  'list-ul',
  'list-ol',
  'blockquote',
  'code',
  'separator',
  'link',
  'separator',
  'hr',
  'separator',
  'clear',
];

export const httpLinkRegex = /^https?:\/\//i;
export const httpsLinkRegex = /^https?:\/\//i;

export const formatOfHHMM = 'HH:mm';
export const formatOfDate = 'DD MMM YYYY';

export const pageSize = {
  pageSize50: 50,
};

export const emptyGuid = '00000000-0000-0000-0000-000000000000';

export const statusPickListCodes = {
  statusProjects: '01',
  statusObAnnoLong: '02',
  criticStatus: '05',
  activityStatus: '04',
  delivarbleStatus: '03',
  sessionStatus: '08',
};

export const objectCodes = {
  calculationTypes: '03',
  userType: '05',
  projectsOption: '08',
  kpiType: '07',
  acumulatoPuntuale: '14',
  feedFilter: '12',
  routineOrganiser: '09',
  routineSchedule: '15',
  criticSeverity: '10',
  deliverablePriority: '16',
  projectCostTypes: '17',
  budgetAlert: '18',
};

export const userRolesCodes = {
  steering: 'Steering',
  teamLeader: 'TeamLeader',
  teamMember: 'TeamMember',
  companyAdmin: 'CompanyAdmin',
  lenovysAdmin: 'LenovysAdmin',
  techAdmin: 'TechAdmin',
  sponsor: 'Sponsor',
  externalReviewer: 'ExternalReviewer',
  teamMemberEssential: 'TeamMemberEssential',
};

export const userRoles = {
  steering: 'Steering',
  teamLeader: 'Team Leader',
  teamMember: 'Team Member',
  companyAdmin: 'Company Admin',
  lenovysAdmin: 'Lenovys Admin',
  techAdmin: 'Tech Admin',
  sponsor: 'Sponsor',
  externalReviewer: 'External Reviewer',
};

export const kpiPuntualeAcumulato = {
  puntuale: 1,
  acumulato: 2,
};

export const kpiType = {
  globale: 1,
  specifico: 2,
};

export const kpiCalculationType = {
  valoreMassimo: 1,
  valoreMinimo: 2,
  valorePercentuale: 3,
  valoreFatoNonFato: 4,
};

export const bowlingChartRowName = {
  target: 'Target',
  targetYtd: 'TargetYTD',
  actual: 'Actual',
  actualYtd: 'ActualYTD',
};

export const workloadTotaleProgetto = {
  budgetProgetto: 'BudgetProgetto',
  allocatoProgetto: 'AllocatoProgetto',
  nonAllocato: 'NonAllocato',
  consuntivo: 'Consuntivo',
};

export const comitieSintesiPeriod = {
  year: 1,
  month: 2,
  week: 3,
};

export const feedObjectType = {
  projectFeed: 1,
  deliverableFeed: 2,
  obLongTermFeed: 3,
  obAnnoFeed: 4,
  commitieFeed: 5,
};

export const feedItemsTypes = {
  notifiche: 1,
  comment: 2,
  file: 3,
  meeting: 4,
};

export const xmatrixStatus = {
  active: 1,
  sospeso: 2,
  draft: 3,
  closed: 4,
};

export const semaphoreStatus = {
  s1: 1,
  s2: 2,
  s3: 3,
  s4: 4,
};

export const longOrAnnualGoalFieldUpdate = {
  anualGoalName: 1,
  anualGoalNotes: 2,
  longTermGoalName: 3,
  longTermGoalNotes: 4,
};

export enum PRSStatus {
  DRAFT = 1,
  OPEN = 2,
  SUSPENDED = 3,
  CLOSED = 4,
}

export const activityStatus = {
  draft: 1,
  active: 2,
  closed: 3,
  cancelled: 4,
};

export const annualGoalStatus = {
  active: 1,
  sospeso: 2,
  draft: 3,
  concluso: 4,
  revisionato: 5,
};

export const longTermGoalStatus = {
  active: 1,
  sospeso: 2,
  draft: 3,
  concluso: 4,
  revisionato: 5,
};

export const deliverableStatus = {
  draft: 1,
  aperto: 2,
  sospeso: 3,
  chiuso: 4,
};

export const mapDeliverableStatus = {
  1: 'draft',
  2: 'aperto',
  3: 'sospeso',
  4: 'chiuso',
};

export const objectTypeUserSecurity = {
  project: 1,
  longTermGoal: 2,
  annualGoal: 3,
  deliverable: 4,
  activity: 5,
};

export const projectStatusAsNumber = {
  [ProjectStatus.Active]: 1,
  [ProjectStatus.Suspended]: 2,
  [ProjectStatus.Draft]: 3,
  [ProjectStatus.Completed]: 4,
};

export const projectStatusFromNumber = Object.fromEntries(
  Object.entries(projectStatusAsNumber).map(([key, value]) => [value, key])
);

export const userProjectCostAccessLevel = {
  viewEdit: 1,
  view: 2,
  limited: 3,
  noAccess: 4,
};

export const priority = {
  high: 1,
  medium: 2,
  low: 3,
};

export const sessionStatus = {
  confirmed: 3,
  notDiscussed: 4,
  toBeConfirmed: 1,
  planned: 2,
};

export const projectWorkloadColumNames = {
  name: 'Name',
  fullName: 'FullName',
  total: 'Totale',
  projectUserRelationshipID: 'ProjectUserRelationshipID',
  key: 'key',
  type: 'Type',
  options: 'Options',
  userID: 'UserID',
  projectName: 'ProjectName',
  projectID: 'ProjectID',
  consuntivo: 'Consuntivo',
  disponibile: 'Disponibile',
};

export const workloadColumnNamesValues = {
  budgetProject: 'BudgetProgetto',
  budgetUserProject: 'BudgetUtenteProgetto',
  totalBudgetProject: 'TotaleBudgetProgetto',
  budget: 'Budget',
  budgetUser: 'BudgetUtente',
  totalBudget: 'TotalBudget',
  consuntivo: 'Consuntivo',
  disponibile: 'Disponibile',
};

export const projectCostColumNames = {
  name: 'Name',
  fullName: 'FullName',
  total: 'Totale',
  projectCostDetailID: 'ProjectCostDetailID',
  key: 'key',
  type: 'Type',
  projectCostName: 'ProjectCostName',
  budget: 'Budget',
  consuntivo: 'Consuntivo',
  residuo: 'Residuo',
  projectID: 'ProjectID',
  budgetProgetto: 'BudgetProgetto',
  allocatoProgetto: 'AllocatoProgetto',
  nonAllocato: 'NonAllocato',
  budgetUtente: 'BudgetUtente',
  consuntivoUtente: 'ConsuntivoUtente',
  ownerID: 'OwnerID',
};

export const workloadTypeValues = {
  project: 'project',
  user: 'user',
  total: 'total',
};

export const projectTeamMembersColums = {
  fullName: 'FullName',
  disponibility: 'Disponibility',
  jobTitle: 'JobTitle',
  userID: 'UserID',
  onCall: 'OnCall',
  key: 'key',
  hasOpenActivities: 'HasOpenActivities',
  isDeleted: 'IsDeleted',
};

export const userTimePlanningColumns = {
  userID: 'UserID',
  projectID: 'ProjectID',
  projectName: 'ProjectName',
  name: 'Name',
  total: 'Totale',
  key: 'key',
  startDate: 'StartDate',
  endDate: 'EndDate',
};

export const projectTimePlanningColums = {
  userID: 'UserID',
  userName: 'UserName',
  fullName: 'FullName',
  projectID: 'ProjectID',
  projectName: 'ProjectName',
  name: 'Name',
  total: 'Totale',
  key: 'key',
  members: 'Members',
  startDate: 'StartDate',
  endDate: 'EndDate',
  budget: 'Budget',
};

export const bowlingChartColums = {
  name: 'Name',
  kpiDetailName: 'KPIDetailName',
  jumpOffPoint: 'JumpOffPoint',
  parentType: 'ParentType',
  parentTypeDescription: 'ParentTypeDescription',
  type: 'Type',
  typeDescription: 'TypeDescription',
  key: 'key',
  kPIProjectRelationShipID: 'KPIProjectRelationShipID',
  isRollupKpi: 'IsRollupKPI',
  rollupKPIName: 'RollupKPIName',
  parentKPIProjectRelationShipID: 'ParentKPIProjectRelationShipID',
};

export const anualGoalBowlingChartColums = {
  name: 'name',
  kpiDetailName: 'kpiDetailName',
  jumpOffPoint: 'jumpOffPoint',
  parentType: 'parentType',
  parentTypeDescription: 'parentTypeDescription',
  type: 'type',
  typeDescription: 'typeDescription',
  key: 'key',
  kPIProjectRelationShipID: 'kpiProjectRelationshipID',
  isRollupKpi: 'isRollupKPI',
  rollupKPIName: 'rollupKpiName',
  parentKPIProjectRelationShipID: 'parentKPIProjectRelationshipID',
  monthsList: 'monthsList',
};

export const bowlingChartColumsValues = {
  targetYTD: 'TargetYTD',
};

export const feedRegardingObjectType = {
  project: 1,
  deliverable: 3,
  longTermGoal: 4,
  annualGoal: 5,
  myActivity: 2,
  commitie: 6,
  kpi: 7,
  feed: 8,
  routine: 9,
  meeting: 10,
  file: 11,
};

export const userParticipation = {
  required: 1,
  optional: 2,
  organiser: 3,
};

export const projectRoutineProgram = {
  daily: 1,
  weekly: 2,
  monthly: 3,
  yearly: 4,
};

export const companyLiceseTypes = [
  {
    key: 2,
    name: 'POC',
  },
  {
    key: 3,
    name: 'Beta',
  },
  {
    key: 1,
    name: 'Standard',
  },
];

export const companycurrencies = [
  {
    key: 'EUR',
    name: 'Euro - EUR',
    symbol: '€',
  },
  {
    key: 'USD',
    name: 'United States Dollar - USD',
    symbol: '$',
  },
  {
    key: 'GBP',
    name: 'British Pound - GBP',
    symbol: '£',
  },
];

export const filterRoles = [
  {
    text: 'Sponsor',
    value: 'Sponsor',
  },
  {
    text: 'Team Leader',
    value: 'Team Leader',
  },
  {
    text: 'Team Member',
    value: 'Team Member',
  },
];

export const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export const kpiTypeRealtion = {
  project: 'project',
  annualGoal: 'annualGoal',
};

export const DatePickerWithDateFns = DatePicker.generatePicker<Date>(dateFnsGenerateConfig);

export const ProjectTypeAsNumber = Object.values(ProjectType).map((value, index) => (
  value
));
