import { Col, Row, Statistic } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TreeXmatrixFilter from 'src/components/shared/components/treeXmatrixFilter/treeXmatrixFilter';
import { useAppSelector } from 'src/redux/store';
import { addQueryParam, getQueryParam, hasQueryParam } from 'src/utils/url-utils';
import MatrixService from '../../../../services/matrix/matrixService';
import CommitieService from '../../../../services/pages/commitieServices';
import '../../../personalDashbard/personalDashboard.scss';
import Loader2 from '../../../shared/components/loader2/loader2';
import { checkUserIsLenovysOrCompanyAdmin } from '../../../shared/utils/authRolesProvilege/authRolesPrivilege';
import { formatOfDate } from '../../../shared/utils/constants';
import CommittieTeam from '../comitieTeam/comitieTeam';
import RelatedComities from '../relatedComities';
import ComitieActivityChart from './activityChart';
import CommitteeProjects from '../CommitteeProjects';

const ComitieSummary = ({ committee }) => {
  const userData = useAppSelector((state) => state.userData.userData);
  const activeXmatrixInfo = useAppSelector((state) => state.activeXMatrix.activeXMatrix);
  const [comitieSummary, setComitieSummary] = useState(null);
  const [loadingComitieSummary, setLoadingComitieSummary] = useState(false);
  const [selectedXmatrixId, setSelectedXmatrixId] = useState('');
  const [xmatrixPicklist, setXmatrixPicklist] = useState([]);

  const [userHasAccess, setUserHasAccess] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    fetchXmatrixPicklist();
  }, []);

  useEffect(() => {
    if (activeXmatrixInfo && xmatrixPicklist) {
      if (
        hasQueryParam('xMatrix') &&
        getQueryParam('xMatrix') !== undefined &&
        getQueryParam('xMatrix') !== 'undefined'
      ) {
        setSelectedXmatrixId(getQueryParam('xMatrix'));
      } else {
        setSelectedXmatrixId(activeXmatrixInfo?.xMatrixID);
      }
    }
  }, [activeXmatrixInfo, xmatrixPicklist]);

  useEffect(() => {
    if (selectedXmatrixId) {
      getComitieSummary();
      getCommitie();

      checkIfUserHasAceess(userData);
    }
  }, [userData, selectedXmatrixId]);

  const fetchXmatrixPicklist = async () => {
    const response = await MatrixService.getXmatrixPicklist();
    const resp = response.data;

    if (resp.success) {
      const respData = resp.responseObject?.value;
      setXmatrixPicklist(respData);
    }
  };

  const getComitieSummary = () => {
    setLoadingComitieSummary(true);
    CommitieService.getComitieSummaryData(committee.id, selectedXmatrixId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const respData = resp.responseObject.value;
          setComitieSummary(respData);
          setLoadingComitieSummary(false);
        } else {
          setLoadingComitieSummary(false);
        }
      })
      .catch((error) => {
        setLoadingComitieSummary(false);
      });
  };

  const getCommitie = () => {
    CommitieService.getById(committee.id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const respData = resp.responseObject.value;
          checkIfUserHasAceess(respData.participants);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const checkIfUserHasAceess = (partecipatUsers) => {
    const accessByRole = checkUserIsLenovysOrCompanyAdmin(userData);

    const isUserPartecipant =
      Array.isArray(partecipatUsers) && partecipatUsers?.filter((user) => user.userID.includes(userData.userId));

    if ((isUserPartecipant && isUserPartecipant.length > 0) || accessByRole) {
      setUserHasAccess(true);
    } else {
      setUserHasAccess(false);
    }
  };

  const onTreeXmatrixChange = (value) => {
    if (value) {
      setSelectedXmatrixId(value[1]);
      addQueryParam('xMatrix', value[1]);
    }
  };

  return (
    <>
      <div className="tw-flex tw-flex-col tw-gap-6">
        {/* Title */}
        <div className="tw-flex tw-flex-row tw-justify-between">
          <h1 className="tw-text-3xl tw-font-medium">{t('comitatiPage.somarioComitati')}</h1>

          <div>
            {xmatrixPicklist && selectedXmatrixId && !CommitieService.isOperational(committee.type) && (
              <TreeXmatrixFilter
                showSearch={false}
                allowClear={false}
                includeChildren={false}
                selectedXmatrixIds={[selectedXmatrixId]}
                xmatrixPicklist={xmatrixPicklist}
                onTreeXmatrixChange={onTreeXmatrixChange}
              />
            )}
          </div>
        </div>

        {loadingComitieSummary && <Loader2 />}

        {comitieSummary && !loadingComitieSummary && (
          <>
            <Row
              gutter={24}
              className="tw-border-b tw-border-zinc-200"
            >
              <Col
                xs={24}
                md={12}
              >
                <Row>
                  <Col
                    xs={24}
                    md={12}
                  >
                    <Statistic
                      className="brand-statistic [&_.ant-statistic-content-value]:!tw-text-primary tw-border-b tw-border-r"
                      title={t('comitatiPage.comitatiProgramati')}
                      value={comitieSummary.plannedCommitee ? comitieSummary.plannedCommitee : '-'}
                    />
                  </Col>
                  <Col
                    xs={24}
                    md={12}
                  >
                    <Statistic
                      className="brand-statistic tw-border-b tw-border-r"
                      title={t('comitatiPage.comitatiSvolti')}
                      value={comitieSummary.closedCommittee ? comitieSummary.closedCommittee : '-'}
                    />
                  </Col>

                  <Col
                    xs={24}
                    md={12}
                  >
                    <Statistic
                      className="brand-statistic tw-border-r"
                      title={t('comitatiPage.azioniAperte')}
                      value={comitieSummary.openActivities ? comitieSummary.openActivities : '-'}
                    />
                  </Col>

                  <Col
                    xs={24}
                    md={12}
                  >
                    <Statistic
                      className="brand-statistic tw-border-r"
                      title={t('comitatiPage.prosimoComitato')}
                      value={
                        comitieSummary.nextCommittee ? moment(comitieSummary.nextCommittee).format(formatOfDate) : '-'
                      }
                    />
                  </Col>
                </Row>
              </Col>

              <Col
                xs={24}
                md={12}
              >
                <ComitieActivityChart
                  className="tw-grow tw-shrink-0"
                  t={t}
                  comitieId={committee.id}
                />
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xs={24}>
                <CommitteeProjects
                  committee={committee}
                  onlyView={true}
                  showXmatrixFilter={false}
                />
              </Col>

              <Col
                xs={24}
                className="tw-mt-4"
              >
                <RelatedComities
                  comitieId={committee.id}
                  onlyView={true}
                  userHasAccess={userHasAccess}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={24}>
                <CommittieTeam
                  committee={committee}
                  onlyView={true}
                  userHasAccess={userHasAccess}
                />
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default ComitieSummary;
