import { Button, message, Popconfirm, Popover, Table, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ProjectSectionTitle from 'src/components/shared/components/ProjectSectionTitle/ProjectSectionTitle';
import Section4Service from '../../../services/a3/section4Service';
import { ResponsiblePopoverContent, projectDeliverableStatusColor } from '../../shared/utils/functions';
import BraftEditor from 'braft-editor';
import { RichTextEditorControls } from 'src/components/shared/utils/constants';
import ProgettiService from 'src/services/pages/projectServices/progettiService';
import { notifyMessages } from 'src/components/shared/utils/notifyMessages';
import { IoArchiveOutline } from 'react-icons/io5';
import { useAppSelector } from 'src/redux/store';
import { format } from 'date-fns';
import FeedService from 'src/services/pages/projectServices/feedServices';
import { useDispatch } from 'react-redux';
import { getProjectById } from 'src/redux/slices/projectSlice';

function ProssimiPassi({ projectId, companyData, currentProject }) {
  const [deliverableData, setDeliverableData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [editorState, setEditorState] = useState(BraftEditor.createEditorState(currentProject.nextStepsNote));

  const { t } = useTranslation();

  const userData = useAppSelector((state) => state.userData.userData);
  const dispatch = useDispatch();

  useEffect(() => {
    retrieveDeliverable(projectId);
  }, []);

  const retrieveDeliverable = async (projectId) => {
    setLoadingData(true);

    try {
      const response = await Section4Service.getProjectDeliverable(projectId, companyData.implementationDaysOfNotice);
      const resp = response.data;

      if (resp.success) {
        const respData = resp.responseObject.value;
        setDeliverableData(respData);
      }
    } catch {
    } finally {
      setLoadingData(false);
    }
  };

  const columns = [
    {
      title: `${t('general.end')}`,
      dataIndex: 'startDate',
      ellipsis: {
        showTitle: false,
      },
      width: '120px',
      render: (text, record) => {
        return {
          props: {
            style: { borderLeft: `5px solid ${projectDeliverableStatusColor(record.deliverableStatus)}` },
          },
          children: (
            <div>
              <Tooltip title={record.notes}>{moment(text).format('MM/YYYY')}</Tooltip>
            </div>
          ),
        };
      },
    },
    {
      title: `${t('general.deliverable')}`,
      dataIndex: 'name',
      key: 'name',
      className: 'twoRowEllipses',
      render: (title, record) => (
        <Tooltip title={title}>
          <Link
            to={{ pathname: `/deliverables/id/${record.deliverableID}` }}
            style={{ color: 'black' }}>
            {title}
          </Link>
        </Tooltip>
      ),
    },
    {
      title: `${t('general.assegnatoA')}`,
      dataIndex: 'ownerFullNameInitials',
      key: 'ownerFullNameInitials',
      width: '80px',
      ellipsis: {
        showTitle: false,
      },
      render: (title, record) => (
        <Popover
          content={
            <ResponsiblePopoverContent
              data={record}
              t={t}
            />
          }
          title={record.ownerFullName}>
          <span style={record.isGenericResponsabile === true ? { color: '#f2883b' } : {}}>{title}</span>
        </Popover>
      ),
    },
  ];

  const onEditorChange = (value) => {
    setEditorState(value);
  };

  const saveNextStepsNote = async () => {
    try {
      const payload = {
        projectFieldName: 'nextStepsNote',
        projectFieldValue: editorState.toHTML(),
        projectId,
      };

      await ProgettiService.updateEditorField(payload);

      dispatch(getProjectById({ projectId }));
      message.success(notifyMessages.updateSuccess);
    } catch {}
  };

  const archiveNote = async function () {
    try {
      await FeedService.addFeedComment({
        createdBy: userData?.userId,
        priority: false,
        regardingObjectTypeCode: 1,
        subject: t('proggetiPage.commentoProssimiPassi') + ' - ' + format(new Date(), 'dd/MM/yyyy'),
        description: editorState.toHTML(),
        regardingObjectID: currentProject.projectID,
      });

      const payload = {
        projectFieldName: 'nextStepsNote',
        projectFieldValue: null,
        projectId,
      };

      await ProgettiService.updateEditorField(payload);

      dispatch(getProjectById({ projectId }));

      setEditorState(BraftEditor.createEditorState(''));

      message.success(notifyMessages.updateSuccess);
    } catch {
    }
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <ProjectSectionTitle title={t('proggetiPage.prossimiPassi')} />

      <Table
        className={'custom-table-section1 key-decision}'}
        columns={columns}
        rowClassName={(record) => 'status-' + record.deliverableStatus}
        dataSource={deliverableData}
        pagination={false}
        loading={loadingData}
        rowKey={(obj) => obj.deliverableID}
        size="small"
      />

      <div className="tw-flex tw-justify-between">
        <h1 className="tw-text-3xl tw-font-semibold">{t('proggetiPage.commentoProssimiPassi')}</h1>

        {currentProject.nextStepsNote && (<Popconfirm
          placement="topRight"
          title={t('a3Page.archiveSectionConfirm')}
          onConfirm={() => { void archiveNote(); }}
          okText={t('general.si')}
          cancelText={t('general.no')}>
          <Button
            type="default"
            icon={<IoArchiveOutline />}></Button>
        </Popconfirm>) }
      </div>

      <div className="tw-rounded-lg tw-overflow-hidden">
        <BraftEditor
          value={editorState}
          onChange={onEditorChange}
          controls={RichTextEditorControls}
          id="editor-text"
        />
      </div>

      <div className="tw-flex tw-justify-end">
        <Button
          type="primary"
          onClick={saveNextStepsNote}>
          {t('buttons.salva')}
        </Button>
      </div>
    </div>
  );
}

export default ProssimiPassi;
