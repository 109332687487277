import { BorderOuterOutlined, DesktopOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import { Button, Tabs, Tooltip, message } from 'antd';
import type React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { ObHeader } from 'src/components/obietiviAnnoListPage/details';
import { useFullscreen } from 'src/hooks/FullScreen';
import { useAppSelector } from 'src/redux/store';
import CommonService from '../../../services/commonService';
import MatrixService from '../../../services/matrix/matrixService';
import ObiettiviLongService from '../../../services/pages/obiettiviLongService';
import { addQueryParam, getQueryParam } from '../../../utils/url-utils';
import Loader from '../../shared/components/loader/loader';
import { isTeamleaderOrTeamMemberOrObjectSponsor } from '../../shared/utils/authRolesProvilege/authRolesPrivilege';
import { longOrAnnualGoalFieldUpdate, objectTypeUserSecurity } from '../../shared/utils/constants';
import { NoAccessMesssage } from '../../shared/utils/functions';
import { notifyMessages } from '../../shared/utils/notifyMessages';
import '../obietiviLongTerm.scss';
import LongTermFeed from './feed';
import Impostazioni from './impostazioni';
import Workshop from './workshop';

const IndexDetailsObLungo: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id: longTermId, xmatrix: routeSelectedXmatrix } = useParams();
  const { enterFullscreen, exitFullscreen, isFullscreen } = useFullscreen();

  const userData = useAppSelector((state) => state.userData.userData);

  const [activeXmatrixInfo, setActiveXmatrixInfo] = useState<any>(null);
  const [selectedXmatrixValue, setSelectedXmatrixValue] = useState<string | null>(null);
  const [longTermData, setLongTermData] = useState<any>(null);
  const [loadingData, setLoadingData] = useState(false);
  const [checkUserAccessOnObject, setCheckUserAccessOnObject] = useState(false);
  const [activeKeyTab, setActiveKeyTab] = useState(getQueryParam('tab') || '2');
  const [editableTitle, setEditableTitle] = useState('');
  const [presentationMode, setPresentationMode] = useState(false);

  useEffect(() => {
    if (longTermId) {
      getActiveXmatrix();
      checkIfUserHasAccess(longTermId);
    }

    setActiveKeyTab(getQueryParam('tab') || '2');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getObiettiviLongTermData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeXmatrixInfo, selectedXmatrixValue]);

  const onXmatrixSelect = (selectedXmatrix: { value: string }) => {
    setSelectedXmatrixValue(selectedXmatrix.value);
  };

  const getActiveXmatrix = async () => {
    try {
      const response = await MatrixService.getActiveXmatrix();
      const data = response.data;
      if (data.success) {
        setActiveXmatrixInfo(data.responseObject?.value);
      }
    } catch (error) {
      console.error('Error fetching active Xmatrix:', error);
    }
  };

  const checkIfUserHasAccess = async (objectId: string) => {
    const payload = {
      type: objectTypeUserSecurity.longTermGoal,
      objectID: objectId,
    };
    try {
      const response = await CommonService.chaeckUserAccessOnObject(payload);
      const data = response.data;
      setCheckUserAccessOnObject(data.responseObject?.value?.hasPrivilege === 1);
    } catch (error) {
      console.error('Error checking user access:', error);
    }
  };

  const getObiettiviLongTermData = async () => {
    if (!longTermId) return;

    const xmatrix = selectedXmatrixValue || activeXmatrixInfo?.xMatrixID;
    if (xmatrix) {
      setLoadingData(true);
      try {
        const response = await ObiettiviLongService.getObiettiviLongByiD(longTermId, xmatrix);
        const data = response.data;
        if (data.success) {
          setLongTermData(data.responseObject?.value);
          setEditableTitle(data.responseObject?.value?.title || '');
        } else {
          message.error(notifyMessages.retrieveFailed);
        }
      } catch (error) {
        message.error(notifyMessages.retrieveFailed);
      } finally {
        setLoadingData(false);
      }
    }
  };

  const onTitleSave = (value: string) => {
    const payload = {
      itemID: longTermId,
      nameOrNote: value,
      updateCase: longOrAnnualGoalFieldUpdate.longTermGoalName,
    };
    setEditableTitle(value);
    updateCommentOrName(payload);
  };

  const updateCommentOrName = async (payload: any) => {
    try {
      const response = await ObiettiviLongService.updateCommentOrNameField(payload);
      const data = response.data;
      if (data.success) {
        getObiettiviLongTermData();
      } else {
        message.error(notifyMessages.updateFailed);
      }
    } catch (error) {
      message.error(notifyMessages.updateFailed);
    }
  };

  const onPresentationModeActivate = () => {
    setActiveKeyTab('1');
    setPresentationMode(true);
  };

  const updateLongTermData = (selectedLongTermId: string) => {
    if (selectedLongTermId === longTermId) {
      getObiettiviLongTermData();
    }
  };

  const isRoleTeamMemberOrTeamLeader = isTeamleaderOrTeamMemberOrObjectSponsor(userData, longTermData);

  const tabsItems = [
    {
      label: t('obiettiviLungoPage.workShopComments'),
      key: '1',
      children: (
        <Workshop
          isPresentationMode={presentationMode}
          routeSelectedXmatrix={routeSelectedXmatrix}
          updateLongTermData={updateLongTermData}
          longTermData={longTermData}
          longTermId={longTermId}
          isRoleTeamMemberOrTeamLeader={isRoleTeamMemberOrTeamLeader}
          updateFields={updateCommentOrName}
        />
      ),
    },
    {
      label: t('obiettiviLungoPage.impostazioni'),
      key: '2',
      disabled: presentationMode,
      children: (
        <Impostazioni
          routeSelectedXmatrix={routeSelectedXmatrix}
          longTermData={longTermData}
          longTermId={longTermId}
          isRoleTeamMemberOrTeamLeader={isRoleTeamMemberOrTeamLeader}
          reloadData={getObiettiviLongTermData}
          activeXmatrixInfo={activeXmatrixInfo}
          onXmatrixSelect={onXmatrixSelect}
          loadingData={loadingData}
        />
      ),
    },
    {
      label: t('general.commenti'),
      key: '3',
      disabled: presentationMode,
      children: (
        <LongTermFeed
          defaultCheckedFeedType={2}
          longTermId={longTermId}
          commentFeedsOnly
          fileFeedsOnly={false}
          othersFeedsOnly={false}
        />
      ),
    },
    {
      label: t('proggetiPage.file'),
      key: '4',
      disabled: presentationMode,
      children: (
        <LongTermFeed
          defaultCheckedFeedType={3}
          longTermId={longTermId}
          commentFeedsOnly={false}
          fileFeedsOnly
          othersFeedsOnly={false}
        />
      ),
    },
    {
      label: t('proggetiPage.notificeRiunioni'),
      key: '5',
      disabled: presentationMode,
      children: (
        <LongTermFeed
          defaultCheckedFeedType={4}
          longTermId={longTermId}
          commentFeedsOnly={false}
          fileFeedsOnly={false}
          othersFeedsOnly
        />
      ),
    },
  ];

  const handlePresentationMode = () => {
    if (isFullscreen) {
      exitFullscreen();
    } else {
      enterFullscreen();
    }
  };

  return (
    <div className="tw-bg-white">
      <Loader />
      {longTermData && (
        <>
          {!checkUserAccessOnObject ? (
            <NoAccessMesssage />
          ) : (
            <div className="tw-bg-white">
              <ObHeader
                editableTitle={editableTitle}
                onTitleSave={onTitleSave}
                isRoleTeamMemberOrTeamLeader={isRoleTeamMemberOrTeamLeader}
              />

              <Tabs
                className="tw-px-4"
                activeKey={activeKeyTab}
                onChange={(key) => {
                  setActiveKeyTab(key);
                  addQueryParam('tab', key);
                }}
                items={tabsItems}
                tabBarExtraContent={{
                  right: activeKeyTab === '1' && (
                    <>
                      {presentationMode ? (
                        <div className="tw-flex tw-items-center tw-gap-2">
                          <Tooltip title={t('a3Page.fullscreenPage')}>
                            <Button
                              icon={<BorderOuterOutlined />}
                              onClick={handlePresentationMode}
                            />
                          </Tooltip>
                          <Button
                            onClick={() => setPresentationMode(false)}
                            icon={<FullscreenExitOutlined />}
                            type="primary"
                          >
                            {t('general.exit')}
                          </Button>
                        </div>
                      ) : (
                        <Button
                          type="primary"
                          icon={<DesktopOutlined />}
                          onClick={onPresentationModeActivate}
                        >
                          {t('a3Page.presenta')}
                        </Button>
                      )}
                    </>
                  ),
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default IndexDetailsObLungo;
