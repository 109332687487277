import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Card, Col, Modal, Row, Select, Switch } from 'antd';
import { type FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  type GetCommitteePicklistDto,
  type GetDivisionPicklistDto,
  type GetProjectCodesResponseDto,
  type GetProjectStatusPicklistDto,
  type GetSponsorPicklistResponseDto,
  type GetTeamLeaderPicklistDto,
  ProjectParentship,
  ProjectStatus,
  ProjectType,
  type QueryProjectFilters,
} from 'src/connectors/backend';

const { Option } = Select;

export interface FiltersPicklists {
  teamLeaders: GetTeamLeaderPicklistDto[];
  projectStatuses: GetProjectStatusPicklistDto[];
  projectCodes: GetProjectCodesResponseDto[];
  divisions: GetDivisionPicklistDto[];
  committees: GetCommitteePicklistDto[];
  sponsors: GetSponsorPicklistResponseDto[];
}

export interface ProjectsTimelineFiltersProps {
  showFiltersModal: boolean;
  filtersModalClose: () => void;
  applyFilters: (newFilters: QueryProjectFilters) => void;
  picklists: FiltersPicklists;
  initialValue: QueryProjectFilters;
}

const ProjectsTimelineFilters: FC<ProjectsTimelineFiltersProps> = ({
  initialValue,
  applyFilters,
  picklists,
  showFiltersModal,
  filtersModalClose,
}) => {
  const { t } = useTranslation();

  const [filters, setFilters] = useState<QueryProjectFilters>({
    selectNotRelatedProjects: true,
    type: ProjectType.NonOperational,
    projectStatuses: [ProjectStatus.Active],
    sponsorIds: [],
    commiteeIds: [],
    divisionIds: [],
    projectCodes: [],
    teamLeaderIds: [],
    projectParentship: ProjectParentship.All,
    xMatrixIds: [],
  });

  useEffect(() => {
    setFilters(initialValue);
  }, [initialValue]);

  const onShowProjectsWithoutRelationsChange = (checked: boolean) => {
    setFilters({ 
      ...filters, 
      type: checked ? ProjectType.Operational : ProjectType.NonOperational,
      selectNotRelatedProjects: checked,
    });
  };

  const onTeamLeaderChange = (userId: string) => {
    setFilters({ ...filters, teamLeaderIds: [userId] });
  };

  const onProjectStatusChange = (status: ProjectStatus) => {
    setFilters({ ...filters, projectStatuses: [status] });
  };

  const onProjectCodeChange = (code: string) => {
    setFilters({ ...filters, projectCodes: [code] });
  };

  const onDivisionChange = (division: string) => {
    setFilters({ ...filters, divisionIds: [division] });
  };

  const onCommitteeChange = (committee: string) => {
    setFilters({ ...filters, commiteeIds: [committee] });
  };

  const onSponsorChange = (sponsor: string) => {
    setFilters({ ...filters, sponsorIds: [sponsor] });
  };

  const translateProjectStatus = (status: ProjectStatus) => {
    const projectStatusTranslations: { [key in ProjectStatus]: string } = {
      active: 'Attivo',
      suspended: 'Sospeso',
      draft: 'Draft',
      completed: 'Chiuso',
    };

    return projectStatusTranslations[status];
  };

  const onClearFilters = () => {
    const clearedFilters: QueryProjectFilters = {
      selectNotRelatedProjects: true,
      sponsorIds: [],
      teamLeaderIds: [],
      projectStatuses: [],
      projectCodes: [],
      divisionIds: [],
      commiteeIds: [],
      projectParentship: ProjectParentship.All,
      xMatrixIds: [],
    };

    setFilters(clearedFilters);
    applyFilters(clearedFilters);
  };

  return (
    <Modal
      width={800}
      title={t('dashboardProgettiPage.dashboardFilter')}
      open={showFiltersModal}
      onCancel={filtersModalClose}
      footer={null}
    >
      <Card className="tw-flex tw-flex-col">
        <Row
          className="tw-py-2"
          gutter={{ lg: 24 }}
          style={{ borderBottom: '1px dashed #a0a0a0' }}
        >
          <Col
            className="center-text pad6 gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <span>{t('dashboardProgettiPage.teamLeader')}</span>
            <Select
              value={filters.teamLeaderIds?.at(0)}
              style={{ width: '100%' }}
              placeholder={t('general.seleziona')}
              onChange={(value) => onTeamLeaderChange(value)}
              options={picklists.teamLeaders.map((user) => ({
                value: user.userID,
                label: user.fullName,
                disabled: user.isDeleted,
                key: user.userID,
              }))}
              showSearch
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            />
          </Col>

          <Col
            className="center-text pad6 gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <span>{t('dashboardProgettiPage.statoProgetto')}</span>
            <Select
              value={filters.projectStatuses?.at(0)}
              style={{ width: '100%' }}
              showSearch
              placeholder={t('general.seleziona')}
              optionFilterProp="children"
              onChange={(value) => onProjectStatusChange(value)}
            >
              <Option
                value={undefined}
                key={undefined}
                style={{ color: '#afaeae' }}
              >
                {t('general.seleziona')}...
              </Option>
              {Object.values(ProjectStatus).map((status) => (
                <Option
                  value={status}
                  key={status}
                >
                  {translateProjectStatus(status)}
                </Option>
              ))}
            </Select>
          </Col>

          <Col
            className="center-text pad6 gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <span>{t('proggetiPage.codiceProgetto')}</span>
            <Select
              value={filters.projectCodes?.at(0)}
              style={{ width: '100%' }}
              showSearch
              placeholder={t('general.seleziona')}
              optionFilterProp="children"
              onChange={(value) => onProjectCodeChange(value)}
            >
              <Option
                value={undefined}
                key={undefined}
                style={{ color: '#afaeae' }}
              >
                {t('general.seleziona')}...
              </Option>
              {picklists.projectCodes.map((item) => (
                <Option
                  value={item.projectCode}
                  key={item.projectCode}
                >
                  {item.projectCode}
                </Option>
              ))}
            </Select>
          </Col>

          <Col
            className="center-text pad6 gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <span>{t('dashboardProgettiPage.divisioniReparti')}</span>
            <Select
              value={filters.divisionIds?.at(0)}
              style={{ width: '100%' }}
              showSearch
              placeholder={t('general.seleziona')}
              optionFilterProp="children"
              onChange={(value) => onDivisionChange(value)}
              filterOption={(input, option) =>
                (option as GetDivisionPicklistDto).divisionName?.toLowerCase() === input.toLowerCase()
              }
            >
              <Option
                value={undefined}
                key={undefined}
                style={{ color: '#afaeae' }}
              >
                {t('general.seleziona')}...
              </Option>
              {picklists.divisions.map((item) => (
                <Option
                  disabled={item.hasAccess !== 1}
                  value={item.divisionID}
                  key={item.divisionID}
                >
                  {item.divisionName}
                </Option>
              ))}
            </Select>
          </Col>

          <Col
            className="center-text pad6 gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <span>{t('general.commitato')}</span>
            <Select
              value={filters.commiteeIds?.at(0)}
              style={{ width: '100%' }}
              showSearch
              placeholder={t('general.seleziona')}
              optionFilterProp="children"
              onChange={(value) => onCommitteeChange(value)}
              filterOption={(input, option) =>
                (option as GetCommitteePicklistDto).name?.toLowerCase() === input.toLowerCase()
              }
            >
              <Option
                value={undefined}
                key={undefined}
                style={{ color: '#afaeae' }}
              >
                {t('general.seleziona')}...
              </Option>
              {picklists.committees.map((item) => (
                <Option
                  disabled={item.hasAccess !== 1}
                  value={item.committeeID}
                  key={item.committeeID}
                >
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>

          <Col
            className="center-text pad6 gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <span>{t('general.sponsor')}</span>
            <Select
              value={filters.sponsorIds?.at(0)}
              style={{ width: '100%' }}
              showSearch
              placeholder={t('general.seleziona')}
              optionFilterProp="children"
              onChange={(value) => onSponsorChange(value)}
              filterOption={(input, option) =>
                (option as GetSponsorPicklistResponseDto).fullName?.toLowerCase() === input.toLowerCase()
              }>
              <Option
                value={undefined}
                key={undefined}
                style={{ color: '#afaeae' }}>
                {t('general.seleziona')}...
              </Option>
              {picklists.sponsors.map((item) => (
                <Option
                  value={item.userID}
                  key={item.userID}>
                  {item.fullName}
                </Option>
              ))}
            </Select>
          </Col>

          <Col
            className="pad6 gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}>
            <span>{t('proggetiPage.showOperationalProjects')}</span>
            <br />
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={filters.type === ProjectType.Operational}
              onChange={(checked) => onShowProjectsWithoutRelationsChange(checked)}
            />
          </Col>
        </Row>
        <Col
          className="gutter-row-filter pad6"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}>
          <div className="buttonFilters tw-py-2">
            <Button
              type="primary"
              style={{ marginRight: 10 }}
              onClick={() => applyFilters(filters)}>
              {t('general.filter')}
            </Button>
            <Button
              type="dashed"
              onClick={() => onClearFilters()}>
              {t('general.clearFilters')}
            </Button>
          </div>
        </Col>
      </Card>
    </Modal>
  );
};

export default ProjectsTimelineFilters;
