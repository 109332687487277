import { EditFilled, EditOutlined, PlusOutlined, SaveOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { Button, Col, Form, Popover, Radio, Row, Space, Table, Tabs, Tooltip, Typography, message } from 'antd';
import BraftEditor from 'braft-editor';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { enumCast } from 'src/config/connectors';
import { AnnualGoalStatus } from 'src/connectors/backend';
import CommonService from '../../../services/commonService';
import MatrixService from '../../../services/matrix/matrixService';
import ObAnnoService from '../../../services/matrix/obAnnoService';
import ObLongService from '../../../services/matrix/obLongService';
import RelationsService from '../../../services/matrix/relationsService';
import ObiettiviAnnoService from '../../../services/pages/obiettiviAnnoService';
import XmatrixDropDownFilter from '../../shared/components/xmatrixDropdownFilter/xmatrixDropdownFilter';
import TableLayout from '../../shared/tableLayout';
import {
  RichTextEditorControls,
  formItemLayout,
  longOrAnnualGoalFieldUpdate,
  statusPickListCodes,
} from '../../shared/utils/constants';
import { notifyMessages } from '../../shared/utils/notifyMessages';
import AddObietivoAnnoModal from '../../xMatrix/data_tables/center/addObAnnoModal';
import AddObietivoLungoModal from '../../xMatrix/data_tables/center/addObLungoModal';

const Workshop = ({
  annualGoalData,
  annualGoalId,
  isRoleTeamMemberOrTeamLeader,
  loadingSaveField = false,
  updateFields,
  updateAnnualGoalData,
  routeSelectedXmatrix,
  isPresentationMode,
}) => {
  const { t } = useTranslation();

  const [comentEditiorValue, setComentEditiorValue] = useState(
    annualGoalData.getAnnualGoalDto.notes ? annualGoalData.getAnnualGoalDto.notes : '',
  );

  const [activeXmatrixInfo, setActiveXmatrixInfo] = useState(null);
  const [selectedXmatrixValue, setSelectedXmatrixValue] = useState(null);
  const [annualGoalList, setAnnualGoalList] = useState(null);
  const [loadingAnualGoal, setLoadingAnualGoal] = useState(false);
  const [loadingUpdateRelation, setLoadingUpdateRelation] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [statusList, setStatusList] = useState(null);
  const [kpiList, setKpiList] = useState(null);
  const [loadingKpiList, setKoadingKpiList] = useState(false);
  const [obAnnualRelationCheck] = useState(false);

  const [newLongTermModal, setNewLongTermModal] = useState(false);
  const [newAnnualGoalModal, setNewAnnualGoalModal] = useState(false);

  const [selectedObietivoData, setSelectedObietivoData] = useState(null);
  const [selectedObietivoID, setSelectedObietivoID] = useState(annualGoalId);
  const [loadingSelectedObietivo, setLoadingSelectedObietivo] = useState(false);

  useEffect(() => {
    getActiveXmatrix();
    retrieveAnnualGoalList();
    retrieveGoalStatuses();

    if (routeSelectedXmatrix) {
      setSelectedXmatrixValue(routeSelectedXmatrix);
    }
  }, []);

  useEffect(() => {
    getSelectedObietivoData(selectedObietivoID);
    getObjAnnoKPI(selectedObietivoID);
    retrieveAnnualGoalList();
  }, [activeXmatrixInfo, selectedXmatrixValue]);

  const getActiveXmatrix = async () => {
    await MatrixService.getActiveXmatrix()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const respData = resp.responseObject.value;
          setActiveXmatrixInfo(respData);
        } else {
        }
      })
      .catch((error) => {});
  };

  const onXmatrixSelect = (selectedXmatrix) => {
    setSelectedXmatrixValue(selectedXmatrix.value);
  };

  const retrieveGoalStatuses = async () => {
    const objectCode = statusPickListCodes.statusObAnnoLong;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setStatusList(resp.responseObject.value);
        } else {
        }
      })
      .catch((error) => {});
  };

  const retrieveAnnualGoalList = async () => {
    if (activeXmatrixInfo) {
      const xmatrix = selectedXmatrixValue ? selectedXmatrixValue : activeXmatrixInfo?.xMatrixID;

      setLoadingAnualGoal(true);
      await ObiettiviAnnoService.getObiettiviAnnoList(obAnnualRelationCheck, xmatrix)
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            const respData = resp.responseObject.value;
            setAnnualGoalList(respData);
            setLoadingAnualGoal(false);
          } else {
            message.error(notifyMessages.retrieveFailed);
            setLoadingAnualGoal(true);
          }
        })
        .catch((error) => {
          message.error(notifyMessages.retrieveFailed);
          setLoadingAnualGoal(true);
        });
    } else {
    }
  };

  const getObjAnnoKPI = (id) => {
    if (activeXmatrixInfo) {
      const xmatrix = selectedXmatrixValue ? selectedXmatrixValue : activeXmatrixInfo?.xMatrixID;

      setKoadingKpiList(true);
      ObiettiviAnnoService.getObjAnnoKpi(id, xmatrix)
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            const respData = resp.responseObject.value;
            setKpiList(respData);
            setKoadingKpiList(false);
          } else {
            setKoadingKpiList(false);
          }
        })
        .catch((error) => {
          setKoadingKpiList(false);
        });
    } else {
    }
  };

  const getSelectedObietivoData = (id) => {
    if (activeXmatrixInfo) {
      const xmatrix = selectedXmatrixValue ? selectedXmatrixValue : activeXmatrixInfo?.xMatrixID;
      setLoadingSelectedObietivo(true);
      ObiettiviAnnoService.getObiettiviAnnoByiD(id, xmatrix)
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            const respData = resp.responseObject.value;
            setSelectedObietivoData(respData);
            setLoadingSelectedObietivo(false);
          } else {
            message.error(notifyMessages.retrieveFailed);
            setLoadingSelectedObietivo(false);
          }
        })
        .catch((error) => {
          message.error(notifyMessages.retrieveFailed);
          setLoadingSelectedObietivo(false);
        });
    } else {
    }
  };

  const onObAnnoSelect = (annualGoalID) => {
    setSelectedObietivoID(annualGoalID);
    getObjAnnoKPI(annualGoalID);
    getSelectedObietivoData(annualGoalID);
  };

  const handleCommentEditorChange = (editorState) => {
    setComentEditiorValue(editorState);
  };

  const handleCommentEditorSave = async () => {
    const payload = {};
    const fieldValue = BraftEditor.createEditorState(comentEditiorValue).toHTML();
    payload['itemID'] = annualGoalId;
    payload['nameOrNote'] = fieldValue;
    payload['updateCase'] = longOrAnnualGoalFieldUpdate.anualGoalNotes;
    if (!isRoleTeamMemberOrTeamLeader) {
      updateFields(payload);
    }
  };

  const onUpdateRelation = (values, record) => {
    values['xMatrixID'] = selectedXmatrixValue ? selectedXmatrixValue : activeXmatrixInfo?.xMatrixID;
    values['longTermGoalID'] = record.longTermGoalID;
    values['annualGoalID'] = selectedObietivoID;

    setLoadingUpdateRelation(true);
    RelationsService.updateLongAnnualRelation(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          setLoadingUpdateRelation(false);
          getSelectedObietivoData(selectedObietivoID);
          updateAnnualGoalData(selectedObietivoID);
        } else {
          message.error(notifyMessages.updateFailed);
          setLoadingUpdateRelation(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        setLoadingUpdateRelation(false);
      });
  };

  const onUpdateProjectRelation = (values, record) => {
    values['xMatrixID'] = selectedXmatrixValue ? selectedXmatrixValue : activeXmatrixInfo?.xMatrixID;
    values['projectID'] = record.projectID;
    values['annualGoalID'] = selectedObietivoID;

    setLoadingUpdateRelation(true);
    RelationsService.updateAnnualProjectRelation(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          setLoadingUpdateRelation(false);
          getSelectedObietivoData(selectedObietivoID);
          updateAnnualGoalData(selectedObietivoID);
        } else {
          message.error(notifyMessages.updateFailed);
          setLoadingUpdateRelation(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        setLoadingUpdateRelation(false);
      });
  };

  const renderUpdateRelation = (record, isProjectRelation) => {
    return (
      <>
        <Form
          name="edit_relation"
          layout="vertical"
          {...formItemLayout}
          initialValues={{ correlationCoefficient: record.correlationCoefficient }}
          onValuesChange={(values) =>
            isProjectRelation ? onUpdateProjectRelation(values, record) : onUpdateRelation(values, record)
          }
        >
          <Form.Item name="correlationCoefficient">
            <Radio.Group className="tw-flex tw-flex-col tw-gap-1">
              <Radio
                className="tw-flex tw-flex-row tw-items-center"
                key="1"
                value={1}
              >
                {t('xMatrixPage.primaria')}
              </Radio>
              <Radio
                key="2"
                value={2}
              >
                {t('xMatrixPage.secondaria')}
              </Radio>
              <Radio
                key="3"
                value={3}
              >
                {t('xMatrixPage.nessuna')}
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </>
    );
  };

  const handleModalClose = () => {
    setNewLongTermModal(false);
    setNewAnnualGoalModal(false);
  };

  const addObietivoLungo = async (values) => {
    setLoadingSave(true);

    try {
      const { data } = await ObLongService.addObiettivoLungoxMartix({
        status: values.status,
        title: values.title,
        xMatrixId: selectedXmatrixValue ? selectedXmatrixValue : activeXmatrixInfo?.xMatrixID,
      });
      if (!data.success) {
        throw new Error('Insert failed');
      }

      void message.success(notifyMessages.addSuccess);
      handleModalClose();
      getSelectedObietivoData(selectedObietivoID);
    } catch {
      void message.error(notifyMessages.addFailed);
    }

    setLoadingSave(false);
  };

  const addObietvoAnno = (values) => {
    setLoadingSave(true);
    ObAnnoService.addObiettivoAnnoxMartix({
      ...values,
      status: enumCast(AnnualGoalStatus, values.status) ?? AnnualGoalStatus.Draft,
      xMatrixId: selectedXmatrixValue ? selectedXmatrixValue : activeXmatrixInfo?.xMatrixID,
    })
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          handleModalClose();
          setLoadingSave(false);
          retrieveAnnualGoalList();
        } else {
          message.error(notifyMessages.addFailed);
          setLoadingSave(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        setLoadingSave(false);
      });
  };

  const annualGoalListColumns = [
    {
      title: `${t('general.nome')}`,
      dataIndex: 'description',
      ellipsis: {
        showTitle: true,
      },
      width: '95%',
      render: (title) => <Tooltip title={title}>{title}</Tooltip>,
    },
    {
      key: 'action',
      width: '5%',
      render: (text, record) => (
        <Space>
          <Link to={{ pathname: `/obiettiviannuali/details/id/${record.annualGoalID}` }}>
            <EditFilled style={{ color: '#023B72' }} />
          </Link>
        </Space>
      ),
    },
  ];

  const longTermGoalColumns = [
    {
      title: `${t('general.nome')}`,
      dataIndex: 'title',
      ellipsis: {
        showTitle: true,
      },
      width: '60%',
      sorter: (a, b) => {
        a = a.title || '';
        b = b.title || '';
        return a.localeCompare(b);
      },
      render: (title) => <Tooltip title={title}>{title}</Tooltip>,
    },
    {
      title: `${t('general.sponsor')}`,
      dataIndex: 'sponsorFullName',
      align: 'center',
      showSorterTooltip: [false],
    },
    {
      title: `${t('general.relazione')}`,
      align: 'center',
      dataIndex: 'relationship',
      render: (text, record) => {
        return (
          <Popover
            content={() => renderUpdateRelation(record, false)}
            trigger="click"
          >
            {record.correlationCoefficient === 1 && (
              <div className="tw-flex tw-justify-center tw-items-center tw-gap-2">
                <span
                  className="relationFull"
                  style={{ cursor: 'pointer' }}
                ></span>
              </div>
            )}
            {record.correlationCoefficient === 2 && (
              <div className="tw-flex tw-justify-center tw-items-center tw-gap-2">
                <span
                  className="relationPartial margin-center"
                  style={{ cursor: 'pointer' }}
                ></span>
              </div>
            )}
            {record.correlationCoefficient === 3 && (
              <div className="tw-flex tw-justify-center tw-items-center tw-gap-2">
                <span
                  className="noRelation margin-center"
                  style={{ cursor: 'pointer' }}
                ></span>
              </div>
            )}
          </Popover>
        );
      },
    },
    {
      key: 'action',
      width: '50px',
      align: 'center',
      render: (text, record) => (
        <Space>
          <Link
            to={{
              pathname: `/obiettivialungoperiodo/details/id/${record.longTermGoalID}`,
            }}
          >
            <EditOutlined />
          </Link>
        </Space>
      ),
    },
  ];

  const progettiColumns = [
    {
      title: `${t('general.nome')}`,
      dataIndex: 'name',
      ellipsis: {
        showTitle: true,
      },
      width: '80%',
      render: (title) => <Tooltip title={title}>{title}</Tooltip>,
    },
    {
      title: `${t('proggetiPage.codice')}`,
      dataIndex: 'projectCode',
    },
    {
      title: `${t('general.relazione')}`,
      align: 'center',
      dataIndex: 'relationship',
      render: (text, record) => {
        if (record.correlationCoefficient === 1) {
          return (
            <Tooltip title={record.correlationDescription}>
              <div className="tw-w-full tw-flex tw-flex-row tw-justify-center">
                <span className="relationFull"></span>
              </div>
            </Tooltip>
          );
        } else if (record.correlationCoefficient === 2) {
          return (
            <Tooltip title={record.correlationDescription}>
              <div className="tw-w-full tw-flex tw-flex-row tw-justify-center">
                <span className="relationPartial"></span>
              </div>
            </Tooltip>
          );
        } else {
        }
      },
    },
    {
      key: 'action',
      width: '50px',
      render: (text, record) => (
        <Space>
          <Link
            to={{
              pathname: `/progetti/id/${record.projectID}`,
            }}
          >
            <EditOutlined />
          </Link>
        </Space>
      ),
    },
  ];

  const kpiColumns = [
    {
      dataIndex: 'isFavourite',
      width: '40px',
      render: (text, record, index) => {
        if (record.isFavourite) {
          return (
            <StarFilled
              style={{ color: '#f5a645' }}
              title={t('kpiPage.preferita')}
            />
          );
        } else {
          return <StarOutlined title={t('kpiPage.preferita')} />;
        }
      },
    },
    {
      title: `${t('general.nome')}`,
      dataIndex: 'name',
      ellipsis: {
        showTitle: true,
      },
      width: '40%',
      render: (title) => <Tooltip title={title}>{title}</Tooltip>,
    },
    {
      title: `${t('kpiPage.tipoCalcolo')}`,
      dataIndex: 'calculationTypeDescription',
    },
    {
      title: `${t('general.tipo')}`,
      dataIndex: 'kpiTypeDescription',
      render: (title) => <Tooltip title={title}>{title}</Tooltip>,
    },
    {
      title: `${t('kpiPage.cumulatoPuntuale')}`,
      dataIndex: 'parentTypeDescription',
      render: (title) => <Tooltip title={title}>{title}</Tooltip>,
    },
  ];

  return (
    <>
      <AddObietivoLungoModal
        showObLungo={newLongTermModal}
        handleObLungoClose={handleModalClose}
        handleObLungoSave={addObietivoLungo}
        loadingButton={loadingSave}
        statusPickList={statusList}
        t={t}
      />

      <AddObietivoAnnoModal
        showObAnno={newAnnualGoalModal}
        handleObAnnoClose={handleModalClose}
        handleObAnnoSave={addObietvoAnno}
        loadingButton={loadingSave}
        statusPickList={statusList}
        t={t}
        annualGoals={[]}
      />

      <TableLayout
        className="tw-mb-4"
        title={t('obiettiviAnnoPage.comments')}
      >
        <TableLayout.Actions>
          {!isRoleTeamMemberOrTeamLeader ? (
            <Form.Item className="tw-mb-0">
              <Button
                htmlType="submit"
                loading={loadingSaveField}
                icon={<SaveOutlined />}
                type="primary"
              >
                {t('buttons.salva')}
              </Button>
            </Form.Item>
          ) : null}
        </TableLayout.Actions>
        <TableLayout.Content>
          <Form
            name="add_ob_lungo"
            {...formItemLayout}
            onFinish={handleCommentEditorSave}
          >
            <Form.Item>
              <BraftEditor
                contentFormat="html"
                language="en"
                id="editor-text"
                className={`${isPresentationMode ? '[&_.DraftEditor-editorContainer]:tw-text-2xl' : null}`}
                controls={RichTextEditorControls}
                value={BraftEditor.createEditorState(comentEditiorValue)}
                onChange={handleCommentEditorChange}
                onBlur={handleCommentEditorSave}
                disabled={isRoleTeamMemberOrTeamLeader}
              />
            </Form.Item>
          </Form>
        </TableLayout.Content>
      </TableLayout>

      {!isPresentationMode && (
        <Row gutter={24}>
          <Col
            xs={24}
            lg={12}
          >
            <TableLayout
              title={
                <Typography.Title
                  className="!tw-mb-0"
                  level={4}
                >
                  {t('obiettiviAnnoPage.obbietiviAnuali')}
                </Typography.Title>
              }
            >
              <TableLayout.Actions>
                {activeXmatrixInfo && (
                  <XmatrixDropDownFilter
                    onXmatrixSelect={onXmatrixSelect}
                    activeXmatrixInfo={activeXmatrixInfo}
                    preSelectedXmatrixId={routeSelectedXmatrix}
                    t={t}
                  />
                )}
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setNewAnnualGoalModal(true)}
                >
                  {t('buttons.aggiungiNuovo')}
                </Button>
              </TableLayout.Actions>
              <TableLayout.Content>
                <Table
                  columns={annualGoalListColumns}
                  dataSource={annualGoalList}
                  rowKey={(obj) => obj.annualGoalID}
                  loading={loadingAnualGoal}
                  pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (event) => {
                        onObAnnoSelect(record.annualGoalID);
                        setSelectedObietivoID(record.annualGoalID);
                      },
                    };
                  }}
                  rowClassName={(record, index) =>
                    record.annualGoalID === selectedObietivoID ? 'active-obietivi-row' : ''
                  }
                />
              </TableLayout.Content>
            </TableLayout>
          </Col>
          <Col
            xs={24}
            lg={12}
          >
            {selectedObietivoData && (
              <TableLayout
                title={
                  <Typography.Title
                    level={4}
                    className="!tw-mb-0"
                  >
                    {t('obiettiviAnnoPage.selectedAnualGoalData')}
                  </Typography.Title>
                }
              >
                <TableLayout.Actions>
                  <Button
                    type="primary"
                    onClick={() => setNewLongTermModal(true)}
                    icon={<PlusOutlined />}
                  >
                    {t('buttons.aggiungiNuovo')}
                  </Button>
                </TableLayout.Actions>
                <TableLayout.Content>
                  <Tabs
                    defaultActiveKey="1"
                    items={[
                      {
                        label: <>{t('obiettiviLungoPage.obiettiviLungo')}</>,
                        key: '1',
                        children: (
                          <Table
                            columns={longTermGoalColumns}
                            dataSource={selectedObietivoData.longTermGoalByAnnualGoal}
                            loading={loadingSelectedObietivo}
                            rowKey={(obj) => obj.longTermGoalID}
                          />
                        ),
                      },
                      {
                        label: <>{t('headerMenu.progetti')}</>,
                        key: '2',
                        children: (
                          <Table
                            columns={progettiColumns}
                            dataSource={selectedObietivoData.projectByAnnualGoal}
                            rowKey={(obj) => obj.projectID}
                            loading={loadingSelectedObietivo}
                          />
                        ),
                      },
                      {
                        label: <>{t('headerMenu.kpi')}</>,
                        key: '3',
                        children: (
                          <Table
                            columns={kpiColumns}
                            dataSource={kpiList}
                            rowKey={(obj) => obj.annualGoalKPIRelationShipID}
                            loading={loadingKpiList}
                          />
                        ),
                      },
                    ]}
                  ></Tabs>
                </TableLayout.Content>
              </TableLayout>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};
export default Workshop;
