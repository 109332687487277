import { Form } from 'antd';
import { Component, useState } from 'react';
import { connect } from 'react-redux';
import '../../project.scss';
import '../projectResourceWorload/projectResource.scss';

import {
  EditOutlined,
  FolderOpenOutlined,
  PlusOutlined,
  SaveOutlined,
  SearchOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Button, Col, Divider, Input, InputNumber, Row, Space, Table, Tooltip, Typography, message } from 'antd';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import ProjectSectionTitle from 'src/components/shared/components/ProjectSectionTitle/ProjectSectionTitle';
import ResourceWorkloadService from '../../../../services/pages/resourceWorkloadServices';
import TableLayout from '../../../shared/tableLayout';
import { projectCostColumNames, userProjectCostAccessLevel, workloadTypeValues } from '../../../shared/utils/constants';
import { capitalizeFirstLetter, getCurrencySymbol, numberWithCommasProjectCost } from '../../../shared/utils/functions';
import { notifyMessages } from '../../../shared/utils/notifyMessages';
import {
  getTotalCostBudgetProgettoYTD,
  getTotalCostConsutivoYTD,
  getTotalCostNonAllocatoProgettoYTD,
} from '../projectResourceWorload/ytdCalculations';
import AddProjectCost from './addProjectCostModal';
import ProjectCostDetails from './projectCostDetailsModal';

const { Text } = Typography;

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, currency, ...restProps }) => {
  // const inputNode = inputType === 'number' ? <InputNumber min={0} step="0.001" /> : <InputNumber min={0} step="0.001" />;
  const inputNode = (
    <InputNumber
      min={0}
      decimalSeparator=","
      precision="2"
      formatter={(value) => `${getCurrencySymbol(currency)} ${value}`}
    />
  );
  return (
    <td {...restProps}>
      {editing ? (
        <>
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              () => ({
                validator(_, value) {
                  if (isNaN(value) && value < 0) {
                    return Promise.reject('No negative numbers');
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            {inputNode}
          </Form.Item>
        </>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTable = (props) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          //   this.searchInput = node;
          // }}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    setSearchText('');
  };

  const isEditableRow = (record) => {
    if (props.isTotal) {
      return record.Name === projectCostColumNames.budgetProgetto ? true : false;
    } else {
      return record.Name === projectCostColumNames.budget || record.Name === projectCostColumNames.consuntivo
        ? true
        : false;
    }
  };

  const isEditing = (record) => record.key === editingKey;

  //   const hasBottomBorder = (record) => (record.Name === projectWorkloadColumNames.disponibile && record.Type === workloadTypeValues.user) ? "2px solid #6f6f6f" : "";
  const hasBottomBorder = (record) => (record.Name === projectCostColumNames.residuo ? '2px solid #6f6f6f' : '');

  const edit = (record) => {
    form.setFieldsValue({
      // name: '',
      // age: '',
      // address: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key, record) => {
    try {
      const values = await form.getFieldsValue(null, (meta) => meta.touched);

      props.updateRow(values, record);
    } catch (errInfo) {}
  };

  const renderedColumns = () => {
    const columns = [];

    const tabelData = props.propsData;

    if (tabelData && tabelData.length > 0) {
      const firstRow = tabelData[0];

      for (const col in firstRow) {
        if (col === projectCostColumNames.name) {
          columns.push({
            dataIndex: col,
            fixed: 'left',
            title: '',
            editable: false,
            //width: 40,
            ellipsis: {
              showTitle: false,
            },
            render(text, record) {
              return {
                props: {
                  style: { borderBottom: hasBottomBorder(record) },
                },
                // children: <Tooltip  title={convertCamelCaseToNormal(text)}>
                //   {isEditableRow(record) ? <Text strong>{convertCamelCaseToNormal(text)}</Text> : <Text>{convertCamelCaseToNormal(text)}</Text>}
                // </Tooltip>
                children: (
                  <Tooltip title={props.t(`budgetTypes.${text}`)}>
                    {isEditableRow(record) ? (
                      <Text strong>{props.t(`budgetTypes.${text}`)}</Text>
                    ) : (
                      <Text>{props.t(`budgetTypes.${text}`)}</Text>
                    )}
                  </Tooltip>
                ),
              };
            },
          });
        } else if (col === projectCostColumNames.projectCostName || col === projectCostColumNames.fullName) {
          columns.push({
            ...(props.isTotal ? '' : getColumnSearchProps(col)),
            dataIndex: col,
            fixed: 'left',
            //width: 40,
            title: '',
            editable: false,
            ellipsis: {
              showTitle: false,
            },
            render: (text, row, index) => {
              if (props.isTotal) {
                const val = <Tooltip title={text}>{text}</Tooltip>;
                const obj = {
                  children: val,
                  props: {},
                };
                if (index % 4 === 0) {
                  obj.props.rowSpan = 4;
                } else {
                  obj.props.rowSpan = 0;
                }
                return obj;
              } else {
                const val = (
                  <Tooltip title={text}>
                    {/* {text} */}
                    <Space direction="vertical">
                      <Text>{text}</Text>
                      {props.projectCostUserAccessProp === userProjectCostAccessLevel.viewEdit && (
                        <FolderOpenOutlined
                          type="primary"
                          onClick={() => props.handleOpenModal(row)}
                        />
                      )}

                      {(props.projectCostUserAccessProp === userProjectCostAccessLevel.view ||
                        props.projectCostUserAccessProp === userProjectCostAccessLevel.limited) &&
                        props.userData.userId === row.OwnerID && (
                          <FolderOpenOutlined
                            type="primary"
                            onClick={() => props.handleOpenModal(row)}
                          />
                        )}
                    </Space>
                  </Tooltip>
                );
                const obj = {
                  children: val,
                  props: {},
                };
                if (index % 3 === 0) {
                  obj.props.rowSpan = 3;
                } else {
                  obj.props.rowSpan = 0;
                }
                return obj;
              }
            },
          });
        } else if (
          col === projectCostColumNames.projectCostDetailID ||
          col === projectCostColumNames.key ||
          col === projectCostColumNames.type ||
          col === projectCostColumNames.projectID ||
          col === projectCostColumNames.ownerID
        ) {
          continue;
        } else if (col === projectCostColumNames.total) {
          columns.push({
            align: 'right',
            fixed: 'right',
            dataIndex: col,
            title: `${props.t('general.totale')}`,
            // title: col,
            // width: 50,
            width: 100,
            editable: false,
            render(text, record) {
              return {
                props: {
                  style: {
                    borderBottom: hasBottomBorder(record),
                    background: Number.parseInt(text) < 0 ? '#ff6b6b' : '',
                  },
                },
                children: isEditableRow(record) ? (
                  <Text strong>{numberWithCommasProjectCost(text, props.currency)}</Text>
                ) : (
                  <Text>{numberWithCommasProjectCost(text, props.currency)}</Text>
                ),
              };
            },
          });
        } else {
          const month = moment(col).format('MMM YYYY');
          columns.push({
            align: 'right',
            dataIndex: col,
            title: capitalizeFirstLetter(month),
            editable: true,
            width: 100,
            //width: 50,

            render(text, record) {
              return {
                props: {
                  style: {
                    borderBottom: hasBottomBorder(record),
                    background: Number.parseInt(text) < 0 ? '#ff6b6b' : '',
                  },
                },
                children: isEditableRow(record) ? (
                  <Text strong>{numberWithCommasProjectCost(text, props.currency)}</Text>
                ) : (
                  <Text>{numberWithCommasProjectCost(text, props.currency)}</Text>
                ),
              };
            },
          });
        }
      }
      if (!props.isTotalTeam) {
        columns.push({
          title: '',
          dataIndex: 'operation',
          //width: 50,
          fixed: 'right',
          render: (_, record) => {
            if (isEditableRow(record)) {
              const editable = isEditing(record);
              return editable ? (
                <span>
                  {props.loadingIcon && (
                    <SyncOutlined
                      style={{ color: '#0f87dc', marginRight: 5 }}
                      spin
                    />
                  )}
                  <Typography.Link onClick={() => save(record.key, record)}>
                    <SaveOutlined style={{ marginRight: 5 }} />
                    {props.t('buttons.salva')}
                  </Typography.Link>
                  <Divider
                    type="vertical"
                    style={{ borderLeft: '1px solid rgb(183 166 166)' }}
                  />
                  <Typography.Link onClick={() => cancel()}>{props.t('buttons.chiudi')}</Typography.Link>
                </span>
              ) : (
                <>
                  {props.projectCostUserAccessProp === userProjectCostAccessLevel.viewEdit && (
                    <Typography.Link
                      disabled={editingKey !== ''}
                      onClick={() => edit(record)}
                    >
                      <EditOutlined /> {props.t('buttons.modifica')}
                    </Typography.Link>
                  )}

                  {!props.isTotal && (
                    <>
                      {(props.projectCostUserAccessProp === userProjectCostAccessLevel.view ||
                        props.projectCostUserAccessProp === userProjectCostAccessLevel.limited) &&
                        props.userData.userId === record.OwnerID &&
                        record.Name === projectCostColumNames.consuntivo && (
                          <Typography.Link
                            disabled={editingKey !== ''}
                            onClick={() => edit(record)}
                          >
                            <EditOutlined /> {props.t('buttons.modifica')}
                          </Typography.Link>
                        )}
                    </>
                  )}
                </>
              );
            } else {
              return;
            }
          },
        });
      }
      return columns;
    }
    return columns;
  };

  const mergedColumns = renderedColumns().map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'number',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        currency: props.currency,
      }),
    };
  });

  return (
    <Form
      form={form}
      component={false}
    >
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        size="small"
        dataSource={props.propsData}
        columns={
          props.hasUserAccess
            ? mergedColumns.filter((el) => {
                return el.dataIndex !== 'operation';
              })
            : mergedColumns
        }
        pagination={false}
        rowClassName={(record) => {
          if (
            record.Type === workloadTypeValues.project &&
            (record.Name === projectCostColumNames.budgetUtente ||
              record.Name === projectCostColumNames.consuntivoUtente)
          ) {
            return 'table-row-user-project';
          } else if (record.Type === workloadTypeValues.project) {
            return 'table-row-project';
          }
        }}
        //scroll={{ x: 'calc(100% + 100%)' }}
        // scroll={{ x: 'max-content' }}
        scroll={props.isTotal ? { x: 'max-content' } : { x: 'max-content', y: 350 }}
        loading={props.loadingData || props.loadingTotal || props.loadingTeam}
      />
    </Form>
  );
};

class ProjectCosts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      loadingSaveButton: false,
      loadingDeleteButton: false,
      loadingSaveIcon: false,
      loadingSaveDetailsButton: false,
      loadingData: false,
      loadingTotal: false,
      loadingTeamData: false,
      projectCostData: null,
      totalProjectCostData: null,
      teamProjectCostData: null,
      editingKey: '',
      budgetProgettoYTD: null,
      allocatoProgettoYTD: null,
      nonAllocatoYTD: null,
      consutivoYTD: null,
      showProjectCostDetailsModal: false,
      selectedprojectCostDetailID: null,
      selectedProjectCostName: null,
      newProjectCostDetailsModal: false,
      selectedProjectCostData: null,

      totalProjectCostDataUtente: null,
      totalProjectCostDataProject: null,
    };
  }

  componentWillMount() {
    this.getProjectCosts();
    this.getTotalProjectCost();
    this.getTeamProjectCost();
  }

  async getProjectCosts() {
    const { projectId } = this.props;
    this.setState({ loadingData: true });
    await ResourceWorkloadService.getProjectCostData(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          resp.responseObject.value.map((item, index) => {
            let total = 0;
            for (const col in item) {
              if (
                col !== projectCostColumNames.projectCostName &&
                col !== projectCostColumNames.name &&
                col !== projectCostColumNames.projectCostDetailID &&
                col !== projectCostColumNames.type &&
                col !== projectCostColumNames.ownerID
              ) {
                total += item[col];
              }
            }
            item['Totale'] = total;
            item['key'] = index.toString();
          });
          this.setState({ projectCostData: resp.responseObject.value });
          this.setState({ loadingData: false });
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingData: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingData: false });
      });
  }

  async getTotalProjectCost() {
    const { projectId } = this.props;
    this.setState({ loadingTotal: true });
    await ResourceWorkloadService.getTotalProjectCost(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          resp.responseObject.value.map((item, index) => {
            let total = 0;
            for (const col in item) {
              if (
                col !== projectCostColumNames.fullName &&
                col !== projectCostColumNames.name &&
                col !== projectCostColumNames.projectID &&
                col !== projectCostColumNames.type
              ) {
                total += item[col];
              }
            }
            item['Totale'] = total;
            item['key'] = index.toString();
          });

          // let costsDataProject = resp.responseObject.value.filter(function (el) {
          //   return (el.Name === projectCostColumNames.budgetProgetto ||  el.Name === projectCostColumNames.allocatoProgetto ||  el.Name === projectCostColumNames.nonAllocato
          //            ||  el.Name === projectCostColumNames.consuntivo)
          // });

          this.setState({ totalProjectCostData: resp.responseObject.value }, () => {
            this.calculateBudgetProgettoYTD();
            this.calculateAllocatoProgettoYTD();
            this.calculateNonAllocatoProgettoYTD();
            this.calculateConsutivoYTD();
          });

          // let costsDataUtente = resp.responseObject.value.filter(function (el) {
          //   return (el.Name === projectCostColumNames.budgetUtente ||  el.Name === projectCostColumNames.consuntivoUtente)
          // });
          // this.setState({ totalProjectCostDataUtente: costsDataUtente })

          this.setState({ loadingTotal: false });
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingTotal: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingData: false });
      });
  }

  async getTeamProjectCost() {
    const { projectId } = this.props;
    this.setState({ loadingTeamData: true });
    await ResourceWorkloadService.getTeamProjectCost(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          resp.responseObject.value.map((item, index) => {
            let total = 0;
            for (const col in item) {
              if (
                col !== projectCostColumNames.name &&
                col !== projectCostColumNames.type &&
                col !== projectCostColumNames.fullName
              ) {
                total += item[col];
              }
            }
            item['Totale'] = total;
            item['key'] = index.toString();
          });

          this.setState({ teamProjectCostData: resp.responseObject.value });

          this.setState({ loadingTeamData: false });
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingTeamData: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingTeamData: false });
      });
  }

  updateProjectTotalCost = (values, record) => {
    const { projectId } = this.props;
    const payload = {};
    let monthsArray = [];

    payload['projectID'] = projectId;

    if (values) {
      for (const item in values) {
        monthsArray.push({ referencePeriod: item, budget: values[item] });
      }
    } else {
      monthsArray = [];
    }
    payload['months'] = monthsArray;

    this.setState({ loadingSaveIcon: true });
    ResourceWorkloadService.updateProjectTotalCost(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.setState({ loadingSaveIcon: false });
          this.setState({ cancelRow: true });

          // this.getTotalProjectCost().then(() => {
          //   this.getProjectCosts();
          // })

          window.location = `/progetti/id/${projectId}/7`;
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingSaveIcon: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingSaveIcon: false });
      });
  };

  updateProjectCost = (values, record) => {
    const { projectId } = this.props;
    const payload = {};
    let monthsArray = [];

    payload['projectCostDetailID'] = record.ProjectCostDetailID;

    if (record.Name === projectCostColumNames.budget) {
      payload['type'] = 1;
    } else if (record.Name === projectCostColumNames.consuntivo) {
      payload['type'] = 2;
    } else {
      payload['type'] = null;
    }

    if (values) {
      for (const item in values) {
        monthsArray.push({ referencePeriod: item, valueBudget: values[item] });
      }
    } else {
      monthsArray = [];
    }
    payload['months'] = monthsArray;

    this.setState({ loadingSaveIcon: true });
    ResourceWorkloadService.updateProjectCost(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.setState({ loadingSaveIcon: false });
          this.setState({ cancelRow: true });

          this.getProjectCosts().then(() => {
            this.getTotalProjectCost();
          });
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingSaveIcon: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingSaveIcon: false });
      });
  };

  handleSaveNewCostDeatils = (values) => {
    const { projectId } = this.props;

    values['projectID'] = projectId;

    this.setState({ loadingSaveButton: true });
    ResourceWorkloadService.insertProjectCost(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.handleNewDetailModalClose();
          message.success(notifyMessages.addSuccess);
          this.getProjectCosts().then(() => {
            this.getTotalProjectCost();
          });
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingSaveButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingSaveButton: false });
      });
  };

  removeProjectCostDetail = (id) => {
    this.setState({ loadingDeleteButton: true });

    ResourceWorkloadService.removeProjectCost(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.deleteSuccess);
          this.handleModalClose();

          this.getProjectCosts().then(() => {
            this.getTotalProjectCost();
          });
        } else {
          this.handleModalClose();
          message.error(notifyMessages.deleteFailed);
        }
      })
      .catch((error) => {
        this.handleModalClose();
        message.error(notifyMessages.deleteFailed);
      });
  };

  calculateBudgetProgettoYTD = () => {
    const { totalProjectCostData } = this.state;
    const { currency } = this.props;

    const valueYTD = getTotalCostBudgetProgettoYTD(totalProjectCostData);
    const formatedValueYtd = numberWithCommasProjectCost(valueYTD, currency);

    this.setState({ budgetProgettoYTD: formatedValueYtd });
  };

  calculateAllocatoProgettoYTD = () => {
    const { totalProjectCostData } = this.state;
    const { currency } = this.props;
    const valueYTD = getTotalCostNonAllocatoProgettoYTD(totalProjectCostData);
    const formatedValueYtd = numberWithCommasProjectCost(valueYTD, currency);
    this.setState({ allocatoProgettoYTD: formatedValueYtd });
  };

  calculateNonAllocatoProgettoYTD = () => {
    const { totalProjectCostData } = this.state;
    const { currency } = this.props;
    const valueYTD = getTotalCostNonAllocatoProgettoYTD(totalProjectCostData);
    const formatedValueYtd = numberWithCommasProjectCost(valueYTD, currency);
    this.setState({ nonAllocatoYTD: formatedValueYtd });
  };

  calculateConsutivoYTD = () => {
    const { totalProjectCostData } = this.state;
    const { currency } = this.props;
    const valueYTD = getTotalCostConsutivoYTD(totalProjectCostData);
    const formatedValueYtd = numberWithCommasProjectCost(valueYTD, currency);
    this.setState({ consutivoYTD: formatedValueYtd });
  };

  handleModalClose = () => {
    this.setState({ showProjectCostDetailsModal: false });
    this.setState({ loadingDeleteButton: false });
  };

  toggleProjectCostsModal = (rowData) => {
    this.setState({ showProjectCostDetailsModal: true });
    this.setState({ selectedprojectCostDetailID: rowData.ProjectCostDetailID });
    this.setState({ selectedProjectCostName: rowData.ProjectCostName });
    this.setState({ selectedProjectCostData: rowData });
  };

  handleNewDetailModalClose = () => {
    this.setState({ newProjectCostDetailsModal: false });
    this.setState({ loadingSaveButton: false });
  };

  toggleNewProjectCostsModal = () => {
    this.setState({ newProjectCostDetailsModal: true });
  };

  updateProjectCostDetails = (values, projectCostDetailID) => {
    values['projectCostDetailID'] = projectCostDetailID;

    this.setState({ loadingSaveDetailsButton: true });

    ResourceWorkloadService.updateProjectCostDetails(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.setState({ loadingSaveDetailsButton: false });
          this.setState({ showProjectCostDetailsModal: false });

          this.getProjectCosts().then(() => {
            this.getTotalProjectCost();
          });
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingSaveDetailsButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingSaveDetailsButton: false });
      });
  };

  render() {
    const {
      loadingData,
      loadingTotal,
      loadingTeamData,
      loadingSaveIcon,
      projectCostData,
      totalProjectCostData,
      budgetProgettoYTD,
      allocatoProgettoYTD,
      nonAllocatoYTD,
      consutivoYTD,
      showProjectCostDetailsModal,
      selectedprojectCostDetailID,
      newProjectCostDetailsModal,
      selectedProjectCostName,
      loadingSaveButton,
      loadingDeleteButton,
      teamProjectCostData,
      loadingSaveDetailsButton,
      selectedProjectCostData,
    } = this.state;
    const { hasUserAccess, projectId, currentProject, companyData, userData, projectCostUserAccessProp, t } =
      this.props;

    return (
      <div>
        {showProjectCostDetailsModal && (
          <ProjectCostDetails
            showProjectCostDetailsModal={showProjectCostDetailsModal}
            handleModalClose={this.handleModalClose}
            selectedprojectCostDetailID={selectedprojectCostDetailID}
            projectCostName={selectedProjectCostName}
            removeProjectCost={this.removeProjectCostDetail}
            handleEditProjectCostDetails={this.updateProjectCostDetails}
            loadingDeleteButton={loadingDeleteButton}
            loadingSaveDetailsButton={loadingSaveDetailsButton}
            projectId={projectId}
            selectedProjectCostData={selectedProjectCostData}
            projectCostUserAccessProp={projectCostUserAccessProp}
            userData={userData}
            t={t}
          />
        )}
        {newProjectCostDetailsModal && (
          <AddProjectCost
            newProjectCostDetailsModal={newProjectCostDetailsModal}
            handleNewDetailModalClose={this.handleNewDetailModalClose}
            handleSaveNewDeatils={this.handleSaveNewCostDeatils}
            loadingSaveButton={loadingSaveButton}
            projectId={projectId}
            projectTeamLeader={currentProject.teamLeaderID}
            t={t}
          />
        )}

        <TableLayout title={<ProjectSectionTitle title={t('proggetiPage.costiProgetto')} />}>
          <TableLayout.Actions>
            {projectCostUserAccessProp === userProjectCostAccessLevel.viewEdit ? (
              <Button
                onClick={() => this.toggleNewProjectCostsModal()}
                type="primary"
                icon={<PlusOutlined />}
              >
                {t('buttons.aggiungiNuovo')}
              </Button>
            ) : (
              ''
            )}
          </TableLayout.Actions>

          <TableLayout.Content>
            {(projectCostUserAccessProp === userProjectCostAccessLevel.view ||
              projectCostUserAccessProp === userProjectCostAccessLevel.viewEdit) && (
              <div className="tw-bg-zinc-100 tw-grid tw-grid-flow-col tw-py-4 tw-rounded-md tw-px-2">
                <Text strong>{t('proggetiPage.analisiSintesi')}</Text>
                <Text>
                  {t('proggetiPage.budgetProgettoYtd')}:{' '}
                  <Text
                    className="ml"
                    strong
                  >
                    {getCurrencySymbol(companyData.currency)} {budgetProgettoYTD}
                  </Text>
                </Text>
                <Text>
                  {t('proggetiPage.allocatoProgettoYtd')}:{' '}
                  <Text
                    className="ml"
                    strong
                  >
                    {getCurrencySymbol(companyData.currency)} {allocatoProgettoYTD}
                  </Text>
                </Text>
                <Text>
                  {t('proggetiPage.nonAllocatoYtd')}:{' '}
                  <Text
                    className="ml"
                    strong
                  >
                    {getCurrencySymbol(companyData.currency)} {nonAllocatoYTD}
                  </Text>
                </Text>
                <Text>
                  {t('proggetiPage.consutivoYtd')}:{' '}
                  <Text
                    className="ml"
                    strong
                  >
                    {getCurrencySymbol(companyData.currency)} {consutivoYTD}
                  </Text>
                </Text>
              </div>
            )}
            <>
              {(projectCostUserAccessProp === userProjectCostAccessLevel.view ||
                projectCostUserAccessProp === userProjectCostAccessLevel.viewEdit) && (
                <Row gutter={{ lg: 24 }}>
                  <Col>
                    <h2 className="tw-text-lg tw-my-4">{t('proggetiPage.sintesiCosti')}</h2>
                    <EditableTable
                      t={t}
                      hasUserAccess={hasUserAccess}
                      propsData={totalProjectCostData}
                      updateRow={this.updateProjectTotalCost}
                      loadingIcon={loadingSaveIcon}
                      isTotal={true}
                      isTotalTeam={false}
                      loadingTotal={loadingTotal}
                      currency={companyData.currency}
                      projectCostUserAccessProp={projectCostUserAccessProp}
                    />
                  </Col>
                </Row>
              )}

              <Row gutter={{ lg: 24 }}>
                <Col>
                  <h2 className="tw-text-lg tw-my-4">{t('proggetiPage.detaglioCosti')}</h2>

                  {(projectCostUserAccessProp === userProjectCostAccessLevel.view ||
                    projectCostUserAccessProp === userProjectCostAccessLevel.viewEdit) && (
                    <EditableTable
                      t={t}
                      hasUserAccess={hasUserAccess}
                      propsData={teamProjectCostData}
                      updateRow={this.updateProjectTotalCost}
                      loadingIcon={loadingSaveIcon}
                      isTotal={true}
                      isTotalTeam={true}
                      loadingTeam={loadingTeamData}
                      currency={companyData.currency}
                      projectCostUserAccessProp={projectCostUserAccessProp}
                      userData={userData}
                    />
                  )}

                  {(projectCostUserAccessProp === userProjectCostAccessLevel.view ||
                    projectCostUserAccessProp === userProjectCostAccessLevel.viewEdit ||
                    projectCostUserAccessProp === userProjectCostAccessLevel.limited) && (
                    <EditableTable
                      t={t}
                      hasUserAccess={hasUserAccess}
                      propsData={projectCostData}
                      updateRow={this.updateProjectCost}
                      loadingIcon={loadingSaveIcon}
                      isTotal={false}
                      isTotalTeam={false}
                      loadingData={loadingData}
                      handleOpenModal={this.toggleProjectCostsModal}
                      currency={companyData.currency}
                      projectCostUserAccessProp={projectCostUserAccessProp}
                      userData={userData}
                    />
                  )}
                </Col>
              </Row>
            </>
          </TableLayout.Content>
        </TableLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  companyData: state.companyData.companyData,
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(ProjectCosts));
