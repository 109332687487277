import { Form, Switch, type TabsProps } from 'antd';
import { type FC, useEffect, useState } from 'react';

import { DeleteOutlined, LinkOutlined, SaveOutlined, SelectOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Space,
  Tabs,
  Tooltip,
  Typography,
  message,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
  type CommitteeDto,
  type CommitteeMemberDto,
  CommitteeType,
  type CreateCommitteeRequest,
  type UpdateCommitteeRequest,
  type UpsertAgendaRequest,
} from 'src/connectors/backend';
import UsersService from 'src/services/pages/usersService';
import CommitieService from '../../services/pages/commitieServices';
import Loader from '../shared/components/loader/loader';
import { formItemLayout } from '../shared/utils/constants';
import { notifyMessages, requiredFields } from '../shared/utils/notifyMessages';
import AgendaModal from './agendaModal';
import { AgendaService } from 'src/services/pages/agendaService';
import RelatedComities from './comitieDashboard/relatedComities';
import CommitteeProjects from './comitieDashboard/CommitteeProjects';

const { Text, Link } = Typography;

const CommitteePage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { t } = useTranslation();

  const [committeeParticipants, setCommitteeParticipants] = useState<CommitteeMemberDto[]>([]);
  const [committeeAuditors, setCommitteeAuditors] = useState<CommitteeMemberDto[]>([]);

  const [committee, setCommittee] = useState<CommitteeDto>();
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingSaveClose, setLoadingSaveClose] = useState(false);
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [userOptions, setUserOptions] = useState<
    {
      value: string;
      label: string;
      key: string;
      disabled: boolean;
    }[]
  >();

  const [standardAgendaModal, setStandardAgendaModal] = useState(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    void UsersService.getUsersListData().then(({ data }) => {
      setUserOptions(
        data.responseObject?.value?.map((u) => ({
          disabled: u.isDisabled === true,
          key: u.userID as string,
          label: u.fullName as string,
          value: u.userID as string,
        })) ?? [],
      );
    });

    void loadCommittee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const redirectToCommitteeDashboard = () => {
    history.push(`/committee-dashboard/${id as string}`);
  };

  const loadCommittee = async () => {
    if (!id) {
      setCommittee({
        id: committee?.id ?? '',
        type: CommitteeType.NonOperational,
        activitiesDuration: 45,
        name: '',
        projectsCount: 0,
        repositoryLink: '',
      });

      return;
    }

    try {
      const { data } = await CommitieService.getById(id);
      data.type = CommitieService.isOperational(data.type) ? CommitteeType.Operational : CommitteeType.NonOperational;

      setCommittee(data);
      setCommitteeParticipants(data.members?.filter((m) => !m.userIsDeleted && !m.isRevisori) ?? []);
      setCommitteeAuditors(data.members?.filter((m) => !m.userIsDeleted && m.isRevisori) ?? []);
    } catch {
      void message.error(notifyMessages.retrieveFailed);
    }
  };

  const tabItems: TabsProps['items'] = [
    {
      label: <Text>{t('comitatiPage.progettiCollegati')}</Text>,
      key: '1',
      children: (
        <>
          {committee && (
            <CommitteeProjects
              committee={committee}
              onlyView={false}
              fetchCommittee={loadCommittee}
            />
          )}
        </>
      ),
    },
    {
      label: <Text>{t('comitatiPage.comitatiCollegati')}</Text>,
      key: '2',
      children: (
        <RelatedComities
          comitieId={id}
          onlyView={false}
        />
      ),
    },
  ];

  const sendForm = async (values: CreateCommitteeRequest | UpdateCommitteeRequest) => {
    setLoadingButton(true);

    const committee = await (id ? updateCommittee(id, values) : createCommittee(values));
    if (committee) {
      setCommittee(committee);
      setCommitteeParticipants(committee.members?.filter((m) => !m.userIsDeleted && !m.isRevisori) ?? []);
      setCommitteeAuditors(committee.members?.filter((m) => !m.userIsDeleted && m.isRevisori) ?? []);
    }

    setLoadingButton(false);
  };

  const createCommittee = async (values: CreateCommitteeRequest) => {
    values.type = values.type ? CommitteeType.Operational : CommitteeType.NonOperational;

    try {
      const { data } = await CommitieService.create(values);

      void message.success(notifyMessages.addSuccess);
      history.push(`/committie/id/${data.id}`);

      return data;
    } catch {
      void message.error(notifyMessages.addFailed);
    }
  };

  const updateCommittee = async (id: string, values: UpdateCommitteeRequest) => {
    try {
      const { data } = await CommitieService.update(id, values);
      void message.success(notifyMessages.updateSuccess);

      return data;
    } catch {
      void message.error(notifyMessages.updateFailed);
    }
  };

  const deleteCommittee = async () => {
    try {
      await CommitieService.delete(id as string);
      void message.success(notifyMessages.deleteSuccess);
      history.push('/committies');
    } catch {
      void message.error(notifyMessages.deleteCommitieFailed);
    }
  };

  const linkLabel = (link?: string | null) => {
    return (
      <Space direction="horizontal">
        {t('comitatiPage.repositoryLink')}
        {link && (
          <Tooltip title={t('comitatiPage.goToRepositoryLink')}>
            <LinkOutlined />{' '}
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer external">
              {' '}
              Link{' '}
            </a>
          </Tooltip>
        )}
      </Space>
    );
  };

  const addStandardAgenda = async (values: UpsertAgendaRequest, closeModal: boolean) => {
    if (!committee) {
      return;
    }

    closeModal ? setLoadingSaveClose(true) : setLoadingSave(true);

    try {
      await AgendaService.upsertAgenda({
        ...values,
        committeeID: committee.id,
        sessionID: null,
        agendaID: committee.agendaHeader?.id,
      });
      void message.success(notifyMessages.addSuccess);
      closeModal && setStandardAgendaModal(false);
      void loadCommittee();
    } catch {
      void message.error(notifyMessages.addFailed);
    } finally {
      closeModal ? setLoadingSaveClose(false) : setLoadingSave(false);
    }
  };

  const removeAgenda = async (agendaId: string) => {
    try {
      await CommitieService.deleteAgenda(agendaId);
      void message.success(notifyMessages.deleteSuccess);
      void loadCommittee();
    } catch {
      void message.error(notifyMessages.deleteCommitieFailed);
    }
  };

  return (
    <div className="edit-commitie-wrapper">
      {standardAgendaModal && committee && (
        <AgendaModal
          showModal={standardAgendaModal}
          handleModalClose={() => setStandardAgendaModal(false)}
          saveDefaultAgenda={addStandardAgenda}
          loadingSave={loadingSave}
          loadingSaveClose={loadingSaveClose}
          committee={committee}
          setLastAgendaItem={() => {}}
        />
      )}

      <Loader />

      {committee && (
        <>
          {id ? (
            <div className="site-card-wrapper">
              <h3 className="objective-item tw-mr-1">{committee.name}</h3>
              <span>- {t('comitatiPage.dettagliComitati')} </span>
            </div>
          ) : (
            <div className="site-card-wrapper">{t('comitatiPage.aggiungiCommitato')}</div>
          )}

          <Card>
            <Form
              name="comitie_details"
              layout="vertical"
              {...formItemLayout}
              onFinish={sendForm}
              onValuesChange={() => {
                setFieldsChanged(true);
              }}>
              <Row gutter={{ lg: 24 }}>
                <Col
                  className="gutter-row"
                  xs={{ span: 4 }}
                  sm={{ span: 4 }}
                  md={{ span: 4 }}
                  lg={{ span: 4 }}
                />
                <Col
                  className="gutter-row"
                  xs={{ span: 20 }}
                  sm={{ span: 20 }}
                  md={{ span: 20 }}
                  lg={{ span: 20 }}>
                  <div className="button-wrapper">
                    {id && committee.projectsCount === 0 ? (
                      <Form.Item wrapperCol={{ span: 24 }}>
                        <Popconfirm
                          title={t('comitatiPage.eliminaComitato')}
                          onConfirm={deleteCommittee}
                          okText={t('general.si')}
                          cancelText={t('general.no')}>
                          <Button
                            type="primary"
                            className="tw-float-end"
                            icon={<DeleteOutlined />}>
                            {t('buttons.elimina')}
                          </Button>
                        </Popconfirm>
                      </Form.Item>
                    ) : (
                      <Form.Item wrapperCol={{ span: 24 }}>
                        <Tooltip title={t('comitatiPage.deleteCommitteeWarning')}>
                          <Button
                            type="primary"
                            className="tw-float-end"
                            disabled={true}
                            onClick={deleteCommittee}>
                            <DeleteOutlined /> {t('buttons.elimina')}
                          </Button>
                        </Tooltip>
                      </Form.Item>
                    )}

                    <Form.Item wrapperCol={{ span: 24 }}>
                      <Button
                        loading={loadingButton}
                        type="primary"
                        className="tw-float-end"
                        disabled={!fieldsChanged}
                        htmlType="submit">
                        <SaveOutlined /> {t('buttons.salva')}
                      </Button>
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row gutter={{ lg: 24 }}>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}>
                  <Card
                    title={t('general.informazioniGenerali')}
                    extra={
                      <Button onClick={() => redirectToCommitteeDashboard()}>
                        {t('comitatiPage.goToComitieDashboard')} <SelectOutlined />
                      </Button>
                    }>
                    <Row
                      gutter={{ lg: 24 }}
                      className="mt10">
                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                          label={t('general.nome')}
                          name="name"
                          rules={[{ required: true, message: requiredFields.required }]}
                          initialValue={committee.name}>
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                          label={t('comitatiPage.activitiesDuration')}
                          name="activitiesDuration"
                          rules={[{ required: true, message: requiredFields.required }]}
                          initialValue={committee.activitiesDuration}>
                          <InputNumber
                            min={0}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                          label={linkLabel(committee.repositoryLink)}
                          name="repositoryLink"
                          initialValue={committee.repositoryLink ? committee.repositoryLink : ''}
                          extra="Link format: http://example.com/">
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row
                      gutter={{ lg: 24 }}
                      className="mt10">
                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                          label={t('comitatiPage.participants')}
                          name="participants"
                          hasFeedback
                          initialValue={committeeParticipants.map((p) => p.userId)}>
                          <Select
                            style={{ width: '100%' }}
                            placeholder={t('general.seleziona')}
                            mode="multiple"
                            allowClear
                            options={userOptions?.filter(
                              (o) => !committeeAuditors.map((a) => a.userId).includes(o.value),
                            )}
                            showSearch
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            loading={!userOptions}
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                          label={t('comitatiPage.revisori')}
                          name="auditors"
                          hasFeedback
                          initialValue={committeeAuditors.map((a) => a.userId)}>
                          <Select
                            style={{ width: '100%' }}
                            placeholder={t('general.seleziona')}
                            allowClear
                            mode="multiple"
                            options={userOptions?.filter(
                              (o) => !committeeParticipants.map((p) => p.userId).includes(o.value),
                            )}
                            showSearch
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            loading={!userOptions}
                          />
                        </Form.Item>
                      </Col>

                      {id && (
                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 8 }}
                          md={{ span: 8 }}
                          lg={{ span: 8 }}>
                          <Form.Item label={t('comitatiPage.verbaleStandart')}>
                            <Space
                              direction="horizontal"
                              style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Link onClick={() => setStandardAgendaModal(true)}>
                                {t('comitatiPage.defaultAgendda')} <SelectOutlined />
                              </Link>

                              {committee.agendaHeader?.id && (
                                <Popconfirm
                                  title={t('comitatiPage.removeAgendaNotify')}
                                  onConfirm={() => removeAgenda(committee.agendaHeader?.id as string)}
                                  okText={t('general.si')}
                                  cancelText={t('general.no')}>
                                  <Button
                                    type="dashed"
                                    icon={<DeleteOutlined />}>
                                    {t('comitatiPage.removeAgenda')}
                                  </Button>
                                </Popconfirm>
                              )}
                            </Space>
                          </Form.Item>
                        </Col>
                      )}

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 4 }}
                        lg={{ span: 4 }}>
                        <Tooltip title={committee?.id ? t('proggetiPage.unableToChangeCommitteeStatus') : ''}>
                          <Form.Item
                            label={t('comitatiPage.isOperational')}
                            name="type"
                            initialValue={CommitieService.isOperational(committee.type)}>
                            <Switch disabled={id !== undefined} />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              {id && committee && (
                <Row gutter={{ lg: 24 }}>
                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}>
                    <Tabs
                      defaultActiveKey="1"
                      items={tabItems}
                    />
                  </Col>
                </Row>
              )}
            </Form>
          </Card>
        </>
      )}
    </div>
  );
};

export default CommitteePage;
