import { trackPromise } from 'react-promise-tracker';
import { authenticatedApi } from 'src/config/connectors';
import { CommitteeType, CommonApi, LegacyProjectApi, UserApi } from 'src/connectors/backend';
import i18n from '../i18n';

const CommonService = {
  getStatusData: async (objectCode: string) => {
    const languageCode = i18n.language;
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetStatusPicklist(objectCode, languageCode));
  },

  getCalculationTypeList: async (objectCode: string) => {
    const languageCode = i18n.language;
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetObjectCodePicklist(objectCode, languageCode));
  },

  getUsersPicklist: async () => {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetUserSelect());
  },

  getCommitiePicklist: async (committeeType?: CommitteeType) => {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetCommitteePicklist(committeeType));
  },

  getProjectsOptionData: async (objectCode: string) => {
    const languageCode = i18n.language;
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetObjectCodePicklist(objectCode, languageCode));
  },

  getSeverityPickList: async (objectCode: string) => {
    const languageCode = i18n.language;
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetObjectCodePicklist(objectCode, languageCode));
  },

  getUsersAvailability: async (projectId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetUserAvailability(projectId));
  },

  getPicklist: async (objectCode: string) => {
    const languageCode = i18n.language;
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetObjectCodePicklist(objectCode, languageCode));
  },

  getResponsibleUserList: async (projectId: string) => {
    const api = await authenticatedApi(LegacyProjectApi);

    return trackPromise(api.legacyProjectGetProjectMembersList(projectId));
  },

  getUserProjects: async (xMatrixID: string, userId: string) => {
    const languageCode = i18n.language;
    const api = await authenticatedApi(UserApi);

    return trackPromise(api.userGetUserProject(userId, xMatrixID, languageCode));
  },

  getKpiAnualGoalPicklist: async (annualGoalID: string) => {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetKPIAnnualGoalPicklist(annualGoalID));
  },

  chaeckUserAccessOnObject: async (object: any) => {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonCheckObjectSecurityUser(object));
  },

  getProjectCostType: async (objectCode: string) => {
    const languageCode = i18n.language;
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetObjectCodePicklist(objectCode, languageCode));
  },

  getAvailableCommitteeMembers: async (committeeID: string, revisori: boolean) => {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetAvailableCommitteeMembers(committeeID, revisori));
  },
};

export default CommonService;
