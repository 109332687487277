import { Form } from 'antd';
import moment from 'moment';
import React from 'react';
import '../../projects/projectForm/projectActivities/activities.scss';

import { SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
  Switch,
  Table,
  TimePicker,
} from 'antd';
import BraftEditor from 'braft-editor';
import { withTranslation } from 'react-i18next';
import UsersService from '../../../services/pages/usersService';
import ModalFooterButtons from '../components/modal/modalFooterButtons';
import {
  RichTextEditorControls,
  feedItemsTypes,
  httpLinkRegex,
  httpsLinkRegex,
  userParticipation,
} from '../utils/constants';
import { disableDatesBeforeToday } from '../utils/functions';
import { requiredFields } from '../utils/notifyMessages';

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const NewFeedCommentForm = (props) => {
  return (
    <Row
      gutter={{ lg: 24 }}
      className="row-margin"
    >
      <Col
        className="gutter-row"
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 24 }}
      >
        <Form.Item
          label={props.t('general.soggetto')}
          name="subject"
          rules={[{ required: true, message: requiredFields.required }]}
          initialValue={props.modalData.subject}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col
        className="gutter-row"
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 3 }}
        lg={{ span: 24 }}
      >
        <Form.Item
          label={props.t('general.haPriorita')}
          name="priority"
          valuePropName="checked"
          // initialValue={false}
          initialValue={props.modalData.priority === 1 ? true : false}
        >
          <Switch />
        </Form.Item>
      </Col>

      <Col
        className="gutter-row"
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 21 }}
        lg={{ span: 24 }}
      >
        <Form.Item
          label={props.t('general.descrizione')}
          name="description"
          // initialValue={props.modalData.description}
          initialValue={BraftEditor.createEditorState(props.modalData.description)}
        >
          {/* <Input /> */}
          <BraftEditor
            //style={{ height: 350, overflowX: 'auto' }}
            contentFormat="html"
            language="en"
            id="editor-text"
            controls={RichTextEditorControls}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

const NewFeedFileForm = (props) => {
  return (
    <Row
      gutter={{ lg: 24 }}
      className="row-margin"
    >
      <Col
        className="gutter-row"
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 24 }}
      >
        <Form.Item
          label={props.t('general.soggetto')}
          name="subject"
          rules={[{ required: true, message: requiredFields.required }]}
          initialValue={props.modalData.subject}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col
        className="gutter-row"
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 3 }}
        lg={{ span: 3 }}
      >
        <Form.Item
          label={props.t('general.haPriorita')}
          name="priority"
          valuePropName="checked"
          initialValue={props.modalData.priority === 1 ? true : false}
        >
          <Switch />
        </Form.Item>
      </Col>

      <Col
        className="gutter-row"
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 24 }}
      >
        <Form.Item
          label={props.t('feed.urlDocumento')}
          name="documentURL"
          extra="Link format: http://example.com/"
          rules={[
            { required: true, message: requiredFields.required },
            {
              validator: async (_, value) => {
                if (!httpLinkRegex.test(value)) {
                  return Promise.reject(new Error('Link not valid'));
                }
                if (!httpsLinkRegex.test(value)) {
                  return Promise.reject(new Error('Link not valid'));
                }
              },
            },
          ]}
          initialValue={props.modalData.documentURL}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col
        className="gutter-row"
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 21 }}
        lg={{ span: 21 }}
      >
        <Form.Item
          label={props.t('general.descrizione')}
          name="description"
          initialValue={BraftEditor.createEditorState(props.modalData.description)}
        >
          <BraftEditor
            contentFormat="html"
            language="en"
            id="editor-text"
            controls={RichTextEditorControls}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

const NewFeedMeetingForm = ({
  modalData,
  rowSelection,
  columns,
  usersList,
  onStartDateChange,
  onDurationChange,
  onStartTimeChange,
  chDuration,
  chEndDate,
  chEndTime,
  chStartTime,
  t,
}) => {
  return (
    <Row
      gutter={{ lg: 24 }}
      className="row-margin"
    >
      <Col
        className="gutter-row"
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 24 }}
      >
        <Form.Item
          label={t('general.soggetto')}
          name="subject"
          rules={[{ required: true, message: requiredFields.required }]}
          initialValue={modalData.subject}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col
        className="gutter-row"
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 24 }}
      >
        <Row>
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
          >
            <Form.Item
              label={t('proggetiPage.createReunioneOutlook')}
              name="integrateInOutlook"
              valuePropName="checked"
              initialValue={modalData.integrateInOutlook}
            >
              <Switch />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
          >
            <Form.Item
              label={t('proggetiPage.createReunioneTeams')}
              name="integrateInTeams"
              valuePropName="checked"
              initialValue={modalData.integrateInTeams}
            >
              <Switch />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 3 }}
            lg={{ span: 3 }}
          >
            <Form.Item
              label={t('general.haPriorita')}
              name="priority"
              valuePropName="checked"
              initialValue={modalData.priority === 1 ? true : false}
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </Col>

      <Col
        className="gutter-row"
        xs={{ span: 12 }}
        sm={{ span: 5 }}
        md={{ span: 5 }}
        lg={{ span: 5 }}
      >
        <Form.Item
          label={t('general.start')}
          name="scheduledStart"
          rules={[{ required: true, message: requiredFields.required }]}
          initialValue={modalData.scheduledStart ? moment(modalData.scheduledStart) : modalData.scheduledStart}
        >
          {/* <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY"  disabledDate={(current) => disableDatesBeforeToday(current)} /> */}
          <DatePicker
            allowClear={false}
            onChange={(date) => onStartDateChange(date)}
            style={{ width: '100%' }}
            format="DD/MM/YYYY"
            disabledDate={(current) => disableDatesBeforeToday(current)}
          />
        </Form.Item>
      </Col>

      <Col
        className="gutter-row"
        xs={{ span: 12 }}
        sm={{ span: 4 }}
        md={{ span: 4 }}
        lg={{ span: 4 }}
      >
        <Form.Item
          label={t('proggetiPage.oraInizio')}
          name="startTime"
          rules={[{ required: true, message: requiredFields.required }]}
          initialValue={modalData.startTime ? moment(modalData.startTime, 'HH:mm') : modalData.startTime}
        >
          <TimePicker
            allowClear={false}
            format="HH:mm"
            onChange={(value) => onStartTimeChange(value)}
          />
        </Form.Item>
      </Col>

      <Col
        className="gutter-row"
        xs={{ span: 1 }}
        sm={{ span: 1 }}
        md={{ span: 1 }}
        lg={{ span: 1 }}
      >
        <Divider
          type="vertical"
          style={{ height: '100%', margin: '0 2px' }}
        />
      </Col>

      <Col
        className="gutter-row"
        xs={{ span: 12 }}
        sm={{ span: 4 }}
        md={{ span: 4 }}
        lg={{ span: 4 }}
      >
        <Form.Item
          label={t('proggetiPage.durata') + ' (h)'}
          name="duration"
          rules={[{ required: true, message: requiredFields.required }]}
          initialValue={chDuration}
        >
          <InputNumber
            step=".5"
            style={{ width: '100%' }}
            onChange={(value) => onDurationChange(value, chStartTime)}
          />
        </Form.Item>
      </Col>

      <Col
        className="gutter-row"
        xs={{ span: 1 }}
        sm={{ span: 1 }}
        md={{ span: 1 }}
        lg={{ span: 1 }}
      >
        <Divider
          type="vertical"
          style={{ height: '100%', margin: '0 2px' }}
        />
      </Col>

      <Col
        className="gutter-row"
        xs={{ span: 11 }}
        sm={{ span: 5 }}
        md={{ span: 5 }}
        lg={{ span: 5 }}
      >
        <Form.Item
          label={t('general.end')}
          //name="scheduledEnd"
          rules={[{ required: true, message: requiredFields.required }]}
          //initialValue={modalData.scheduledEnd ? moment(modalData.scheduledEnd) : modalData.scheduledEnd}
        >
          <DatePicker
            allowClear={false}
            value={chEndDate ? moment(chEndDate) : chEndDate}
            style={{ width: '100%' }}
            format="DD/MM/YYYY"
            disabled={true}
          />
        </Form.Item>
      </Col>

      <Col
        className="gutter-row"
        xs={{ span: 12 }}
        sm={{ span: 4 }}
        md={{ span: 4 }}
        lg={{ span: 4 }}
      >
        <Form.Item
          label={t('proggetiPage.oraFine')}
          //name="endTime"
          rules={[{ required: true, message: requiredFields.required }]}
          //initialValue={modalData.endTime ? moment(modalData.endTime, "HH:mm") : modalData.endTime}
        >
          <TimePicker
            allowClear={false}
            disabled={true}
            format="HH:mm"
            value={chEndTime ? moment(chEndTime, 'HH:mm') : chEndTime}
          />
        </Form.Item>
      </Col>

      <Col
        className="gutter-row"
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 24 }}
      >
        <div className="ant-col ant-col-24 ant-form-item-label">
          <label className="ant-form-item-required">{t('general.partecipanti')} </label>
        </div>
        <Table
          rowSelection={rowSelection}
          className="table-height"
          columns={columns}
          size="small"
          dataSource={usersList}
          bordered={true}
          rowKey={(obj) => obj.userID}
          style={{ width: '99%' }}
        />
      </Col>

      <Col
        className="gutter-row"
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 21 }}
        lg={{ span: 24 }}
      >
        <Form.Item
          label={t('general.descrizione')}
          name="description"
          // initialValue={modalData.description}
          initialValue={BraftEditor.createEditorState(modalData.description)}
        >
          <BraftEditor
            style={{ height: 350, overflowX: 'auto' }}
            contentFormat="html"
            language="en"
            id="editor-text"
            controls={RichTextEditorControls}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

class EditFeedItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      usersList: null,
      selectedRowKeys: props.modalData.users.length > 0 ? props.modalData.users.map((a) => a.userID) : [],

      endTime: props.modalData.endTime,
      startTime: props.modalData.startTime,
      endDate: props.modalData.scheduledEnd,
      duration: props.modalData.duration,
    };
  }

  componentDidMount() {
    this.retrieveUsersList();
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    this.setState({ searchText: '' });
  };

  async retrieveUsersList() {
    await UsersService.getUsersListData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ usersList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  editData = (values, activityData) => {
    const { endDate, startTime, endTime, duration } = this.state;
    values['scheduledEnd'] = endDate;
    values['startTime'] = startTime;
    values['endTime'] = endTime;
    values['duration'] = duration;

    const editorContentToHtml = BraftEditor.createEditorState(values['description']).toHTML();
    values['description'] = editorContentToHtml;

    this.props.handleUpdateFeed(values, activityData);
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onStartDateChange = (startDate) => {
    this.setState({ endDate: startDate });
  };

  onDurationChange = (value, startTime) => {
    if (value && startTime) {
      const endTime = moment(startTime, 'HH:mm').add(value, 'hours');
      this.setState({
        endTime: endTime,
        startTime: startTime,
        duration: value,
      });
    } else if (value && !startTime) {
      this.setState({
        duration: value,
      });
    } else {
      return;
    }
  };

  onStartTimeChange = (value) => {
    const { duration } = this.state;

    if (value && duration) {
      const endTime = moment(value, 'HH:mm').add(duration, 'hours');
      this.setState({
        endTime: endTime,
        startTime: value,
        duration: duration,
      });
    } else if (value && !duration) {
      this.setState({
        endTime: value,
        startTime: value,
      });
    } else {
      return;
    }
  };

  render() {
    const { showEditModal, handleEditModalClose, loadingSave, modalData, t } = this.props;
    const { usersList, selectedRowKeys, duration, endTime, startTime, endDate } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: (record) => ({
        disabled: true,
      }),
    };

    const columns = [
      {
        title: `${t('general.nome')}`,
        dataIndex: 'fullName',
        ellipsis: {
          showTitle: true,
        },
        sorter: (a, b) => {
          a = a.fullName || '';
          b = b.fullName || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('fullName'),
      },
      {
        title: `${t('general.partecipazione')}`,
        dataIndex: 'participationTypeMask',
        render: (text, record, index) => {
          const participationMaskFound =
            modalData && modalData.users.length > 0
              ? modalData.users.filter((obj) => {
                  return obj.userID === record.userID;
                })
              : [];

          if (participationMaskFound.length > 0) {
            return (
              <Switch
                disabled={true}
                defaultChecked={
                  participationMaskFound[0].participationTypeMask === userParticipation.required ? true : false
                }
                checkedChildren="Necessario"
                unCheckedChildren="Opzionale"
              />
            );
          } else {
            return (
              <Switch
                disabled={true}
                defaultChecked={false}
                checkedChildren={t('general.necessario')}
                unCheckedChildren={t('general.opzionale')}
              />
            );
          }
        },
      },
    ];

    return (
      <Modal
        className="add-kpi-modal"
        width={800}
        title={t('feed.aggiornamentoFeed')}
        open={showEditModal}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={handleEditModalClose}
        footer={[
          <>
            {/* 
                    <Space direction='horizontal'>
                    <Button
                        loading={loadingSave}
                        form={"editFeedForm"}
                        icon={<SaveOutlined />}
                        type="primary"
                        key="submit"
                        htmlType="submit"
                        disabled={!this.state.fieldsChanged}
                    >
                        {t('buttons.salva')}</Button>

                    <Button
                        loading={loadingSaveClose}
                        type="primary"
                        key="submitClose"
                        disabled={!fieldsChanged || !formErrors.length < 1}
                        onClick={() => saveAgendaData(false, true)}
                    >
                        {t('buttons.salvaChiudi')}</Button>

                    <Button onClick={handleEditModalClose} key='close'>
                        {t('buttons.annulla')}
                    </Button>
                </Space> */}

            <ModalFooterButtons
              disabled={!this.state.fieldsChanged}
              loadingButton={loadingSave}
              modalClose={handleEditModalClose}
              formId={'editFeedForm'}
            />
          </>,
        ]}
      >
        <Card>
          <Form
            id="editFeedForm"
            layout="vertical"
            {...formItemLayout}
            onFinish={(values) => this.editData(values, modalData)}
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}
          >
            <Row
              gutter={{ lg: 24 }}
              className="row-margin"
              style={{ display: 'inherit' }}
            >
              {modalData && modalData.activityType === feedItemsTypes.comment && (
                <NewFeedCommentForm
                  t={t}
                  modalData={modalData}
                />
              )}

              {modalData && modalData.activityType === feedItemsTypes.file && (
                <NewFeedFileForm
                  t={t}
                  modalData={modalData}
                />
              )}

              {modalData && modalData.activityType === feedItemsTypes.meeting && (
                <NewFeedMeetingForm
                  usersList={usersList}
                  rowSelection={rowSelection}
                  columns={columns}
                  modalData={modalData}
                  onStartDateChange={this.onStartDateChange}
                  onDurationChange={this.onDurationChange}
                  onStartTimeChange={this.onStartTimeChange}
                  chDuration={duration}
                  chEndTime={endTime}
                  chStartTime={startTime}
                  chEndDate={endDate}
                  t={t}
                />
              )}
            </Row>
          </Form>
        </Card>
      </Modal>
    );
  }
}

export default withTranslation()(EditFeedItem);
