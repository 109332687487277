import {
  CheckOutlined,
  DeleteOutlined,
  DownOutlined,
  EditFilled,
  EditOutlined,
  ExportOutlined,
  PlusOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Checkbox, Input, Popconfirm, Select, Table, Tag, Tooltip, message } from 'antd';
import ButtonGroup from 'antd/es/button/button-group';
import type { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { type FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiCollapseDiagonal2Fill, RiExpandDiagonal2Fill } from 'react-icons/ri';
import EditProjectActivity from 'src/components/projects/projectForm/projectActivities/editProjectActivity';
import ProjectSectionTitle from 'src/components/shared/components/ProjectSectionTitle/ProjectSectionTitle';
import type { GetProjectResponseDto } from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import { type PRS, getProjectReviewSystemList } from 'src/services/pages/prsService';
import { handleDateChange } from 'src/utils/date';
import { KanBanView, List, Summary } from '../../../../assets/icons';
import ActivityService from '../../../../services/pages/activitiesServices';
import DeliverablePageService from '../../../../services/pages/deliverableService';
import DeliverableService from '../../../../services/pages/projectServices/deliverableServices';
import { addQueryParam, getQueryParam } from '../../../../utils/url-utils';
import TableLayout from '../../../shared/tableLayout';
import { userIsProjectTeamLeaderOrSponsor } from '../../../shared/utils/authRolesProvilege/authRolesPrivilege';
import { PRSStatus, activityStatus, deliverableStatus, formatOfDate } from '../../../shared/utils/constants';
import { checkDeliverableStatus, generateUserInitials, updateNotificationBell } from '../../../shared/utils/functions';
import { notifyMessages } from '../../../shared/utils/notifyMessages';
import '../../project.scss';
import { DeliverableIcon, PhaseIcon, TaskIcon } from './kanban-view/components/icons';
import PrsKanBanView from './kanban-view/index';
import NewProjectDeliverable from './newDeliverable';

const defaultStatusFilter = [PRSStatus.OPEN, PRSStatus.DRAFT];

const ProjectDeliverable: FC<{
  currentProject: GetProjectResponseDto;
  hasUserAccess: boolean;
  isPresentationMode: boolean;
}> = ({ hasUserAccess, isPresentationMode }) => {
  const { t } = useTranslation();
  const userData = useAppSelector((state) => state.userData.userData);
  const currentProject = useAppSelector((state) => state.project.currentProject);

  const [showEditModal, setShowEditModal] = useState(false);
  const [modalData, setModalData] = useState<PRS | null>(null);
  const [loadingDeliverables, setLoadingDeliverables] = useState(false);
  const [showNewDeliverableModal, setShowNewDeliverableModal] = useState(false);
  const [deliverablesList, setDeliverablesList] = useState<PRS[]>([]);
  const [filteredDeliverables, setFilteredDeliverables] = useState<PRS[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [view, setView] = useState(getQueryParam('view') || 'list');
  const [activeStatuses, setActiveStatuses] = useState<number[]>(defaultStatusFilter);

  useEffect(() => {
    retrieveData();
    setView(getQueryParam('view') || 'list');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filterList = (list: PRS[]): PRS[] => {
      return list
        .map((item) => {
          // Recursively filter children if they exist
          const filteredChildren = item.children ? filterList(item.children) : []; // Recursive call

          // Include the item if it matches the status or has matching children
          if (matchesStatus(item) || filteredChildren.length > 0) {
            return {
              ...item,
              children: filteredChildren.length > 0 ? filteredChildren : undefined, // Keep children only if present
            };
          }

          // Exclude item if no match and no matching children
          return null;
        })
        .filter((item) => item != null) as PRS[];
    };

    // Apply filter only if activeStatuses is not empty
    if (activeStatuses.length > 0) {
      if (activeStatuses !== defaultStatusFilter) {
        toggleExpandAll(true);
      }
      const filtered = filterList(deliverablesList);
      setFilteredDeliverables(filtered);
    } else {
      setFilteredDeliverables(deliverablesList);
    }

    setLoadingDeliverables(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStatuses, deliverablesList]);

  const retrieveData = async () => {
    if (!currentProject?.projectID) {
      return;
    }

    setLoadingDeliverables(true);

    try {
      const prs = await getProjectReviewSystemList({
        projectId: currentProject?.projectID,
      });

      setDeliverablesList(prs || []);
    } catch {
      setLoadingDeliverables(false);
    }
  };

  const matchesStatus = (item: PRS): boolean => {
    /**
     * This is a workaround to check if the status matches the activeStatuses
     * Activities have a different status than deliverables and phases.
     */

    const s = item.status as number;

    if (item.activityType === 'activity') {
      if (s === PRSStatus.SUSPENDED && activeStatuses.includes(PRSStatus.CLOSED)) {
        return true;
      }

      if (s === PRSStatus.CLOSED && activeStatuses.includes(PRSStatus.SUSPENDED)) {
        return true;
      }

      if (s === PRSStatus.DRAFT && activeStatuses.includes(PRSStatus.DRAFT)) {
        return true;
      }

      if (s === PRSStatus.OPEN && activeStatuses.includes(PRSStatus.OPEN)) {
        return true;
      }
    } else {
      return activeStatuses.includes(s);
    }

    return false;
  };

  const statusFilterOptions = [
    {
      label: `${t('status.draft')}`,
      value: PRSStatus.DRAFT,
    },
    {
      label: `${t('status.aperto')}`,
      value: PRSStatus.OPEN,
    },
    {
      label: `${t('status.sospeso')}`,
      value: PRSStatus.SUSPENDED,
    },
    {
      label: `${t('status.closed')}`,
      value: PRSStatus.CLOSED,
    },
  ];

  const onExpand = async (expanded: boolean, record: any) => {
    if (expanded) {
      setExpandedKeys([...expandedKeys, record.key]);
    } else {
      const index = expandedKeys.indexOf(record.key);
      if (index > -1) {
        expandedKeys.splice(index, 1);
        setExpandedKeys([...expandedKeys]);
      }
    }
  };

  const toggleExpandAll = (force?: boolean) => {
    const getAllKeys = (list: PRS[]) => {
      let keys: string[] = [];

      list?.forEach((child) => {
        keys.push(child.key);
        if ('children' in child && child.children && child.children?.length > 0) {
          keys = keys.concat(getAllKeys(child.children as PRS[]));
        }
      });

      return keys;
    };

    const allKeys = getAllKeys(deliverablesList);

    if (force === undefined) {
      if (expandedKeys.length === 0) {
        setExpandedKeys(allKeys);
      } else {
        setExpandedKeys([]);
      }
    } else {
      setExpandedKeys(force ? allKeys : []);
    }
  };

  const toggleNewDeliverableModal = () => {
    setShowNewDeliverableModal(true);
  };

  const handleNewDeliverableModalClose = () => {
    setShowNewDeliverableModal(false);
  };

  const addProjectDeliverable = async (values: any, projectId: string, deliverableDefaultStartDate: string) => {
    try {
      const response = await DeliverablePageService.insertDeliverable({
        ...values,
        startDate: handleDateChange(deliverableDefaultStartDate),
        endDate: handleDateChange(values.endDate),
        projectID: projectId,
      });

      if (response.data.success) {
        message.success(notifyMessages.addSuccess);
        handleNewDeliverableModalClose();
        await retrieveData();
      } else {
        message.error(notifyMessages.addFailed);
      }
    } catch (error) {
      message.error(notifyMessages.addFailed);
    }
  };

  const removeDeliverable = async (deliverableId: string) => {
    try {
      const response = await DeliverableService.removeDeliverable(deliverableId);
      const resp = response.data;

      if (resp.success) {
        message.success(notifyMessages.deleteSuccess);
        await retrieveData();
      } else {
        message.error(notifyMessages.deleteFailed);
      }
    } catch (error) {
      message.error(notifyMessages.deleteFailed);
    }
  };

  const handleDeliverableFastClose = (record: PRS) => {
    DeliverablePageService.fastCloseDeliverable({
      deliverableID: record.deliverableID,
      status: deliverableStatus.chiuso,
      ownerID: record.owner,
      projectID: currentProject?.projectID,
    })
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          void retrieveData();
          updateNotificationBell();
        } else {
          message.error(notifyMessages.updateFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
      });
  };

  const onRemoveActivity = async (activityID?: string | null) => {
    if (!activityID) return;

    try {
      const { data } = await ActivityService.removeActivity(activityID);

      if (!data.success) {
        message.error(notifyMessages.deleteFailed);
        return;
      }

      void retrieveData();
      message.success(notifyMessages.deleteSuccess);
    } catch {
      message.error(notifyMessages.deleteFailed);
    }
  };

  const handleCloseProjectActivity = (record: PRS) => {
    ActivityService.updateActivity({
      ...record,
      status: activityStatus.closed,
      projectID: currentProject?.projectID,
    })
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          void retrieveData();
        } else {
          message.error(notifyMessages.updateFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
      });
  };

  const toggleEditProjectAtivityModal = (rowData: PRS) => {
    setShowEditModal(true);
    setModalData(rowData);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
    setModalData(null);
  };

  const handleUpdateProjectActivity = (values: any, activityID: string, projectID: string) => {
    ActivityService.updateActivity({
      activityID: activityID,
      projectID: projectID,
      startDate: handleDateChange(values.startDate),
      endDate: handleDateChange(values.endDate),
    })
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          handleEditModalClose();
          message.success(notifyMessages.updateSuccess);
          void retrieveData();
        } else {
          message.error(notifyMessages.updateFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
      });
  };

  const handleSearch = (value: string) => {
    if (!value) {
      setFilteredDeliverables(deliverablesList);
      return;
    }

    const searchLower = value.toLowerCase();

    const filterRecursive = (items: PRS[]) =>
      items
        .map((item) => {
          const isMatch =
            (item.name && item.name.toLowerCase().includes(searchLower)) ||
            item.subject?.toLowerCase().includes(searchLower);

          // Recursively check children
          if (item.children) {
            const filteredChildren = filterRecursive(item.children) as PRS[];
            if (filteredChildren.length > 0 || isMatch) {
              return { ...item, children: filteredChildren };
            }
          }

          // Keep the item if it matches the search criteria
          return isMatch ? { ...item } : null;
        })
        .filter((item) => item != null); // Remove non-matching items

    const filteredData = filterRecursive(deliverablesList);
    setFilteredDeliverables(filteredData);
  };

  const columns: ColumnsType<PRS> = [
    {
      title: (
        <div className="tw-flex tw-gap-2 tw-items-center">
          <Summary />
          Summary
        </div>
      ),
      width: '400px',
      dataIndex: 'name',
      ellipsis: {
        showTitle: true,
      },
      key: 'name',
      showSorterTooltip: true,

      render(title, record) {
        return {
          children: (
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-ml-5">
              {record.activityType === 'activity' ? (
                <>
                  <Tooltip title="Task">
                    <TaskIcon />
                  </Tooltip>
                  <Tooltip title={record.subject}>
                    <span className="tw-truncate">{record.subject}</span>
                  </Tooltip>
                </>
              ) : record.parentDeliverableID ? (
                <Tooltip title="Deliverable">
                  <DeliverableIcon />
                </Tooltip>
              ) : (
                <Tooltip title="Fase">
                  <PhaseIcon />
                </Tooltip>
              )}
              <Tooltip title={title}>
                <span className="tw-truncate">{title?.toUpperCase()}</span>
              </Tooltip>
              {record.activityType !== 'activity' && (
                <>
                  <DownOutlined
                    className="tw-ml-auto tw-scale-125 tw-mr-3"
                    style={{ color: checkDeliverableStatus(record, 'projectDeliverable') }}
                  />
                </>
              )}
            </div>
          ),
        };
      },
    },
    {
      title: `${t('general.end')}`,
      dataIndex: 'endDate',
      key: 'endDate',
      width: '80px',
      showSorterTooltip: true,
      ellipsis: {
        showTitle: false,
      },
      render: (title) => (
        <Tooltip title={title ? moment(title).format(formatOfDate) : ''}>
          {title ? moment(title).format(formatOfDate) : ''}
        </Tooltip>
      ),
    },
    {
      title: `${t('general.stato')}`,
      dataIndex: 'statusDescription',
      key: 'statusDescription',
      width: '70px',
      showSorterTooltip: true,
      render(text, record) {
        return {
          children: <Tag data-testid="stateTag">{text}</Tag>,
        };
      },
    },

    {
      title: `${t('proggetiPage.chi')}`,
      dataIndex: 'ownerFullName',
      key: 'ownerFullName',
      width: '30px',
      ellipsis: {
        showTitle: false,
      },
      showSorterTooltip: true,

      render: (title, record) =>
        record.activityType === 'activity' ? (
          <div className="table-avatar">
            <Tooltip title={record.fullName}>
              <Avatar size="small">
                {generateUserInitials(record.fullName?.split(' ')?.at(0), record.fullName?.split(' ')?.at(1))}
              </Avatar>
            </Tooltip>
          </div>
        ) : (
          <div className="table-avatar">
            <Tooltip title={title}>
              <Avatar size="small">{generateUserInitials(title?.split(' ')?.at(0), title?.split(' ')?.at(1))}</Avatar>
            </Tooltip>
          </div>
        ),
      // </Popover>
    },
    {
      title: `${t('deliverablePage.dataFinePrevista')}`,
      dataIndex: 'rescheduledEndDate',
      key: 'rescheduledEndDate',
      width: '80px',
      ellipsis: {
        showTitle: false,
      },
      render: (title) => (
        <Tooltip title={title ? moment(title).format(formatOfDate) : ''}>
          {title ? moment(title).format(formatOfDate) : ''}
        </Tooltip>
      ),
      showSorterTooltip: true,
    },
    {
      title: '',
      key: 'action',
      width: '80px',
      render: (text, record) => {
        const hasAccess =
          record.hasAccess ||
          userIsProjectTeamLeaderOrSponsor(userData, currentProject?.teamLeaderID, currentProject?.sponsorID);

        if (!hasAccess) {
          return null; // no actions if there no permission
        }

        if (record.activityType === 'activity') {
          const isClosed = record.status === activityStatus.closed;
          const quickClose = record.status === activityStatus.active || record.status === activityStatus.draft;

          return (
            <div className="tw-flex tw-gap-2">
              {!isClosed && (
                <Popconfirm
                  key="remove"
                  title={t('buttons.rimuoveAttivita')}
                  icon={<WarningOutlined />}
                  okText={t('general.si')}
                  cancelText={t('general.no')}
                  onConfirm={() => onRemoveActivity(record.activityID)}
                >
                  <Button
                    danger
                    size="middle"
                    className="tw-text-red-500"
                    icon={<DeleteOutlined title={t('buttons.rimuoveAttivita')} />}
                  />
                </Popconfirm>
              )}

              {record.deliverableID ? (
                <Button
                  size="middle"
                  icon={<ExportOutlined />}
                  href={`/deliverables/id/${record.deliverableID}`}
                />
              ) : (
                <Button
                  size="middle"
                  icon={<EditFilled title={t('buttons.modificaAttivita')} />}
                  onClick={() => toggleEditProjectAtivityModal(record)}
                />
              )}

              {quickClose && (
                <Popconfirm
                  key="close"
                  placement="topRight"
                  title={t('leMieAttivitaPage.chiudiQuestaAttivita')}
                  icon={<WarningOutlined />}
                  okText={t('general.si')}
                  cancelText={t('general.no')}
                  onConfirm={() => handleCloseProjectActivity(record)}
                >
                  <Tooltip title={t('buttons.chiusuraRapida')}>
                    <Button
                      size="middle"
                      icon={<CheckOutlined />}
                    />
                  </Tooltip>
                </Popconfirm>
              )}
            </div>
          );
        }

        return (
          <div className="tw-flex tw-items-center tw-gap-2">
            <Popconfirm
              placement="topRight"
              title={t('proggetiPage.rimuoveProgettoDeliverable')}
              onConfirm={() => removeDeliverable(record.deliverableID)}
              okText={t('general.si')}
              cancelText={t('general.no')}
            >
              <Button
                danger
                onClick={(e) => e.stopPropagation()}
                size="middle"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>

            <Button
              size="middle"
              onClick={(e) => e.stopPropagation()}
              icon={<EditOutlined />}
              href={`/deliverables/id/${record.deliverableID}`}
            />

            {record.status !== deliverableStatus.chiuso && (
              <Popconfirm
                key="close"
                placement="topRight"
                title={t('deliverablePage.chiudiDeliverable')}
                icon={<WarningOutlined />}
                okText={t('general.si')}
                cancelText={t('general.no')}
                onConfirm={() => handleDeliverableFastClose(record)}
              >
                <Tooltip title={t('buttons.chiusuraRapida')}>
                  <Button
                    size="middle"
                    onClick={(e) => e.stopPropagation()}
                    icon={<CheckOutlined />}
                  />
                </Tooltip>
              </Popconfirm>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      {showNewDeliverableModal && (
        <NewProjectDeliverable
          showNewDeliverableModal={showNewDeliverableModal}
          handleNewDeliverableModalClose={handleNewDeliverableModalClose}
          addProjectDeliverable={addProjectDeliverable}
          currentProject={currentProject}
        />
      )}

      {showEditModal && currentProject && (
        <EditProjectActivity
          showEditModal={showEditModal}
          projectActivitiesColumns
          handleEditModalClose={handleEditModalClose}
          handleUpdateProjectActivity={handleUpdateProjectActivity}
          modalData={modalData}
          projectID={currentProject?.projectID}
          projectName={currentProject?.name}
          currentProject={currentProject}
        />
      )}
      {view === 'list' ? (
        <TableLayout title={<ProjectSectionTitle title={t('proggetiPage.projectReviewSystem')} />}>
          <TableLayout.ExtraTitle>
            <Button
              className={'tw-bg-primary/10'}
              icon={<List className="tw-fill-primary" />}
            ></Button>
            <ButtonGroup>
              <Tooltip title={t('general.boardView')}>
                <Button
                  onClick={() => {
                    addQueryParam('view', 'board');
                    setView('board');
                  }}
                  icon={<KanBanView className={'tw-fill-black'} />}
                ></Button>
              </Tooltip>
            </ButtonGroup>
          </TableLayout.ExtraTitle>
          <TableLayout.Actions>
            <Button
              icon={expandedKeys.length === 0 ? <RiExpandDiagonal2Fill /> : <RiCollapseDiagonal2Fill />}
              onClick={() => toggleExpandAll()}
            >
              {expandedKeys.length === 0 ? t('buttons.expandAll') : t('buttons.collapseAll')}
            </Button>

            <Checkbox
              className="tw-border tw-h-10 tw-rounded-md tw-flex tw-items-center tw-pl-2"
              checked={activeStatuses.includes(PRSStatus.CLOSED)}
              onChange={(e) => {
                if (e.target.checked) {
                  setActiveStatuses([...activeStatuses, PRSStatus.CLOSED]);
                } else {
                  setActiveStatuses(activeStatuses.filter((s) => s !== PRSStatus.CLOSED));
                }
              }}
            >
              {t('buttons.showClosed')}
            </Checkbox>

            <Select
              mode="multiple"
              className="tw-shrink-0"
              data-testid="statusFilter"
              id="statusFilter"
              allowClear
              optionFilterProp="label"
              onChange={(value) => setActiveStatuses(value)}
              style={{ width: 250 }}
              maxTagCount={1}
              value={activeStatuses}
              options={statusFilterOptions}
              placeholder={t('general.seleziona')}
            ></Select>

            <Input.Search
              allowClear
              placeholder={t('general.search')}
              onSearch={handleSearch}
              style={{ width: 250 }}
            />

            {!isPresentationMode && !hasUserAccess ? (
              <NewDeliverableButton onClick={toggleNewDeliverableModal} />
            ) : null}
          </TableLayout.Actions>
          <TableLayout.Content>
            <Table
              sticky
              className="tw-min-h-full"
              size="small"
              columns={
                hasUserAccess
                  ? columns.filter((el) => {
                      return el.key !== 'action2' && el.key !== 'action';
                    })
                  : columns
              }
              dataSource={filteredDeliverables}
              expandable={{
                expandedRowKeys: expandedKeys,
                onExpand: onExpand,
                expandRowByClick: true,
                indentSize: 10,
              }}
              loading={loadingDeliverables}
              pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
              rowClassName={(record, index) => ((record?.children! && record?.children.length) > 0 ? 'parentRow' : '')}
            />
          </TableLayout.Content>
        </TableLayout>
      ) : (
        <PrsKanBanView
          updateDeliverables={() => retrieveData()}
          view={view}
          setView={setView}
          deliverablesList={deliverablesList}
          newBtn={
            !isPresentationMode && !hasUserAccess ? <NewDeliverableButton onClick={toggleNewDeliverableModal} /> : null
          }
        />
      )}
    </>
  );
};

const NewDeliverableButton = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Button
      htmlType="submit"
      icon={<PlusOutlined />}
      type="primary"
      {...props}
    >
      {t('buttons.aggiungiNuovo')}
    </Button>
  );
};

export default ProjectDeliverable;
