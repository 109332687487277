import { Form } from 'antd';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import '../../projects/project.scss';
import '../deliverable.scss';

import {
  EditOutlined,
  FolderOpenOutlined,
  PlusOutlined,
  RollbackOutlined,
  SaveOutlined,
  SearchOutlined,
  SelectOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tooltip,
  message,
} from 'antd';
import BraftEditor from 'braft-editor';
import Parser from 'html-react-parser';
import { withTranslation } from 'react-i18next';
import CommonService from '../../../services/commonService';
import DeliverablePageService from '../../../services/pages/deliverableService';
import DeliverableService from '../../../services/pages/projectServices/deliverableServices';
import ProgettiService from '../../../services/pages/projectServices/progettiService';
import Loader2 from '../../shared/components/loader2/loader2';
import Feed from '../../shared/feed/feed';
import {
  RichTextEditorControls,
  deliverableStatus,
  feedObjectType,
  feedRegardingObjectType,
  objectCodes,
  statusPickListCodes,
} from '../../shared/utils/constants';
import { areDatesValid, showErrorNotification, updateNotificationBell } from '../../shared/utils/functions';
import { notifyMessages, requiredFields } from '../../shared/utils/notifyMessages';
import EndDateConfirmationModal from './endDateConfirmationModal';

const createBrowserHistory = require('history').createBrowserHistory;
const history = createBrowserHistory({ forceRefresh: true });

const { Option } = Select;
const { TextArea } = Input;

class ViewEditDeliverable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      fieldsChanged: false,
      loadingButtonDelete: false,
      currentDeliverable: props.deliverableData,
      responsiblePickList: null,
      projectPickList: null,
      deliverablePickList: null,
      statusList: null,
      priorityPicklist: null,
      hasParent: false,
      parentEndDate: null,
      projectData: null,
      dataFinePrevista: props.deliverableData.rescheduledEndDate,
      isGenericResponsabileField: props.deliverableData.isGenericResponsabile,
      showGenericResponsabileField: props.deliverableData.isGenericResponsabile,
      showIsGenericResponsabileField: true,
      endDateConfirmationModal: false,
      formValues: null,

      selectedDeliverableData: null,
      selectedProjectData: null,
      selectedParentDeliverable: props.deliverableData.parentDeliverableID,
      selectedResponsabile: props.deliverableData.ownerID,

      currentPageUrl: window.location.pathname,
      showCommentEditor: false,
      statusListFilter: [],
    };
  }

  componentDidMount() {
    this.retrieveStatus();
    this.retrievePriority();
    this.retrieveProjectPickList();
    this.retrieveResponsibleUsersList(this.state.currentDeliverable.projectID);
    this.retrieveDeliverablePickList(this.state.currentDeliverable.projectID);
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    this.setState({ searchText: '' });
  };

  async retrieveStatus() {
    const objectCode = statusPickListCodes.delivarbleStatus;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const obj = [];
          resp.responseObject.value.map((item) => {
            obj.push({ text: item.statusDescription, value: item.statusDescription });
          });
          this.setState({ statusListFilter: obj });
          this.setState({ statusList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrievePriority() {
    const objectCode = objectCodes.deliverablePriority;
    await CommonService.getPicklist(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ priorityPicklist: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveResponsibleUsersList(projectId) {
    await CommonService.getResponsibleUserList(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const usr = [];
          const respData = resp.responseObject.value;
          respData.map((item) => {
            usr.push({ value: item.userID, label: item.fullName, key: item.userID, disabled: item.isDeleted });
          });

          this.setState({ responsiblePickList: usr });
        } else {
        }
      })
      .catch((error) => {});
  }

  // Retrieve list of projects
  async retrieveProjectPickList() {
    const { currentDeliverable } = this.state;
    await ProgettiService.getProjectPicklist()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const respData = resp.responseObject.value;

          const checkDeliverableProject = respData.some((el) => el.projectID === currentDeliverable.projectID);

          if (!checkDeliverableProject) {
            respData.push({ name: currentDeliverable.projectName, projectID: currentDeliverable.projectID });
          }
          this.setState({ projectPickList: respData });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveDeliverablePickList(projectId) {
    const { currentDeliverable } = this.state;
    const deliverableId = currentDeliverable.deliverableID;
    await DeliverableService.getParentDeliverableProjectPicklist(projectId, deliverableId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ deliverablePickList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  backFunction = () => {
    const { currentDeliverable } = this.state;
    const url = `/progetti/id/${currentDeliverable.projectID}/2`;
    history.push(url);
  };

  backFunctionButton = () => {
    history.goBack();
  };

  handleDateChange(date) {
    if (date) {
      const formatedDate = moment(date).format('YYYY-MM-DD');
      return formatedDate;
    }
    return null;
  }

  updateData = (values, id, confirmationResponse) => {
    const {
      selectedParentDeliverable,
      selectedResponsabile,
      currentDeliverable,
      dataFinePrevista,
      isGenericResponsabileField,
    } = this.state;

    const editorContentToHtml = BraftEditor.createEditorState(values['comment']).toHTML();
    values['comment'] = editorContentToHtml;
    values['updateParentEndDate'] = confirmationResponse;
    values.startDate = this.handleDateChange(values.startDate);
    values.endDate = this.handleDateChange(values.endDate);
    values['rescheduledEndDate'] = this.handleDateChange(dataFinePrevista);
    values.actualEndDate = this.handleDateChange(values.actualEndDate);
    values['deliverableID'] = id;

    values['parentDeliverableID'] = selectedParentDeliverable;
    values['ownerID'] = selectedResponsabile;
    values['isGenericResponsabile'] = isGenericResponsabileField;
    values['ownerName'] = this.props.deliverableData.ownerName;
    if (isGenericResponsabileField === false) {
      values['genericResponsible'] = '';
    }

    if (currentDeliverable.status === deliverableStatus.chiuso) {
      if (currentDeliverable.status !== values.status) {
        values['actualEndDate'] = null;
      }
    }

    this.setState({ loadingButton: true });
    DeliverablePageService.updateDeliverable(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          updateNotificationBell();
          message.success(notifyMessages.updateSuccess);
          this.setState({ loadingButton: false });
          window.location.reload();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  onProjectSelect = (option) => {
    this.setState({ selectedParentDeliverable: null });
    this.setState({ selectedResponsabile: null });
    this.setState({ responsiblePickList: null });
    this.retrieveDeliverablePickList(option);
    this.retrieveResponsibleUsersList(option);
    this.getSelectedProjectData(option);
    this.setState({ selectedDeliverableData: null });
  };

  onParentDeliverableChange = (option) => {
    if (option) {
      this.setState({ fieldsChanged: true });
      this.setState({ selectedParentDeliverable: option });
      this.getSelectedDeliverableData(option);
    }
  };

  getSelectedDeliverableData = (id) => {
    DeliverablePageService.getDeliverableById(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ selectedDeliverableData: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  };

  getSelectedProjectData = (id) => {
    ProgettiService.getParentProjectByID(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ selectedProjectData: resp.responseObject.value });

          if (resp.responseObject.value.teamLeaderID == null) {
            this.setState({ showIsGenericResponsabileField: false });
            this.setState({ isGenericResponsabileField: false });
            this.setState({ showGenericResponsabileField: false });
          } else {
            this.setState({ showIsGenericResponsabileField: true });
          }
        } else {
        }
      })
      .catch((error) => {});
  };

  onParentDeliverableClear = () => {
    this.setState({ fieldsChanged: true });
    this.setState({ selectedParentDeliverable: null });
    this.setState({ selectedDeliverableData: null });
  };

  onResponsabileClear = () => {
    this.setState({ selectedResponsabile: null });
  };

  onReponsabileChange = (option) => {
    if (option) {
      this.setState({ fieldsChanged: true });
      this.setState({ selectedResponsabile: option });
    }
  };

  disabledDate(current) {
    const { selectedDeliverableData, selectedProjectData, currentDeliverable } = this.state;

    if (selectedProjectData && !selectedDeliverableData) {
      const curr = moment(current).endOf('day');
      const startDate = moment(selectedProjectData.startDate).endOf('day');
      const endDate = moment(selectedProjectData.endDate).endOf('day');
      return curr < startDate || curr > endDate;
    } else if (selectedProjectData && selectedDeliverableData) {
      const curr = moment(current).endOf('day');
      const startDate = moment(selectedProjectData.startDate).endOf('day');
      const endDate = moment(selectedProjectData.endDate).endOf('day');
      return curr < startDate || curr > endDate;
    } else if (selectedDeliverableData) {
      const curr = moment(current).endOf('day');
      const startDate = moment(currentDeliverable.projectStartDate).endOf('day');
      const endDate = moment(currentDeliverable.projectEndDate).endOf('day');
      return curr < startDate || curr > endDate;
    } else {
      const curr = moment(current).endOf('day');
      const startDate = moment(currentDeliverable.projectStartDate).endOf('day');
      const endDate = moment(currentDeliverable.projectEndDate).endOf('day');
      return curr < startDate || curr > endDate;
    }
  }

  disableStartDate(current) {
    const { selectedDeliverableData, selectedProjectData, currentDeliverable } = this.state;

    if (selectedProjectData && !selectedDeliverableData) {
      const curr = moment(current).endOf('day');
      const startDate = moment(selectedProjectData.startDate).endOf('day');
      const endDate = moment(selectedProjectData.endDate).endOf('day');
      return curr < startDate || curr > endDate;
    } else if (selectedProjectData && selectedDeliverableData) {
      if (selectedDeliverableData.parentDeliverableID) {
        const curr = moment(current).endOf('day');
        const startDate = moment(selectedDeliverableData.parentDeliverableStartDate).endOf('day');
        const endDate = moment(selectedDeliverableData.parentDeliverableEndDate).endOf('day');
        return curr < startDate || curr > endDate;
      } else {
        const curr = moment(current).endOf('day');
        const startDate = moment(selectedProjectData.startDate).endOf('day');
        const endDate = moment(selectedProjectData.endDate).endOf('day');
        return curr < startDate || curr > endDate;
      }
    } else if (selectedDeliverableData) {
      if (selectedDeliverableData.parentDeliverableID) {
        const curr = moment(current).endOf('day');
        const startDate = moment(selectedDeliverableData.parentDeliverableStartDate).endOf('day');
        const endDate = moment(selectedDeliverableData.parentDeliverableEndDate).endOf('day');
        return curr < startDate || curr > endDate;
      } else {
        const curr = moment(current).endOf('day');
        const startDate = moment(selectedDeliverableData.startDate).endOf('day');
        const endDate = moment(selectedDeliverableData.endDate).endOf('day');
        return curr < startDate || curr > endDate;
      }
    } else {
      if (currentDeliverable.parentDeliverableID) {
        const curr = moment(current).endOf('day');
        const startDate = moment(currentDeliverable.parentDeliverableStartDate).endOf('day');
        const endDate = moment(currentDeliverable.parentDeliverableEndDate).endOf('day');
        return curr < startDate || curr > endDate;
      }
      if (currentDeliverable) {
        const curr = moment(current).endOf('day');
        const startDate = moment(currentDeliverable.projectStartDate).endOf('day');
        const endDate = moment(currentDeliverable.projectEndDate).endOf('day');
        return curr < startDate || curr > endDate;
      } else {
        const curr = moment(current).endOf('day');
        const startDate = moment(currentDeliverable.projectStartDate).endOf('day');
        const endDate = moment(currentDeliverable.projectEndDate).endOf('day');
        return curr < startDate || curr > endDate;
      }
    }
  }

  onStatusChange = (option) => {
    if (option === deliverableStatus.chiuso) {
      this.setState({ dataFinePrevista: null });
    }
  };

  onDataPrevistaChange = (date) => {
    this.setState({ fieldsChanged: true });
    if (date) {
      const convertedDate = moment(date);
      this.setState({ dataFinePrevista: convertedDate });
    } else {
      this.setState({ dataFinePrevista: null });
    }
  };

  checkDeliverableEndDateValidity = (selectedDeliverableData, currentDeliverable, values) => {
    const selectedDate = values.endDate;
    if (selectedDeliverableData) {
      if (moment(selectedDeliverableData.endDate) >= selectedDate) {
        return true;
      } else {
        return false;
      }
    } else if (
      currentDeliverable &&
      currentDeliverable.parentDeliverableID &&
      selectedDate > moment(currentDeliverable.parentDeliverableEndDate)
    ) {
      return false;
    } else {
      return true;
    }
  };

  checkDatesValidity = (values, deliverableID) => {
    const { t } = this.props;
    const { selectedResponsabile, selectedDeliverableData, currentDeliverable } = this.state;
    if (areDatesValid(values.startDate, values.endDate)) {
      if (selectedResponsabile) {
        if (!this.checkDeliverableEndDateValidity(selectedDeliverableData, currentDeliverable, values)) {
          this.toggleEndDateModal(values);
        } else {
          this.updateData(values, deliverableID, false);
        }
      } else {
        showErrorNotification(`${t('notifications.generalError')}`, requiredFields.responsabileRequired);
      }
    } else {
      showErrorNotification(`${t('notifications.generalError')}`, requiredFields.validateEndDate);
    }
  };

  showHideCommentEdit = () => {
    const { showCommentEditor } = this.state;
    this.setState({ showCommentEditor: !showCommentEditor });
  };

  commentLabel = () => {
    const { t } = this.props;
    return (
      <span>
        {t('general.commenti')} <EditOutlined onClick={() => this.showHideCommentEdit()} />
      </span>
    );
  };

  onGenericCheck = (checked) => {
    this.setState({ fieldsChanged: true });
    const { selectedProjectData, isGenericResponsabileField, currentDeliverable } = this.state;
    this.setState({ isGenericResponsabileField: !isGenericResponsabileField });

    const selectedResponsible =
      (selectedProjectData && selectedProjectData.teamLeaderID) !== null
        ? selectedProjectData.teamLeaderID
        : currentDeliverable.ownerID;

    if (selectedProjectData && selectedProjectData.teamLeaderID) {
      this.setState({ isGenericResponsabileField: false });
    }

    if (checked) {
      this.setState({ isGenericResponsabileField: true });
      this.setState({ showGenericResponsabileField: true });
      this.setState({ selectedResponsabile: selectedResponsible });
    } else {
      this.setState({ isGenericResponsabileField: false });
      this.setState({ showGenericResponsabileField: false });
      this.setState({ selectedResponsabile: selectedResponsible });
    }
  };

  handleModalEndDateClsose = () => {
    this.setState({ endDateConfirmationModal: false });
  };

  toggleEndDateModal = (values) => {
    this.setState({ formValues: values }, () => {
      this.setState({ endDateConfirmationModal: true });
    });
  };

  handleConfirmResponse = (values, response) => {
    const { currentDeliverable } = this.state;
    this.handleModalEndDateClsose();
    this.updateData(values, currentDeliverable.deliverableID, response);
  };

  handleDeclineResponse = (values, response) => {
    const { currentDeliverable, selectedDeliverableData } = this.state;
    if (selectedDeliverableData) {
      values.endDate = this.handleDateChange(selectedDeliverableData.endDate);
      this.handleModalEndDateClsose();
      this.updateData(values, currentDeliverable.deliverableID, response);
    } else {
      values.endDate = this.handleDateChange(currentDeliverable.parentDeliverableEndDate);
      this.handleModalEndDateClsose();
      this.updateData(values, currentDeliverable.deliverableID, response);
    }
  };

  render() {
    const {
      currentDeliverable,
      loadingButton,
      responsiblePickList,
      statusList,
      priorityPicklist,
      projectPickList,
      deliverablePickList,
      dataFinePrevista,
      selectedParentDeliverable,
      selectedResponsabile,
      showCommentEditor,
      isGenericResponsabileField,
      showGenericResponsabileField,
      showIsGenericResponsabileField,
      statusListFilter,
      formValues,
    } = this.state;
    const { userData, t } = this.props;

    const childDeliverableColumns = [
      {
        title: `${t('general.nome')}`,
        dataIndex: 'deliverableName',
        ellipsis: {
          showTitle: true,
        },
        width: '30%',
        sorter: (a, b) => {
          a = a.deliverableName || '';
          b = b.deliverableName || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('deliverableName'),
        render: (title) => <Tooltip title={title}>{title}</Tooltip>,
      },
      {
        title: `${t('general.responsabile')}`,
        dataIndex: 'ownerName',
        sorter: (a, b) => {
          a = a.ownerName || '';
          b = b.ownerName || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('ownerName'),
      },

      {
        title: `${t('general.stato')}`,
        dataIndex: 'statusDescription',
        sorter: (a, b) => {
          a = a.statusDescription || '';
          b = b.statusDescription || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        filters: statusListFilter,
        onFilter: (value, record) => (record.statusDescription ? record.statusDescription.includes(value) : ''),
      },
      {
        key: 'action',
        dataIndex: 'action',
        width: '50px',
        render: (text, record) => (
          <Link
            to={{
              pathname: `/deliverables/id/${record.deliverableID}`,
            }}
          >
            <EditOutlined />
          </Link>
        ),
      },
    ];

    return (
      <>
        {!currentDeliverable && <Loader2 />}
        {currentDeliverable && (
          <>
            {this.state.endDateConfirmationModal && (
              <EndDateConfirmationModal
                showModal={this.state.endDateConfirmationModal}
                handleModalClose={this.handleModalEndDateClsose}
                handleConfirmResponse={this.handleConfirmResponse}
                handleDeclineResponse={this.handleDeclineResponse}
                t={t}
                deliverableFormValues={formValues}
              />
            )}

            <Form
              name="deliverable_details_formData"
              layout="vertical"
              onFinish={(values) => this.checkDatesValidity(values, currentDeliverable.deliverableID)}
              onValuesChange={() => {
                this.setState({ fieldsChanged: true });
              }}
            >
              <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
                <div>
                  <div className="tw-flex tw-items-center tw-gap-2">
                    <Button
                      icon={<RollbackOutlined />}
                      onClick={this.backFunction}
                    >
                      {t('deliverablePage.goToPrs')}
                    </Button>

                    <Button
                      icon={<FolderOpenOutlined />}
                      href={`/progetti/id/${currentDeliverable.projectID}`}
                    >
                      {t('proggetiPage.passaProgetto')}
                    </Button>

                    {currentDeliverable.parentDeliverableID && (
                      <Button
                        icon={<SelectOutlined />}
                        href={`/deliverables/id/${currentDeliverable.parentDeliverableID}`}
                      >
                        {t('deliverablePage.goToMacroAttivita')}
                      </Button>
                    )}
                  </div>
                </div>
              </div>

              <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
                <div>
                  <Card
                    className="card-body-bacground"
                    title={t('general.detagli')}
                    extra={
                      <Form.Item
                        key="save"
                        className="tw-mb-0"
                      >
                        <Button
                          loading={loadingButton}
                          icon={<SaveOutlined />}
                          type="primary"
                          htmlType="submit"
                          className="tw-float-end"
                          disabled={!this.state.fieldsChanged}
                        >
                          {t('buttons.salva')}
                        </Button>
                      </Form.Item>
                    }
                  >
                    <Row className="mb-row">
                      <Col xs={{ span: 24 }}>
                        <Form.Item
                          labelAlign="left"
                          label={t('general.nome')}
                          name="name"
                          rules={[{ required: true, message: requiredFields.required }]}
                          initialValue={currentDeliverable.deliverableName}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col xs={{ span: 24 }}>
                        <Form.Item
                          labelAlign="left"
                          label={t('deliverablePage.macroAttivita')}
                          //name="parentDeliverableID"
                          hasFeedback
                        >
                          <Select
                            value={selectedParentDeliverable}
                            style={{ width: '100%' }}
                            placeholder={t('general.seleziona')}
                            allowClear
                            onChange={(option) => this.onParentDeliverableChange(option)}
                            onClear={(option) => this.onParentDeliverableClear(option)}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {deliverablePickList &&
                              deliverablePickList.map((item) => (
                                <Option
                                  value={item.deliverableID}
                                  key={item.deliverableID}
                                >
                                  {item.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col xs={{ span: 24 }}>
                        <Form.Item
                          labelAlign="left"
                          label={t('general.progetto')}
                          name="projectID"
                          rules={[{ required: true, message: requiredFields.required }]}
                          hasFeedback
                          initialValue={currentDeliverable.projectID}
                        >
                          <Select
                            style={{ width: '100%' }}
                            placeholder={t('general.seleziona')}
                            onSelect={(option) => this.onProjectSelect(option)}
                            showSearch
                            optionFilterProp="children"
                            disabled={currentDeliverable.projectID}
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {projectPickList &&
                              projectPickList.map((item) => (
                                <Option
                                  value={item.projectID}
                                  key={item.projectID}
                                >
                                  {item.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}
                      >
                        <Form.Item
                          labelAlign="left"
                          label={t('general.responsabile')}
                          name="ownerID"
                          initialValue={currentDeliverable.ownerName}
                          required={true}
                        >
                          <Select
                            value={selectedResponsabile}
                            placeholder={t('general.seleziona')}
                            style={{ width: '100%' }}
                            onChange={(option) => this.onReponsabileChange(option)}
                            onClear={() => this.onResponsabileClear()}
                            options={responsiblePickList}
                            showSearch
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}
                      >
                        {showIsGenericResponsabileField && (
                          <Form.Item label={t('deliverablePage.isGenericResponsible')}>
                            <Switch
                              checked={isGenericResponsabileField}
                              value={currentDeliverable.isGenericResponsabile}
                              onChange={(checked) => this.onGenericCheck(checked)}
                            />
                          </Form.Item>
                        )}
                      </Col>

                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}
                      >
                        {showGenericResponsabileField && (
                          <Form.Item
                            labelAlign="left"
                            label={t('deliverablePage.genericResponsabile')}
                            name="genericResponsible"
                            rules={[{ required: true, message: requiredFields.required }]}
                            initialValue={currentDeliverable.genericResponsible}
                          >
                            <Input />
                          </Form.Item>
                        )}
                      </Col>
                    </Row>

                    <Row className="mb-row">
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                      >
                        <Form.Item
                          labelAlign="left"
                          label={t('general.stato')}
                          name="status"
                          hasFeedback
                          rules={[{ required: true, message: requiredFields.required }]}
                          initialValue={currentDeliverable.status}
                        >
                          <Select
                            style={{ width: '100%' }}
                            placeholder={t('general.seleziona')}
                            onChange={(option) => this.onStatusChange(option)}
                          >
                            {statusList &&
                              statusList.map((item) => (
                                <Option
                                  value={item.statusID}
                                  key={item.statusID}
                                >
                                  {item.statusDescription}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                      >
                        <Form.Item
                          labelAlign="left"
                          label={t('general.priorita')}
                          name="priority"
                          hasFeedback
                          rules={[{ required: true, message: requiredFields.required }]}
                          initialValue={currentDeliverable.priority}
                        >
                          <Select
                            style={{ width: '100%' }}
                            placeholder={t('general.seleziona')}
                          >
                            {priorityPicklist &&
                              priorityPicklist.map((item) => (
                                <Option
                                  value={item.objectCodeListID}
                                  key={item.objectCodeListID}
                                >
                                  {item.description}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row
                      className="mb-row"
                      style={{ marginTop: '6px' }}
                    >
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                      >
                        <Form.Item
                          label={t('general.start')}
                          name="startDate"
                          rules={[{ required: true, message: requiredFields.required }]}
                          initialValue={
                            currentDeliverable.startDate
                              ? moment(currentDeliverable.startDate)
                              : currentDeliverable.startDate
                          }
                        >
                          <DatePicker
                            style={{ width: '100%' }}
                            format="DD/MM/YYYY"
                            disabledDate={(current) => this.disableStartDate(current)}
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                      >
                        <Form.Item
                          label={t('general.end')}
                          name="endDate"
                          rules={[{ required: true, message: requiredFields.required }]}
                          initialValue={
                            currentDeliverable.endDate ? moment(currentDeliverable.endDate) : currentDeliverable.endDate
                          }
                        >
                          <DatePicker
                            style={{ width: '100%' }}
                            format="DD/MM/YYYY"
                            disabledDate={(current) => this.disabledDate(current)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row className="mb-row">
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                      >
                        <Form.Item
                          label={t('general.dataChiusura')}
                          name="actualEndDate"
                          initialValue={
                            currentDeliverable.actualEndDate
                              ? moment(currentDeliverable.actualEndDate)
                              : currentDeliverable.actualEndDate
                          }
                        >
                          <DatePicker
                            style={{ width: '100%' }}
                            format="DD/MM/YYYY"
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                      >
                        <Form.Item label={t('deliverablePage.dataFinePrevista')}>
                          <DatePicker
                            style={{ width: '100%' }}
                            format="DD/MM/YYYY"
                            value={dataFinePrevista ? moment(dataFinePrevista) : null}
                            onChange={(date) => this.onDataPrevistaChange(date)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row className="mb-row">
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                      >
                        <Form.Item
                          label={t('general.budget')}
                          name="budget"
                          initialValue={currentDeliverable.budget}
                        >
                          <InputNumber
                            min={0}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                      >
                        <Form.Item
                          label={t('deliverablePage.etichetta')}
                          name="tag"
                          initialValue={currentDeliverable.tag}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row className="mb-row">
                      <Col
                        xs={{ span: 24 }}
                        style={{
                          border: '1px solid #e9e9e9',
                          margin: '14px 14px 14px 14px',
                          maxWidth: '97%',
                        }}
                      >
                        {showCommentEditor && (
                          <Form.Item
                            label={this.commentLabel()}
                            name="comment"
                            initialValue={BraftEditor.createEditorState(currentDeliverable.comment)}
                          >
                            <BraftEditor
                              contentFormat="html"
                              language="en"
                              id="editor-text"
                              controls={RichTextEditorControls}
                            />
                          </Form.Item>
                        )}

                        {!showCommentEditor && (
                          <Form.Item
                            label={this.commentLabel()}
                            name="comment"
                            initialValue={BraftEditor.createEditorState(currentDeliverable.comment)}
                          >
                            {Parser(currentDeliverable.comment ? currentDeliverable.comment : '')}
                          </Form.Item>
                        )}
                      </Col>

                      <Col xs={{ span: 24 }}>
                        <Form.Item
                          labelAlign="left"
                          label={t('general.nota')}
                          name="notes"
                          initialValue={currentDeliverable.notes}
                        >
                          <TextArea
                            rows={5}
                            allowClear
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </div>

                <div className="tw-flex tw-flex-col tw-gap-4">
                  <Card
                    className="!tw-shadow-none"
                    styles={{ header: { padding: 0 }, body: { padding: 0 } }}
                    bordered={false}
                    title={t('deliverablePage.deliverableFigli')}
                    extra={
                      currentDeliverable.parentDeliverableID === null && (
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          data-testid="newChildDeliverable"
                          href={`/deliverables/new/pid/${currentDeliverable.deliverableID}/${currentDeliverable.projectID}`}
                        >
                          {t('buttons.aggiungiNuovo')}
                        </Button>
                      )
                    }
                  >
                    <Table
                      columns={childDeliverableColumns}
                      dataSource={currentDeliverable.childs}
                      size="small"
                      rowKey={(obj) => obj.deliverableID}
                      // scroll={{ x: 'calc(450px + 50%)' }}
                      pagination={false}
                    />
                  </Card>
                  <Feed
                    objectId={currentDeliverable.deliverableID}
                    userData={userData}
                    objectType={feedObjectType.deliverableFeed}
                    isProject={0}
                    isDeliverable={true}
                    feedRegardingObjType={feedRegardingObjectType.deliverable}
                  />
                </div>
              </div>
            </Form>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(ViewEditDeliverable));
