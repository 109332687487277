import { BorderOuterOutlined, DesktopOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import { Button, Menu, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { history } from '../../../App';
import CommonService from '../../../services/commonService';
import ProgettiService from '../../../services/pages/projectServices/progettiService';
import UsersService from '../../../services/pages/usersService';
import { addQueryParam, getQueryParam, hasQueryParam } from '../../../utils/url-utils';
import {
  hasUserProjectCostAccess,
  userHasAccessProjectFields,
} from '../../shared/utils/authRolesProvilege/authRolesPrivilege';
import {
  feedObjectType,
  feedRegardingObjectType,
  objectTypeUserSecurity,
  projectStatusAsNumber,
} from '../../shared/utils/constants';
import { NoAccessMesssage } from '../../shared/utils/functions';
import '../project.scss';
import ProjectDataHeader from '../projectDataHeader';
import ProjectGoalEditor from '../projectGoalEditor/goalEditor';
import ReasonToAct from '../projectReasonToAct/reasonToAct';
import ChildProjects from './childProjects';
import ProjectKpi from './deliverablesAndKpi/projectKpi';
import ProjectDeliverable from './deliverablesAndKpi/projectReviewSystem';
import SettingsAndTeam from './mainInfo/settingsAndTeam';
import ProjectActivities from './projectActivities/projectActivities';
import ProjectCosts from './projectCosts/projectCost';
import ProjectFeed from './projectFeed/projectFeed';
import ProjectFiles from './projectFiles/projectFiles';
import ProjectObjectivesIndex from './projectObjectives/index';
import ProjectResourceWorkload from './projectResourceWorload/projectResourceWorkload';
import RiskAndPrecautions from './riskAndPrecaution/riskAndPrecaution';

import AttivitaSvolte from '../../a3/section3/attivitaSvolte';

import KpiDashboard from '../../kpiDashboard/kpiDashboard';
import ProjectMeetings from './projectMeetings/projectMeetings';

import ButtonGroup from 'antd/es/button/button-group';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import {
  RiCheckboxLine,
  RiCheckboxMultipleBlankLine,
  RiFile3Line,
  RiFileLine,
  RiFileList3Line,
  RiFileTextLine,
  RiFileWarningLine,
  RiFocus2Line,
  RiFocusLine,
  RiLinksLine,
  RiListOrdered,
  RiMenu2Fill,
  RiMenuLine,
  RiMessageLine,
  RiMoneyEuroBoxLine,
  RiNotification2Line,
  RiOpenSourceLine,
  RiQuestionLine,
  RiSettingsLine,
  RiSpeedLine,
  RiStackLine,
  RiTeamLine,
  RiTodoLine,
  RiZoomInLine,
  RiZoomOutLine,
} from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import ProssimiPassi from 'src/components/a3/section4/prossimiPassi';
import ProjectHanseiPage from 'src/components/projects/projectForm/ProjectHanseiPage';
import ProjectTargetIndicators from 'src/components/projects/projectForm/ProjectTargetIndicatorsPage';
import { useFullscreen } from 'src/hooks/FullScreen';
import { usePresentationZoom } from 'src/hooks/PresentationZoom';
import { getProjectById } from 'src/redux/slices/projectSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import ProjectMasterPlan from './ProjectMasterPlanPage';
import ProjectObjective from './ProjectObjectivePage';
import ProjectTeamAndRoutines from './projectTeamAndRoutines/projectTeamAndRoutines';

const ProjectIndex = (props) => {
  const userData = useAppSelector((state) => state.userData.userData);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const currentProject = useAppSelector((state) => state.project.currentProject);
  const companyData = useAppSelector((state) => state.companyData.companyData);
  const { enterFullscreen, exitFullscreen, isFullscreen } = useFullscreen();
  const { zoomIn, zoomOut } = usePresentationZoom();

  const { id, cmpid } = useParams();

  const [activeTab, setActiveTab] = useState(1);
  const [checkUserAccessOnObject, setCheckUserAccessOnObject] = useState(null);
  const [projectCostUserAccessSP, setProjectCostUserAccessSP] = useState(null);
  const [projectCostUserAccess, setProjectCostUserAccess] = useState(null);
  const [projectTeamMembers, setProjectTeamMembers] = useState([]);
  const [projectTeamMembersList, setProjectTeamMembersList] = useState([]);
  const [isPresentationMode, setIsPresentationMode] = useState(false);
  const [zoomStep, setZoomStep] = useState(0);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [hasUserAccess, setHasUserAccess] = useState(null);

  useEffect(() => {
    if (!id) {
      history.push('/progetti');
    }

    getUserProjectCostAccess(id);
    getProjectData(id);
    getTeamMembers(id);
    checkIfUserHasAccess(id);

    if (hasQueryParam('presentationMode') && getQueryParam('presentationMode') === 'true') {
      setIsPresentationMode(true);
      setActiveTab('15');
      addQueryParam({ activeTab: '15' });
    }

    if (hasQueryParam('tab')) {
      setActiveTab(getQueryParam('tab') || '1');
    } else {
      setActiveTab('1');
    }

    if (cmpid && cmpid.length > 2) {
      updateUserCompnay(id, cmpid);
    }
    if (cmpid && cmpid.length <= 2) {
      setActiveTab(cmpid);
    }
  }, [id, cmpid]);

  useEffect(() => {
    if (currentProject && userData) {
      setHasUserAccess(
        userHasAccessProjectFields(userData, currentProject.teamLeaderID, currentProject.sponsorID) ||
          currentProject.status !== projectStatusAsNumber.completed
          ? false
          : true,
      );

      const access = hasUserProjectCostAccess(
        userData,
        currentProject.teamLeaderID,
        currentProject.sponsorID,
        projectCostUserAccessSP,
        projectTeamMembers,
      );

      setProjectCostUserAccess(access);
    }
  }, [currentProject, userData]);

  const getTeamMembers = async (id) => {
    if (projectTeamMembersList.length > 0) {
      return;
    }

    try {
      const response = await ProgettiService.getProjectTeamMembers(id);
      const resp = response.data;
      if (resp.success) {
        const respData = resp.responseObject.value;
        const usr = [];
        setProjectTeamMembers(respData.map((a) => a.userID));

        respData.map((item) => {
          usr.push({ value: item.userID, label: item.fullName, key: item.userID, disabled: item.isDeleted });
        });
        setProjectTeamMembersList(usr);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserProjectCostAccess = async (projectId) => {
    try {
      const response = await ProgettiService.getProjectCostUserAccess(projectId);
      const resp = response.data;
      if (resp.success) {
        setProjectCostUserAccessSP(resp.responseObject.value.hasAccess === 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProjectData = async (id) => {
    dispatch(getProjectById({ projectId: id }));
  };

  const updateUserCompnay = async (id, companyId) => {
    const payload = {};
    payload['userID'] = userData.userId;
    payload['companyID'] = companyId;

    if (userData !== undefined && userData.hasOwnProperty('userId')) {
      const lastLogInCompany = userData.userCompanies.filter((item) => {
        return item.isLastLoggedInCompany === 1;
      });

      if (lastLogInCompany[0].lastLoggedInCompany !== companyId.toLowerCase()) {
        try {
          const response = await UsersService.updateUserLogedInCompany(payload);
          const resp = response.data;
          if (resp.success) {
            window.location.replace(`/progetti/id/${id}/${companyId}`);
            dispatch(getProjectById({ projectId: id }));
          } else {
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const checkIfUserHasAccess = async (objectId) => {
    const obj = {
      type: objectTypeUserSecurity.project,
      objectID: objectId,
    };

    try {
      const response = await CommonService.chaeckUserAccessOnObject(obj);
      const { success, responseObject } = response.data;

      if (success) {
        const { value } = responseObject;
        setCheckUserAccessOnObject(value.hasPrivilege === 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMenuClick = (e) => {
    const { key } = e;

    addQueryParam('tab', key);
    setActiveTab(key);
  };

  const handlePresentationMode = () => {
    addQueryParam('presentationMode', !isPresentationMode);
    setIsPresentationMode(!isPresentationMode);
    setActiveTab('15');
  };

  const handleFullScreenMode = () => {
    if (isFullscreen) {
      exitFullscreen();
    } else {
      enterFullscreen();
    }
  };

  const renderComponent = () => {
    switch (activeTab) {
      case '1':
        return (
          <ProjectObjective
            id={currentProject.projectID}
            hasUserAccess={hasUserAccess}
          />
        );

      case '40':
        return (
          <ProjectMasterPlan
            showGantt
            id={currentProject.projectID}
            currentProject={currentProject}
            hasUserAccess={hasUserAccess}
          />
        );

      case '41':
        return (
          <ProjectTargetIndicators
            project={currentProject}
            isPresentationMode={isPresentationMode}
            hasUserAccess={hasUserAccess}
          />
        );

      case '42':
        return (
          <ProjectHanseiPage
            isEditable={!hasUserAccess}
            isPresentationMode={isPresentationMode}
          />
        );

      case '2':
        return (
          <ProjectDeliverable
            hasUserAccess={hasUserAccess}
            currentProject={currentProject}
            isPresentationMode={isPresentationMode}
          />
        );

      case '3':
        return (
          <RiskAndPrecautions
            projectId={currentProject.projectID}
            hasUserAccess={hasUserAccess}
          />
        );

      case '4':
        return (
          <ProjectActivities
            userData={userData}
            t={t}
            projectId={currentProject.projectID}
            projectName={currentProject.name}
            currentProject={currentProject}
            hasUserAccess={hasUserAccess}
          />
        );

      case '5':
        return (
          <ProjectResourceWorkload
            userData={userData}
            t={t}
            hasUserAccess={hasUserAccess}
            projectId={currentProject.projectID}
            projectName={currentProject.name}
            reloadProject={() => dispatch(getProjectById({ projectId: currentProject.projectID }))}
          />
        );

      case '6':
        return (
          <ChildProjects
            t={t}
            projectId={currentProject.projectID}
            projectName={currentProject.name}
          />
        );

      case '7':
        return (
          <ProjectCosts
            t={t}
            projectCostUserAccessProp={projectCostUserAccess}
            hasUserAccess={hasUserAccess}
            projectId={currentProject.projectID}
            projectName={currentProject.name}
            currentProject={currentProject}
            reloadProject={() => dispatch(getProjectById({ projectId: currentProject.projectID }))}
          />
        );

      case '8':
        return (
          <ProjectKpi
            isPresentationMode={isPresentationMode}
            projectTeamMembers={projectTeamMembersList}
            t={t}
            hasUserAccess={hasUserAccess}
            currentProject={currentProject}
            projectId={currentProject.projectID}
            projectName={currentProject.name}
            history={history}
          />
        );

      case '11':
        return (
          <ProjectFeed
            objectId={currentProject.projectID}
            userData={userData}
            isProject={1}
            hasUserAccess={hasUserAccess}
            objectType={feedObjectType.projectFeed}
            feedRegardingObjType={feedRegardingObjectType.project}
            showFeed={true}
          />
        );

      case '12':
        return (
          <ProjectFiles
            userData={userData}
            defaultCheckedFeedType={3}
            feedRegardingObjType={feedRegardingObjectType.project}
            objectId={currentProject.projectID}
            hasUserAccess={hasUserAccess}
            t={t}
            projectId={currentProject.projectID}
            currentProject={currentProject}
          />
        );

      case '13':
        return (
          <SettingsAndTeam
            t={t}
            projectId={currentProject.projectID}
            currentProject={currentProject}
            reloadProject={() => dispatch(getProjectById({ projectId: currentProject.projectID }))}
            hasUserAccess={hasUserAccess}
          />
        );

      case '14':
        return (
          <ProjectObjectivesIndex
            currentProject={currentProject}
            hasUserAccess={hasUserAccess}
          />
        );

      case '15':
        return (
          <ReasonToAct
            key={'groundToAct'}
            currentProject={currentProject}
            isPresentationMode={isPresentationMode}
          />
        );

      case '16':
        return (
          <ProjectGoalEditor
            isEditable={!hasUserAccess}
            isPresentationMode={isPresentationMode}
            key={'projectGoal'}
            currentProject={currentProject}
          />
        );

      case '17':
        return (
          <ProjectTeamAndRoutines
            isPresentationMode={isPresentationMode}
            projectId={currentProject.projectID}
            currentProject={currentProject}
            reloadProject={() => dispatch(getProjectById({ projectId: currentProject.projectID }))}
            hasUserAccess={hasUserAccess}
          />
        );

      case '19':
        return (
          <ProjectDeliverable
            isPresentationMode={true}
            userData={userData}
            t={t}
            hasUserAccess={hasUserAccess}
            currentProject={currentProject}
            projectId={currentProject.projectID}
            projectName={currentProject.name}
            history={history}
          />
        );

      case '20':
        return (
          <AttivitaSvolte
            t={t}
            projectId={currentProject.projectID}
            isPresentationMode={isPresentationMode}
            companyData={companyData}
          />
        );

      case '21':
        return (
          <KpiDashboard
            isPresentationMode={isPresentationMode}
            presentationProjectId={currentProject.projectID}
          />
        );

      case '22':
        return (
          <ProssimiPassi
            currentProject={currentProject}
            projectId={currentProject.projectID}
            isPresentationMode={isPresentationMode}
            companyData={companyData}
          />
        );

      case '23':
        return (
          <RiskAndPrecautions
            className="sub700-pres-card-body"
            t={t}
            projectId={currentProject.projectID}
            isPresentationMode={true}
          />
        );

      case '25':
        return (
          <ProjectFiles
            isPresentationMode={true}
            t={t}
            feedRegardingObjType={feedRegardingObjectType.file}
            projectId={currentProject.projectID}
            currentProject={currentProject}
          />
        );

      case '26':
        return (
          <ProjectFeed
            isPresentationMode={true}
            objectId={currentProject.projectID}
            userData={userData}
            isProject={1}
            hasUserAccess={hasUserAccess}
            objectType={feedObjectType.projectFeed}
            feedRegardingObjType={feedRegardingObjectType.feed}
            showFeed={false}
          />
        );

      case '27':
        return (
          <ProjectMeetings
            objectId={currentProject.projectID}
            userData={userData}
            isProject={1}
            hasUserAccess={hasUserAccess}
            objectType={feedObjectType.projectFeed}
            feedRegardingObjType={feedRegardingObjectType.meeting}
            showFeed={true}
          />
        );

      default:
        return null;
    }
  };

  const menuItemsPresentationMode = [
    {
      key: '15',
      icon: <RiQuestionLine />,
      label: t('proggetiPage.motivoAgire'),
    },
    {
      key: '16',
      icon: <RiFocusLine />,
      label: t('proggetiPage.expectedResult'),
    },
    {
      key: '17',
      icon: <RiTeamLine />,
      label: t('proggetiPage.team'),
    },
    {
      key: '21',
      icon: <RiSpeedLine />,
      label: t('proggetiPage.kpiStatus'),
    },
    {
      key: '40',
      icon: <RiFileList3Line />,
      label: t('proggetiPage.masterplan'),
    },
    {
      key: '19',
      icon: <RiCheckboxMultipleBlankLine />,
      label: t('proggetiPage.projectReviewSystem'),
    },
    {
      key: '20',
      icon: <RiCheckboxLine />,
      label: t('proggetiPage.attivitaSvolte'),
    },
    {
      key: '22',
      icon: <RiListOrdered />,
      label: t('proggetiPage.prossimiPassi'),
    },
    {
      key: '23',
      icon: <RiFileWarningLine />,
      label: t('proggetiPage.criticitaRischi'),
    },
    {
      key: '42',
      icon: <RiFileList3Line />,
      label: t('proggetiPage.hensei'),
    },
    {
      key: '25',
      icon: <RiFileLine />,
      label: t('proggetiPage.file'),
    },
    {
      key: '26',
      icon: <RiMessageLine />,
      label: t('proggetiPage.minutes'),
    },
  ];

  const menuItemsDefaultMode = [
    {
      key: '1',
      icon: <RiSpeedLine />,
      label: t('proggetiPage.obiettivoProgetto'),
    },
    {
      key: '17',
      icon: <RiTeamLine />,
      label: t('proggetiPage.team'),
    },
    {
      key: '14',
      icon: <RiLinksLine />,
      label: t('proggetiPage.linkToStrategy'),
    },
    {
      key: '41',
      icon: <RiFocus2Line />,
      label: t('general.kpiIndicators'),
    },
    {
      key: '40',
      icon: <RiFile3Line />,
      label: t('proggetiPage.masterplan'),
    },
    {
      key: '2',
      icon: <RiCheckboxMultipleBlankLine />,
      label: t('proggetiPage.projectReviewSystem'),
    },
    {
      key: '3',
      icon: <RiFileWarningLine />,
      label: t('proggetiPage.criticitaRischi'),
    },
    {
      key: '4',
      icon: <RiTodoLine />,
      label: t('proggetiPage.toDoProject'),
    },
    {
      key: '11',
      icon: <RiFileTextLine />,
      label: t('feed.feed'),
    },
    {
      key: '42',
      icon: <RiFileList3Line />,
      label: t('proggetiPage.hensei'),
    },
    {
      key: '7',
      icon: <RiMoneyEuroBoxLine />,
      label: t('proggetiPage.costiProgetto'),
    },
    {
      key: '5',
      icon: <RiOpenSourceLine />,
      label: t('proggetiPage.caricoRisorse'),
    },
    {
      key: '6',
      icon: <RiStackLine />,
      label: t('proggetiPage.progetttoFigli'),
    },
    {
      key: '27',
      icon: <RiNotification2Line />,
      label: t('proggetiPage.notificeRiunioni'),
    },
    {
      key: '12',
      icon: <RiFileLine />,
      label: t('proggetiPage.file'),
    },
    {
      key: '13',
      icon: <RiSettingsLine />,
      label: t('general.settings'),
    },
  ];

  return (
    <div>
      {currentProject && (
        <>
          {!checkUserAccessOnObject && <NoAccessMesssage />}

          {checkUserAccessOnObject && (
            <div className="tw-flex tw-flex-col tw-overflow-hidden tw-absolute tw-inset-0 tw-size-full">
              <ProjectDataHeader
                id={currentProject.projectID}
                presentationModeTemplate={
                  <>
                    <div>
                      {!isPresentationMode && (
                        <Button
                          onClick={handlePresentationMode}
                          icon={<DesktopOutlined className="editIcon printIcon" />}
                          type="primary"
                        >
                          {t('a3Page.presenta')}
                        </Button>
                      )}

                      {isPresentationMode && (
                        <div className="tw-flex tw-items-center tw-gap-2">
                          <ButtonGroup>
                            <Button
                              icon={<RiZoomInLine className="tw-text-2xl" />}
                              onClick={zoomIn}
                            />
                            <Button
                              icon={<RiZoomOutLine className="tw-text-2xl" />}
                              onClick={zoomOut}
                            />
                          </ButtonGroup>

                          <Tooltip
                            placement="bottom"
                            title={isFullscreen ? t('a3Page.closeFullscreen') : t('a3Page.fullscreenPage')}
                          >
                            <Button
                              type="primary"
                              onClick={handleFullScreenMode}
                              icon={<BorderOuterOutlined />}
                            ></Button>
                          </Tooltip>
                          <Tooltip
                            placement="bottom"
                            title={t('a3Page.chiuderePresentazioneA3')}
                          >
                            <Button
                              type="primary"
                              icon={<FullscreenExitOutlined />}
                              onClick={() => {
                                addQueryParam('presentationMode', false);

                                setIsPresentationMode(false);
                                setActiveTab('1');
                              }}
                            >
                              Esci
                            </Button>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  </>
                }
                isPresentationMode={isPresentationMode}
                currentProjectData={currentProject}
                location={'project'}
              />

              <div className="tw-flex tw-size-full tw-overflow-y-hidden">
                <div className="tw-flex tw-flex-col tw-h-full">
                  <Button
                    shape="circle"
                    onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
                    className={'tw-ml-6 tw-mt-4 tw-mb-2'}
                    icon={isSidebarCollapsed ? <RiMenu2Fill /> : <RiMenuLine />}
                  ></Button>
                  <Menu
                    onClick={handleMenuClick}
                    className="[&_svg]:!tw-scale-125 tw-max-w-[250px] !tw-overflow-auto"
                    inlineCollapsed={isSidebarCollapsed}
                    items={isPresentationMode ? menuItemsPresentationMode : menuItemsDefaultMode}
                    defaultSelectedKeys={[activeTab]}
                    selectedKeys={[activeTab]}
                  />
                </div>
                <div className={classNames('tw-size-full zoom-container tw-p-8 tw-overflow-auto tw-h-full', {})}>
                  {renderComponent()}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProjectIndex;
