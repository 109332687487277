import { Form } from 'antd';
import { Component } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Input, Modal, Row, Select, Space, Switch, Table } from 'antd';
import BraftEditor from 'braft-editor';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import CommonService from '../../../services/commonService';
import UsersService from '../../../services/pages/usersService';
import TimeRangePicker from '../TimeRangePicker';
import ModalFooterButtons from '../components/modal/modalFooterButtons';
import {
  RichTextEditorControls,
  feedItemsTypes,
  httpLinkRegex,
  httpsLinkRegex,
  objectCodes,
  userParticipation,
} from '../utils/constants';
import { disableDatesBeforeToday, handleDateConvert, handleTimeConvert } from '../utils/functions';
import { requiredFields } from '../utils/notifyMessages';

const { Option } = Select;

const NewFeedCommentForm = () => {
  const { t } = useTranslation();

  return (
    <Row gutter={{ lg: 24 }}>
      <Col xs={{ span: 24 }}>
        <Form.Item
          label={t('general.soggetto')}
          name="subject"
          rules={[{ required: true, message: requiredFields.required }]}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 24 }}
      >
        <Form.Item
          label={t('general.haPriorita')}
          name="priority"
          valuePropName="checked"
          initialValue={false}
        >
          <Switch />
        </Form.Item>
      </Col>

      <Col xs={{ span: 24 }}>
        <Form.Item
          label={t('general.descrizione')}
          name="description"
        >
          <div className="tw-overflow-hidden">
            <BraftEditor
              contentFormat="html"
              id="editor-text"
              controls={RichTextEditorControls}
            />
          </div>
        </Form.Item>
      </Col>
    </Row>
  );
};

const NewFeedFileForm = ({ t }) => {
  return (
    <Row gutter={{ lg: 24 }}>
      <Col xs={{ span: 24 }}>
        <Form.Item
          label={t('general.soggetto')}
          name="subject"
          rules={[{ required: true, message: requiredFields.required }]}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 24 }}
      >
        <Form.Item
          label={t('general.haPriorita')}
          name="priority"
          labelAlign="left"
          valuePropName="checked"
          initialValue={false}
        >
          <Switch />
        </Form.Item>
      </Col>

      <Col xs={{ span: 24 }}>
        <Form.Item
          label={t('feed.urlDocumento')}
          name="documentURL"
          extra="Link format: http://example.com/"
          rules={[
            { required: true, message: requiredFields.required },
            {
              validator: async (_, value) => {
                if (!httpLinkRegex.test(value)) {
                  return Promise.reject(new Error('Link not valid'));
                }
                if (!httpsLinkRegex.test(value)) {
                  return Promise.reject(new Error('Link not valid'));
                }
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 24 }}
      >
        <Form.Item
          label={t('general.descrizione')}
          name="description"
        >
          <div className="tw-overflow-hidden">
            <BraftEditor
              contentFormat="html"
              id="editor-text"
              controls={RichTextEditorControls}
            />
          </div>
        </Form.Item>
      </Col>
    </Row>
  );
};

const NewFeedMeetingForm = ({
  columns,
  usersList,
  rowSelection,
  chEndDate,
  chEndTime,
  chStartTime,
  onStartDateChange,
  duration,
  onDurationChange,
  onStartTimeChange,
  t,
}) => {
  return (
    <Row gutter={{ lg: 24 }}>
      <Col xs={{ span: 24 }}>
        <Form.Item
          label={t('general.soggetto')}
          name="subject"
          rules={[{ required: true, message: requiredFields.required }]}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col xs={{ span: 24 }}>
        <Row>
          <Col xs={{ span: 8 }}>
            <Form.Item
              label={t('proggetiPage.createReunioneOutlook')}
              name="integrateInOutlook"
              valuePropName="checked"
              initialValue={false}
            >
              <Switch />
            </Form.Item>
          </Col>

          <Col xs={{ span: 8 }}>
            <Form.Item
              label={t('proggetiPage.createReunioneTeams')}
              name="integrateInTeams"
              valuePropName="checked"
              initialValue={false}
            >
              <Switch />
            </Form.Item>
          </Col>

          <Col xs={{ span: 8 }}>
            <Form.Item
              label={t('general.haPriorita')}
              name="priority"
              className="tw-w-full"
              valuePropName="checked"
              initialValue={false}
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </Col>

      <Col xs={{ span: 24 }}>
        <Row className="tw-flex tw-justify-between">
          <Col xs={{ span: 11 }}>
            <Form.Item
              label={t('general.start')}
              name="scheduledStart"
              rules={[{ required: true, message: requiredFields.required }]}
            >
              <DatePicker
                allowClear={false}
                onChange={(date) => onStartDateChange(date)}
                style={{ width: '100%' }}
                format="DD/MM/YYYY"
                disabledDate={(current) => disableDatesBeforeToday(current)}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 11 }}>
            <Form.Item
              label={t('general.end')}
              //name="scheduledEnd"
              rules={[{ required: true, message: requiredFields.required }]}
            >
              <DatePicker
                allowClear={false}
                value={chEndDate}
                style={{ width: '100%' }}
                format="DD/MM/YYYY"
                disabled={true}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>

      <Col xs={{ span: 24 }}>
        <TimeRangePicker
          startTime={{
            name: 'startTime',
            defaultValue: chStartTime,
          }}
          endTime={{
            name: 'endTime',
            defaultValue: chEndTime,
          }}
        />
      </Col>

      {/*

      <Col
        xs={{ span: 12 }}
        sm={{ span: 4 }}
        md={{ span: 4 }}
        lg={{ span: 4 }}>
        <Form.Item
          label={t('proggetiPage.oraInizio')}
          name="startTimePlaceholder"
          rules={[{ required: true, message: requiredFields.required }]}>
          <TimePicker
            allowClear={false}
            required
            format="HH:mm"
            value={chStartTime}
            onChange={(value) => onStartTimeChange(value)}
          />
        </Form.Item>
      </Col>

      <Col
        xs={{ span: 1 }}
        sm={{ span: 1 }}
        md={{ span: 1 }}
        lg={{ span: 1 }}>
        <Divider
          type="vertical"
          style={{ height: '100%', margin: '0 2px' }}
        />
      </Col>

      <Col
        xs={{ span: 12 }}
        sm={{ span: 4 }}
        md={{ span: 4 }}
        lg={{ span: 4 }}>
        <Form.Item
          label={t('proggetiPage.durata') + ' (h)'}
          //name="duration"
          rules={[{ required: true, message: requiredFields.required }]}>
          <InputNumber
            step=".5"
            style={{ width: '100%' }}
            value={duration}
            onChange={(value) => onDurationChange(value, chStartTime)}
          />
        </Form.Item>
      </Col>

      <Col
        xs={{ span: 1 }}
        sm={{ span: 1 }}
        md={{ span: 1 }}
        lg={{ span: 1 }}>
        <Divider
          type="vertical"
          style={{ height: '100%', margin: '0 2px' }}
        />
      </Col>

      <Col
        xs={{ span: 12 }}
        sm={{ span: 4 }}
        md={{ span: 4 }}
        lg={{ span: 4 }}>
        <Form.Item
          label={t('proggetiPage.oraFine')}
          // name="endTime"
          rules={[{ required: true, message: requiredFields.required }]}>
          <TimePicker
            allowClear={false}
            disabled={true}
            format="HH:mm"
            value={chEndTime}
          />
        </Form.Item>
      </Col> */}

      <Col xs={{ span: 24 }}>
        <div className="ant-col ant-col-24 ant-form-item-label">
          <label className="ant-form-item-required">{t('general.partecipanti')} </label>
        </div>
        <Table
          rowSelection={rowSelection}
          className="table-height"
          columns={columns}
          size="small"
          dataSource={usersList}
          bordered={true}
          rowKey={(obj) => obj.userID}
          style={{ width: '99%' }}
        />
      </Col>

      <Col xs={{ span: 24 }}>
        <Form.Item
          label={t('general.descrizione')}
          name="description"
        >
          <BraftEditor
            contentFormat="html"
            id="editor-text"
            controls={RichTextEditorControls}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

class AddFeedItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      statusList: null,
      loadingButton: false,
      feedTypes: null,
      selectedType: null,
      usersList: null,
      selectedPaticipants: [],
      endDate: null,
      duration: null,
      endTime: null,
      startTime: null,
    };
  }

  componentDidMount() {
    const feedType = this.props.defaultCheckedFeedType;

    if (feedType) {
      this.onTypeSelect(feedType);
    }

    this.retrieveFeedFilterPicklist();
    this.retrieveUsersList();
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    this.setState({ searchText: '' });
  };

  checkIfSelected = (id) => {
    return this.state.selectedPaticipants.every((e) => e.userID !== id);
  };

  onParticipationChange = (checked, userId) => {
    const { selectedPaticipants } = this.state;
    if (checked) {
      const obj = selectedPaticipants.find((f) => f.userID === userId);
      if (obj) {
        obj.participationTypeMask = userParticipation.required;
      }
    } else {
      const obj = selectedPaticipants.find((f) => f.userID === userId);
      if (obj) {
        obj.participationTypeMask = userParticipation.optional;
      }
    }
  };

  onSelectChange = (selectedPaticipants) => {
    this.setState({ selectedPaticipants });
  };

  removeByAttr = (arr, attr, value) => {
    let i = arr.length;
    while (i--) {
      if (arr[i] && arr[i].hasOwnProperty(attr) && arr[i][attr] === value) {
        arr.splice(i, 1);
      }
    }
    return arr;
  };

  onRowSelect = (record, selected) => {
    const { selectedPaticipants } = this.state;
    const { userData } = this.props;
    const selectedPaticipantsList = selectedPaticipants;

    if (selected) {
      const userObj = {};
      userObj['userID'] = record.userID;
      userObj['email'] = record.emailAddress;
      if (record.userID !== userData.userId) {
        userObj['participationTypeMask'] = userParticipation.optional;
      } else {
        userObj['participationTypeMask'] = userParticipation.organiser;
      }

      // selectedPaticipants.push(userObj)
      selectedPaticipantsList.push(userObj);
      this.setState({ selectedPaticipants: selectedPaticipantsList });
    } else {
      this.removeByAttr(selectedPaticipantsList, 'userID', record.userID);
      this.checkIfSelected(record.userID);
      this.setState({ selectedPaticipants: selectedPaticipantsList });
    }
  };

  // Retrieve list of owner
  async retrieveUsersList() {
    const { userData } = this.props;

    try {
      const response = await UsersService.getUserListOnFeed();
      const resp = response.data;

      if (resp.success) {
        // let participants = resp.responseObject.value.filter(function (person) {
        //     return person.userID !== userData.userId
        // })
        // this.setState({ usersList: participants })

        const currentUserObj = {};
        currentUserObj['userID'] = userData.userId;
        currentUserObj['email'] = userData.emailAddress;
        currentUserObj['participationTypeMask'] = userParticipation.organiser;

        this.state.selectedPaticipants.push(currentUserObj);

        this.setState({ usersList: resp.responseObject.value });
      } else {
      }
    } catch (error) {}
  }

  async retrieveFeedFilterPicklist() {
    const { isCommitie } = this.props;
    const objectCode = objectCodes.feedFilter;

    try {
      const response = await CommonService.getPicklist(objectCode);
      const resp = response.data;

      if (resp.success) {
        let feedTypeArray = resp.responseObject.value;
        if (isCommitie === 1) {
          feedTypeArray = feedTypeArray.filter((item) => item.objectCodeListID !== 1 && item.objectCodeListID !== 4);
        } else {
          feedTypeArray = feedTypeArray.filter((item) => item.objectCodeListID !== 1);
        }
        this.setState({ feedTypes: feedTypeArray });
      }
    } catch (error) {}
  }

  onTypeSelect = (value, option) => {
    this.setState({ selectedType: value });
  };

  addData = (values, objectId, selectedPaticipants) => {
    const { userData, regardingObjectType } = this.props;
    const { selectedType, endDate, startTime, endTime, duration } = this.state;

    values['regardingObjectID'] = objectId;
    values['regardingObjectTypeCode'] = regardingObjectType;
    values['createdBy'] = userData.userId;
    values['users'] = selectedPaticipants;

    values['scheduledEnd'] = endDate;
    values['startTime'] = startTime;
    values['endTime'] = endTime;
    values['duration'] = duration;

    values.startTime = handleTimeConvert(values.startTime);
    values.endTime = handleTimeConvert(values.endTime);

    values.scheduledStart = handleDateConvert(values.scheduledStart);
    values.scheduledEnd = handleDateConvert(values.scheduledEnd);

    const editorContentToHtml = BraftEditor.createEditorState(values['description']).toHTML();
    values['description'] = editorContentToHtml;

    if (selectedType === feedItemsTypes.comment) {
      this.props.addNewCommentFeed(values);
    }

    if (selectedType === feedItemsTypes.file) {
      this.props.addNewFileFeed(values);
    }

    if (selectedType === feedItemsTypes.meeting) {
      this.props.addNewMeetingFeed(values);
    }
  };

  onStartDateChange = (startDate) => {
    this.setState({ endDate: startDate });
  };

  onDurationChange = (value, startTime) => {
    if (value && startTime) {
      const endTime = moment(startTime, 'HH:mm').add(value, 'hours');
      this.setState({
        endTime: endTime,
        startTime: startTime,
        duration: value,
      });
    } else if (value && !startTime) {
      this.setState({
        duration: value,
      });
    } else {
      return;
    }
  };

  onStartTimeChange = (value) => {
    const { duration } = this.state;

    console.log(value);

    if (value && duration) {
      const endTime = moment(value, 'HH:mm').add(duration, 'hours');
      this.setState({
        endTime: endTime,
        startTime: value,
        duration: duration,
      });
    } else if (value && !duration) {
      this.setState({
        endTime: value,
        startTime: value,
      });
    } else {
      return;
    }
  };

  render() {
    const { showNewFeedModal, handleNewFeedModalClose, loadingButton, objectId, userData, t } = this.props;
    const { feedTypes, selectedType, usersList, selectedPaticipants, endDate, duration, endTime, startTime } =
      this.state;

    const partiList = selectedPaticipants.length > 0 ? selectedPaticipants.map((a) => a.userID) : [];

    const rowSelection = {
      selectedRowKeys: partiList,
      hideSelectAll: true,
      //onChange: this.onSelectChange,
      onSelect: this.onRowSelect,
      getCheckboxProps: (record) => ({
        disabled: record.isDeleted,
      }),
    };

    const columns = [
      {
        title: `${t('general.nome')}`,
        dataIndex: 'fullName',
        ellipsis: {
          showTitle: true,
        },
        sorter: (a, b) => {
          a = a.fullName ?? '';
          b = b.fullName ?? '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('fullName'),
      },
      {
        title: `${t('general.partecipazione')}`,
        dataIndex: 'participation',
        render: (text, record, index) => {
          if (record.userID === userData.userId) {
            return (
              <Switch
                disabled={true}
                defaultChecked={true}
                checkedChildren={t('general.organizzatore')}
              />
            );
          } else {
            return (
              <Switch
                disabled={this.checkIfSelected(record.userID)}
                onChange={(checked) => this.onParticipationChange(checked, record.userID)}
                defaultChecked={false}
                checkedChildren={t('general.necessario')}
                unCheckedChildren={t('general.opzionale')}
              />
            );
          }
        },
      },
    ];

    return (
      <Modal
        className="add-critic-modal"
        width={800}
        title={t('feed.aggiungiNuovoFeedElement')}
        open={showNewFeedModal}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={handleNewFeedModalClose}
        footer={[
          <ModalFooterButtons
            disabled={!this.state.fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handleNewFeedModalClose}
            formId={'newFeedForm'}
          />,
        ]}
      >
        <Form
          id="newFeedForm"
          layout="vertical"
          onFinish={(values) => this.addData(values, objectId, selectedPaticipants)}
          onValuesChange={() => {
            this.setState({ fieldsChanged: true });
          }}
        >
          <Row gutter={{ lg: 24 }}>
            <Col xs={{ span: 24 }}>
              {!this.props.defaultCheckedFeedType && (
                <Form.Item
                  label={t('feed.tipoFeed')}
                  hasFeedback
                  rules={[{ required: true, message: requiredFields.required }]}
                >
                  <Select
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}
                    onChange={(value, option) => this.onTypeSelect(value, option)}
                  >
                    {feedTypes &&
                      feedTypes.map((item) => (
                        <Option
                          value={item.objectCodeListID}
                          key={item.objectCodeListID}
                        >
                          {item.description}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
            </Col>
          </Row>

          {selectedType && selectedType === feedItemsTypes.comment && <NewFeedCommentForm t={t} />}

          {selectedType && selectedType === feedItemsTypes.file && <NewFeedFileForm t={t} />}

          {selectedType && selectedType === feedItemsTypes.meeting && (
            <NewFeedMeetingForm
              usersList={usersList}
              rowSelection={rowSelection}
              columns={columns}
              chEndDate={endDate}
              onStartDateChange={this.onStartDateChange}
              chDuration={duration}
              onDurationChange={this.onDurationChange}
              onStartTimeChange={this.onStartTimeChange}
              chEndTime={endTime}
              chStartTime={startTime}
              t={t}
            />
          )}
        </Form>
      </Modal>
    );
  }
}

export default AddFeedItem;
