import { Card, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addQueryParam, getQueryParam } from '../../utils/url-utils';
import TableLayout from '../shared/tableLayout';
import BowlingChart, { BowlingChartType } from './bowlingChart';

const BowlingChartIndex: React.FC = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState<string>('1');

  useEffect(() => {
    setActiveTab(getQueryParam('tab') || '1');
  }, []);

  const tabsItems = [
    {
      label: <>{t('general.progetti')}</>,
      key: '1',
      children: <BowlingChart chartType={BowlingChartType.Projects} />,
    },
    {
      label: <>{t('general.obiettiviAnno')}</>,
      key: '2',
      children: <BowlingChart chartType={BowlingChartType.AnnualGoals} />,
    },
    {
      label: <>{t('general.comitato')}</>,
      key: '3',
      children: <BowlingChart chartType={BowlingChartType.Committees} />,
    },
  ];

  return (
    <Card>
      <TableLayout
        className="project-wrapper"
        title={t('bowlingChartPage.bowlingChart')}
      >
        <TableLayout.Content>
          <Tabs
            onChange={(key) => {
              addQueryParam('tab', key);
              setActiveTab(key);
            }}
            activeKey={activeTab}
            items={tabsItems}
            defaultActiveKey="1"
          />
        </TableLayout.Content>
      </TableLayout>
    </Card>
  );
};

export default BowlingChartIndex;
