import {
  CloseCircleOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  MinusSquareOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Alert, Button, Card, Col, DatePicker, Row, Space, Table, Tooltip, Typography, Upload, message } from 'antd';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { ExcelRenderer } from 'react-excel-renderer';
import * as XLSX from 'xlsx';
import KpiService from '../../services/pages/kpiService';
import { capitalizeFirstLetterKpiMonths } from '../shared/utils/functions';
import { notifyMessages } from '../shared/utils/notifyMessages';
import './kpi.scss';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const ErrorList = ({ errorList }) => {
  return (
    <Space direction="vertical">
      {errorList.map((item) => (
        <Text> * {item.error}</Text>
      ))}
    </Space>
  );
};

const UploadKpiGlobal = ({ kpiData }) => {
  const { t } = useTranslation();

  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingExcelData, setLoadingExcelData] = useState(false);
  const [cols, setCols] = useState([]);
  const [rows, setRows] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [kpiApiData, setKpiApiData] = useState(null);

  const [clearFilters, setClearFilters] = useState(false);
  const [selectedYear, setSelectedYear] = useState(moment().format('YYYY'));

  const [rowValidationErrors, setRowValidationErrors] = useState([]);
  const [showErrorsList, setShowErrorsList] = useState(false);

  useEffect(() => {
    void fetchExcelData();
  }, []);

  const fetchExcelData = async () => {
    setLoadingExcelData(true);

    try {
      const response = await KpiService.getKpiGlobaleExcelData(kpiData.kpiDetailID, selectedYear);
      const resp = response.data;

      if (resp.success) {
        setKpiApiData(resp.responseObject.value);
      } else {
        message.error(notifyMessages.retrieveFailed);
      }
    } catch (error) {
      message.error(notifyMessages.retrieveFailed);
    } finally {
      setLoadingExcelData(false);
    }
  };

  /* const checkFile = (file) => {
    if (!file || !file[0]) {
      return;
    }
    const isExcel =
      file[0].type === 'application/vnd.ms-excel' ||
      file[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (!isExcel) {
      setErrorMessage('Upload only excel files');
    }
    const isLessThan2MB = file[0].size / 1024 / 1024 < 2;

    if (!isLessThan2MB) {
      setErrorMessage('File must be less than 2 MB! ');
    }

    return errorMessage;
  }; */

  const exportToCSV = (kpiApiData) => {
    const fileName = `KPI_${kpiData.name}`;

    if (kpiApiData) {
      const ws = XLSX.utils.json_to_sheet(kpiApiData);

      //column formatter
      const wsColsFormatter = [
        { hidden: true }, // hidde first column
        { wpx: 100 }, // column width
        { wpx: 100 }, // column width
        { wpx: 100 }, // column width
        { wpx: 100 }, // column width
      ];

      ws['!cols'] = wsColsFormatter;

      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };

  const onClearFilter = () => {
    const currentYear = moment().format('YYYY');

    setSelectedYear(currentYear);
    setClearFilters(true);

    void fetchExcelData();
    void cancelUpload();
  };

  const cancelUpload = async () => {
    setRowValidationErrors([]);
    setRows([]);
  };

  const showHideErrorList = () => {
    setShowErrorsList(!showErrorsList);
  };

  const onYearChange = (year) => {
    const formatedYear = moment(year).format('YYYY');
    setSelectedYear(formatedYear);
    void fetchExcelData();
    void cancelUpload();
  };

  const fileHandler = (fileList) => {
    const fileObj = fileList;

    if (!fileObj) {
      setErrorMessage('No files uploaded!');
      return false;
    }

    if (
      !(
        fileObj.type === 'application/vnd.ms-excel' ||
        fileObj.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
    ) {
      setErrorMessage('Upload only excel files');
      return false;
    }

    const isLt2M = fileObj.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      setErrorMessage('File must be less than 2 MB! ');
      return false;
    }
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        const newRows = [];
        resp.rows.slice(1).map((row, index) => {
          let rowErrorObj = {};

          if (isNaN(row[3])) {
            rowErrorObj['error'] = `${t('kpiPage.rigaActual')} ` + (index + 1) + ` ${t('kpiPage.nonValido')}.`;
            rowValidationErrors.push(rowErrorObj);
            rowErrorObj = {};
          }

          if (row[2] === null || row[2] === undefined) {
            rowErrorObj['error'] = `${t('kpiPage.rigaMese')} ` + (index + 1) + ` ${t('kpiPage.nonValido')}.`;
            rowValidationErrors.push(rowErrorObj);
            rowErrorObj = {};
          }

          if (row && row !== 'undefined') {
            newRows.push({
              key: index + 1,
              kPIDetailID: row[0],
              name: row[1],
              targetMonth: row[2],
              actual: row[3],
              unitOfMeasure: row[4],
            });
          }
        });

        if (newRows.length === 0) {
          setErrorMessage(`${t('utentiPage.noDataOnFile')} `);
          return false;
        } else {
          setCols(resp.cols);
          setRows(newRows);
          setErrorMessage(null);
        }
      }
    });
    return false;
  };

  /* const disablePreviousYears = (current) => {
    return current && (current > moment().add(10, 'year') || current < moment());
  }; */

  const handleSubmit = async () => {
    const payloadArray = [];

    rows.forEach((item, index) => {
      const payloadObj = {};

      payloadObj['actual'] = item.actual;
      payloadObj['unitOfMeasure'] = item.unitOfMeasure;
      payloadObj['kPIDetailID'] = item.kPIDetailID;
      payloadObj['referencePeriod'] = item.targetMonth;

      payloadArray.push(payloadObj);
    });

    setLoadingButton(true);
    KpiService.updateGlobalKpiFromExcel(payloadArray)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          setRows([]);
          setLoadingButton(false);
        } else {
          message.error(notifyMessages.updateFailed);
          setLoadingButton(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        setLoadingButton(false);
      });

    //submit to API
    //if successful, banigate and clear the data
    //setState({ rows: [] })
  };

  const columns = [
    {
      title: '',
      dataIndex: 'key',
    },
    {
      title: `${t('general.nome')}`,
      dataIndex: 'name',
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: `${t('general.mese')}`,
      dataIndex: 'targetMonth',
      render: (text) => capitalizeFirstLetterKpiMonths(text ? moment(text).format('MMM YYYY') : null),
    },
    {
      title: `${t('general.actual')}`,
      dataIndex: 'actual',
    },
    {
      title: `${t('kpiPage.unitaMisura')}`,
      dataIndex: 'unitOfMeasure',
    },
  ];

  return (
    <>
      {/* {showErrorsModal &&
                <UploadErrorModal
                    showErrorsModal={showErrorsModal}
                    handleCloseModal={handleCloseModal}                   
                    loadingButton={loadingButton}
                    errorList={rowVlidaionErrors}
                />
            } */}

      <Card>
        <Row
          gutter={{ lg: 24 }}
          className="mb10">
          <Col
            className="gutter-row"
            xs={{ span: 8 }}
            sm={{ span: 6 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}>
            {/* <DatePicker allowClear={false} placeholder={t('general.anno')} picker="year" value={moment(selectedYear)} style={{ width: "100%" }} format="YYYY" onChange={(date) => onYearChange(date)} disabledDate={(current) => disablePreviousYears(current)} /> */}
            <DatePicker
              allowClear={false}
              placeholder={t('general.anno')}
              picker="year"
              value={moment(selectedYear)}
              style={{ width: '100%' }}
              format="YYYY"
              onChange={(date) => onYearChange(date)}
            />
          </Col>
          <Col
            xs={{ span: 4 }}
            sm={{ span: 4 }}
            md={{ span: 4 }}
            lg={{ span: 4 }}>
            <Tooltip title={t('general.clearFilters')}>
              <Button
                type="dashed"
                icon={<MinusSquareOutlined />}
                onClick={() => onClearFilter()}
                disabled={selectedYear ? false : true}
              />
            </Tooltip>
          </Col>
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}>
            <Button
              type="primary"
              loading={loadingExcelData}
              icon={<DownloadOutlined />}
              onClick={() => exportToCSV(kpiApiData)}>
              {t('buttons.scaricaModello')}{' '}
            </Button>
            <Tooltip
              placement="top"
              title={t('kpiPage.scaricaModelloKpiInfo')}>
              <InfoCircleOutlined style={{ color: '#928e8e', marginLeft: 10 }} />
            </Tooltip>
          </Col>
        </Row>

        <Row gutter={{ lg: 24 }}>
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}>
            <div className="button-wrapper">
              {rows.length > 0 && (
                <>
                  <Button
                    onClick={handleSubmit}
                    type="primary"
                    style={{ marginBottom: 16, marginLeft: 10 }}
                    loading={loadingButton}
                    disabled={rowValidationErrors && rowValidationErrors.length > 0}
                    className="tw-float-end">
                    {t('buttons.salva')}
                  </Button>

                  <Button
                    onClick={cancelUpload}
                    type="ghost"
                    style={{ marginBottom: 16, marginLeft: 10 }}
                    className="long-term-button remove"
                    icon={<CloseCircleOutlined />}>
                    {t('buttons.cancella')}
                  </Button>
                </>
              )}
            </div>
          </Col>
        </Row>

        {rowValidationErrors && rowValidationErrors.length > 0 && (
          <Alert
            message={t('general.alcuniDatiNonValidi')}
            showIcon
            description={showErrorsList ? <ErrorList errorList={rowValidationErrors} /> : ''}
            //description={showErrorsList ? "<ErrorList errorList={rowVlidaionErrors}" : ""}
            type="error"
            action={
              <Button
                danger
                onClick={showHideErrorList}>
                {t('buttons.dettagli')}
              </Button>
            }
          />
        )}

        {rows.length < 1 && (
          <Row gutter={{ lg: 24 }}>
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}>
              <Upload
                name="file"
                maxCount={1}
                multiple={false}
                beforeUpload={fileHandler}
                onRemove={() => setRows([])}>
                <Button icon={<UploadOutlined />}>{t('buttons.sleionaExcel')}</Button>
              </Upload>
              <p className="upload-text"> * {t('utentiPage.clickFileExcel')} </p>

              {errorMessage && <p className="upload-text-error"> {errorMessage}</p>}
            </Col>
          </Row>
        )}

        {rows.length > 0 && (
          <Row
            gutter={{ lg: 24 }}
            style={{ marginTop: 10 }}>
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}>
              <Table
                className="table-height"
                dataSource={rows}
                columns={columns}
                bordered={true}
                scroll={{ x: 'calc(700px + 50%)' }}
                size="small"
                pagination={{ pageSize: 50 }}
                // loading={loadingData}
              />
            </Col>
          </Row>
        )}
      </Card>
    </>
  );
};

export default UploadKpiGlobal;
