import { EditFilled, FolderOpenOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Input, Space, Table, Tooltip, message } from 'antd';
import type { FilterDropdownProps } from 'antd/es/table/interface';
import type { ColumnType } from 'antd/lib/table';
import type { DataIndex } from 'rc-table/lib/interface';
import { type FC, type Key, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import type { CommitteeDto, UserDetailsRespose } from 'src/connectors/backend';
import CommitieService from '../../services/pages/commitieServices';
import TableLayout from '../shared/tableLayout';
import { userRolesCodes } from '../shared/utils/constants';
import { notifyMessages } from '../shared/utils/notifyMessages';
import './committee.scss';

const CommitteesListPage: FC<{ currentUser: Required<UserDetailsRespose> }> = ({ currentUser }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const adminRoles = [userRolesCodes.techAdmin, userRolesCodes.lenovysAdmin, userRolesCodes.companyAdmin];

  const [searchText, setSearchText] = useState<Key>();
  const [committeeList, setCommitteeList] = useState<CommitteeDto[]>([]);

  useEffect(() => {
    void retrieveCommitieList();
  }, []);

  const getColumnSearchProps = (
    dataIndex: DataIndex<CommitteeDto>,
  ): Pick<ColumnType<CommitteeDto>, 'filterDropdown' | 'filterIcon' | 'onFilter' | 'render'> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),

    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,

    onFilter: (value: React.Key | boolean, record: CommitteeDto) => {
      const path = typeof dataIndex === 'string' ? [dataIndex] : (dataIndex as string[]);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let target: any = record;
      for (const elem of path) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (target && elem in target && target[elem]) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
          target = target[elem];

          continue;
        }

        return false;
      }

      if (typeof target !== 'string') {
        return false;
      }

      return target.toLowerCase().includes(value.toString().toLowerCase());
    },
  });

  const handleSearch = ([selectedKeys]: Key[], confirm: FilterDropdownProps['confirm']) => {
    confirm();
    setSearchText(selectedKeys);
  };

  const handleReset = (clearFilters: (() => void) | undefined, confirm: FilterDropdownProps['confirm']) => {
    clearFilters && clearFilters();
    handleSearch([], confirm);
  };

  const retrieveCommitieList = async () => {
    try {
      const { data } = await CommitieService.getCommitiesList();
      setCommitteeList(data);
    } catch {
      void message.error(notifyMessages.retrieveFailed);
    }
  };

  const onNew = () => {
    history.push('/committie/new');
  };

  const columns: ColumnType<CommitteeDto>[] = [
    {
      title: `${t('general.nome')}`,
      dataIndex: 'name',
      ellipsis: {
        showTitle: true,
      },
      width: '100%',
      sorter: (a: CommitteeDto, b: CommitteeDto) => {
        return (a.name || '').localeCompare(b.name || '');
      },
      showSorterTooltip: false,
      ...getColumnSearchProps('name'),
      render: (_, record) => (
        <Link
          to={{ pathname: `/committie/id/${record.id}` }}
          style={{ color: '#323232' }}
        >
          {record.name}
        </Link>
      ),
    },

    {
      key: 'action',
      width: '100px',
      render: (_, record) => (
        <div className="tw-flex tw-items-center tw-gap-2">
          <Tooltip title={t('comitatiPage.goToComitieDashboard')}>
            <Button
              type="primary"
              icon={<FolderOpenOutlined />}
              href={`/committee-dashboard/${record.id}`}
            ></Button>
          </Tooltip>
          {adminRoles?.includes(currentUser.roleCode as string) && (
            <Button
              icon={<EditFilled />}
              href={`/committie/id/${record.id}`}
            ></Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      {currentUser && committeeList && (
        <Card>
          <TableLayout title={t('comitatiPage.listaComitati')}>
            <TableLayout.Actions>
              {adminRoles?.includes(currentUser.roleCode as string) && (
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={onNew}
                >
                  <PlusOutlined />
                  {t('buttons.aggiungiNuovo')}
                </Button>
              )}
            </TableLayout.Actions>
            <TableLayout.Content>
              <Table
                className="table-height"
                columns={columns}
                size="small"
                dataSource={committeeList}
                rowKey={(obj) => obj.id}
                pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
              />
            </TableLayout.Content>
          </TableLayout>
        </Card>
      )}
    </>
  );
};

export default CommitteesListPage;
