import { Form } from 'antd';
import { type FC, useState } from 'react';

import { Card, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { type CommitteeDto, type ProjectDto } from 'src/config/connectors';
import ModalFooterButtons from '../../shared/components/modal/modalFooterButtons';
import { formItemLayout } from '../../shared/utils/constants';
import { requiredFields } from '../../shared/utils/notifyMessages';
const { Option } = Select;

type FormData = {
  projectId: string;
};

interface AddProjectToCommitteeModalProps {
  addProjectToCommittee: (projectId: string) => Promise<void>;
  handleModalClose: () => void;
  loadingSave: boolean;
  showModal: boolean;
  committee: CommitteeDto;
  projectsPicklist: ProjectDto[];
  selectedXmatrixName: string;
}

const AddProjectToCommitteeModal: FC<AddProjectToCommitteeModalProps> = ({
  addProjectToCommittee,
  handleModalClose,
  showModal,
  loadingSave,
  committee,
  projectsPicklist,
  selectedXmatrixName,
}) => {
  const { t } = useTranslation();

  const [fieldsChanged, setFieldsChanged] = useState(false);

  const saveData = async ({ projectId }: FormData) => {
    void addProjectToCommittee(projectId);
  };

  return (
    <div className="edit-commitie-wrapper">
      <Modal
        width={800}
        title={t('comitatiPage.aggiungiProgetto')}
        open={showModal}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={handleModalClose}
        footer={[
          <ModalFooterButtons
            disabled={!fieldsChanged}
            loadingButton={loadingSave}
            modalClose={handleModalClose}
            formId={'newProjectCommittee'}
            key="newProjectCommittee"
          />,
        ]}
      >
        <Card>
          <Form<FormData>
            id="newProjectCommittee"
            layout="vertical"
            {...formItemLayout}
            onFinish={(values) => saveData(values)}
            onValuesChange={() => {
              setFieldsChanged(true);
            }}>
            <Form.Item<FormData>
              className="main-container"
              label={t('general.progetti') + ' ' + selectedXmatrixName}
              name="projectId"
              hasFeedback
              rules={[{ required: true, message: requiredFields.required }]}>
              <Select
                showSearch
                allowClear
                className='tw-w-full'
                placeholder={t('general.seleziona')}
                optionFilterProp="children">
                {projectsPicklist.map((item) => (
                  <Option
                    value={item.id}
                    key={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Card>
      </Modal>
    </div>
  );
};

export default AddProjectToCommitteeModal;
