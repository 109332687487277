import { List, Typography } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import MatrixService from '../../../../services/matrix/matrixService';
import ProgettiService from '../../../../services/pages/projectServices/progettiService';
import Loader2 from '../../../shared/components/loader2/loader2';
import XmatrixDropdownFilter from '../../../shared/components/xmatrixDropdownFilter/xmatrixDropdownFilter';
import TableLayout from '../../../shared/tableLayout';
import ProjectSectionTitle from 'src/components/shared/components/ProjectSectionTitle/ProjectSectionTitle';
import { GetXMatrixInfoResponseDto, GoalByProject, ProjectAnnualGoal, ProjectLongTermGoal } from 'src/connectors/backend';

const { Text } = Typography;

export interface ProjectObjectivesProps {
  projectId: string;
}

const ProjectObjectives: FC<ProjectObjectivesProps> = ({ projectId }) => {
  const { t } = useTranslation();

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [longTermGoals, setLongTermGoals] = useState<ProjectLongTermGoal[]>([]);
  const [annualGoals, setAnnualGoals] = useState<ProjectAnnualGoal[]>([]);
  const [selectedXmatrixValue, setSelectedXmatrixValue] = useState<string>();
  const [activeXmatrixInfo, setActiveXmatrixInfo] = useState<GetXMatrixInfoResponseDto>();

  useEffect(() => {
    retrieveComponentData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const retrieveComponentData = async () => {
    await getActiveXmatrix();
    await retrieveProjectOjectives();
  };
  
  useEffect(() => {
    if (activeXmatrixInfo) {
      onXmatrixSelect({
        label: '',
        value: activeXmatrixInfo.xMatrixID as string
      });
    }
  }, [activeXmatrixInfo]);

  const getActiveXmatrix = async () => {
    try {
      const response = await MatrixService.getActiveXmatrix();
      const resp = response.data;
      if (resp.success) {
        setActiveXmatrixInfo(resp.responseObject?.value as GetXMatrixInfoResponseDto);
      }
    } catch (error) {
      console.error(error);
    }
  };


  const retrieveProjectOjectives = async () => {
    const xmatrixId: string = selectedXmatrixValue ?? (activeXmatrixInfo?.xMatrixID as string);

    setIsDataLoading(true);

    try {
      const response = await ProgettiService.getProjectObjectives(projectId, xmatrixId);
      const resp = response.data;
      if (resp.success) {
        const goalByProject = resp.responseObject?.value as GoalByProject;

        setLongTermGoals(goalByProject?.longTermGoal ?? []);
        setAnnualGoals(goalByProject?.annualGoal ?? []);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsDataLoading(false);
    }
  };

  const onXmatrixSelect = (selectedXmatrix: { label: string; value: string }) => {
    setSelectedXmatrixValue(selectedXmatrix.value);
    retrieveProjectOjectives();
  };

  return (
    <TableLayout title={
      <ProjectSectionTitle title={t('proggetiPage.obiettiviCollegati')} />
    }>
      <TableLayout.Actions>
        {activeXmatrixInfo && (
          <XmatrixDropdownFilter
            onXmatrixSelect={onXmatrixSelect}
            activeXmatrixInfo={activeXmatrixInfo}
          />
        )}
      </TableLayout.Actions>

      <TableLayout.Content>
        {isDataLoading && <Loader2 />}
        {longTermGoals && annualGoals && (
          <>
            <List
              header={<Text>{t('proggetiPage.obiettiviLungo')}</Text>}
              dataSource={longTermGoals}
              renderItem={(item) => (
                <List.Item>
                  <Link to={{ pathname: `/obiettivialungoperiodo/details/id/${item.longTermGoalID}` }}>
                    {item.longTermGoalDesription}
                  </Link>
                </List.Item>
              )}
            />
            <List
              header={<Text>{t('proggetiPage.obbietiviAnuali')}</Text>}
              dataSource={annualGoals}
              renderItem={(item) => (
                <List.Item>
                  <Link to={{ pathname: `/obiettiviannuali/details/id/${item.annualGoalID}` }}>
                    {item.annualGoalDesription}
                  </Link>
                </List.Item>
              )}
            />
          </>
        )}
      </TableLayout.Content>
    </TableLayout>
  );
};

export default ProjectObjectives;
