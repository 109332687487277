import { Button, Col, Form, Row, message } from 'antd';
import BraftEditor from 'braft-editor';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProgettiService from '../../../../services/pages/projectServices/progettiService';
import Loader from '../../../shared/components/loader/loader';
import { notifyMessages } from '../../../shared/utils/notifyMessages';
import '../../project.scss';
import ProjectObjectives from '../projectObjectives/projectObjectives';
import { formItemLayout, RichTextEditorControls } from 'src/components/shared/utils/constants';
import { GetProjectResponseDto } from 'src/connectors/backend';
import { useAppDispatch } from 'src/redux/store';
import { useDispatch } from 'react-redux';
import { getProjectById } from 'src/redux/slices/projectSlice';

export interface ProjectObjectivesIndexProps {
  currentProject: GetProjectResponseDto;
  hasUserAccess: any;
}

const ProjectObjectivesIndex: FC<ProjectObjectivesIndexProps> = ({ currentProject, hasUserAccess }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loadingEditorButton, setLoadingEditorButton] = useState(false);
  const [description, setDescription] = useState(BraftEditor.createEditorState(currentProject.description));

  const updateProjectDescription = async (values: any) => {
    setLoadingEditorButton(true);

    const payload = {
      projectID: currentProject.projectID as string,
      projectFieldName: 'description',
      projectFieldValue: description.toHTML(),
    };

    try {
      const response = await ProgettiService.updateEditorField(payload);
      const resp = response.data;

      if (resp.success) {
        message.success(notifyMessages.updateSuccess);
        dispatch(getProjectById({ projectId: currentProject.projectID as string }));
      }
    } catch {
      message.error(notifyMessages.updateFailed);
    } finally {
      setLoadingEditorButton(false);
    }
  };

  return (
    <>
      <Loader />
      {currentProject && (
        <>
          <Row gutter={{ lg: 24 }}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 10 }}
              lg={{ span: 10 }}>
              <ProjectObjectives projectId={currentProject?.projectID as string} />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 14 }}
              lg={{ span: 14 }}>
              <h2 className="tw-text-2xl tw-mb-5 tw-font-medium">{t('general.descrizione')}</h2>

              <Form
                name="project_field"
                layout="vertical"
                {...formItemLayout}
                onFinish={(values) => {
                  void updateProjectDescription(values);
                }}>
                <Row gutter={{ lg: 24 }}>
                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}>
                    <Form.Item
                      name={'description'}
                      >
                      <div className="tw-rounded-lg tw-grow tw-overflow-hidden">
                        <BraftEditor
                          defaultValue={description}
                          onChange={(value) => {
                            setDescription(value);
                          }}
                          language="en"
                          id="editor-text"
                          controls={RichTextEditorControls}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                </Row>

                <div className="tw-flex tw-justify-end tw-mt-1">
                  <Button
                    type="primary"
                    htmlType="submit"
                    key="submit"
                    loading={loadingEditorButton}>
                    {t('buttons.salva')}
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ProjectObjectivesIndex;
