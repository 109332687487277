import { trackPromise } from 'react-promise-tracker';
import {
  AgendaApi,
  type AgendaDto,
  authenticatedApi,
  CommitteeApi,
  type UpsertAgendaRequest,
} from 'src/config/connectors';

const prepareSingleAgenda = (agendas: AgendaDto[]) => {
  if (agendas.length > 1) {
    throw new Error('More than one agenda found');
  }

  const [agenda] = agendas;
  agenda?.agendaItems?.sort((a, b) => (a.orderString ?? '')?.localeCompare(b.orderString ?? ''));

  return agenda;
};

export const AgendaService = {
  getSessionAgenda: async (committeeId: string, sessionId: string) => {
    const api = await authenticatedApi(AgendaApi);
    const { data: agendas } = await trackPromise(api.agendaListAgendas(committeeId, false, sessionId));

    return prepareSingleAgenda(agendas);
  },

  getStandardAgenda: async (committeeId: string) => {
    const api = await authenticatedApi(AgendaApi);
    const { data: agendas } = await trackPromise(api.agendaListAgendas(committeeId, true));

    return prepareSingleAgenda(agendas);
  },

  listAvailableProjects: async (committeeId: string, isStandard: boolean, xmatrixId?: string | null) => {
    const api = await authenticatedApi(CommitteeApi);

    return trackPromise(api.committeeListProjectsForAgenda(committeeId, xmatrixId, isStandard));
  },

  upsertAgenda: async (request: UpsertAgendaRequest) => {
    const api = await authenticatedApi(AgendaApi);

    return trackPromise(api.agendaUpsertAgenda(request));
  },
};
