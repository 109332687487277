import { Popover, Table, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ProjectSectionTitle from 'src/components/shared/components/ProjectSectionTitle/ProjectSectionTitle';
import { useAppSelector } from 'src/redux/store';
import Section3Service from '../../../services/a3/section3Service';
import { ResponsiblePopoverContent, projectDeliverableStatusColor } from '../../shared/utils/functions';

function AttivitaSvolte({ projectId, isPresentationMode }) {
  const companyData = useAppSelector((state) => state.companyData.companyData);
  const [dataActivities, setDataActivities] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    retrieveProjectCompletedActivities();
  }, []);

  const retrieveProjectCompletedActivities = () => {
    setLoadingData(true);

    Section3Service.getCompletedActivities(projectId, companyData.implementationDaysOfNotice)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const respData = resp.responseObject.value;
          setDataActivities(respData);
          setLoadingData(false);
        } else {
          setLoadingData(false);
        }
      })
      .catch((error) => {
        setLoadingData(false);
      });
  };

  const tableColumns = [
    {
      title: `${t('general.end')}`,
      dataIndex: 'startDate',
      key: 'startDate',
      ellipsis: {
        showTitle: true,
      },
      width: '120px',
      render: (text, record) => {
        return {
          props: {
            style: { borderLeft: `5px solid ${projectDeliverableStatusColor(record.deliverableStatus)}` },
          },
          children: (
            <div>
              <Tooltip title={record.notes}>{moment(text).format('MM/YYYY')}</Tooltip>
            </div>
          ),
        };
      },
    },
    {
      title: `${t('general.deliverable')}`,
      dataIndex: 'name',
      key: 'name',
      className: 'twoRowEllipses',
      render: (title, record) => (
        <Tooltip title={title}>
          <Link
            to={{ pathname: `/deliverables/id/${record.deliverableID}` }}
            style={{ color: 'black' }}>
            {title}
          </Link>
        </Tooltip>
      ),
    },
    {
      title: `${t('general.assegnatoA')}`,
      dataIndex: 'ownerFullNameInitials',
      key: 'ownerFullNameInitials',
      width: '100px',
      ellipsis: {
        showTitle: false,
      },
      render: (title, record) => (
        <Popover
          content={
            <ResponsiblePopoverContent
              data={record}
              t={t}
            />
          }
          title={record.ownerFullName}>
          <span style={record.isGenericResponsabile ? { color: '#f2883b' } : {}}>{title}</span>
        </Popover>
      ),
    },
  ];

  return (
    <>
      <ProjectSectionTitle
        title={t('proggetiPage.attivitàSvolteTitle', {
          days: companyData.implementationDaysOfNotice,
        })}
        isPresentationMode={isPresentationMode}
      />

      <Table
        // className="custom-table-section1 project-status"
        className={'custom-table-section1 project-status'}
        columns={tableColumns}
        rowClassName={(record) => 'status-' + record.deliverableStatus}
        dataSource={dataActivities}
        pagination={false}
        loading={loadingData}
        rowKey={(obj) => obj.deliverableID}
        size="small"
      />
    </>
  );
}

export default AttivitaSvolte;
