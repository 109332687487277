import { Button } from 'antd';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import type React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RichTextEditorControls } from '../../../shared/utils/constants';

interface TextEditorProps {
  data: { fieldValue: string };
  handleEditorSave: (content: string) => void;
  loadingEditorButton: boolean;
}

const TextEditor: React.FC<TextEditorProps> = ({ data, handleEditorSave, loadingEditorButton }) => {
  const { t } = useTranslation();
  const [editorState, setEditorState] = useState(BraftEditor.createEditorState(data.fieldValue));

  const updateField = () => {
    if (editorState.isEmpty()) {
      return;
    }

    handleEditorSave(editorState.toHTML());
  };

  const handleEditorChange = (newEditorState: any) => {
    setEditorState(newEditorState);
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      <BraftEditor
        style={{ height: '400px', overflowY: 'hidden', overflowX: 'hidden' }}
        language="en"
        id="editor-text"
        controls={RichTextEditorControls}
        value={editorState}
        onChange={handleEditorChange}
      />

      <div className="tw-flex tw-justify-end">
        <Button
          type="primary"
          onClick={updateField}
          loading={loadingEditorButton}
        >
          {t('buttons.salva')}
        </Button>
      </div>
    </div>
  );
};

export default TextEditor;
