import { type FC, useEffect, useState } from 'react';

import { Button, Col, Modal, Row, message } from 'antd';
import { useTranslation } from 'react-i18next';
import type { BowlingChartEntry } from 'src/connectors/backend';
import KpiService from 'src/services/pages/kpiService';
import { notifyMessages } from '../shared/utils/notifyMessages';
import { BowlingChartTable } from './components/bowlingChartTable';

const BowlingChartChildrenDetailsModal: FC<{
  kpi: BowlingChartEntry;
  handleModalClose: () => void;
  showKpiRelationDetails: boolean;
  showModal: boolean;
  year: number;
}> = ({ handleModalClose, kpi, showKpiRelationDetails, showModal, year }) => {
  const { t } = useTranslation();
  const [data, setData] = useState<BowlingChartEntry[]>([]);
  const [isLoadingData, setIsLoadingData] = useState(true);

  useEffect(() => {
    void loadData(kpi);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kpi]);

  const loadData = async (kpiData: BowlingChartEntry) => {
    setIsLoadingData(true);

    try {
      const {
        data: { entries },
      } = await KpiService.getBowlingChartData({
        year,
        parentKpiDetailId: kpiData.details.id,
      });

      setData(entries);
    } catch {
      void message.error(notifyMessages.retrieveFailed);
    }

    setIsLoadingData(false);
  };

  return (
    <Modal
      className="add-kpi-modal"
      width={'99%'}
      title={kpi.details.name}
      open={showModal}
      destroyOnClose={true}
      onCancel={handleModalClose}
      footer={[
        <div key="close_buttons">
          <Button onClick={handleModalClose}>{t('buttons.chiudi')}</Button>
        </div>,
      ]}
    >
      <>
        <Row gutter={{ lg: 24 }}>
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
          >
            <BowlingChartTable
              data={data}
              isLoadingData={isLoadingData}
              showKpiRelationDetails={showKpiRelationDetails}
              toggleKpiDetailModal={async () => {}}
              year={year}
            />
          </Col>
        </Row>
      </>
    </Modal>
  );
};

export default BowlingChartChildrenDetailsModal;
