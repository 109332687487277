import { Comment } from '@ant-design/compatible';

import { Avatar, Button, Collapse, Divider, Empty, Form, Input, Tooltip, Typography, message } from 'antd';
import { Component } from 'react';

import { DoubleLeftOutlined, PlusOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { animateScroll } from 'react-scroll';
import ProjectSectionTitle from 'src/components/shared/components/ProjectSectionTitle/ProjectSectionTitle';
import CommonService from '../../../../services/commonService';
import FeedService from '../../../../services/pages/projectServices/feedServices';
import Loader2 from '../../../shared/components/loader2/loader2';
import EditFeedItem from '../../../shared/feed/editFeedItem';
import { CustomPanel } from '../../../shared/feed/feed';
import '../../../shared/feed/feed.scss';
import AddFeedItem from '../../../shared/feed/newFeedItem';
import TableLayout from '../../../shared/tableLayout';
import { feedItemsTypes, feedRegardingObjectType, objectCodes } from '../../../shared/utils/constants';
import { handleDateConvert, handleTimeConvert } from '../../../shared/utils/functions';
import { notifyMessages } from '../../../shared/utils/notifyMessages';

const { Paragraph } = Typography;
const { Search } = Input;

// const FeedOptions = (props) => {
//   const { removeFeed, t } = props;
//   return (
//     <Menu>
//       <Menu.Item>
//         <div>
//           <Popconfirm
//             placement="topRight"
//             title={t('feed.eliminareFeed')}
//             onConfirm={removeFeed}
//             okText={t('general.si')}
//             cancelText={t('general.no')}>
//             <DeleteOutlined /> {t('buttons.elimina')}
//           </Popconfirm>
//         </div>
//       </Menu.Item>
//     </Menu>
//   );
// };

function sortDateLatest(a, b) {
  var dateA = new Date(a.createdOn || '').getTime();
  var dateB = new Date(b.createdOn || '').getTime();
  return dateA > dateB ? 1 : -1;
}

function sortDateOldest(a, b) {
  var dateA = new Date(a.createdOn || '').getTime();
  var dateB = new Date(b.createdOn || '').getTime();
  return dateA < dateB ? 1 : -1;
}

class ProjectFeed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingSave: false,
      isDataLoading: false,
      loadingActivities: false,
      activityList: null,
      feedList: null,
      commentFeedList: null,
      fullCommentFeedList: null,
      isCommentListLoading: false,
      fullFeedList: null,
      activeKey: null,
      ellipsis: true,
      postingComment: false,
      commentValue: '',
      fieldsChanged: false,
      feedFilterData: null,
      sortDatesLatest: false,
      showNewFeedModal: false,
      showEditModal: false,
      modalData: null,
      isSubmitted: false,
    };
  }

  scrollToBottom(feedId) {
    animateScroll.scrollToBottom({
      containerId: feedId,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.userData !== prevProps.userData) {
      this.retrieveFeed();
    }
  }

  componentWillMount() {
    this.retrieveFeedFilterPicklist();
    this.retrieveFeed();
  }

  componentWillUnmount() {
    this.setState({ isDataLoading: false });
  }

  async retrieveFeedFilterPicklist() {
    const objectCode = objectCodes.feedFilter;
    await CommonService.getPicklist(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ feedFilterData: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveFeed() {
    this.setState({ isDataLoading: true });
    this.setState({ isCommentListLoading: true });
    const { objectId, userData, isProject } = this.props;
    if (userData !== undefined && userData.hasOwnProperty('userId')) {
      await FeedService.getFeedData(objectId, userData.userId, isProject)
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            const data = resp.responseObject.value;
            this.setState({ feedList: data.filter((obj) => obj.activityType !== feedItemsTypes.comment) });
            this.setState({ fullFeedList: data.filter((obj) => obj.activityType !== feedItemsTypes.comment) });

            this.setState({
              commentFeedList: data.filter(
                (obj) => obj.activityType === feedItemsTypes.comment || obj.activityType === feedItemsTypes.meeting,
              ),
            });
            this.setState({
              fullCommentFeedList: data.filter(
                (obj) => obj.activityType === feedItemsTypes.comment || obj.activityType === feedItemsTypes.meeting,
              ),
            });

            this.setState({ isDataLoading: false });
            this.setState({ isCommentListLoading: false });
          } else {
            this.setState({ isDataLoading: false });
            this.setState({ isCommentListLoading: false });
          }
        })
        .catch((error) => {
          this.setState({ isDataLoading: false });
          this.setState({ isCommentListLoading: false });
        });
    }
  }

  getFeedActivity = (feedId) => {
    if (feedId === this.state.activeKey) {
      this.setState({ activeKey: null });
    } else {
      this.setState({ activeKey: feedId });

      this.setState({ loadingActivities: true });

      FeedService.getFeedActivity(feedId)
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            this.setState({ activityList: resp.responseObject.value });
            this.setState({ loadingActivities: false });
            // updateScroll(feedId);
            this.scrollToBottom(feedId);
          } else {
            this.setState({ loadingActivities: false });
          }
        })
        .catch((error) => {
          this.setState({ loadingActivities: false });
        });
    }
  };

  reloadFeedActivity = (feedId) => {
    this.setState({ loadingActivities: true });

    FeedService.getFeedActivity(feedId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ activityList: resp.responseObject.value });
          this.setState({ loadingActivities: false });
          this.scrollToBottom(feedId);
        } else {
          this.setState({ loadingActivities: false });
        }
      })
      .catch((error) => {
        this.setState({ loadingActivities: false });
      });
  };

  handleCommentPost = (parentActivityId, regardingObjectID, regardingObjectTypeCode, subject, commentValue) => {
    if (!commentValue) {
      return;
    }
    this.setState({ postingComment: true });
    const obj = {};
    const { userData } = this.props;
    obj['parentActivityID'] = parentActivityId;
    obj['createdBy'] = userData.userId;
    obj['description'] = commentValue;
    obj['regardingObjectID'] = regardingObjectID;
    obj['regardingObjectTypeCode'] = feedRegardingObjectType.feed;
    obj['subject'] = subject;
    FeedService.addFeedComment(obj)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ postingComment: false });
          this.reloadFeedActivity(parentActivityId);
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ postingComment: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ postingComment: false });
      });
    this.setState({ isSubmitted: true });
  };

  handleCommentChange = (e) => {
    this.setState({ commentValue: e.target.value });
  };

  onFeedSearch = (value, feedType) => {
    const { fullFeedList, fullCommentFeedList } = this.state;
    if (value) {
      if (feedType === 'comments') {
        if (fullCommentFeedList) {
          this.setState({ isCommentListLoading: true });
          const res = fullCommentFeedList.filter((obj) => obj.subject.toLowerCase().includes(value.toLowerCase()));
          this.setState({ commentFeedList: res });
          this.setState({ isCommentListLoading: false });
        } else {
          return;
        }
      } else {
        if (fullFeedList) {
          this.setState({ isDataLoading: true });
          const res = fullFeedList.filter((obj) => obj.subject.toLowerCase().includes(value.toLowerCase()));
          this.setState({ feedList: res });
          this.setState({ isDataLoading: false });
        } else {
          return;
        }
      }
    } else {
      this.setState({ feedList: fullFeedList, commentFeedList: fullCommentFeedList });
    }
  };

  onFeedFilter = (value) => {
    const { fullFeedList } = this.state;
    if (value.length > 0) {
      if (fullFeedList) {
        this.setState({ isDataLoading: true });
        const res = fullFeedList.filter((obj) => value.includes(obj.activityType));
        this.setState({ feedList: res });
        this.setState({ isDataLoading: false });
      } else {
        return;
      }
    } else {
      this.setState({ feedList: fullFeedList });
    }
  };

  onFeedSortDateLatest = (feedType) => {
    const { fullFeedList, feedList, commentFeedList, fullCommentFeedList } = this.state;
    if (feedType === 'comments') {
      if (fullCommentFeedList) {
        this.setState({ isCommentListLoading: true });
        const res = commentFeedList.sort(sortDateLatest);
        this.setState({ commentFeedList: res });
        this.setState({ isCommentListLoading: false });
        this.setState({ sortDatesLatest: true });
      } else {
        return;
      }
    } else {
      if (fullFeedList) {
        this.setState({ isDataLoading: true });
        const res = feedList.sort(sortDateLatest);
        this.setState({ feedList: res });
        this.setState({ isDataLoading: false });
        this.setState({ sortDatesLatest: true });
      } else {
        return;
      }
    }
  };

  onFeedSortDateOldest = (feedType) => {
    const { fullFeedList, feedList, commentFeedList, fullCommentFeedList } = this.state;
    if (feedType === 'comments') {
      if (fullCommentFeedList) {
        this.setState({ isCommentListLoading: true });
        const res = commentFeedList.sort(sortDateOldest);
        this.setState({ commentFeedList: res });
        this.setState({ isCommentListLoading: false });
        this.setState({ sortDatesLatest: false });
      } else {
        return;
      }
    } else {
      if (fullFeedList) {
        this.setState({ isDataLoading: true });
        const res = feedList.sort(sortDateOldest);
        this.setState({ feedList: res });
        this.setState({ isDataLoading: false });
        this.setState({ sortDatesLatest: false });
      } else {
        return;
      }
    }
  };

  likeFeed = (activityId) => {
    const obj = {};
    const { userData } = this.props;
    obj['activityID'] = activityId;
    obj['userID'] = userData.userId;
    // obj["regardingObjectID"] = regardingObjectID
    FeedService.addLike(obj)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.retrieveFeed();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ isDataLoading: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ isDataLoading: false });
      });
  };

  toggleEditModal = (rowData) => {
    this.setState({ showEditModal: true });
    this.setState({ modalData: rowData });
  };

  handleEditModalClose = () => {
    this.setState({ showEditModal: false });
    this.setState({ loadingSave: false });
  };

  handleNewFeedModalClose = () => {
    this.setState({ showNewFeedModal: false });
    this.setState({ loadingSave: false });
  };

  toggleNewFeedModal = () => {
    this.setState({ showNewFeedModal: true });
  };

  addNewCommentFeed = (values) => {
    this.setState({ loadingSave: true });
    FeedService.addFeedComment(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.handleNewFeedModalClose();
          message.success(notifyMessages.addSuccess);
          this.retrieveFeed();
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingSave: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingSave: false });
      });
  };

  addNewFileFeed = (values) => {
    this.setState({ loadingSave: true });
    FeedService.addFeedFile(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.handleNewFeedModalClose();
          message.success(notifyMessages.addSuccess);
          this.retrieveFeed();
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingSave: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingSave: false });
      });
  };

  addNewMeetingFeed = (values) => {
    const currentUserTime = moment().format('YYYY-MM-DD HH:mm');
    // values["timezone"] = currentUserTime.toString();
    values['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.setState({ loadingSave: true });
    FeedService.addFeedMeeting(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.handleNewFeedModalClose();
          message.success(notifyMessages.addSuccess);
          this.retrieveFeed();
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingSave: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingSave: false });
      });
  };

  handleUpdateFeed = (values, activityData) => {
    const currentUserTime = moment().format('YYYY-MM-DD HH:mm');
    //values["timezone"] = currentUserTime.toString();
    values['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;

    values['activityID'] = activityData.activityID;
    values['activityType'] = activityData.activityType;
    values.priority = values.priority ? 1 : 0;
    values['outlookEventId'] = activityData.outlookEventId; //"C29DD108-FA5E-405C-B1FA-046F5780FC64"

    // values.startTime = handleTimeConvert(values.startTime);
    // values.endTime = handleTimeConvert(values.endTime);

    if (typeof values.startTime !== 'string') {
      values.startTime = handleTimeConvert(values.startTime);
    }

    if (typeof values.endTime !== 'string') {
      values.endTime = handleTimeConvert(values.endTime);
    }

    values.scheduledStart = handleDateConvert(values.scheduledStart);
    values.scheduledEnd = handleDateConvert(values.scheduledEnd);

    this.setState({ loadingSave: true });
    FeedService.updateFeedItem(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.handleEditModalClose();
          message.success(notifyMessages.updateSuccess);
          this.retrieveFeed();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingSave: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingSave: false });
      });
  };

  removeFeed = (id, type, outlookEventId) => {
    FeedService.removeFeedItem(id, type, outlookEventId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.deleteSuccess);
          this.retrieveFeed();
        } else {
          message.error(notifyMessages.deleteFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.deleteFailed);
      });
  };

  render() {
    const {
      showEditModal,
      modalData,
      commentFeedList,
      activeKey,
      ellipsis,
      postingComment,
      commentValue,
      activityList,
      loadingActivities,
      sortDatesLatest,
      showNewFeedModal,
      loadingSave,
      isCommentListLoading,
    } = this.state;
    const { userData, objectId, hasUserAccess, feedRegardingObjType, isPresentationMode, isCommitie } = this.props;
    const t = this.props.t;
    return (
      <div className="feed">
        <>
          <AddFeedItem
            showNewFeedModal={showNewFeedModal}
            handleNewFeedModalClose={this.handleNewFeedModalClose}
            addNewCommentFeed={this.addNewCommentFeed}
            addNewFileFeed={this.addNewFileFeed}
            defaultCheckedFeedType={2}
            addNewMeetingFeed={this.addNewMeetingFeed}
            loadingButton={loadingSave}
            objectId={objectId}
            userData={userData}
            regardingObjectType={feedRegardingObjType}
            t={t}
            isCommitie={isCommitie}
          />

          {showEditModal && (
            <EditFeedItem
              showEditModal={showEditModal}
              handleEditModalClose={this.handleEditModalClose}
              handleUpdateFeed={this.handleUpdateFeed}
              loadingSave={loadingSave}
              modalData={modalData}
              userData={userData}
              t={t}
            />
          )}

          <TableLayout
            title={
              !isPresentationMode ? (
                <ProjectSectionTitle title={t('feed.feed')} />
              ) : (
                <ProjectSectionTitle
                  title={t('proggetiPage.minutesTitle')}
                  isPresentationMode={true}
                />
              )
            }
          >
            <TableLayout.Actions>
              {sortDatesLatest && (
                <Button
                  type="text"
                  icon={<SortDescendingOutlined />}
                  onClick={() => this.onFeedSortDateOldest('comments')}
                />
              )}
              {!sortDatesLatest && (
                <Button
                  type="text"
                  icon={<SortAscendingOutlined />}
                  onClick={() => this.onFeedSortDateLatest('comments')}
                />
              )}
              <Search
                placeholder={t('buttons.cerca')}
                allowClear
                onSearch={(value) => this.onFeedSearch(value, 'comments')}
              />
              {!hasUserAccess ? (
                <Button
                  type="primary"
                  onClick={this.toggleNewFeedModal}
                  icon={<PlusOutlined />}
                >
                  {t('buttons.aggiungiNuovo')}
                </Button>
              ) : null}
            </TableLayout.Actions>
            <TableLayout.Content>
              <div className="tw-flex tw-gap-2 tw-flex-col">
                {isCommentListLoading && <Loader2 />}
                {(!commentFeedList || commentFeedList.length < 1) && <Empty />}
                {commentFeedList &&
                  commentFeedList.map((activity, index) => (
                    <Collapse
                      className="!tw-bg-zinc-100 !tw-border-0 [&_.ant-collapse-expand-icon]:!tw-hidden"
                      activeKey={activeKey}
                      key={index}
                    >
                      <CustomPanel
                        onClick={() => this.getFeedActivity(activity.activityID)}
                        onLikeFeed={() => this.likeFeed(activity.activityID)}
                        key={activity.activityID}
                        acivityData={activity}
                        hasUserAccess={hasUserAccess}
                        toggleEditModal={() => this.toggleEditModal(activity)}
                        removeFeed={() =>
                          this.removeFeed(activity.activityID, activity.activityType, activity.outlookEventId)
                        }
                        t={t}
                      >
                        <div
                          className="comment-section"
                          key={index}
                          id={activity.activityID}
                        >
                          {loadingActivities && <Loader2 />}
                          {activityList &&
                            activityList.length > 0 &&
                            activityList.map((comment) => (
                              <div key={comment.activityID}>
                                <Comment
                                  author={comment.fullName}
                                  key={comment.activityID}
                                  avatar={
                                    <Tooltip title={comment.fullName}>
                                      <Avatar size={'small'}>{comment.initials}</Avatar>
                                    </Tooltip>
                                  }
                                  content={
                                    <Paragraph
                                      ellipsis={ellipsis ? { rows: 1, expandable: true, symbol: 'more' } : false}
                                      style={{ fontSize: 12 }}
                                    >
                                      {comment.description}
                                    </Paragraph>
                                  }
                                  datetime={
                                    <span>
                                      {comment.createdOn ? moment(comment.createdOn).format('DD/MM/YYYY HH:mm') : ''}
                                    </span>
                                  }
                                />
                                <Divider style={{ margin: '2px 0px' }} />
                              </div>
                            ))}
                          <Comment
                            avatar={
                              <Tooltip title={userData.fullName}>
                                <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                                  {userData.userInitials}
                                </Avatar>
                              </Tooltip>
                            }
                            content={
                              <div>
                                <Form.Item>
                                  <Input.TextArea
                                    rows={3}
                                    onChange={(e) => this.handleCommentChange(e)}
                                    value={commentValue}
                                    id="commentField"
                                    allowClear
                                  />
                                </Form.Item>
                                <Form.Item>
                                  <Button
                                    key="post-comment"
                                    htmlType="submit"
                                    loading={postingComment}
                                    onClick={() => {
                                      this.handleCommentPost(
                                        activity.activityID,
                                        activity.regardingObjectID,
                                        activity.regardingObjectTypeCode,
                                        activity.subject,
                                        commentValue,
                                      );
                                      this.handleCommentChange({ target: { value: '' } }); // Clear the textarea
                                    }}
                                    type="dashed"
                                    icon={<DoubleLeftOutlined />}
                                  >
                                    {t('feed.postaCommento')}
                                  </Button>
                                </Form.Item>
                              </div>
                            }
                          />
                        </div>
                      </CustomPanel>
                    </Collapse>
                  ))}
              </div>
            </TableLayout.Content>
          </TableLayout>

          {/* {showFeed && (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 10 }}
                lg={{ span: 10 }}>
                <Card title={t('proggetiPage.notificeRiunioni')}>
                  <Row
                    gutter={{ lg: 24 }}
                    className="row-margin">
                    <Col
                      className="gutter-row "
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 12 }}>
                      <Search
                        placeholder={t('buttons.cerca')}
                        allowClear
                        onSearch={(value) => this.onFeedSearch(value, 'notifications')}
                      />
                    </Col>
                    <Col
                      className="gutter-row "
                      xs={{ span: 2 }}
                      sm={{ span: 2 }}
                      md={{ span: 2 }}
                      lg={{ span: 2 }}>
                      {sortDatesLatest && (
                        <Button
                          type="text"
                          icon={<SortDescendingOutlined />}
                          onClick={() => this.onFeedSortDateOldest('notifications')}
                        />
                      )}
                      {!sortDatesLatest && (
                        <Button
                          type="text"
                          icon={<SortAscendingOutlined />}
                          onClick={() => this.onFeedSortDateLatest('notifications')}
                        />
                      )}
                    </Col>
                    <Col
                      className="gutter-row"
                      xs={{ span: 20 }}
                      sm={{ span: 20 }}
                      md={{ span: 20 }}
                      lg={{ span: 10 }}>
                      <Select
                        showSearch={false}
                        maxTagCount="responsive"
                        mode="multiple"
                        style={{ width: '100%' }}
                        allowClear
                        placeholder={t('general.categoria')}
                        onChange={this.onFeedFilter}>
                        {feedFilterData &&
                          feedFilterData.map((item) => (
                            <Option
                              value={item.objectCodeListID}
                              key={item.objectCodeListID}>
                              {item.description}
                            </Option>
                          ))}
                      </Select>
                    </Col>
                    <Divider style={{ margin: '10px 0px' }} />
                  </Row>

                  <Row
                    gutter={{ lg: 24 }}
                    className="row-margin">
                    <Col
                      className="gutter-row no-padding"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}>
                      <Space
                        direction="vertical"
                        className="commentCollapse">
                        {isDataLoading && <Loader2 />}
                        {(!feedList || feedList.length < 1) && <Empty />}
                        {feedList &&
                          feedList.map((activity, index) => (
                            <Collapse
                              collapsible="header"
                              activeKey={activeKey}
                              key={index}>
                              <CustomPanel
                                onClick={() => this.getFeedActivity(activity.activityID)}
                                onLikeFeed={() => this.likeFeed(activity.activityID)}
                                key={activity.activityID}
                                acivityData={activity}
                                hasUserAccess={hasUserAccess}
                                toggleEditModal={() => this.toggleEditModal(activity)}
                                removeFeed={() =>
                                  this.removeFeed(activity.activityID, activity.activityType, activity.outlookEventId)
                                }
                                t={t}>
                                <div
                                  className="comment-section"
                                  key={index}
                                  id={activity.activityID}>
                                  {loadingActivities && <Loader2 />}
                                  {activityList &&
                                    activityList.length > 0 &&
                                    activityList.map((comment) => (
                                      <div key={comment.activityID}>
                                        <Comment
                                          author={comment.fullName}
                                          key={comment.activityID}
                                          avatar={
                                            <Tooltip title={comment.fullName}>
                                              <Avatar size={'small'}>{comment.initials}</Avatar>
                                            </Tooltip>
                                          }
                                          content={
                                            <Paragraph
                                              ellipsis={
                                                ellipsis ? { rows: 1, expandable: true, symbol: 'more' } : false
                                              }
                                              style={{ fontSize: 12 }}>
                                              {comment.description}
                                            </Paragraph>
                                          }
                                          datetime={
                                            <span>
                                              {comment.createdOn
                                                ? moment(comment.createdOn).format('DD/MM/YYYY HH:mm')
                                                : ''}
                                            </span>
                                          }
                                        />
                                        <Divider style={{ margin: '2px 0px' }} />
                                      </div>
                                    ))}
                                  <Divider style={{ margin: '2px 0px' }} />
                                  <Comment
                                    avatar={
                                      <Tooltip title={userData.fullName}>
                                        <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                                          {userData.userInitials}
                                        </Avatar>
                                      </Tooltip>
                                    }
                                    content={
                                      <div>
                                        <Form.Item>
                                          <Input.TextArea
                                            rows={3}
                                            onChange={(e) => this.handleCommentChange(e)}
                                            value={commentValue}
                                            id="commentField"
                                            allowClear
                                          />
                                        </Form.Item>
                                        <Form.Item>
                                          <Button
                                            key="post-comment"
                                            htmlType="submit"
                                            loading={postingComment}
                                            onClick={() => {
                                              this.handleCommentPost(
                                                activity.activityID,
                                                activity.regardingObjectID,
                                                activity.regardingObjectTypeCode,
                                                activity.subject,
                                                commentValue,
                                              );
                                              this.handleCommentChange({ target: { value: '' } }); // Clear the textarea
                                            }}
                                            type="dashed"
                                            icon={<DoubleLeftOutlined />}>
                                            {t('feed.postaCommento')}
                                          </Button>
                                        </Form.Item>
                                      </div>
                                    }
                                  />
                                </div>
                              </CustomPanel>
                            </Collapse>
                          ))}
                      </Space>
                    </Col>
                  </Row>
                </Card>
              </Col>
            )} */}
        </>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(ProjectFeed));
