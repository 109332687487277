import { Form } from 'antd';
import { type FC, useEffect, useState } from 'react';

import { Card, Col, DatePicker, Input, InputNumber, Modal, Row, Select, TimePicker } from 'antd';
import moment from 'moment';
import CommonService from '../../../../services/commonService';
import ModalFooterButtons from '../../../shared/components/modal/modalFooterButtons';
import { formItemLayout, formatOfDate, formatOfHHMM, statusPickListCodes } from '../../../shared/utils/constants';
import { handleDateConvert, handleTimeConvert } from '../../../shared/utils/functions';
import { requiredFields } from '../../../shared/utils/notifyMessages';
import type {
  GetCommitteeSessionsResponseDto,
  InsertUpdateCommitteeSessionRequestDto,
  StatusPicklistResponseDto,
} from 'src/connectors/backend';
import { useTranslation } from 'react-i18next';
import dayjs, { type Dayjs } from 'dayjs';
import { useAppSelector } from 'src/redux/store';

const { Option } = Select;

type FormDataType = {
  codice: string;
  date: Dayjs;
  duration: string;
  startTime: Dayjs;
  state: number;
};

const UpdateSessionModal: FC<{
  loadingSave: boolean;
  showModal: boolean;
  handleModalClose: () => void;
  updateSession: (values: InsertUpdateCommitteeSessionRequestDto) => void;
  sessionData: GetCommitteeSessionsResponseDto;
  committeeID: string;
}> = ({ loadingSave, showModal, handleModalClose, updateSession, sessionData, committeeID }) => {
  console.log(sessionData);
  const { t } = useTranslation();
  const activeXmatrixInfo = useAppSelector((state) => state.activeXMatrix.activeXMatrix);

  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [endTime, setEndTime] = useState<string>();
  const [duration, setDuration] = useState(sessionData.duration);
  const [sessionStatusList, setSessionStatusList] = useState<StatusPicklistResponseDto[]>([]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    void retrieveStatus();
    onStartTimeChange(dayjs(sessionData.startTime, formatOfHHMM), sessionData.duration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const retrieveStatus = async () => {
    const objectCode = statusPickListCodes.sessionStatus;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success && resp.responseObject?.value) {
          setSessionStatusList(resp.responseObject.value);
        }
      })
      .catch(() => {});
  };

  const saveData = (values: FormDataType) => {
    updateSession({
      codice: values.codice,
      committeeID,
      date: handleDateConvert(values.date) as string,
      duration: sessionData.duration,
      endTime: endTime as string,
      sessionID: sessionData.sessionID,
      startTime: handleTimeConvert(values.startTime) as string,
      state: values.state,
      xMatrixID: activeXmatrixInfo?.xMatrixID as string,
    });
  };

  function formatDuration(totalMinutes: number) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.round(totalMinutes % 60);

    if (hours === 0) {
      return `${minutes} min`;
    }
    if (minutes === 0) {
      return `${hours} h`;
    }
    return `${hours} h ${minutes} min`;
  }

  const onStartTimeChange = (startTime: Dayjs, durationInMinutes: number) => {
    if (!startTime) {
      return;
    }

    if (!durationInMinutes) {
      setEndTime(startTime.format(formatOfHHMM));
      return;
    }

    const endMoment = startTime.clone().add(durationInMinutes, 'minutes');

    setEndTime(endMoment.format(formatOfHHMM));
    setDuration(durationInMinutes);
  };

  return (
    <>
      <Modal
        width={800}
        title={t('comitatiPage.editSession')}
        open={showModal}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={handleModalClose}
        footer={[
          <ModalFooterButtons
            disabled={!fieldsChanged}
            loadingButton={loadingSave}
            modalClose={handleModalClose}
            formId={'updateSession'}
            key="updateSession"
          />,
        ]}
      >
        <Card>
          <Form<FormDataType>
            id="updateSession"
            layout="vertical"
            {...formItemLayout}
            onFinish={(values) => saveData(values)}
            onValuesChange={() => {
              setFieldsChanged(true);
            }}
          >
            <Row
              gutter={{ lg: 24 }}
              className="row-margin"
            >
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <Form.Item<FormDataType>
                  label={t('proggetiPage.codice')}
                  name="codice"
                  hasFeedback
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={sessionData.codice}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item<FormDataType>
                  label={t('comitatiPage.date')}
                  name="date"
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={sessionData.date ? moment(sessionData.date) : sessionData.date}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    format={formatOfDate}
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Form.Item<FormDataType>
                  labelAlign="left"
                  label={t('general.stato')}
                  name="state"
                  hasFeedback
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={sessionData.stateID}
                >
                  <Select
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}
                  >
                    {sessionStatusList.map((item) => (
                      <Option
                        value={item.statusID}
                        key={item.statusID}
                      >
                        {item.statusDescription}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item<FormDataType>
                  label={t('comitatiPage.startTime')}
                  name="startTime"
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={
                    sessionData.startTime ? moment(sessionData.startTime, formatOfHHMM) : sessionData.startTime
                  }
                >
                  <TimePicker
                    allowClear={false}
                    placeholder={t('comitatiPage.startTime')}
                    style={{ width: '100%' }}
                    format={formatOfHHMM}
                    onChange={(value) => onStartTimeChange(value, duration)}
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 8 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item<FormDataType>
                  label={`${t('proggetiPage.durata')} (h/min)`}
                  name="duration"
                  initialValue={formatDuration(sessionData.duration)}
                >
                  <InputNumber
                    disabled={true}
                    style={{ width: '100%' }}
                    min={0}
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 8 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item label={t('comitatiPage.endTime')}>
                  <Input
                    value={endTime}
                    placeholder={t('comitatiPage.endTime')}
                    style={{ width: '100%' }}
                    disabled={true}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Modal>
    </>
  );
};
export default UpdateSessionModal;
