import classNames from 'classnames';
import type React from 'react';
import { useFullscreen } from 'src/hooks/FullScreen';
import './baseLayout.scss';
import AdminNavbar from './header/navbar';

interface AppLayoutProps {
  children: React.ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const { isFullscreen } = useFullscreen();

  return (
    <div
      className={classNames('tw-h-screen tw-absolute tw-inset-0 tw-overflow-hidden tw-flex tw-flex-col', {
        '[&_>.ant-layout-header]:tw-hidden': isFullscreen,
      })}
    >
      <AdminNavbar />
      <div className="tw-relative tw-overflow-y-auto tw-grow tw-w-full">{children}</div>
    </div>
  );
};

export default AppLayout;
