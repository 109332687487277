import React, { useState } from 'react';
import Text, { TextProps } from 'antd/es/typography/Text';
import { Tooltip } from 'antd';
import { EllipsisConfig } from 'antd/es/typography/Base';

type TooltipTruncatedProps = Omit<TextProps, 'ellipsis'> & {
  ellipsis?: EllipsisConfig;
};

const TooltipTruncated: React.FC<TooltipTruncatedProps> = ({ children, ellipsis, ...props }) => {
  const [truncated, setTruncated] = useState(false);

  return (
    <Tooltip title={truncated ? children : undefined}>
      <Text
        {...props}
        ellipsis={{ ...ellipsis, onEllipsis: setTruncated }}>
        {/* NOTE: Fragment is necessary to avoid showing the title */}
        <>{children}</>
      </Text>
    </Tooltip>
  );
};

export default TooltipTruncated;
