import { type ChangeEvent, useEffect, useState } from 'react';

import { Button, Form, Input, InputNumber, Select, Switch, message } from 'antd';
import type { Rule } from 'antd/es/form';
import { endOfDay, isAfter, isBefore } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  CommitteeType,
  type CreateProjectRequest,
  type GetCommitteePicklistDto,
  type GetDivisionPicklistDto,
  type GetProjectCreationPicklistResponseDto,
  type GetProjectResponseDto,
  type ProjectStatus,
  ProjectType,
  type StatusPicklistResponseDto,
} from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import CommonService from '../../services/commonService';
import DashboardService from '../../services/pages/dashboardService';
import ProgettiService from '../../services/pages/projectServices/progettiService';
import UserSelectDropDown from '../shared/components/userSelect/userSelect';
import { DatePickerWithDateFns, projectStatusAsNumber, statusPickListCodes } from '../shared/utils/constants';
import {
  areDatesValid,
  handleDateConvert,
  showErrorNotification,
  updateNotificationBell,
  validateProjectCode,
} from '../shared/utils/functions';
import { notifyMessages, requiredFields } from '../shared/utils/notifyMessages';
import { useHistory } from 'react-router-dom';

const { Option } = Select;
const { TextArea } = Input;

interface CreateProjectRequestDto {
  name: string;
  projectCode: string;
  parentProjectID: string;
  status: number;
  budget: number;
  teamLeaderID: string;
  sponsorID: string;
  committeeID: string;
  divisionID: string;
  startDate: string;
  endDate: string;
  hasExternalReview: boolean;
  type: boolean;
  projectGoal: string;
}

const MAX_PROJECT_CODE_LENGTH = 10;

const AddNewProjectPage = () => {
  const { t } = useTranslation();
  const { xmid } = useParams();
  const activeXMatrix = useAppSelector((state) => state.activeXMatrix.activeXMatrix);
  const [form] = Form.useForm();
  const history = useHistory();

  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [statusList, setStatusList] = useState<StatusPicklistResponseDto[]>([]);
  const [committeesPicklist, setCommitteesPicklist] = useState<GetCommitteePicklistDto[]>([]);
  const [parentProjectList, setParentProjectList] = useState<GetProjectCreationPicklistResponseDto[]>([]);
  const [divisionsList, setDivisionsList] = useState<GetDivisionPicklistDto[]>([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [isMandatory, setIsMandatory] = useState(false);
  const [preDefinedProjectCode, setPreDefinedProjectCode] = useState<string>();
  const [projectCodeValue, setProjectCodeValue] = useState<string>();
  const [selectedProjectData, setSelectedProjectData] = useState<GetProjectResponseDto>();
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [projectType, setProjectType] = useState<ProjectType>(ProjectType.NonOperational);
  const [invalidCode, setInvalidCode] = useState(true);

  useEffect(() => {
    void retrieveStatus();
    void fetchCommittees();
    void getParentProjects();
    retrieveDivisions();
  }, [xmid]);

  useEffect(() => {
    if (projectType) {
      void fetchCommittees();
    }
  }, [projectType]);

  const getParentProjects = async () => {
    try {
      const { data } = await ProgettiService.getParentProjects();

      if (data.success) {
        setParentProjectList(data.responseObject?.value as GetProjectCreationPicklistResponseDto[]);
      }
    } catch { }
  };

  const retrieveDivisions = async () => {
    try {
      const { data } = await DashboardService.getDivisionFilterData();

      if (data.success) {
        setDivisionsList(data.responseObject?.value as GetDivisionPicklistDto[]);
      }
    } catch { }
  };

  const retrieveStatus = async () => {
    const objectCode = statusPickListCodes.statusProjects;

    try {
      const { data } = await CommonService.getStatusData(objectCode);

      if (data.success) {
        setStatusList(data.responseObject?.value as StatusPicklistResponseDto[]);
      }
    } catch { }
  };

  const fetchCommittees = async () => {
    try {

      const committeeType = projectType === ProjectType.Operational ? CommitteeType.Operational : CommitteeType.NonOperational;
      const { data } = await CommonService.getCommitiePicklist(committeeType);

      if (data.success) {
        setCommitteesPicklist(data.responseObject?.value as GetCommitteePicklistDto[]);
      }
    } catch { }
  };

  const onProjectClear = () => {
    setPreDefinedProjectCode(undefined);
    setSelectedProjectData(undefined);
  };

  const disableBefore = (current: Date, start: Date) => {
    return isBefore(endOfDay(current), endOfDay(start));
  };

  const disableAfter = (current: Date, end: Date) => {
    return isAfter(endOfDay(current), endOfDay(end));
  };

  const getProjectData = async (id: string) => {
    try {
      const response = await ProgettiService.getProgettiByiD(id);
      const resp = response.data;

      if (resp.success) {
        setSelectedProjectData(resp.responseObject?.value as GetProjectResponseDto);
      }
    } catch { }
  };

  const onProjectSelect = (selectedProjectId: string) => {
    const selectedProject = parentProjectList.find((project) => project.projectID === selectedProjectId);
    const selectedProjectCode = selectedProject?.projectCode ?? undefined;

    void getProjectData(selectedProjectId);
    setPreDefinedProjectCode(selectedProjectCode);
  };

  const onProjectCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProjectCodeValue(e.target.value);
  };

  const onStatusChange = (value: number, option: any) => {
    setIsMandatory(option.children === 'Attivo');
  };

  const invertObject = <T extends Record<string, string | number>>(obj: T) =>
    Object.fromEntries(Object.entries(obj).map(([key, value]) => [String(value), key]));

  const addData = async (values: CreateProjectRequestDto) => {
    const PROJECTS_ROUTE = '/progetti';

    const payload: CreateProjectRequest = {
      code: preDefinedProjectCode ? preDefinedProjectCode + '-' + values.projectCode : values.projectCode,
      name: values.name,
      xMatrixId: projectType === ProjectType.NonOperational ? activeXMatrix?.xMatrixID : undefined,
      startDate: handleDateConvert(values.startDate) as string,
      endDate: handleDateConvert(values.endDate) as string,
      type: projectType,
      status: invertObject(projectStatusAsNumber)[values.status] as ProjectStatus,
      teamLeaderId: values.teamLeaderID,
      sponsorId: values.sponsorID,
      commiteeId: values.committeeID,
      divisionId: values.divisionID,
      parentProjectId: values.parentProjectID,
      hasExternalReview: values.hasExternalReview,
      fastCreation: false,
      secondaryProject: false,
      notes: values.projectGoal,
    };

    setLoadingButton(true);

    try {
      const { data } = await ProgettiService.createProject(payload);

      if (data.success) {
        updateNotificationBell();
        message.success(notifyMessages.addSuccess);
        history.push(PROJECTS_ROUTE);
      } else {
        message.error(notifyMessages.addFailed);
      }
    } catch {
      message.error(notifyMessages.addFailed);
    } finally {
      setLoadingButton(false);
    }
  };

  const checkValidityBeforeSave = (values: CreateProjectRequestDto) => {
    if (!areDatesValid(values.startDate, values.endDate)) {
      showErrorNotification(`${t('notifications.generalError')}`, requiredFields.validateEndDate);
    }

    void addData(values);
  };

  return (
    <div className="edit-obietivo-anno-wrapper">
      <>
        <div className="site-card-wrapper tw-justify-between">
          <span>{t('proggetiPage.aggiungiNuovoProgetto')}</span>

          <div className='tw-flex tw-gap-x-3'>
            <Button
              loading={loadingButton}
              onClick={() => history.go(-1)}
              type="default">
              {t('buttons.annulla')}
            </Button>
            <Button
              loading={loadingButton}
              onClick={() => form.submit()}
              type="primary"
              htmlType="submit"
              disabled={!fieldsChanged}>
              {t('buttons.create')}
            </Button>
          </div>
        </div>

        <div className='tw-p-12'>
          <h1 className='tw-mb-6 tw-text-3xl tw-font-medium'>{t('proggetiPage.projectDetails')}</h1>

          <Form<CreateProjectRequestDto>
            name="add_ob_project"
            form={form}
            onFinish={checkValidityBeforeSave}
            onValuesChange={() => setFieldsChanged(true)}
            layout="vertical">
            <div className='tw-flex tw-gap-x-12'>

              <Form.Item<CreateProjectRequestDto>
                label={t('proggetiPage.isOperative')}
                name="type"
                initialValue={false}>
                <Switch onChange={(checked) => setProjectType(checked ? ProjectType.Operational : ProjectType.NonOperational)} />
              </Form.Item>
              <Form.Item<CreateProjectRequestDto>
                className='tw-grow'
                label={t('proggetiPage.nomeProgetto')}
                name="name"
                rules={[{ required: true, message: requiredFields.required }]}>
                <Input />
              </Form.Item>
            </div>

            <div className="tw-grid tw-gap-x-12 tw-grid-cols-2">
              <Form.Item<CreateProjectRequestDto>
                extra={t('proggetiPage.codiceLungeza')}
                label={t('proggetiPage.codiceProgetto')}
                name="projectCode"
                rules={[
                  { required: true, message: requiredFields.required },
                  { max: MAX_PROJECT_CODE_LENGTH, message: `${t('proggetiPage.codiceLungeza')}` },
                  {
                    validator: validateProjectCode,
                  },
                ]}
              >
                <Input
                  addonBefore={preDefinedProjectCode ? `${preDefinedProjectCode}-` : ''}
                  value={projectCodeValue}
                  onChange={(e) => onProjectCodeChange(e)}
                  maxLength={MAX_PROJECT_CODE_LENGTH}
                />
              </Form.Item>

              <Form.Item<CreateProjectRequestDto>
                label={t('proggetiPage.progettoPrincipale')}
                name="parentProjectID"
                hasFeedback>
                <Select
                  allowClear
                  showSearch
                  filterOption={(value, option) => {
                    return option?.label.toLowerCase().includes(value.toLowerCase()) ?? false;
                  }}
                  style={{ width: '100%' }}
                  placeholder={t('general.seleziona')}
                  onSelect={(option) => onProjectSelect(option.value)}
                  onClear={() => onProjectClear()}
                  options={parentProjectList.map((p) => {
                    return {
                      value: p.projectID,
                      label: `${p.name} ${p.projectCode}`
                    };
                  })}>
                </Select>
              </Form.Item>
            </div>

            <div className='tw-grid tw-gap-x-12 tw-grid-cols-4'>
              <Form.Item<CreateProjectRequestDto>
                label={t('general.stato')}
                name="status"
                hasFeedback
                rules={[{ required: true, message: requiredFields.required }]}
                initialValue={projectStatusAsNumber.draft}>
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={t('general.seleziona')}
                  onChange={(value, option) => onStatusChange(value, option)}>
                  {statusList.map((item) => (
                    <Option
                      value={item.statusID}
                      key={item.statusID}>
                      {item.statusDescription}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item<CreateProjectRequestDto>
                labelAlign="left"
                label={t('general.budget')}
                name="budget">
                <InputNumber className='tw-w-full' min={0} />
              </Form.Item>

              <UserSelectDropDown
                allowClear={true}
                fieldLabel={t('general.teamLeader')}
                formName={'teamLeaderID'}
                required={isMandatory}
                initValue={undefined} />

              <UserSelectDropDown
                allowClear={true}
                fieldLabel={t('general.sponsor')}
                formName={'sponsorID'}
                required={isMandatory}
                initValue={undefined} />
            </div>

            <div className="tw-grid tw-gap-x-12 tw-grid-cols-4">
              <Form.Item<CreateProjectRequestDto>
                label={t('general.comitato')}
                name="committeeID"
                hasFeedback>
                <Select
                  showSearch
                  allowClear
                  filterOption={(value, option) => {
                    return option?.label.toLowerCase().includes(value.toLowerCase()) ?? false;
                  }}
                  style={{ width: '100%' }}
                  placeholder={t('general.seleziona')}
                  options={committeesPicklist.map((committee) => {
                    return {
                      disabled: committee.hasAccess !== 1,
                      value: committee.committeeID,
                      label: `${committee.name}`
                    };
                  })}>
                </Select>
              </Form.Item>

              <Form.Item<CreateProjectRequestDto>
                labelAlign="left"
                label={t('general.divisione')}
                name="divisionID"
                hasFeedback>
                <Select
                  showSearch
                  allowClear
                  filterOption={(value, option) => {
                    return option?.label.toLowerCase().includes(value.toLowerCase()) ?? false;
                  }}
                  style={{ width: '100%' }}
                  placeholder={t('general.seleziona')}
                  options={divisionsList.map((division) => {
                    return {
                      disabled: division.hasAccess !== 1,
                      value: division.divisionID,
                      label: `${division.divisionName}`
                    };
                  })}>
                </Select>
              </Form.Item>

              <Form.Item<CreateProjectRequestDto>
                label={t('general.start')}
                name="startDate"
                rules={[{ required: true, message: requiredFields.required }]}>
                <DatePickerWithDateFns
                  style={{ width: '100%' }}
                  format="DD/MM/YYYY"
                  disabledDate={(current) => !!endDate && disableAfter(current, endDate)}
                  onChange={(current) => setStartDate(current)}
                />
              </Form.Item>

              <Form.Item<CreateProjectRequestDto>
                label={t('general.end')}
                name="endDate"
                rules={[{ required: true, message: requiredFields.required }]}>
                <DatePickerWithDateFns
                  style={{ width: '100%' }}
                  format="DD/MM/YYYY"
                  disabledDate={(current) => !startDate || disableBefore(current, startDate)}
                  onChange={(current) => setEndDate(current)}
                />
              </Form.Item>
            </div>

            <div className='tw-w-full'>
              <Form.Item<CreateProjectRequestDto>
                label={t('proggetiPage.revisioneEsterna')}
                name="hasExternalReview"
                initialValue={false}>
                <Switch />
              </Form.Item>
            </div>

            <div className='tw-w-full'>
              <Form.Item<CreateProjectRequestDto>
                label={t('proggetiPage.obiettivoProgetto')}
                name="projectGoal"
                initialValue="">
                <TextArea rows={4} />
              </Form.Item>
            </div>
          </Form>
        </div>
      </>
    </div>
  );
};

export default AddNewProjectPage;
