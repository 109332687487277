import classNames from 'classnames';
import type { FC } from 'react';

export interface ProjectSectionTitleProps {
  isPresentationMode?: boolean;
  title: string;
}

const ProjectSectionTitle: FC<ProjectSectionTitleProps> = ({ isPresentationMode, title }) => {
  return (
    <h1
      className={classNames([
        'tw-text-3xl tw-font-semibold',
        {
          'tw-mb-4': isPresentationMode,
        },
      ])}
    >
      {title}
    </h1>
  );
};

export default ProjectSectionTitle;
