import { Button, message } from 'antd';
import BraftEditor, { type EditorState } from 'braft-editor';
import 'braft-editor/dist/index.css';
import classNames from 'classnames';
import type React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getProjectById } from 'src/redux/slices/projectSlice';
import { useAppSelector } from 'src/redux/store';
import ProgettiService from '../../../services/pages/projectServices/progettiService';
import { RichTextEditorControls } from '../../shared/utils/constants';
import { notifyMessages, requiredFields } from '../../shared/utils/notifyMessages';

interface Props {
  isPresentationMode: boolean;
  isEditable: boolean;
}

const ProjectHanseiComment: React.FC<Props> = ({ isPresentationMode, isEditable }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const project = useAppSelector((state) => state.project.currentProject);

  const [editorContent, setEditorContent] = useState<EditorState>();
  const [isTouched, setIsTouched] = useState(false);
  const [loadingEditorButton, setLoadingEditorButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (project) {
      setEditorContent(BraftEditor.createEditorState(project.hanseiLearnedLessons));
    }
  }, [project]);

  const handleEditorChange = (editorState: any) => {
    setEditorContent(editorState);
    setIsTouched(true);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!editorContent) {
      setErrorMessage(requiredFields.required);
      return;
    }

    if (!id) {
      return;
    }

    setLoadingEditorButton(true);

    try {
      await ProgettiService.updateEditorField({
        projectID: id,
        projectFieldName: 'hanseiLearnedLessons',
        projectFieldValue: editorContent.toHTML(),
      });
      message.success(notifyMessages.updateSuccess);
      setIsTouched(false);
      setErrorMessage('');
      if (id) {
        dispatch(getProjectById({ projectId: id }));
      }
    } catch {
      message.error(notifyMessages.updateFailed);
    } finally {
      setLoadingEditorButton(false);
    }
  };

  return (
    <div className="tw-h-full">
      {project && (
        <form
          className="tw-h-full tw-flex tw-flex-col"
          data-testid="hanseiForm"
          id="editorForm"
          onSubmit={handleSubmit}
        >
          <h1
            className={classNames('tw-text-xl tw-mb-2 tw-font-semibold', {
              '!tw-text-3xl !tw-mb-4': isPresentationMode,
            })}
          >
            {t('proggetiPage.henseiLezioniImparate')}
          </h1>

          <div className="tw-rounded-lg tw-grow tw-overflow-hidden">
            <BraftEditor
              value={editorContent}
              language="en"
              readOnly={isPresentationMode || !isEditable}
              onChange={handleEditorChange}
              controls={isPresentationMode ? [] : RichTextEditorControls}
              id="editor-text"
              className={classNames({
                '[&_.bf-content]:tw-h-full tw-min-h-full [&_.bf-content]:tw-text-3xl': isPresentationMode,
              })}
            />
            {errorMessage && <p className="tw-text-red-500 tw-mt-2">{errorMessage}</p>}
          </div>

          {!isPresentationMode && (
            <div
              key="buttons"
              className="tw-ml-auto tw-w-fit tw-mt-4"
            >
              <Button
                type="primary"
                htmlType="submit"
                disabled={!isTouched}
                loading={loadingEditorButton}
              >
                {t('buttons.salva')}
              </Button>
            </div>
          )}
        </form>
      )}
    </div>
  );
};

export default ProjectHanseiComment;
