import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip, Typography, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { type FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UploadKpiSpecifico from 'src/components/kpiListPage/uploadKpiSpecifico';
import EditProjectKpi from 'src/components/projects/projectForm/deliverablesAndKpi/editKpi';
import NewProjectKpi from 'src/components/projects/projectForm/deliverablesAndKpi/newKpi';
import ProjectSectionTitle from 'src/components/shared/components/ProjectSectionTitle/ProjectSectionTitle';
import TableLayout from 'src/components/shared/tableLayout';
import { userHasAccessProjectFields } from 'src/components/shared/utils/authRolesProvilege/authRolesPrivilege';
import { projectStatusAsNumber } from 'src/components/shared/utils/constants';
import { italianNumberFormat, kpiSemaphoreColor } from 'src/components/shared/utils/functions';
import { notifyMessages } from 'src/components/shared/utils/notifyMessages';
import type {
  GetKPIByProjectResponseDto,
  GetProjectMembersResponseDto,
  GetProjectResponseDto,
} from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import DeliverableService from 'src/services/pages/projectServices/deliverableServices';
import ProgettiService from 'src/services/pages/projectServices/progettiService';

const { Text } = Typography;

export interface ProjectTargetIndicatorsProps {
  project: GetProjectResponseDto;
  isPresentationMode: boolean;
  hasUserAccess: boolean;
}

const ProjectTargetIndicators: FC<ProjectTargetIndicatorsProps> = ({ project, isPresentationMode, hasUserAccess }) => {
  return (
    <IndicatoriTarget
      currentProject={project}
      isPresentationMode={isPresentationMode}
      hasUserAccess={hasUserAccess}
    />
  );
};

export default ProjectTargetIndicators;

interface IndicatoriTargetProps {
  currentProject: GetProjectResponseDto;
  isPresentationMode: boolean;
  hasUserAccess: boolean;
}

const IndicatoriTarget: React.FC<IndicatoriTargetProps> = ({ currentProject, hasUserAccess, isPresentationMode }) => {
  const [kpiList, setKpiList] = useState<GetKPIByProjectResponseDto[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [showNewKpiModal, setShowNewKpiModal] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [showEditKpiModal, setShowEditKpiModal] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<GetKPIByProjectResponseDto | null>(null);
  const [showUploadKpiModal, setShowUploadKpiModal] = useState<boolean>(false);
  const [projectMembers, setProjectMembers] = useState<GetProjectMembersResponseDto[]>([]);
  const userData = useAppSelector((state) => state.userData.userData);

  const { t } = useTranslation();

  useEffect(() => {
    if (currentProject?.projectID) {
      retrieveKPIList();
    }
  }, [currentProject]);

  useEffect(() => {
    if (showEditKpiModal) {
      getProjectMembers();
    }
  }, [showEditKpiModal]);

  const getProjectMembers = () => {
    if (!currentProject.projectID) {
      return;
    }
    setLoadingData(true);
    ProgettiService.getProjectTeamMembers(currentProject.projectID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setProjectMembers(resp.responseObject?.value || []);
          setLoadingData(false);
        } else {
          setLoadingData(false);
        }
      })
      .catch((error) => {
        setLoadingData(false);
      });
  };

  const retrieveKPIList = () => {
    if (!currentProject.projectID) {
      return;
    }

    setLoadingData(true);
    DeliverableService.getProjectKpi(currentProject.projectID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const respData = resp.responseObject?.value;

          setKpiList(respData || []);
          setLoadingData(false);
        } else {
          setLoadingData(false);
        }
      })
      .catch((error) => {
        setLoadingData(false);
      });
  };

  const toggleEditKpiModal = (rowData: GetKPIByProjectResponseDto) => {
    setSelectedRow(rowData);
    setShowEditKpiModal(!showEditKpiModal);
  };

  // const toggleUploadKpiSpecificoModal = (rowData: GetKPIByProjectResponseDto) => {
  //   setShowUploadKpiModal(!showUploadKpiModal);
  //   setSelectedRow(rowData);
  // };

  // const removeKpi = (kpiProjectRelationShipID: string | null | undefined) => {
  //   if (!kpiProjectRelationShipID) {
  //     message.error((notifyMessages as any).deleteFailed);
  //     return;
  //   }

  //   DeliverableService.removeKpi(kpiProjectRelationShipID)
  //     .then((response) => response.data)
  //     .then((resp) => {
  //       if (resp.success) {
  //         message.success((notifyMessages as any).deleteSuccess);
  //         retrieveKPIList();
  //       } else {
  //         message.error((notifyMessages as any).deleteFailed);
  //       }
  //     })
  //     .catch((error) => {
  //       message.error((notifyMessages as any).deleteFailed);
  //     });
  // };

  const updateBowlingChart = (payload: any) => {
    DeliverableService.updateKpiBowlingChart(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          setShowEditKpiModal(false);
          setSelectedRow(null);
          retrieveKPIList();
        } else {
          message.error(notifyMessages.updateFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
      });
  };

  const kpiColumns: ColumnsType<GetKPIByProjectResponseDto> = [
    {
      title: `${t('general.nome')}`,
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      className: 'twoRowEllipses',
      render: (text, record) => {
        return {
          props: {
            style: { borderLeft: `5px solid  ${kpiSemaphoreColor(record.semaforo)}` },
          },
          children: (
            <div>
              {record.showInA3 === true && (
                <Tooltip title={text}>
                  <Text strong>{record.name}</Text>{' '}
                </Tooltip>
              )}

              {record.showInA3 !== true && (
                <Tooltip title={text}>
                  <Text>{record.name}</Text>
                </Tooltip>
              )}
            </div>
          ),
        };
      },
    },

    {
      title: `${t('general.jumpOffPoint')}`,
      dataIndex: 'jumpOffPoint',
      key: 'jumpOffPoint',
      ellipsis: {
        showTitle: false,
      },
      render: (text: string, record) => <Text>{italianNumberFormat(record.jumpOffPoint)}</Text>,
    },
    {
      title: `${t('kpiPage.finalTarget')}`,
      dataIndex: 'target',
      key: 'target',
      ellipsis: {
        showTitle: false,
      },
      render: (text: string, record) => <Text>{italianNumberFormat(record.target)}</Text>,
    },
    {
      title: `${t('general.updateYtd')}`,
      dataIndex: 'updateYTD',
      key: 'updateYTD',
      ellipsis: {
        showTitle: false,
      },
      render: (text: string, record) => <Text>{italianNumberFormat(record.updateYTD)}</Text>,
    },
    {
      title: `${t('general.targetYtd')}`,
      dataIndex: 'targetYTD',
      key: 'targetYTD',
      ellipsis: {
        showTitle: false,
      },
      render: (text: string, record) => <Text>{italianNumberFormat(record.targetYTD)}</Text>,
    },
    {
      key: 'action',
      width: '50px',
      render: (_: string, record) => {
        const hasUserAccess =
          currentProject && userData
            ? // biome-ignore lint/complexity/noUselessTernary: <explanation>
              !userHasAccessProjectFields(userData, currentProject.teamLeaderID, currentProject.sponsorID) &&
              currentProject.status === projectStatusAsNumber.completed
              ? true
              : false
            : null;

        return (
          <>
            <div className="tw-flex tw-items-center tw-gap-2">
              <Button
                icon={<EditOutlined />}
                onClick={() => toggleEditKpiModal(record)}
              />
              {/* <Popconfirm
              placement="topRight"
              title={t('proggetiPage.rimuoveProgettoKpi')}
              onConfirm={() => removeKpi(record.kpiProjectRelationShipID)}
              okText={t('general.si')}
              cancelText={t('general.no')}>
              <Button icon={<DeleteOutlined />}></Button>
            </Popconfirm> */}

              {/* <Tooltip placement="top">
              <Button
                onClick={() => toggleUploadKpiSpecificoModal(record)}
                icon={<UploadOutlined />}></Button>
            </Tooltip> */}
            </div>
          </>
        );
      },
    },
  ];

  const handleNewKpiModalClose = () => {
    setShowNewKpiModal(false);
    setLoadingButton(false);
  };

  const toggleNewKpiModal = () => {
    setShowNewKpiModal(true);
  };

  const addProjectKpi = async (values: { kpiId: string }) => {
    setLoadingButton(true);

    try {
      const response = await DeliverableService.insertKpiProject({
        kpiDetailID: values.kpiId,
        projectID: currentProject.projectID ?? '',
      });

      const resp = response.data;

      if (resp.success) {
        message.success(notifyMessages.addSuccess);
        handleNewKpiModalClose();
        retrieveKPIList();
      } else {
        message.error(notifyMessages.addFailed);
      }
    } catch (error) {
      message.error(notifyMessages.addFailed);
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <>
      {showEditKpiModal && (
        <EditProjectKpi
          showEditKpiModal={showEditKpiModal}
          handleEditKpiModalClose={() => {
            setShowEditKpiModal(false);
            setSelectedRow(null);
          }}
          handleUpdateBowlingChart={updateBowlingChart}
          projectId={currentProject.projectID}
          kpiRowData={selectedRow}
          projectTeamMembers={projectMembers}
          currentProject={currentProject}
        />
      )}

      {showUploadKpiModal && (
        <UploadKpiSpecifico
          showUploadKpiModal={showUploadKpiModal}
          handleUploadKpiModalClose={() => {
            setShowUploadKpiModal(false);
            setSelectedRow(null);
          }}
          projectId={currentProject.projectID}
          kpiRowData={selectedRow}
          projectName={currentProject.name}
        />
      )}

      {!isPresentationMode && showNewKpiModal && (
        <NewProjectKpi
          showNewKpiModal={showNewKpiModal}
          handleNewKpiModalClose={handleNewKpiModalClose}
          handleKpiModalSave={addProjectKpi}
          loadingButton={loadingButton}
          projectId={currentProject.projectID as string}
        />
      )}
      <TableLayout title={<ProjectSectionTitle title={t('general.kpiIndicators')} />}>
        <TableLayout.Actions>
          {!isPresentationMode && !hasUserAccess && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={toggleNewKpiModal}
            >
              {t('buttons.aggiungiNuovo')}
            </Button>
          )}
        </TableLayout.Actions>

        <TableLayout.Content>
          <Table
            columns={kpiColumns}
            dataSource={kpiList}
            pagination={false}
            size="small"
            loading={loadingData}
            rowKey={(obj) => obj.kpiDetailID || crypto.randomUUID()}
          />
        </TableLayout.Content>
      </TableLayout>
    </>
  );
};
