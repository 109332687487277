import React, {
  type Dispatch,
  type FC,
  type ReactElement,
  type SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { DeleteOutlined, MenuOutlined, SaveOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  type InputRef,
  Modal,
  Row,
  Space,
  Table,
  Transfer,
  Typography,
  notification,
} from 'antd';
import { arrayMoveImmutable } from 'array-move';
import BraftEditor from 'braft-editor';
import { useTranslation } from 'react-i18next';
import { SortableContainer, type SortableContainerProps, SortableElement, SortableHandle } from 'react-sortable-hoc';
import CommitieService from '../../services/pages/commitieServices';
import Loader2 from '../shared/components/loader2/loader2';
import { RichTextEditorControls } from '../shared/utils/constants';
import { generateNewGuid } from '../shared/utils/functions';
import type {
  AgendaDto,
  AgendaItemDto,
  CommitteeDto,
  GetCommitteeSessionsResponseDto,
  InsertUpdateCommitteeSessionRequestDto,
  ProjectDto,
  UpsertAgendaRequest,
} from 'src/connectors/backend';
import type { ColumnType } from 'antd/es/table';
import type { Key } from 'antd/es/table/interface';
import type { FormInstance } from 'antd/lib';
import dayjs from 'dayjs';
import { AgendaService } from 'src/services/pages/agendaService';
import { useAppSelector } from 'src/redux/store';

const { Panel } = Collapse;

const { TextArea } = Input;
const { Text, Title } = Typography;

const EditableContext = React.createContext<FormInstance<AgendaItemDto> | null>(null);

const EditableCell: FC<{
  title: string;
  editable: boolean;
  children: ReactElement<typeof Form.Item>[];
  dataIndex: keyof AgendaItemDto;
  record: AgendaItemDto;
  inputType: 'number' | 'text';
  handleSave: (row: AgendaItemDto) => void;
  handleFieldChange: () => null;
  handleFormErrors: (errors: unknown[]) => void;
  agendaRows: AgendaItemDto[];
  setAgendaRows: Dispatch<React.SetStateAction<AgendaItemDto[]>>;
}> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  inputType,
  handleSave,
  handleFieldChange,
  handleFormErrors,
  agendaRows,
  setAgendaRows,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext) as FormInstance<AgendaItemDto>;

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      handleFormErrors([]);
      toggleEdit();
      // Parse input time and duration
      const inputTime = values.input ? values.input : record.input;
      const durationMinutes = values.duration ?? record.duration ?? 0;
      const durationHours = durationMinutes / 60;

      if (inputTime && durationHours) {
        // Convert input time to minutes
        const [inputHours, inputMinutes] = inputTime.split(':').map(Number);
        const inputMinutesTotal = inputHours * 60 + inputMinutes;

        // Calculate output time in minutes
        let outputMinutesTotal = inputMinutesTotal + durationHours * 60;

        // Handle wrap-around to the next day
        if (outputMinutesTotal >= 1440) {
          outputMinutesTotal -= 1440; // Subtract 24 hours (1440 minutes)
        }

        // Convert output time back to HH:mm format
        const outputHours = Math.floor(outputMinutesTotal / 60);
        const outputMinutes = outputMinutesTotal % 60;
        const outputTime = `${String(outputHours).padStart(2, '0')}:${String(outputMinutes).padStart(2, '0')}`;
        // Update the "Output" field for the current row
        const updatedAgendaRows = agendaRows.map((item): AgendaItemDto => {
          if (item.id === record.id) {
            return {
              ...item,
              ...values,
              output: outputTime, // Update the "Output" field
            };
          }
          return item;
        });

        // Update the "Input" and "Output" fields for all rows below
        const currentIndex = updatedAgendaRows.findIndex((item) => item.id === record.id);
        if (currentIndex !== -1) {
          let currentInputTime = outputTime;
          for (let i = currentIndex + 1; i < updatedAgendaRows.length; i++) {
            const row = updatedAgendaRows[i];
            const rowDurationHours = (row.duration ?? 0) / 60;
            const [rowInputHours, rowInputMinutes] = currentInputTime.split(':').map(Number);
            let rowInputMinutesTotal = rowInputHours * 60 + rowInputMinutes;
            rowInputMinutesTotal = (rowInputMinutesTotal + rowDurationHours * 60) % 1440;
            const rowOutputHours = Math.floor(rowInputMinutesTotal / 60);
            const rowOutputMinutes = rowInputMinutesTotal % 60;
            const rowOutputTime = `${String(rowOutputHours).padStart(2, '0')}:${String(rowOutputMinutes).padStart(2, '0')}`;
            row.input = currentInputTime;
            row.output = rowOutputTime;
            currentInputTime = rowOutputTime;
          }
        }

        setAgendaRows(updatedAgendaRows); // Update the agendaRows state
        handleSave({
          ...record,
          ...values,
          output: outputTime, // Include "Output" in the saved data
        });
      } else {
        handleSave({
          ...record,
          ...values,
        });
      }
    } catch (errInfo) {
      handleFormErrors([errInfo]);
    }
  };

  let childNode = children[0];

  const inputNode =
    inputType === 'number' ? (
      <Input
        type="number"
        min={0}
        ref={inputRef}
        onPressEnter={save}
        onBlur={save}
        onMouseLeave={save}
      />
    ) : dataIndex === 'input' || dataIndex === 'output' ? (
      <Input
        aria-rowcount={2}
        ref={inputRef}
        onPressEnter={save}
        onBlur={save}
        style={{ width: '100px' }}
        onMouseLeave={save}
        onKeyPress={(e) => {
          const specialCharRegex = /[0-9:]/;
          const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);
          if (!specialCharRegex.test(pressedKey)) {
            e.preventDefault();
            return false;
          }
        }}
        onPaste={(e) => {
          e.preventDefault();
          return false;
        }}
      />
    ) : (
      <TextArea
        rows={2}
        ref={inputRef}
        onPressEnter={save}
        onBlur={save}
        onMouseLeave={save}
      />
    );

  childNode = editing ? (
    <Form.Item
      style={{
        margin: 0,
      }}
      name={dataIndex}
      rules={[
        {
          required: true,
          message: `${title} is required.`,
        },
      ]}>
      {editable && inputNode}
    </Form.Item>
  ) : (
    <div
      className="editable-cell-value-wrap"
      style={{
        paddingRight: 24,
      }}
      onKeyDown={({ key }) => key === 'Enter' && toggleEdit()}
      onClick={toggleEdit}>
      {children}
    </div>
  );

  return <td {...restProps}>{childNode}</td>;
};

const SortableItem = SortableElement(
  (props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>) => <tr {...props} />,
);

const SortableBody = SortableContainer(
  (props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>) => (
    <tbody {...props} />
  ),
);

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: 'grab',
      color: '#999',
    }}
  />
));

const AgendaModal: FC<{
  showModal: boolean;
  handleModalClose: () => void;
  saveDefaultAgenda: (
    values: UpsertAgendaRequest,
    closeModal: boolean,
    agendaComitieId: string,
    defaultAgendaId: string,
    sendMail: boolean,
    agendaSessionId?: string,
  ) => void;
  loadingSave: boolean;
  loadingSaveClose: boolean;
  committee: CommitteeDto;
  agendaSessionId?: string;
  loadingSendMail?: boolean;
  data?: GetCommitteeSessionsResponseDto;
  handleSession?: (values: InsertUpdateCommitteeSessionRequestDto) => void;
  setLastAgendaItem: Dispatch<SetStateAction<AgendaItemDto | undefined>>;
}> = ({
  showModal,
  handleModalClose,
  saveDefaultAgenda,
  loadingSave,
  loadingSaveClose,
  committee,
  agendaSessionId,
  loadingSendMail,
  data,
  handleSession,
  setLastAgendaItem,
}) => {
  const { t } = useTranslation();
  const activeXmatrixInfo = useAppSelector((state) => state.activeXMatrix.activeXMatrix);

  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [agenda, setAgenda] = useState<AgendaDto>();
  const [standardAgenda, setStandardAgenda] = useState<AgendaDto>();
  const [loadingAgenda, setLoadingAgendas] = useState(false);
  const [agendaRows, setAgendaRows] = useState<AgendaItemDto[]>([]);
  const [formErrors, setFormErrors] = useState<unknown[]>([]);

  const [agendaScope, setAgendaScope] = useState<string>();
  const [agendaFrequency, setAgendaFrequency] = useState<string>();
  const [agendaNotes, setAgendaNotes] = useState<string>();

  const [targetKeysProjects, setTargetKeysProjects] = useState<Key[]>([]);
  const [targetKeysRelatesProjects, setTargetKeysRelatesProjects] = useState<Key[]>([]);
  const [comitieRelatedProjects, setComitieRelatedProjects] = useState<ProjectDto[]>([]);
  const [subComitieRelatedProjects, setSubComitieRelatedProjects] = useState<ProjectDto[]>([]);
  const [totalDuration, setTotalDuration] = useState(0);

  const DraggableBodyRow = ({
    className,
    style,
    ...restProps
  }: {
    className: string;
    style: React.CSSProperties;
    'data-row-key': string;
  }) => {
    const [form] = Form.useForm();
    const index = agendaRows.findIndex((x) => x.id === restProps['data-row-key']);
    return (
      <Form<AgendaItemDto>
        form={form}
        component={false}>
        <EditableContext.Provider value={form}>
          <SortableItem
            index={index}
            {...restProps}
          />
        </EditableContext.Provider>
      </Form>
    );
  };

  useEffect(() => {
    void getAgendas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    void (async () => {
      try {
        const { data } = await AgendaService.listAvailableProjects(
          committee.id,
          !agendaSessionId,
          CommitieService.isOperational(committee.type) ? undefined : activeXmatrixInfo?.xMatrixID,
        );

        setComitieRelatedProjects(data.filter((p) => p.committee?.id === committee.id));
        setSubComitieRelatedProjects(data.filter((p) => p.committee?.id && p.committee?.id !== committee.id));
      } catch {}
    })();
  }, [activeXmatrixInfo?.xMatrixID, agenda, agendaSessionId, committee]);

  useEffect(() => {
    const newTotalDuration = agendaRows.reduce((total, row) => total + ((row.duration ?? 0) / 60 || 0), 0);
    setTotalDuration(newTotalDuration);
  }, [agendaRows]);

  const reArrangeRows = (newData: AgendaItemDto[]) => {
    if (!agendaSessionId || !data) {
      setAgendaRows(newData);

      return;
    }

    // Initialize currentInput with the start time of the session
    let currentInput = data.startTime.slice(0, 5);
    let outputTime: string;
    const updatedAgendaRows = newData.map((row, index): AgendaItemDto => {
      if (index !== 0) {
        currentInput = outputTime;
      }
      const durationMinutes = row.duration ?? 0;
      const durationHours = durationMinutes / 60;

      // Convert input time to minutes
      const [inputHours, inputMinutes] = currentInput.split(':').map(Number);
      const inputMinutesTotal = inputHours * 60 + inputMinutes;

      // Calculate output time in minutes
      let outputMinutesTotal = inputMinutesTotal + durationHours * 60;

      // Handle wrap-around to the next day
      if (outputMinutesTotal >= 1440) {
        outputMinutesTotal -= 1440; // Subtract 24 hours (1440 minutes)
      }

      // Convert output time back to HH:mm format
      const outputHours = Math.floor(outputMinutesTotal / 60);
      const outputMinutes = outputMinutesTotal % 60;
      outputTime = `${String(outputHours).padStart(2, '0')}:${String(outputMinutes).padStart(2, '0')}`;

      if (index === 0) {
        // For the first row, use the initial input time
        currentInput = data.startTime.slice(0, 5);
      }

      return {
        ...row,
        input: currentInput,
        output: outputTime,
      };
    });

    setAgendaRows(updatedAgendaRows);
    setFieldsChanged(true);
  };

  const DraggableContainer = (props: SortableContainerProps) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
          const newData = arrayMoveImmutable(agendaRows.slice(), oldIndex, newIndex).filter((el) => !!el);
          reArrangeRows(newData);

          setFieldsChanged(true);
        }
      }}
      {...props}
    />
  );

  const defaultColumns: (ColumnType<AgendaItemDto> & { editable?: boolean })[] = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'ID',
      dataIndex: 'orderString',
      editable: false,
      width: '10px',
      className: 'drag-visible',
      render: (_, _2, index) => {
        return index + 1;
      },
    },
    {
      title: `${t('comitatiPage.agendaPoints')}`,
      dataIndex: 'description',
      editable: true,
    },
    {
      title: `${t('comitatiPage.freq')}`,
      dataIndex: 'frequency',
      editable: true,
    },
    {
      title: `${t('comitatiPage.min')}`,
      dataIndex: 'duration',
      editable: true,
    },
    {
      title: `${t('comitatiPage.in')}`,
      dataIndex: 'input',
      editable: true,
    },
    {
      title: `${t('comitatiPage.out')}`,
      dataIndex: 'output',
      editable: true,
    },
    {
      title: `${t('comitatiPage.accountable')}`,
      dataIndex: 'accountable',
      editable: true,
      width: '170px',
    },
    {
      title: '',
      dataIndex: 'operation',
      render: (_, record) =>
        agendaRows.length ? (
          <Button
            type="text"
            size="small"
            onClick={() => {
              const newData = agendaRows.filter((item) => item.id !== record.id);
              reArrangeRows(newData);
              setFieldsChanged(true);
            }}
            icon={<DeleteOutlined />}
          />
        ) : null,
    },
  ];

  const handleAdd = () => {
    const input =
      agendaRows.length === 0 ? data?.startTime?.slice(0, 5) : (agendaRows[agendaRows.length - 1].output as string);

    setAgendaRows([
      ...agendaRows,
      {
        id: generateNewGuid(),
        agendaHeaderId: agenda?.id as string,
        orderString: `${agendaRows.length + 1}`,
        description: '',
        frequency: '',
        duration: null,
        input,
        output: '',
        accountable: '',
      },
    ]);

    setFieldsChanged(true);
  };

  const handleSave = (row: AgendaItemDto) => {
    const newData = [...agendaRows];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setAgendaRows(newData);
    setFieldsChanged(true);
  };

  const components = {
    body: {
      wrapper: DraggableContainer,
      row: DraggableBodyRow,
      cell: EditableCell,
    },
  };

  const agendaRowColumns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    const a: ColumnType<AgendaItemDto> = {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title as string,
        inputType: col.dataIndex === 'duration' ? 'number' : 'text',
        handleSave,
        agendaRows,
        setAgendaRows,
        handleFieldChange: () => false,
        handleFormErrors: setFormErrors,
      }),
    };

    return a;
  });

  const getAgendas = async () => {
    setLoadingAgendas(true);

    try {
      const standardAgendaDto = await AgendaService.getStandardAgenda(committee.id);
      setStandardAgenda(standardAgendaDto);

      const agendaDto = agendaSessionId
        ? await AgendaService.getSessionAgenda(committee.id, agendaSessionId)
        : standardAgendaDto;

      setAgenda(agendaDto ?? {});
      reArrangeRows(agendaDto?.agendaItems ?? []);
      setAgendaScope(agendaDto?.scope ?? undefined);
      setAgendaFrequency(agendaDto?.frequency ?? undefined);
      setAgendaNotes(agendaDto?.notes ?? undefined);

      setTargetKeysProjects(agendaDto?.committeeProjectIds ?? []);
      setTargetKeysRelatesProjects(agendaDto?.subCommitteeProjectIds ?? []);
    } catch (error) {
      console.error(error);
    }

    setLoadingAgendas(false);
  };

  const saveAgendaData = (sendMail: boolean, closeModal: boolean) => {
    const selectedAgendaProjects = targetKeysProjects.concat(targetKeysRelatesProjects);
    const agendaItemsType = agendaRows.map((item, index) => {
      return {
        id: item.id,
        accountable: item.accountable,
        agendaHeaderId: item.agendaHeaderId,
        description: item.description,
        duration: dayjs(item.output, 'HH:mm').diff(dayjs(item.input, 'HH:mm'), 'minutes'),
        frequency: item.frequency,
        input: item.input,
        output: item.output,
        orderString: (index + 1).toString(),
      };
    });

    const values: UpsertAgendaRequest = {
      agendaID: agenda?.id,
      committeeID: committee.id,
      duration: agendaRows.reduce((total, row) => total + (row.duration || 0), 0),
      frequency: agendaFrequency,
      notes: BraftEditor.createEditorState(agendaNotes).toHTML(),
      projects: selectedAgendaProjects.map((k) => `${k}`),
      scope: agendaScope,
      sessionID: agendaSessionId,
      stateID: 1,
      agendaItemsType,
    };

    if (!validateAgendaFields(values)) {
      notification.error({
        message: `${t('general.validationError')}`,
        description: `${t('general.requiredField')}`,
      });

      return;
    }

    if (agenda && agendaSessionId && data) {
      if (!handleSession) {
        throw new Error('handleSession parameter is mandatory when agenda is not default');
      }

      handleSession({
        committeeID: committee.id,
        date: data.date.split('T')[0],
        duration: agendaItemsType.reduce((total, row) => total + (row.duration || 0), 0),
        endTime: agendaItemsType.slice(-1)[0]?.output ?? data.startTime.slice(0, 5),
        startTime: agendaItemsType[0]?.input ?? data.startTime.slice(0, 5),
        state: data.stateID,
        codice: data.codice,
        sessionID: agendaSessionId,
      });

      const lastAgendaItem = agendaRows.slice(-1)[0] ?? standardAgenda?.agendaItems?.[0];
      setLastAgendaItem(lastAgendaItem);
    }

    agenda && saveDefaultAgenda(values, closeModal, committee.id, agenda.id, sendMail, agendaSessionId);
  };

  const validateAgendaFields = (values: UpsertAgendaRequest) => {
    if (agendaSessionId && !values.frequency) {
      return false;
    }

    return values.scope && values.duration !== null;
  };

  function formatDuration(totalMinutes: number) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.round(totalMinutes % 60);

    if (hours === 0) {
      return `${minutes} min`;
    }

    if (minutes === 0) {
      return `${hours} h`;
    }

    return `${hours} h ${minutes} min`;
  }

  const ModalHeader = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Space direction="horizontal">
          <Title level={4}>{`${t('comitatiPage.defaultAgendda')}: ${committee.name}`}</Title>
        </Space>

        <Space direction="horizontal">
          <Button
            loading={loadingSave}
            icon={<SaveOutlined />}
            type="primary"
            key="submit"
            disabled={!fieldsChanged || !!formErrors.length}
            onClick={() => saveAgendaData(false, false)}>
            {t('buttons.salva')}
          </Button>

          <Button
            loading={loadingSaveClose}
            type="primary"
            key="submitClose"
            disabled={!fieldsChanged || !!formErrors.length}
            onClick={() => saveAgendaData(false, true)}>
            {t('buttons.salvaChiudi')}
          </Button>

          <Button
            onClick={handleModalClose}
            key="close">
            {t('buttons.annulla')}
          </Button>
        </Space>
      </div>
    );
  };

  return (
    <>
      <div className="edit-commitie-wrapper">
        <Modal
          width={'90%'}
          title={<ModalHeader />}
          open={showModal}
          destroyOnClose={true}
          maskClosable={false}
          onCancel={handleModalClose}
          closable={false}
          keyboard={false}
          footer={[
            <div
              className={!agendaSessionId ? '' : 'spaceBetween'}
              key={'1'}>
              {agendaSessionId && (
                <Button
                  loading={loadingSendMail}
                  icon={<SaveOutlined />}
                  type="primary"
                  key="submitEmail"
                  disabled={!!formErrors.length}
                  onClick={() => saveAgendaData(true, true)}>
                  {t('comitatiPage.saveAndSendEmail')}
                </Button>
              )}

              <Space direction="horizontal">
                <Button
                  loading={loadingSave}
                  icon={<SaveOutlined />}
                  type="primary"
                  key="submit"
                  disabled={!fieldsChanged || !!formErrors.length}
                  onClick={() => saveAgendaData(false, false)}>
                  {t('buttons.salva')}
                </Button>

                <Button
                  loading={loadingSaveClose}
                  type="primary"
                  key="submitClose"
                  disabled={!fieldsChanged || !!formErrors.length}
                  onClick={() => saveAgendaData(false, true)}>
                  {t('buttons.salvaChiudi')}
                </Button>

                <Button
                  onClick={handleModalClose}
                  key="close">
                  {t('buttons.annulla')}
                </Button>
              </Space>
            </div>,
          ]}>
          <Card>
            {!agenda && <Loader2 />}

            {agenda && agendaRows && (
              <>
                <Row
                  gutter={{ lg: 24 }}
                  className="row-margin">
                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 18 }}
                    md={{ span: 18 }}
                    lg={{ span: 18 }}>
                    <Card
                      className="agendaCards"
                      title={`${t('comitatiPage.scopo')} *`}>
                      <TextArea
                        rows={4}
                        autoSize={true}
                        value={agendaScope}
                        onChange={(e) => {
                          setAgendaScope(e.target.value);
                          setFieldsChanged(true);
                        }}
                      />
                    </Card>
                  </Col>

                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 6 }}
                    md={{ span: 6 }}
                    lg={{ span: 6 }}>
                    <Card
                      className="agendaCards"
                      title={`${t('comitatiPage.tempi')} & ${t('comitatiPage.durataAgenda')}`}>
                      <Space
                        direction="vertical"
                        style={{ width: '100%' }}>
                        <Input
                          onChange={(e) => {
                            setAgendaFrequency(e.target.value);
                            setFieldsChanged(true);
                          }}
                          value={agendaFrequency}
                          addonBefore={agendaSessionId ? `${t('comitatiPage.tempi')} *` : t('comitatiPage.tempi')}
                        />
                        <p>{`${t('comitatiPage.durataAgenda')}: ${formatDuration(totalDuration * 60)}`}</p>
                      </Space>
                    </Card>
                  </Col>

                  <Divider />
                </Row>

                <Row
                  gutter={{ lg: 24 }}
                  className="row-margin">
                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}>
                    <Button
                      onClick={handleAdd}
                      type="primary"
                      style={{ marginBottom: 16 }}>
                      {t('comitatiPage.addRow')}
                    </Button>
                  </Col>

                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}>
                    <Table<AgendaItemDto>
                      className="agendaTable"
                      components={components}
                      rowClassName={() => 'editable-row'}
                      bordered
                      size="small"
                      dataSource={agendaRows}
                      columns={agendaRowColumns}
                      rowKey={(obj) => obj.id}
                      loading={loadingAgenda}
                      pagination={false}
                    />
                  </Col>

                  <Divider />
                </Row>

                <Row
                  gutter={{ lg: 24 }}
                  className="activities-filters">
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                    className="collapse-comitie-activites">
                    <Collapse expandIconPosition={'start'}>
                      <Panel
                        header={t('general.commenti')}
                        key="1">
                        <Row gutter={{ lg: 24 }}>
                          <Col
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 24 }}
                            lg={{ span: 42 }}>
                            <BraftEditor
                              style={{ height: 350, overflowX: 'auto' }}
                              // contentFormat="html"
                              language="en"
                              id="editor-text"
                              controls={RichTextEditorControls}
                              value={BraftEditor.createEditorState(agendaNotes)}
                              onChange={(editorState) => {
                                setAgendaNotes(editorState);
                                setFieldsChanged(true);
                              }}
                            />
                          </Col>
                        </Row>
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>

                <Row
                  gutter={{ lg: 24 }}
                  className="row-margin agendaTransfer">
                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}>
                    <Divider plain>{t('comitatiPage.comitieProjects')}</Divider>
                    <Transfer<ProjectDto>
                      targetKeys={targetKeysProjects}
                      rowKey={(project) => project.id}
                      showSelectAll={false}
                      dataSource={comitieRelatedProjects}
                      titles={[`${t('general.progetti')}`, `${t('general.selectedProjects')}`]}
                      showSearch
                      filterOption={(inputValue, project) =>
                        (project.name ?? '').toLowerCase().indexOf(inputValue.trim().toLowerCase()) > -1
                      }
                      onChange={(newTargetKeys) => {
                        setTargetKeysProjects(newTargetKeys);
                        setFieldsChanged(true);
                      }}
                      render={(item) => `(${item.code}) ${item.name}`}
                      locale={{
                        itemUnit: ' ',
                        notFoundContent: `${t('general.elencoVuoto')}`,
                        searchPlaceholder: `${t('buttons.cerca')}`,
                      }}
                    />
                  </Col>

                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}>
                    <Divider plain>{t('comitatiPage.relatedComitieProjects')}</Divider>
                    <Transfer
                      targetKeys={targetKeysRelatesProjects}
                      rowKey={(project) => project.id}
                      showSelectAll={false}
                      dataSource={subComitieRelatedProjects}
                      showSearch
                      titles={[`${t('comitatiPage.relatedComitieProjects')}`, `${t('general.selectedProjects')}`]}
                      filterOption={(inputValue, project) =>
                        (project.name ?? '').toLowerCase().includes(inputValue.trim().toLowerCase())
                      }
                      onChange={(newTargetKeys) => {
                        setTargetKeysRelatesProjects(newTargetKeys);
                        setFieldsChanged(true);
                      }}
                      render={(project) => {
                        return (
                          <Text>
                            <b>{project.committee?.name}</b> - ({project.code}) {project.name}
                          </Text>
                        );
                      }}
                      locale={{
                        itemUnit: ' ',
                        notFoundContent: `${t('general.elencoVuoto')}`,
                        searchPlaceholder: `${t('buttons.cerca')}`,
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Card>
        </Modal>
      </div>
    </>
  );
};

export default AgendaModal;
